import React, { useState } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import { TestSpecialChars } from '../Generic/Constants.js';
import FormTextInput from '../Generic/FormTextInput';
import FormDateInput from '../Generic/FormDateInput';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();
const specialCharsErrText = 'no puede contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const ProjectModalAdd = ({ closeModal }) => {
  
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    code: '',
    title: '',
    client: '',
    address: '',
    contact: '',
    receiver: '',
    location: '',
    owner: '',
    builder: '',
    sampledBy: '',
    sampleLocation: '',
    quoteNumber: '',
    priority: 5,
    deliveryDate: '',
    addedAt: dayjs().format('YYYY-MM-DD'),
    status: 'activo'
  });

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const FindErrs = () => {
    let errsFound = [];
    if (!form.code) {
      errsFound.push('El código del proyecto es requerido');
    }
    if (form.code && TestSpecialChars(form.code)) {
      errsFound.push('El código del proyecto ' + specialCharsErrText);
    }
    if (form.code && !(/^[a-zA-Z0-9].*[a-zA-Z0-9]$/i.test(form.code))) {
      errsFound.push('El código del proyecto debe comenzar y terminar con números o letras.');
    }
    if (errsFound.length) {
      let errsMsg = 
        <>
          <span>Se encontraron los siguientes errores:</span>
          {errsFound.map((item, itemIdx) => ( <span key={itemIdx} className="mt-1">{item}</span> ))}
        </>
        alert.show(errsMsg, {type: 'error', timeout: 15000});
      return true;
    } else {
      return false;
    }
  }

  const SaveNewRecord = () => {
    if (!FindErrs()) {

      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };

      let url = '/api/projects/add';
      Axios.post(url, form, opts)
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nuevo Proyecto'} closeModal={closeModal} />
        <div className="modal-form-container">
          <FormTextInput title="Código Interno" isRequired={true} tooltip={specialCharsErrText} stateKey={'code'} value={form.code} handleChange={HandleChange} />
          <FormTextInput title="Proyecto/Obra" isRequired={false} stateKey={'title'} value={form.title} handleChange={HandleChange} />
          <FormTextInput title="Cliente" isRequired={false} stateKey={'client'} value={form.client} handleChange={HandleChange} />
          <FormTextInput title="Dirección" isRequired={false} stateKey={'address'} value={form.address} handleChange={HandleChange} />
          <FormTextInput title="Contacto" isRequired={false} stateKey={'contact'} value={form.contact} handleChange={HandleChange} />
          <FormTextInput title="Destinatario" isRequired={false} stateKey={'receiver'} value={form.receiver} handleChange={HandleChange} />
          <FormTextInput title="Ubicación/Ciudad" isRequired={false} stateKey={'location'} value={form.location} handleChange={HandleChange} />
          <FormTextInput title="Propietario" isRequired={false} stateKey={'owner'} value={form.owner} handleChange={HandleChange} />
          <FormTextInput title="Constructor" isRequired={false} stateKey={'builder'} value={form.builder} handleChange={HandleChange} />
          <FormTextInput title="Lugar de Muestreo" isRequired={false} stateKey={'sampleLocation'} value={form.sampleLocation} handleChange={HandleChange} />
          <FormTextInput title="Número Cotización" isRequired={false} stateKey={'quoteNumber'} value={form.quoteNumber} handleChange={HandleChange} />
          <FormDateInput title="Fecha Ingreso" isRequired={false} stateKey={'addedAt'} value={form.addedAt} handleChange={HandleChange} />
          <FormDateInput title="Fecha Entrega" isRequired={false} stateKey={'deliveryDate'} value={form.deliveryDate} handleChange={HandleChange} />
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default ProjectModalAdd;
