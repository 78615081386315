import React from 'react';
import { Outlet } from "react-router";
import Header from "./Header";
import TopNavBar from "./TopNavBar";
import Footer from "./Footer";

function PageLayout(props) {
  return (
    <>
      <Header />
      { props.navBar ? <TopNavBar /> : null }
      <Outlet />
      <Footer />
    </>
  );
}

export default PageLayout;