import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalSections } from '../../Layout/ModalElements';
import LoadingModal from '../../Layout/LoadingModal.js';
import FileDownload from 'js-file-download';
import dayjs from 'dayjs';
import { MdDownload } from 'react-icons/md';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';
import ClasifRevision from './ClasifRevision';
import ProctorRevision from './ProctorRevision';
import CbrRevision from './CbrRevision';
import DensRevision from './DensRevision';
import CdRevision from './CdRevision';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const viewSections = ['Clasificación', 'Proctor', 'CBR', 'Dens. Terreno', 'Corte Directo'];

const RevisionModal = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [projectData, setProjectData] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');

  const isFirstRender = useRef(true);

  const GetProjectData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/projects/codes';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      if (res.data.length) {
        let projectDataHelper = [];
        res.data.forEach(p => {
          projectDataHelper.push({ value: p.code, label: p.code })
        })
        setProjectData(projectDataHelper);
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const DownloadFile = () => {
    if (selectedProject) {
      setLoading(true);
  
      const opts = {
        headers: {
          'Content-Disposition': 'attachment'
        },
        responseType: 'blob',
      };
  
      const url = '/api/revisionHelpers/downloads/'+selectedProject;
  
      Axios.get(url, opts)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          FileDownload(res.data, 'MSTD RESUMEN DE RESULTADOS - ' + selectedProject + ' ' + dayjs().format('DD-MM-YYYY') + '.xlsx');
        } else {
          alert.show('No se encontró un archivo para descargar', {type: 'error'})
        }
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else if (err.response.status === 420) {
          alert.show('Estamos trabajando para usted', {type: 'info'})
        } else if (err.response.status === 404) {
          alert.show('No se pudo descargar el archivo', {type: 'error'})
        } else if (err.response.status === 400) {
          alert.show('No se encontró el archivo', {type: 'error'})
        } else {
          alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
        }
      });
    } else {
      alert.show('Seleccione un proyecto', {type: 'info'})
    }
  }

  const ChangeLoadingState = useCallback(value => { setLoading(value) }, [])

  useEffect(() => {
    if(isFirstRender.current){
      GetProjectData();
    }
  }, [GetProjectData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Revisar resultados para un proyecto'} closeModal={closeModal} closeKb={!loading} />
        <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
        <div className="d-flex align-items-center my-2">
          <div>Seleccionar Proyecto</div>
          <Button className="d-flex ms-2 p-1 rounded-circle" variant="secondary" title="Descargar resultados del proyecto" onClick={() => DownloadFile()}>
            <MdDownload color="white" size={18} className="icon-style"/>
          </Button>
        </div>
        <ReactSelect 
          placeholder={'Código Proyecto'} 
          noOptionsMessage={({ inputValue: string }) => 'No hay resultados'} 
          defaultValue={selectedProject} 
          onChange={(e) => setSelectedProject(e.value)}
          className="modal-form-input"
          options={projectData} 
        />
        {activeSection === 0 ? <ClasifRevision project={selectedProject} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 1 ? <ProctorRevision project={selectedProject} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 2 ? <CbrRevision project={selectedProject} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 3 ? <DensRevision project={selectedProject} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 4 ? <CdRevision project={selectedProject} setLoading={ChangeLoadingState} /> : null}
      </div>
    </div>
  )
}

export default RevisionModal