import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAuth } from "../../Context/Auth";
import LoadingModal from '../../Layout/LoadingModal.js';
import ModalTableTextInput from '../../Generic/ModalTableTextInput.js';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = ['Nombre', 'Sistema de levante de martillo', 'Método de avance y limpieza', 'Tipo de muestreador', 'Método mantenimiento perforación', 
'Revestimiento', 'Profundidad revestida', 'Barras SPT', 'Editado', 'Por', 'Eliminar'];
const eqInfoKeys = ['field1', 'field2', 'field3', 'field4', 'field5', 'field6', 'field7'];

const FWAssetsProbingEquipment = () => {

  const alert = useAlert();
  const { session } = useAuth();

  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState(null);
  const [data, setData] = useState(null);

  const isFirstRender = useRef(true);
  const scrollEndRef = useRef();

  useEffect(() => {
    scrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [data?.length]);

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fwExtras/probingEquipment';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(JSON.parse(JSON.stringify(res.data.data)));
      setOldData(JSON.parse(JSON.stringify(res.data.data)));
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert])

  const HandleChange = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      newData[index].lastModified = Date.now();
      newData[index].lastModifiedUser = session.username;
      setData(newData);
    }
  }

  const AddRecord = () => {
    const newRecord = {
      name: '',
      lastModified: Date.now(), 
      lastModifiedUser: session.username, 
      status: 'activo'
    };
    let newState = [...data];
    newState.push(newRecord);
    setData(newState);
  }

  const RemoveRecord = (idx) => {
    let newState = [...data];
    newState = data.filter((item, itemIdx) => itemIdx !== idx);
    setData(newState);
  }

  const SaveDataStepOne = () => {
    if (data) {
      let foundErrs = [];
      for (let i = 0; i < data.length; i++) {
        if (!data[i].name) {
          foundErrs.push('Fila ' + (i+1) + ': El nombre del equipo es requerido');
        }
        if (data.some((item, itemIdx) => ((item.name === data[i].name) && (itemIdx !== i)))) {
          foundErrs.push('Fila ' + (i+1) + ': El nombre ' + data[i].name + ' ya existe');
        }
      }
  
      if (foundErrs.length) {
        let errsMsg =
        <>
          <span className="mb-2">Se encontraron los siguientes errores: </span>
          { foundErrs.map((item, itemIdx) => <span key={itemIdx}>{item}</span> )}
        </>
        alert.show(errsMsg, {type: 'info'})
      } else {
        SaveDataStepTwo(data)
      }
    } else {
      alert.show('No se encontraron modificaciones', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está modificando información del equipo de sondaje</h4>
            <p>Confirme para continuar </p>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr) }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fwExtras/saveData';

    Axios.post(url, { code: 'probingEquipment', data: saveArr }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 20000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  const RenderData = () => {
    return (
      <>
        {
          data.map((item, itemIdx) => {
            return (
              <div key={itemIdx} className="modal-table-row-input-container">
                <ModalTableTextInput stateIdx={itemIdx} stateKey={'name'} value={item.name} handleChange={HandleChange} /> 
                { eqInfoKeys.map((infoItem, infoItemIdx) => 
                  <ModalTableTextInput key={infoItemIdx} stateIdx={itemIdx} stateKey={infoItem} value={item[infoItem]} handleChange={HandleChange} /> 
                )}
                <div className="modal-table-row-container">
                  <span className="modal-table-title" title={item.lastModified ? dayjs(item.lastModified).format('LLLL') : '-'}>
                    {item.lastModified ? (dayjs().to(dayjs(item.lastModified))) : '-'}
                  </span>
                </div>
                <div className="modal-table-row-container">
                  <span className="modal-table-title"> {item.lastModifiedUser || '-'} </span>
                </div>
                <div className="modal-table-row-container">
                  <Button className="d-flex p-1 rounded-circle bg-lb-white" disabled={loading} title="Eliminar" onClick={() => RemoveRecord(itemIdx)}>
                    <FaTrash color="white" className="icon-style"/>
                  </Button>
                </div>
              </div>
            )
          })
        }
      </>
    )
  }

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="modal-table-outer-container flex-1">
        <div className="modal-table-row-inner-container">
          <div className="modal-table-sticky-header-container">
            <div className="modal-table-sticky-header">
              { rowTitles.map((item, itemIdx) => <div key={itemIdx} className="modal-table-row-container"> {item} </div> )}
            </div>
          </div>
          { data?.length ? RenderData() : <span className="modal-table-loading-text"> No hay información para mostrar </span> }
          <div ref={scrollEndRef}></div>
        </div>
        <div className="d-flex justify-content-center align-items-center p-1">
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" disabled={loading} title="Nuevo Registro" onClick={() => AddRecord()}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
        </div>
      </div>
      <div className="mt-1">
        <Button className="lb-white fs-7" disabled={loading || !data} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default FWAssetsProbingEquipment