import React, { useState, useEffect, useRef } from 'react';
// import Axios from 'axios';
import { useAlert } from 'react-alert';
import LoadingModal from '../Layout/LoadingModal.js';

const ProjectResults = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Resultados";
  }, []);

  const isFirstRender = useRef(true);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      <div>Estamos trabajando para usted</div>
    </div>
  )
}

export default ProjectResults