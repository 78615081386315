import React, { useEffect, useState, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import LoadingModal from '../Layout/LoadingModal.js';
import { TestSpecialChars } from '../Generic/Constants.js';
import FileDownload from 'js-file-download';
import FormTextInput from '../Generic/FormTextInput';
import { FiHelpCircle } from 'react-icons/fi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();
const specialCharsErrText = 'Los campos ID Muestra, Calicata o Sondaje, Horizonte, Profundidad, N° Informe, ' +
'no pueden contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const BatchAdd = (props) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [multiResults, setMultiResults] = useState([]);
  const [sample, setSamples] = useState({
    soilType: '',
    item: '',
    layerThickness: '',
    repArea: '',
    samplePlan: 'MSTD-PP-110',
    sampleProcess: 'MSTD-PP-100',
  });

  const checkMultiErrsRef = useRef(false);

  const HandleChange = (element, value) => {
    let newState = {...sample};
    newState[element] = value;
    setSamples(newState);
  }

  const ReadMultiSample = (file) => {
    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('filename', file);

      // Add new sample with reports to be made or added to
      let url = '/api/samples/batch/read';
      Axios.post(url, formData, {cancelToken: source.token})
      .then((res) => {
        setLoading(false);
        checkMultiErrsRef.current = true;
        if (res.data.errors.length) {
          let batchAddErrs = 
          <>
            <span>{'Errores del documento: '}</span>
            {res.data.errors.map((element, index) => {
              return <span key={index} className="mt-1">{element}</span>
            })}
          </>
          alert.show(batchAddErrs, {type: 'error', timeout: 0});
        }
        setMultiResults(res.data.data);
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }else{
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  const ProjectBgColor = (project) => {
    if (!project || (!props.projectData.some(p => p.code === project))) {
      return { backgroundColor: 'crimson', color: 'white' } 
    } else {
      return { backgroundColor: 'ghostwhite' }
    }
  }

  const SampleBgColor = (sampleId, idx) => {
    if ( !sampleId ) {
      return { backgroundColor: 'crimson' }
    } else if ((props.sampleIds.some(s => s.sampleId === sampleId)) || (multiResults.some((s, sIdx) => (idx !== sIdx && s.sampleId === sampleId)))) {
      // Sample exists either on document or registry.
      return { backgroundColor: '#ffc769' }
    } else if (TestSpecialChars(sampleId)) {
      return { backgroundColor: '#feff49' } 
    } else {
      return { backgroundColor: 'ghostwhite' }
    }
  }

  const ReportBgColor = (reportNumber, form, sampleIdx, reportIdx) => {
    if ((!reportNumber || !form) || (form && form.includes('NO ENCONTRADO'))) {
      return { backgroundColor: 'crimson', color: 'white' } 
    } else if (props.reportNumbers.indexOf(Number(reportNumber)) !== -1) {
      return { backgroundColor: '#ffc769' } 
    } else if ((form && reportNumber) && multiResults.some((s, sIdx) => ((sIdx !== sampleIdx) && s.reports.some(r => (r.reportNumber === reportNumber && r.form !== form))))) {
      // Report exists in document with same reportNumber but diff Form
      return { backgroundColor: '#feff49' } 
    } else if (multiResults[sampleIdx].reports.some((r, rIdx) => ((rIdx !== reportIdx) && (r.reportNumber === reportNumber || r.form === form)))) {
      // Report exists in the same Sample with same reportNumber or Form
      return { backgroundColor: '#feff49' } 
    } else if (TestSpecialChars(String(reportNumber))) {
      return { backgroundColor: '#feff49' } 
    } else {
      return { backgroundColor: 'ghostwhite' }
    }
  }

  const RenderMultiResults = () => {
    if (multiResults.length) {
      const headerTexts = ['ID Muestra', 'Código Proyecto', 'Ensayos', 'Fecha Muestreo', 'Fecha Ingreso', 'N° Boleta', 
      'Muestreado por', 'Fecha de Control', 'Calicata o Sondaje', 'Horizonte', 'Profundidad'];
      return (
        <div className="d-flex flex-column mt-2 mb-1 p-1 border border-primary fs-7">
          <div className="d-flex mt-1 ms-2 py-1">
            <b> Resultados </b>
            <OverlayTrigger placement="right" delay={{ show: 100, hide: 250 }} overlay={<Tooltip id="button-tooltip"> {specialCharsErrText} </Tooltip>} > 
              {({ ref, ...triggerHandler }) => <div className="ms-1 cursor-pointer" {...triggerHandler} ref={ref}><FiHelpCircle color="blue" size={16} className="icon-style"/></div>}
            </OverlayTrigger> 
          </div>
          <div className="d-flex ms-2 my-1 align-items-center"> 
            <span className="me-2"> Leyenda:  </span>
            <span className="me-2 p-1 bg-orange"> YA EXISTE  </span>
            <span className="me-2 p-1 bg-yellow"> CONFLICTO  </span>
            <span className="p-1 bg-danger color-white"> NO EXISTE  </span>  
          </div>
          <div className="d-grid overflow-auto" style={{ maxHeight: '40vh' }} >
            <div className="d-flex text-center position-sticky top-0 bg-gw py-2 zIndex-1">
              <div className="px-1" style={{ minWidth: '30px' }}> # </div>
              {headerTexts.map((header, hIdx) => <div key={hIdx} className="px-1" style={(hIdx !== 2 ? { minWidth: '125px', flex: 1.5 } : { minWidth: '175px', flex: 1.75 })}> {header} </div>)}
            </div>
            { multiResults.map((samp, sampIdx) => (
              <React.Fragment key={sampIdx}>
                {sampIdx !== 0 ? <hr/> : null}
                <div className="d-flex align-items-center text-center py-1">
                  <div className="px-1 ellipsis-text" style={{ minWidth: '30px' }} title={('#' + (sampIdx+1))}> 
                    <span> {(sampIdx+1)} </span>
                  </div> 
                  <div className="px-1 ellipsis-text" style={{ minWidth: '125px', flex: 1.5 }} title={samp.sampleId || '-'} > 
                    <span className="px-1" style={(SampleBgColor(samp.sampleId, sampIdx))}> {samp.sampleId || '-'} </span>
                  </div> 
                  <div className="px-1 ellipsis-text" style={{ minWidth: '125px', flex: 1.5 }} title={samp.project || '-'} > 
                    <span className="px-1" style={(ProjectBgColor(samp.project))}> {samp.project || '-'} </span>
                  </div> 
                  <div className="d-flex flex-column px-1 ellipsis-text" style={{ minWidth: '175px', flex: 1.75 }}> 
                    {samp.reports.length ? samp.reports.map((rep, repIdx) => 
                      <div key={repIdx} className="text-start"> 
                        <span key={repIdx} className="px-1" style={(ReportBgColor(rep.reportNumber, rep.form, sampIdx, repIdx))} title={((rep.reportNumber || '-') + ' - ' + (rep.form || '-'))} >
                          <b>{rep.reportNumber || '-'}</b> - {rep.form || '-'}
                        </span>
                      </div>
                    ) : <span> - </span>}
                  </div> 
                  {[samp.sampleDate, samp.entryDate, samp.billNumber, samp.sampledBy, samp.controlDate, samp.trialPitNumber, samp.horizon, samp.depth].map((item, itemIdx) => 
                  <div key={itemIdx} className="px-1 ellipsis-text" style={{ minWidth: '125px', flex: 1.5}}>
                    <span title={item}> {item} </span>
                  </div> )}
                </div>
              </React.Fragment>
            ))}
          </div>
        </div>
      )
    } else {
      return (
        <div className="d-flex ms-2 my-1">
          <i>Suba un archivo con la información de las muestras.</i>
          <OverlayTrigger placement="right" delay={{ show: 100, hide: 250 }} overlay={<Tooltip id="button-tooltip"> {specialCharsErrText} </Tooltip>} > 
            {({ ref, ...triggerHandler }) => <div className="ms-1 cursor-pointer" {...triggerHandler} ref={ref}><FiHelpCircle color="blue" size={16} className="icon-style"/></div>}
          </OverlayTrigger> 
        </div>
      )
    }
  }

  const FindMultiErrs = useCallback(() => {
    let samplesErr = [];
    if (multiResults.length) {
      for (let i = 0; i < multiResults.length; i++) {
        if (!multiResults[i].sampleId || !multiResults[i].project) {
          samplesErr.push({ msg: 'Se encontró un registro sin ID o Proyecto.', pos: i });
        }  
        if (props.sampleIds.some(s => s.sampleId === multiResults[i].sampleId)) {
          samplesErr.push({ msg: 'La Muestra especificada ya existe en los registros.', pos: i });
        }  
        if (!props.projectData.some(p => p.code === multiResults[i].project)) {
          samplesErr.push({ msg: 'No se encontró el Proyecto asociado a la muestra.', pos: i });
        }
        if (multiResults.some(s => s.sampleId === multiResults[i].sampleId && s.project !== multiResults[i].project)) {
          samplesErr.push({ msg: 'Se encontró un proyecto distinto para la misma muestra.', pos: i });
        }  
        if (multiResults[i].reports.some(r => !r.reportNumber || !r.form)) {
          samplesErr.push({ msg: 'Se encontró un ensayo sin Número de Informe o Ensayo.', pos: i });
        }
        if (multiResults[i].reports.some(r => (r.form && r.form.includes('NO ENCONTRADO')))) {
          samplesErr.push({ msg: 'No se encontró el ensayo especificado.', pos: i });
        }
        if (multiResults[i].reports.some(r => (props.reportNumbers.indexOf(Number(r.reportNumber)) !== -1))) {
          samplesErr.push({ msg: 'El N° de Informe especificado ya existe en los registros.', pos: i });
        }
        if (multiResults.some((s, sIdx) => (sIdx !== i && s.sampleId === multiResults[i].sampleId))) {
          samplesErr.push({ msg: 'El ID de Muestra especificado ya existe en el documento.', pos: i });
        }
        if (multiResults[i].reports.some((r, rIdx) => multiResults[i].reports.some((r2, rIdx2) => 
        ((rIdx !== rIdx2) && (((r.form && r2.form) && (r.form === r2.form)) || ((r.reportNumber && r2.reportNumber) && (r.reportNumber === r2.reportNumber))))))) {
          samplesErr.push({ msg: 'El Número de Informe o el Ensayo especificado ya existe para esta Muestra en el documento.', pos: i });
        }
        if (multiResults[i].reports.some(r => (multiResults.some(s => (s.sampleId !== multiResults[i].sampleId && 
        s.reports.some(r2 => ((r.reportNumber && r2.reportNumber && r.form && r2.form) && (r.reportNumber === r2.reportNumber && r.form !== r2.form)))))))) {
          samplesErr.push({ msg: 'Se encontró un Número de Informe que ya existe con otro Ensayo en el documento.', pos: i });
        }
        if (TestSpecialChars(multiResults[i].sampleId) || TestSpecialChars(multiResults[i].trialPitNumber) || TestSpecialChars(multiResults[i].horizon) || TestSpecialChars(multiResults[i].depth)) {
          samplesErr.push({ msg: specialCharsErrText, pos: i });
        }
        if (multiResults[i].reports.some(r => TestSpecialChars(String(r.reportNumber)))) {
          samplesErr.push({ msg: specialCharsErrText, pos: i });
        }
      }
    } else {
      alert.show('No se encontraron muestras para guardar', {type: 'info', timeout: 20000});
      return true;
    }
    if (samplesErr.length) {
      let sampleMsg = 
        <>
          <span>{'Se encontraron los siguientes errores: '}</span>
          {samplesErr.map((element, index) => {
            return <span key={index} className="mt-1">{'Fila ' + (element.pos+1) + ': ' + element.msg}</span>
          })}
        </>
      alert.show(sampleMsg, {type: 'info', timeout: 0});
      return true;
    } else {
      return false;
    }
  }, [multiResults, alert, props.projectData, props.sampleIds, props.reportNumbers]);

  const SaveNewRecordStepOne = () => {
    let samplesErr = FindMultiErrs();
    if (!samplesErr) {
      let samplesToSave = [];
      multiResults.forEach(s => {
        let reportsHelper = [];
        s.reports.forEach(r => {
          let reportHelper = props.availableReports.find(rep => rep.title === r.form);
          if ( reportHelper ) {
            reportHelper.reportNumber = r.reportNumber;
            reportsHelper.push(reportHelper);
          }
        });
        samplesToSave.push({
          sampleId: s.sampleId,
          project: s.project,
          reports: JSON.parse(JSON.stringify(reportsHelper)),
          soilType: sample.soilType,
          item: sample.item,
          entryDate: s.entryDate,
          sampleDate: s.sampleDate,
          controlDate: s.controlDate,
          billNumber: s.billNumber,
          trialPitNumber: s.trialPitNumber,
          horizon: s.horizon,
          layerThickness: sample.layerThickness,
          depth: s.depth,
          repArea: sample.repArea,
          sampledBy: s.sampledBy,
          samplePlan: sample.samplePlan,
          sampleProcess: sample.sampleProcess,
          comments: '',
          status: 'activo'
        });
      })
      SaveNewRecordStepTwo(samplesToSave);
    }
  }

  const SaveNewRecordStepTwo = (records) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    // Add new sample with reports to be made or added to
    let url = '/api/samples/batch/add';
    Axios.post(url, { records }, opts)
    .then((res) => {
      setLoading(false);
      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsErrs = [];
        res.data.results.forEach(s => {
          if (s.sample.err) {
            resultsErrs.push(s.sample.err);
          } else {
            resultsMsgs.push(s.sample.msg);
          }
          s.reports.forEach(r => {
            if (r.err) {
              resultsErrs.push(r.err);
            } else {
              resultsMsgs.push(r.msg);
            }
          })
        })
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de su ingreso son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 0});
        }
        if (resultsErrs.length) {
          let errResp =
            <>
              {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(errResp, {type: 'error', timeout: 0});
        }
        if (res.data.aborted) {
          alert.show('Operación abortada', {type: 'info', timeout: 0});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      props.getExtraData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  const DownloadTemplateFile = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = ('api/downloads/batchBook');

    Axios.get(url, opts)
    .then((res) => {
      let filename = 'MSTDLab Plantilla Multi Muestras.xlsx';
      FileDownload(res.data, filename);
      setLoading(false);
      alert.show('Se ha descargado la plantilla de con éxito', {type: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (checkMultiErrsRef.current) {
      checkMultiErrsRef.current = false;
      FindMultiErrs();
    }
  }, [multiResults, FindMultiErrs]);

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="modal-form-container">
        <div className="d-flex justify-content-between mt-3 mb-1">
          {
            !loading ? 
              <label>
                <div className="modal-form-button lb-white cursor-pointer">
                  <input
                    type="file"
                    className="d-none"
                    accept=".xlsx"
                    onChange={e => ReadMultiSample(e.target.files[0])}
                  />
                  Subir Archivo
                </div>
              </label>
            : 
              <div className="modal-form-button lb-white cursor-pointer">
                Subir Archivo
              </div>
          }
          <div>
            <button onClick={() => DownloadTemplateFile()} className="modal-form-button lb-white" disabled={loading}>
              {'Descargar Plantilla (08-09-23)'}
            </button>
          </div>
        </div>
        {RenderMultiResults()}
        <FormTextInput title="Item (obra)" isRequired={false} stateKey={'item'} value={sample.item} handleChange={HandleChange} />
        <FormTextInput title="Area representada" isRequired={false} stateKey={'repArea'} value={sample.repArea} handleChange={HandleChange} />
        <FormTextInput title="Plan de muestreo" isRequired={false} stateKey={'samplePlan'} value={sample.samplePlan} handleChange={HandleChange} />
        <FormTextInput title="Procedimiento de muestreo" isRequired={false} stateKey={'sampleProcess'} value={sample.sampleProcess} handleChange={HandleChange} />
      </div>
      <div>
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecordStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default BatchAdd;
