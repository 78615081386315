import React, { useState, useEffect, useMemo, useRef, forwardRef } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import ReactSelect from 'react-select';
import FormDateInput from '../Generic/FormDateInput.js';
import FormTextInput from '../Generic/FormTextInput.js';
import dayjs from 'dayjs';
import { MdVisibilityOff } from 'react-icons/md';
import { FaPen, FaUndo, FaTrash } from 'react-icons/fa';
import { BsFilter } from 'react-icons/bs';
import { BiSearchAlt } from 'react-icons/bi';

const DefaultColumnFilter = ({ column: { filterValue, setFilter } }) => {
  return (
    <InputGroup>
      <Form.Control className="py-0 ps-1 pe-0 fs-7 " value={filterValue || ''} onChange={e => setFilter(e.target.value || undefined)} />
      <button className="bg-gw-lb border" title="Limpiar filtro" onClick={() => setFilter(undefined)}> x </button>
    </InputGroup>
  )
}

const IndeterminateCheckbox = forwardRef(({ indeterminate, ...rest }, ref) => {
  const defaultRef = useRef();
  const resolvedRef = ref || defaultRef;

  useEffect(() => {
    resolvedRef.current.indeterminate = indeterminate;
  }, [resolvedRef, indeterminate])

  return <input type="checkbox" ref={resolvedRef} {...rest} />
})

const TableToolsToggleColumns = (props) => {
  return (
    <Dropdown className="ms-2" autoClose="outside">
      <Dropdown.Toggle id="dropdown-basic" className={"d-flex align-items-center py-1 px-2 " + (props.toggleClass || "bg-lb-white")} title="Mostrar/Ocultar Columnas">
        <MdVisibilityOff color="ghostwhite" size={20} className="align-middle"/>
      </Dropdown.Toggle>
      <Dropdown.Menu className="overflow-auto fs-inherit maxh-45vh zIndex-1">
        <Dropdown.Item className="d-flex align-items-center" title="Mostrar/ocultar todas las columnas" as={"label"}>
          <IndeterminateCheckbox {...props.toggleProps({title: ''})} /> 
          <span className="ms-1">Todas</span>
        </Dropdown.Item>
        <br />
        {props.columns.map(column => (
          <Dropdown.Item className="d-flex align-items-center" key={column.id} title="Mostrar/ocultar esta columna" as={"label"}>
            <input type="checkbox" {...column.getToggleHiddenProps({title: ''})} />
            <span className="ms-1">{column.headerTitleProp || column.Header || ''}</span>
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const TableHeader = (props) => {
  return (
    <div className="d-flex">
      {props.headerGroups.map(headerGroup => (
        <div {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <div className="p-1 rt-header rt-header-highlight" {...column.getHeaderProps()}>
              <div className="d-flex justify-content-center user-select-none text-center fw-bold">
                <div 
                  className={"d-flex justify-content-center flex-1 text-truncate" + (column.disableSortBy ? " cursor-none" : " cursor-pointer")} 
                  {...column.getSortByToggleProps({ title: column.headerTitleProp || column.Header || '' })}
                > 
                  <span className="text-truncate">{column.render('Header')}</span>
                  <span> {column.isSorted ? column.isSortedDesc ? ' 🔽' : ' 🔼' : ''} </span>
                </div>
                {!column.disableResizing && <div className="ps-1" {...column.getResizerProps()}></div>}
              </div>
              <div className="my-1" style={column.disableFilters && {width: '75px'}}>
                {(column.canFilter && !column.disableFilters) && column.render('Filter')}
              </div>
            </div>
          ))}
        </div>
      ))}
      {/* Div below is to occupy the space of the scrollbar in the headers */}
      <div className="rt-header" style={{ minWidth: props.scrollBarSize }} />
    </div>
  )
}

const TableFilterData = ({ getData, filterData, handleFilterDataEdit, resetFilterData, disabled }) => {
  return (
    <Dropdown className="ms-2 ms-md-3 zIndex-1" autoClose="outside">
      <Dropdown.Toggle id="dropdown-basic123" className="d-flex align-items-center py-1 px-2 bg-lb-white" title="Filtros Tabla">
        <BsFilter color="ghostwhite" size={20} className="align-middle"/>
      </Dropdown.Toggle>
      <Dropdown.Menu className="fs-inherit">
        <Dropdown.Header>Filtrar Resultados</Dropdown.Header>
        <Dropdown.Item className="d-flex align-items-center justify-content-center" onClick={() => resetFilterData()}>
          <FaUndo size={15} className="me-1"/>
          <span>Valores Predeterminados</span>
        </Dropdown.Item>
        <FormDateInput title="Fecha de Creación Desde" stateKey={'dateStart'} value={filterData.dateStart} handleChange={handleFilterDataEdit} />
        <FormDateInput title="Fecha de Creación Hasta" stateKey={'dateEnd'} value={filterData.dateEnd} handleChange={handleFilterDataEdit} />
        <FormTextInput title="Máximo de registros" stateKey={'resultsLimit'} value={filterData.resultsLimit} handleChange={handleFilterDataEdit} />
        <Dropdown.Divider />
        <Dropdown.Item className="d-flex align-items-center justify-content-center" disabled={disabled} onClick={() => getData(filterData)}>
          <BiSearchAlt size={20} className="me-1"/>
          <span>Filtrar</span>
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const FilterDateMethod = (rows, id, filterValue) => {
  return rows.filter(row => dayjs(row.values[id]).isValid() ? dayjs(row.values[id]).format('DD-MM-YYYY').includes(String(filterValue).toLowerCase()) : false)
}

const FilterDateTextMethod = (rows, id, filterValue) => {
  return rows.filter(row => (row.values[id] ? (dayjs(row.values[id]).isValid() ? 
    dayjs(row.values[id]).format('DD-MM-YYYY').includes(String(filterValue).toLowerCase()) : 
    row.values[id].toLowerCase().includes(String(filterValue).toLowerCase())) : false)
  )
}

const SortDateMethod = (rowA, rowB, id, desc) => {
  if (rowB.values[id]) {
    if (rowA.values[id]) {
      if (dayjs(rowB.values[id]).isValid()) {
        if (dayjs(rowA.values[id]).isValid()) {
          if (dayjs(rowA.values[id]).diff(dayjs(rowB.values[id])) > 0) {
            return 1;
          } else {
            return -1;
          }
        } else {
          return -1;
        }
      } else {
        return 1;
      }
    }else {
      return -1;
    }
  }else{
    return 1;
  }
}
  
const TableTextInput = ({ row, column, value, editing, handleEdit }) => {

  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value])

  if (editing) {
    return (
      <input
        type="text"
        className="w-100"
        value={data || ''}
        onChange={(e) => setData(e.target.value)}
        onBlur={() => handleEdit(row.index, column.id, data)}
      />
    )
  } else {
    return <div className="text-truncate" title={value}>{value}</div>
  }
}

const TableSelectInput = ({ row, column, value, editing, handleEdit }) => {

  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value])

  if (editing) {
    return (
      <select
        className="w-100"
        value={ data || '' }
        onChange={(e) => handleEdit(row.index, column.id, e.target.value)}
      >
        { column.selectOpts.map((option, optionIdx) => <option key={optionIdx} disabled={option.disabled} value={option.value}>{option.display}</option> )}
      </select>
    );
  } else {
    const foundDisplayValue = column.selectOpts.find(opt => opt.value === value);
    return <div className="text-truncate" title={foundDisplayValue ? foundDisplayValue.display : ''}>{foundDisplayValue ? foundDisplayValue.display : ''}</div>
  }
}

const TableReactSelectInput = ({ row, column, value, editing, handleEdit }) => {

  const [data, setData] = useState(value || []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setData(value || []);
  }, [value])

  const GetCurrentSelectValue = useMemo(() => {
    let newValue = [];
    data.forEach((so) => {
      const foundItem = column.selectOpts.find(opt => opt.value === so)?.label || so;
      newValue.push({ label: foundItem, value: so });
    })
    return newValue;
  }, [column?.selectOpts, data])

  const GetOGLabels = () => {
    let newValue = [];
    if (value?.length) {
      value.forEach((so) => {
        const foundItem = column.selectOpts.find(opt => opt.value === so)?.label || so;
        newValue.push(foundItem);
      })
    }
    return newValue.join(' - ');
  }

  if (editing) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Button className="lb-white" size={'sm'} onClick={() => setIsModalOpen(true)} title={GetOGLabels()}> Modificar </Button>
        <Modal show={isModalOpen} backdrop="static" onHide={() => { setIsModalOpen(false); setData(value || []) }}>
          <Modal.Header closeButton>
            <Modal.Title>{ column.Header }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactSelect 
              noOptionsMessage={({ inputValue: string }) => 'No hay opciones para seleccionar'} 
              value={GetCurrentSelectValue} 
              onChange={(e) => setData(e.map(v => v.value))}
              isMulti
              hideSelectedOptions={false}
              options={column.selectOpts} 
              classNames={{ clearIndicator: () => 'p-1', dropdownIndicator: () => 'p-1' }}
              styles={{ control: (baseStyles) => ({ ...baseStyles, minHeight: '15px' }) }}
            />
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button variant="secondary" size="sm" onClick={() => setData(value)}>
              Deshacer cambios
            </Button>
            <Button variant="primary" size="sm" onClick={() => { setIsModalOpen(false); handleEdit(row.index, column.id, data) }}>
              Aceptar cambios
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  } else {
    let foundDisplayValue = GetOGLabels();
    return <div className="text-truncate" title={foundDisplayValue}>{foundDisplayValue}</div>
  }
}

const TableReactSelectInput2 = ({ row, column, value, handleRSEdit }) => {

  const [data, setData] = useState(value || []);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setData(value || []);
  }, [value])

  const GetCurrentSelectValue = useMemo(() => {
    let newValue = [];
    data.forEach((so) => {
      const foundItem = column.selectOpts.find(opt => opt.value === so)?.label || so;
      newValue.push({ label: foundItem, value: so });
    })
    return newValue;
  }, [column?.selectOpts, data])

  const GetOGLabels = () => {
    let newValue = [];
    if (value?.length) {
      value.forEach((so) => {
        const foundItem = column.selectOpts.find(opt => opt.value === so)?.label || so;
        newValue.push(foundItem);
      })
    }
    return newValue.join(' - ');
  }

  if (!row.depth) {
    return (
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center text-truncate" title={GetOGLabels()}>
          <Button className="me-2 d-flex p-1 rounded-circle" onClick={() => setIsModalOpen(true)}>
            <FaPen size={11} color="white" className="icon-style"/>
          </Button>
          <div className="text-truncate"> {GetOGLabels()} </div>
        </div>
        <Modal show={isModalOpen} backdrop="static" onHide={() => { setIsModalOpen(false); setData(value || []) }}>
          <Modal.Header closeButton>
            <Modal.Title>{ column.Header }</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <ReactSelect 
              noOptionsMessage={({ inputValue: string }) => 'No hay opciones para seleccionar'} 
              value={GetCurrentSelectValue} 
              onChange={(e) => setData(e.map(v => v.value))}
              isMulti
              hideSelectedOptions={false}
              options={column.selectOpts} 
              classNames={{ clearIndicator: () => 'p-1', dropdownIndicator: () => 'p-1' }}
              styles={{ control: (baseStyles) => ({ ...baseStyles, minHeight: '15px' }) }}
            />
          </Modal.Body>
          <Modal.Footer className="justify-content-between">
            <Button variant="secondary" size="sm" onClick={() => setData(value)}>
              Deshacer cambios
            </Button>
            <Button variant="primary" size="sm" onClick={() => { setIsModalOpen(false); handleRSEdit(row.index, column.id, data) }}>
              Aceptar cambios
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  } else {
    return ''
  }
}

const TableDateInput = ({ row, column, value, editing, handleEdit }) => {

  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value])

  if (editing) {
    return (
      <input
        type="date"
        className="w-100"
        value={data ? data.split('T')[0] : ''}
        onChange={(e) => setData(e.target.value)}
        onBlur={() => handleEdit(row.index, column.id, data)}
      />
    )
  } else {
    return <div className="text-truncate" title={dayjs(value).isValid() ? dayjs.utc(value).format('LL') : '-'}> {dayjs(value).isValid() ? dayjs.utc(value).format('DD-MM-YYYY') : '-'} </div>
  }
}

const TableDateTextInput = ({ row, column, value, editing, handleEdit }) => {

  const [data, setData] = useState(value);

  useEffect(() => {
    setData(value);
  }, [value])

  let isValidDate = dayjs(value).isValid();
  let isNoInformed = value === "NO INFORMADA";

  if (editing) {
    if (!value || isValidDate) {
      return (
        <div className="d-flex">
          <div
            className="d-flex flex-1 align-items-center justify-content-center cursor-pointer silver-black"
            title="NO INFORMADA"
            style={{ maxWidth: '30px' }}
            onClick={() => handleEdit(row.index, column.id, 'NO INFORMADA')}
          >
            <b>NI</b>
          </div>
          <input
            type="date"
            className="w-100 flex-1 ms-2"
            value={data ? data.split('T')[0] : ''}
            onChange={(e) => setData(e.target.value)}
            onBlur={() => handleEdit(row.index, column.id, data)}
          />
        </div>
      )
    } else if (isNoInformed) {
      return (
        <div className="d-flex align-items-center justify-content-center" title="NO INFORMADA">
          <Button className="lb-white" size={'sm'}  onClick={() => handleEdit(row.index, column.id, '')}> <b>NO INFORMADA</b> </Button>
        </div>
      )
    } else {
      return <div>{value}</div>
    }
  }else{
    if (value && isValidDate) {
      return <div title={dayjs(value).isValid() ? dayjs.utc(value).format('LL') : '-'}>{dayjs.utc(value).format('DD-MM-YYYY')}</div>
    } else {
      return <div title={value}>{value}</div>
    }
  }
}

const TableCheckboxInput = ({ row, column, value, editing, handleEdit }) => {
  return (
    <div className="d-flex">
      <input
        type="checkbox"
        className="w-100"
        checked={value}
        disabled={!editing}
        onChange={() => handleEdit(row.index, column.id, !value)}
      />
    </div>
  )
}

const TableDeleteCell = ({ row, deleteRecord, selectedFlatRows, subRowRender, editing }) => {
  if (!row.depth || (row.depth && subRowRender)) {
    return (
      <div className="d-flex align-items-center justify-content-center">
        <Button className="d-flex p-1 cursor-pointer bg-transparent" onClick={() => deleteRecord(row.index)} disabled={editing || (selectedFlatRows && selectedFlatRows.length)}>
          <FaTrash size={16} color="black" className="icon-style"/>
        </Button>
      </div>
    )
  } else {
    return null;
  }
}

const TableDisplayDateCell = ({ value }) => (<div title={value ? dayjs(value).format('LLLL') : '-'}>{value ? (dayjs().to(dayjs(value))) : '-'}</div> )

export { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, TableFilterData, FilterDateMethod, FilterDateTextMethod, SortDateMethod, IndeterminateCheckbox, 
  TableTextInput, TableSelectInput, TableReactSelectInput, TableReactSelectInput2, TableDateInput, 
  TableDateTextInput, TableCheckboxInput, TableDeleteCell, TableDisplayDateCell }