import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import dayjs from 'dayjs';
import LoadingModal from '../Layout/LoadingModal.js';
import HumidityCalcModal from './HumidityCalc/HumidityCalcModal.js';
import ClasifExtra from './ClasifExtra.js';
import EquipmentExtra from './EquipmentExtra.js';
import EditEquipmentDefaults from './EditEquipmentDefaults.js';
import DensAutocompleteValues from './DensAutocompleteValues.js';
import CNCImg from './CNCImg.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const Extras = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [hiddenStuff, setHiddenStuff] = useState('');
  const [isCNCImgModalOpen, setIsCNCImgModalOpen] = useState(false);
  const [isHumidityCalcOpen, setIsHumidityCalcOpen] = useState(false);
  const [isClasifExtraOpen, setIsClasifExtraOpen] = useState(false);
  const [isEquipmentExtraOpen, setIsEquipmentExtraOpen] = useState(false);
  const [isEditEquipmentDefaultsOpen, setIsEditEquipmentDefaultsOpen] = useState(false);
  const [isDensAutocompleteValuesOpen, setIsDensAutocompleteValuesOpen] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Extras";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/extras/hiddenStuff';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setHiddenStuff(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const SaveDataStepOne = () => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-large-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se ocultarán todos los cálculos extras</p>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepTwo() }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepTwo = () => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/extras/saveData';

    Axios.post(url, { code: hiddenStuff.code, data: hiddenStuff.data }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      { isHumidityCalcOpen ? <HumidityCalcModal closeModal={() => setIsHumidityCalcOpen(false)} /> : null }
      { isClasifExtraOpen ? <ClasifExtra closeModal={() => setIsClasifExtraOpen(false)} /> : null }
      { isCNCImgModalOpen ? <CNCImg closeModal={() => setIsCNCImgModalOpen(false)} /> : null }
      { isEquipmentExtraOpen ? <EquipmentExtra closeModal={() => setIsEquipmentExtraOpen(false)} /> : null }
      { isEditEquipmentDefaultsOpen ? <EditEquipmentDefaults closeModal={() => setIsEditEquipmentDefaultsOpen(false)} /> : null }
      { isDensAutocompleteValuesOpen ? <DensAutocompleteValues closeModal={() => setIsDensAutocompleteValuesOpen(false)} /> : null }
      <div className="mt-3 mx-4">
        <h4 className="my-2">Extras</h4>
        <div className="my-2">
          { 
            hiddenStuff ? 
              <div className="d-flex align-items-center">
                <label className="d-flex align-items-center cursor-pointer">
                  <input
                    type="checkbox"
                    checked={hiddenStuff.data.isActive}
                    style={{height: '15px', width: '15px'}}
                    onChange={(e) => {
                      let saveHelper = JSON.parse(JSON.stringify(hiddenStuff)) 
                      saveHelper.data.isActive = !hiddenStuff.data.isActive;
                      setHiddenStuff(saveHelper);
                    }}
                    disabled={loading}
                  />
                  <div className="ms-2">Mostrar Cálculos Extra en App</div>
                </label>
                <div className="ms-2" title={(hiddenStuff.lastModified ? dayjs(hiddenStuff.lastModified).format('LLLL') : '-')}>
                  {' - ' + hiddenStuff.lastModifiedUser + ' ' + (hiddenStuff.lastModified ? dayjs().to(dayjs(hiddenStuff.lastModified)) : '')}
                </div>
                <Button className="lb-white ms-3 fs-7" disabled={!hiddenStuff} onClick={e => SaveDataStepOne()}> Guardar </Button>
              </div>
            : null
          }
        </div>
        { hiddenStuff?.data?.isActive ? <div><Button className="lb-white my-3 fs-7" onClick={e => setIsHumidityCalcOpen(true)}> Cálculos Humedad </Button></div> : null }
        { hiddenStuff?.data?.isActive ? <div><Button className="lb-white my-3 fs-7" onClick={e => setIsClasifExtraOpen(true)}> Taras y Picnómetros Clasif </Button></div> : null }
        { hiddenStuff?.data?.isActive ? <div><Button className="lb-white my-3 fs-7" onClick={e => setIsEquipmentExtraOpen(true)}> Generar Equipos Usados </Button></div> : null }
        { hiddenStuff?.data?.isActive ? <div><Button className="lb-white my-3 fs-7" onClick={e => setIsEditEquipmentDefaultsOpen(true)}> Cambiar Equipos Iniciales </Button></div> : null }
        { hiddenStuff?.data?.isActive ? <div><Button className="lb-white my-3 fs-7" onClick={e => setIsDensAutocompleteValuesOpen(true)}> Valores Autocompletar Dens. Terreno </Button></div> : null }
        <div><Button className="lb-white my-3 fs-7" onClick={e => setIsCNCImgModalOpen(true)}> Cambiar Imagen CNC </Button></div>
      </div>
    </div>
  )
}

export default Extras