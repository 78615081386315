import React, { useEffect, useCallback } from 'react';
import Button from 'react-bootstrap/Button';

const ModalHeader = ({ title, closeModal, closeKb }) => {

  useEffect(() => {
    const HandleKeyDown = (e) => {
      if (closeKb) {
        if (e.key === 'Escape') {
          e.preventDefault();
          closeModal();
        }
      }
    };
    document.addEventListener("keydown", HandleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", HandleKeyDown);
    };
  }, [closeKb, closeModal]);

  return (
    <div className="d-flex align-items-center justify-content-between modal-header mb-2">
      <h6> {title} </h6>
      <div onClick={() => closeModal()} className="modal-close-button lb-white cursor-pointer user-select-none" title={closeKb ? '(ESC)' : ''}>
        x
      </div>
    </div>
  )
}

const ModalSections = ({ sections, activeSection, setActiveSection }) => {
  return (
    <div className="d-flex">
      {sections.map((item, itemIdx) => (
        <div key={itemIdx} className={"flex-1 text-center py-2" + (activeSection === itemIdx ? " bg-lb-white-selected" : " cursor-pointer bg-lb-white")} onClick={() => setActiveSection(itemIdx)}>
          {item}
        </div>
      ))}
    </div>
  );
}

const ModalItemSelection = ({ canChange, currentItem, data, setItem, optClasses, optText, backBtnText, fwdBtnText }) => {

  const ChangeSelectedItem = useCallback((direction) => {
    if (data.length) {
      if (((direction > 0) && (currentItem < (data.length-1))) || ((direction < 0) && (currentItem > 0))) {
        setItem(currentItem + direction);
      }
    }
  }, [data.length, currentItem, setItem])

  useEffect(() => {
    const HandleKeyDown = (e) => {
      if (canChange) {
        if (e.key === 'a' || e.key === 'A') {
          e.preventDefault();
          ChangeSelectedItem(-1)
        }
        if (e.key === 's' || e.key === 'S') {
          e.preventDefault();
          ChangeSelectedItem(1)
        }
      }
    };
    document.addEventListener("keydown", HandleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", HandleKeyDown);
    };
  }, [canChange, ChangeSelectedItem]);

  return (
    <div className="d-flex flex-1 py-1">
      <Button className="px-3 px-lg-4" disabled={(currentItem <= 0)} onClick={() => ChangeSelectedItem(-1)} variant="secondary" size="sm">{backBtnText || 'Anterior'}</Button>
      <div className="d-flex flex-1">
        <select className="w-100 mx-1 p-1 form-select-sm" value={currentItem} onChange={(e) => setItem(e.target.value)}>
          { data.map((r, rIdx) => <option key={rIdx} className={optClasses?.(r, rIdx) || ""} value={rIdx}>{optText(r, rIdx)}</option> )}
        </select>
      </div>
      <Button className="px-3 px-lg-4" disabled={(currentItem >= (data.length-1))} onClick={() => ChangeSelectedItem(1)} variant="secondary" size="sm">{fwdBtnText || 'Siguiente'}</Button>
    </div>
  )
}

export { ModalHeader, ModalSections, ModalItemSelection }