import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPlus, FaPen, FaCheck, FaUndo, FaSyncAlt, FaFileExcel, FaRegCheckCircle } from 'react-icons/fa';
// import { TfiWidgetized } from 'react-icons/tfi';
import TemplateModalAdd from './TemplateModalAdd.js';
// import TemplateSignCoordsModal from './TemplateSignCoordsModal.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { regularStatusSelectOptions } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, FilterDateMethod, SortDateMethod, 
  TableTextInput, TableSelectInput, TableDisplayDateCell, TableReactSelectInput, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileDownload from 'js-file-download';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

function Table({ 
  data, 
  columns, 
  editing, 
  setEditing, 
  getData, 
  handleEdit, 
  saveEditedData, 
  setIsModalOpen, 
  discardChanges, 
  updateFile, 
  // setIsSignCoordsModalOpen, 
  downloadTemplateForm, 
  deleteRecord 
}) {

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    ReactSelectCell: TableReactSelectInput,
    SelectCell: TableSelectInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      updateFile,
      // setIsSignCoordsModalOpen,
      downloadTemplateForm,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (editing) {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
            <FaCheck color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
            <FaUndo color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }else{
      return (
        <>
          {/* <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Coordenadas de Firma" disabled={!data.length} onClick={() => setIsSignCoordsModalOpen(true)}>
            <TfiWidgetized color="white" size={15} className="icon-style"/>
          </Button> */}
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Refrescar" onClick={() => getData()}>
            <FaSyncAlt color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Nuevo Formulario" onClick={() => setIsModalOpen(true)}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Editar" onClick={() => setEditing(true)}>
            <FaPen color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }
  }

  return (
    <div className="table-inner-container">
      <div className="d-flex align-items-center justify-content-between my-1 py-1 px-1 flex-wrap" style={{backgroundColor: editing ? '#ffb130cc' : 'initial'}}>
        <div className="d-flex align-items-center">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} />
        </div>
        <div className="d-flex align-items-center">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={35}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const Templates = () => {
  
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [isSignCoordsModalOpen, setIsSignCoordsModalOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [data, setData] = useState([]);
  const [dataIndexToEdit, setDataIndexToEdit] = useState([]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Formularios";
  }, []);

  const assignedUsersOpts = useMemo(() => {
    let formattedUsers = [];
    if (users.length) {
      users.forEach(user => {
        formattedUsers.push({ value: user.fullname, label: user.fullname })
      })
    }
    return formattedUsers
  }, [users]);

  const GetData = useCallback(() => {

    setLoading(true);

    const opts = { cancelToken: source.token };
    const templatesRequest = Axios.get('api/templates', opts);
    const usersRequest = Axios.get('/api/users/names', opts);

    Axios.all([templatesRequest, usersRequest]).then(Axios.spread((...responses) => {
      setData(responses[0].data);
      setUsers(responses[1].data);
    }))
    .catch((err) => {
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    }).finally(() => setLoading(false));
  }, [alert]);

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un formulario</h3>
            <p>
              {'Eliminar el formulario '} {<b>{data[rowId].clientCode}{' v'}{data[rowId].clientVersion}</b>} {'-'} {<b>{data[rowId].code}{' v'}{data[rowId].version}</b>}
              {' implica eliminar todas las muestras asociadas a este ensayo, y los ensayos asociados a estas muestras. ¿Está seguro(a) de eliminar este formulario ?'}
            </p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].code, data[rowId].version, data[rowId].clientCode, data[rowId].clientVersion)
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (code, version, clientCode, clientVersion) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un formulario</h3>
            <p>{'Esta acción es irreversible. Confirme ingresando el código del formulario de cliente'}</p>
            <input
              type="text"
              className="modal-form-input"
              onChange={(e) => { deleteConfirmation = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (clientCode === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(code, version, clientCode, clientVersion)
                    }else{
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  }else{
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (code, version, clientCode, clientVersion) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/templates/delete';

    const templateInfo = {
      code,
      version,
      clientCode,
      clientVersion
    }

    Axios.post(url, templateInfo, opts)
    .then((res) => {
      setLoading(false);
      let deletedElementsAlert =
        <div className="d-flex flex-column">
          {'Los resultados de la eliminación son: '}
          {res.data.err ? <span className="bg-danger color-white">{res.data.err}</span> : null}
          <span><b>{res.data.template}</b></span>
          <span>{res.data.file}</span>
          <hr className="my-2"/>
          {res.data.reports.length ? res.data.reports.map((element, index) => {
            return <span key={index}>{element}</span>
          }) : <span>No se encontraron/eliminaron informes</span>}
          <hr className="my-1"/>
          {res.data.reportFiles.length ? res.data.reportFiles.map((element, index) => {
            return <span key={index}>{element}</span>
          }) : <span>No se encontraron/eliminaron archivos de informes</span>}
        </div>
      alert.show(deletedElementsAlert, {type: 'success', timeout: 60000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DownloadTemplateForm = (rowId) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/template/form/' + data[rowId].code +'&'+ data[rowId].version +'&'+ data[rowId].clientCode +'&'+ data[rowId].clientVersion;

    Axios.get(url, opts)
    .then((res) => {
      FileDownload(res.data, data[rowId].filename);
      setLoading(false);
      alert.show('Se ha descargado su formulario con éxito', {type: 'success'})
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  const SaveEditedDataStepOne = () => {
    if (dataIndexToEdit.length > 0) {

      setLoading(true);
      let dataToUpdate = [];
      let incompleteIndexes = [];

      dataIndexToEdit.forEach((dataIndex) => {
        if ( !data[dataIndex].code || !data[dataIndex].version || !data[dataIndex].clientCode || !data[dataIndex].clientVersion || !data[dataIndex].title || !data[dataIndex].status ) {
          if (incompleteIndexes.indexOf(dataIndex) === -1) {
            incompleteIndexes.push(dataIndex);
          }
        }
      });

      if (incompleteIndexes.length) {
        let incompleteIndexesAlert =
          <div className="d-flex flex-column">
            Los siguientes registros tienen campos sin completar:
            {incompleteIndexes.map((element, index) => <span key={index}>{data[element].code+'_v'+data[element].version}</span> )}
          </div>

        setLoading(false);
        alert.show(incompleteIndexesAlert, {type: 'info'});
      }else{
        dataIndexToEdit.forEach((dataIndexToModify) => {
          dataToUpdate.push(data[dataIndexToModify]);
        });

        SaveEditedDataStepTwo(dataToUpdate);
      }
    }else{
      setEditing(false);
      alert.show('No se realizaron modificaciones', {type: 'success'});
    }
  }

  const SaveEditedDataStepTwo = (dataToUpdate) => {

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/templates/updateMany';

    Axios.post(url, dataToUpdate, opts)
    .then((res) => {
      setLoading(false);
      setEditing(false);
      setDataIndexToEdit([]);
      let editResults =
        <div className="d-flex flex-column">
          {'Los resultados de sus modificaciones son: '}
          {res.data.map((element, index) => <span key={index}>{element}</span> )}
        </div>
      alert.show(editResults, {type: 'success', timeout: 25000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const UpdateFileStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está modificando un formulario</h3>
            <p>{'Cambiar el archivo de un formulario podría generar errores al exportar un informe. Revise que las celdas a rellenar coinciden con el archivo existente.'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  UpdateFileStepTwo(data[rowId].code, data[rowId].version, data[rowId].clientCode, data[rowId].clientVersion);
                }}
              >
                Si, deseo modificar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const UpdateFileStepTwo = (code, version, clientCode, clientVersion) => {

    let fileToUpload = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está modificando un formulario</h3>
            <p>{'Seleccione el nuevo archivo para el formulario '+clientCode+'_v'+clientVersion+'-'+code+'_v'+version}</p>
            <input
              type="file"
              className="modal-form-input"
              accept=".xlsx, .xlsm"
              onChange={(e) => { fileToUpload = e.target.files[0] }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (fileToUpload) {
                    onClose();
                    UpdateFileStepThree(code, version, clientCode, clientVersion, fileToUpload)
                  }else{
                    alert.show('Ingrese el archivo', {type: 'info'});
                  }
                }}
              >
                Modificar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const UpdateFileStepThree = (code, version, clientCode, clientVersion, fileToUpload) => {

    if (code && version && clientCode && clientVersion && fileToUpload) {

      setLoading(true);

      const recordToUpdate = {
        code,
        version,
        clientCode,
        clientVersion
      }

      const formData = new FormData();
      formData.append('record', JSON.stringify(recordToUpdate));
      formData.append('filename', fileToUpload);

      let url = '/api/templates/updateFile';

      Axios.post(url, formData, {cancelToken: source.token})
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success'});
        GetData();
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }else{
      alert.show('Falta información', {type: 'info'});
    }
  }

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
      if (dataIndexToEdit.indexOf(index) === -1) {
        let newEditedIndex = dataIndexToEdit;
        newEditedIndex.push(index);
        setDataIndexToEdit(newEditedIndex);
      }
    }
  }

  const tableColumns = useMemo(() => [
    {
      Header: 'Código Informe Cliente',
      accessor: 'clientCode',
    },{
      Header: 'Versión Informe Cliente',
      accessor: 'clientVersion',
      sortDescFirst: true
    },{
      Header: 'Título',
      accessor: 'title',
      cellRender: 'EditableCell',
      width: 200,
    },{
      Header: 'Título Completo',
      accessor: 'completeTitle',
      cellRender: 'EditableCell',
      width: 200,
    },{
      Header: 'Código Informe Laboratorio',
      accessor: 'code',
    },{
      Header: 'Versión Informe Laboratorio',
      accessor: 'version',
      sortDescFirst: true
    }, {
      Header: 'N° Muestras',
      accessor: 'samplesRequired',
    },{
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: regularStatusSelectOptions,
      width: 125,
    },{
      Header: 'Usuarios Autorizados',
      accessor: 'assignedUsers',
      cellRender: 'ReactSelectCell',
      selectOpts: assignedUsersOpts,
    },{
      Header: 'Comentarios versión',
      accessor: 'versionComments',
      cellRender: 'EditableCell',
    },{
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
    },{
      Header: 'Fecha Creación',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Archivo',
      accessor: 'filename',
      Cell: ({ row, value, updateFile, editing }) => (
        <div className="d-flex align-items-center justify-content-center" title={editing ? '' : (value || 'No se encontró el archivo')}>
          { editing ? <Button className="lb-white" size={'sm'} onClick={() => updateFile(row.index)}> Modificar </Button> : 
          <div className="p-1"> {value ? <FaRegCheckCircle color="black" size={16} className="icon-style" /> : '-'} </div> }
        </div>
      ),
      width: 125,
      disableFilters: true,
      disableSortBy: true,
    }, {
      Header: 'Descargar',
      Cell: ({ row, downloadTemplateForm, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button className="d-flex p-1 cursor-pointer bg-transparent" onClick={() => downloadTemplateForm(row.index)} disabled={editing}>
            <FaFileExcel size={16} color="#03A700" className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }, {
      Header: 'Datos para firma electrónica',
      accessor: 'signData',
      Cell: ({ value, editing }) => (
        <div className="d-flex align-items-center justify-content-center" title={editing ? '' : ('Tiene datos para usar firma electrónica')}>
          { editing ? <Button className="lb-white" size={'sm'} disabled={true} onClick={() => console.log('disabled')}> Modificar </Button> : 
          <div className="p-1"> {value ? <FaRegCheckCircle color="black" size={16} className="icon-style" /> : '-'} </div> }
        </div>
      ),
      width: 125,
      disableFilters: true,
      // disableSortBy: true,
    },{
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], [assignedUsersOpts]);
  
  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isModalOpen ? <TemplateModalAdd closeModal={() => { setIsModalOpen(false); GetData() }}/> : null}
      {/* {isSignCoordsModalOpen ? <TemplateSignCoordsModal templates={data} closeModal={() => { setIsSignCoordsModalOpen(false); GetData() }}/> : null} */}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        getData={GetData}
        handleEdit={HandleEdit}
        saveEditedData={SaveEditedDataStepOne}
        setIsModalOpen={setIsModalOpen}
        discardChanges={() => { setDataIndexToEdit([]); setEditing(false); GetData() }}
        updateFile={UpdateFileStepOne}
        // setIsSignCoordsModalOpen={setIsSignCoordsModalOpen}
        downloadTemplateForm={DownloadTemplateForm}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default Templates;
