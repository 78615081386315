import React, { useState } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import FormTextInput from '../Generic/FormTextInput';
import FormPasswordInput from '../Generic/FormPasswordInput';
import FormReactSelect from '../Generic/FormReactSelect';
import { roleSelectOptions } from '../Generic/Constants';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const UserModalAdd = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    username: '',
    fullname: '',
    email: '',
    password: '',
    role: [],
    station: '',
    status: 'activo'
  });

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const HandleArrChange = (value, element) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const SaveNewRecord = () => {
    if (form.username && form.fullname && form.email && form.password && form.role.length && form.station) {

      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };

      const url = '/api/users/add';

      Axios.post(url, form, opts)
      .then((res) => {
        alert.show(res.data.msg, {type: 'success'});
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      }).finally(() => setLoading(false));
    } else {
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nuevo Usuario'} closeModal={closeModal} />
        <div className="modal-form-container">
          <FormTextInput title="Nombre Usuario" isRequired stateKey={'username'} value={form.username} handleChange={HandleChange} />
          <FormTextInput title="Nombre Completo" isRequired stateKey={'fullname'} value={form.fullname} handleChange={HandleChange} />
          <FormTextInput title="Correo Electrónico" isRequired stateKey={'email'} value={form.email} handleChange={HandleChange} />
          <FormPasswordInput title="Contraseña" isRequired stateKey={'password'} value={form.password} handleChange={HandleChange} />
          <FormReactSelect title="Roles de Usuario" stateKey={'role'} value={form.role} isMulti items={roleSelectOptions} handleChange={HandleArrChange} />
          <FormTextInput title="Cargo (usado en firma electrónica)" isRequired={false} stateKey={'station'} value={form.station} handleChange={HandleChange} />
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default UserModalAdd;
