import React, { useEffect } from 'react';
import { useAuth } from "../Context/Auth";
import { FaUsers, FaFolder, FaClipboardList, FaFileAlt, FaTools } from 'react-icons/fa';
import { MdTabletAndroid } from 'react-icons/md';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { FaTruckField } from "react-icons/fa6";
// import { BsFillClipboard2CheckFill } from 'react-icons/bs';
import { Link } from "react-router-dom";
import logo from '../../assets/img/full-logo.png';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';

const iconClasses = "icon-style p-2";

const menuTabs = [
  {
    to: '/usuarios',
    title: 'Usuarios',
    permissionsRequired: ['superAdmin', 'labAdmin', 'fwAdmin'],
    icon: <FaUsers color="white" className={iconClasses} />
  },
  {
    to: '/proyectos',
    title: 'Proyectos',
    permissionsRequired: ['superAdmin', 'labAdmin'],
    icon: <FaFolder color="white" className={iconClasses} />
  },
  {
    to: '/libro-muestras-de-suelo',
    title: 'Libro de Muestras',
    permissionsRequired: ['superAdmin', 'labAdmin'],
    icon: <FaClipboardList color="white" className={iconClasses} />
  },
  {
    to: '/informes',
    title: 'Informes',
    permissionsRequired: ['superAdmin', 'labAdmin'],
    icon: <HiOutlineDocumentReport color="white" className={iconClasses} />
  },
  // {
  //   to: '/resultados',
  //   title: 'Resultados',
  //   permissionsRequired: true,
  //   icon: <BsFillClipboard2CheckFill color="white" className={iconClasses} />
  // },
  {
    to: '/formularios',
    title: 'Formularios',
    permissionsRequired: ['superAdmin', 'labAdmin'],
    icon: <FaFileAlt color="white" className={iconClasses} />
  },
  {
    to: '/equipos',
    title: 'Equipo',
    permissionsRequired: ['superAdmin', 'labAdmin'],
    icon: <FaTools color="white" className={iconClasses} />
  },
  {
    to: '/terreno',
    title: 'Terreno',
    permissionsRequired: ['superAdmin', 'fwAdmin'],
    icon: <FaTruckField color="white" className={iconClasses} />
  },
  {
    to: '/apps',
    title: 'Aplicaciones',
    permissionsRequired: null,
    icon: <MdTabletAndroid color="white" className={iconClasses} />
  }
];

const Home = () => {

  const { session } = useAuth();

  useEffect(() => {
    document.title = "MSTD Laboratorio - Inicio";
  }, [])

  const RenderLinks = () => {
    return menuTabs.map((tab, tabIdx) => (
      <Col key={tabIdx} className="py-2 py-sm-3">
        <Card className="align-items-center bg-transparent border-0">
          <Card.Body 
            as={Link} 
            to={tab.to} 
            className="home-links-container d-flex flex-column p-1 lb-white color-inherit text-decoration-none"
            style={(tab.permissionsRequired ? (session.role.some(role => tab.permissionsRequired.includes(role))) ? {} : { pointerEvents: 'none', opacity: 0.65, cursor: 'default'} : {})}
          >
            <Card.Header className="d-flex flex-2 justify-content-center">
              {tab.icon}
            </Card.Header>
            <Card.Subtitle className="d-flex flex-1 align-items-center">
              <span className="flex-1 ellipsis-text text-center">{tab.title}</span>
            </Card.Subtitle>
          </Card.Body>
        </Card>
      </Col>
    ));
  }

  return (
    <div className="outer-container user-select-none py-3 fs-6">
      <div className="d-flex flex-md-1 align-items-center justify-content-center py-2 py-md-3">
        <img src={logo} alt="MSTD" className="w-auto"/>
      </div>
      <Container className="w-md-75 flex-4 pb-2">
        <Row xs={2} sm={'auto'} xl={4} className="h-100 align-items-center justify-content-center">
          {RenderLinks()}
        </Row>
      </Container>
    </div>
  )
}

export default Home;
