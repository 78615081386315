import React, { useState } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import FormTextInput from '../Generic/FormTextInput';
import FormDateInput from '../Generic/FormDateInput';
import FormSelectInput from '../Generic/FormSelectInput';
import { boolAsStrSelectOptions } from '../Generic/Constants';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const EquipmentModalAdd = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({
    code: '-MSTD-',
    nameAndBrand: '',
    capacity: '',
    resolution: '',
    shouldVerify: '',
    lastVerification: '',
    verificationPeriod: '',
    shouldCalibrate: '',
    lastCalibrate: '',
    calibratePeriod: '',
    calibratedBy: '',
    externalMaintenance: '',
    equipmentResponsible: 'Ricardo Briones',
    maintenancePeriod: '',
    lastMaintenance: '',
    lastCertificate: '',
    status: 'activo'
  });

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const SaveNewRecord = () => {
    if (form.code && form.nameAndBrand) {

      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };

      const url = '/api/equipment/add';

      Axios.post(url, form, opts)
      .then((res) => {
        alert.show(res.data.msg, {type: 'success'});
      })
      .catch((err) => {
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      }).finally(() => setLoading(false));
    } else {
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nuevo Equipo'} closeModal={closeModal} />
        <div className="modal-form-container">
          <FormTextInput title="N° Serie / Código Equipo" isRequired={true} stateKey={'code'} value={form.code} handleChange={HandleChange} />
          <FormTextInput title="Equipo y Marca" isRequired={true} stateKey={'nameAndBrand'} value={form.nameAndBrand} handleChange={HandleChange} />
          <FormTextInput title="Rango o Capacidad" isRequired={false} stateKey={'capacity'} value={form.capacity} handleChange={HandleChange} />
          <FormTextInput title="Resolución" isRequired={false} stateKey={'resolution'} value={form.resolution} handleChange={HandleChange} />
          <FormSelectInput title="¿Se verifica?" isRequired={false} stateKey={'shouldVerify'} value={form.shouldVerify} options={boolAsStrSelectOptions} handleChange={HandleChange} />
          <FormDateInput title="Fecha última verificación" isRequired={false} stateKey={'lastVerification'} value={form.lastVerification} handleChange={HandleChange} />
          <FormTextInput title="Periodo entre verificaciones" isRequired={false} stateKey={'verificationPeriod'} value={form.verificationPeriod} handleChange={HandleChange} />
          <FormSelectInput title="¿Se calibra?" isRequired={false} stateKey={'shouldCalibrate'} value={form.shouldCalibrate} options={boolAsStrSelectOptions} handleChange={HandleChange} />
          <FormDateInput title="Fecha última calibración" isRequired={false} stateKey={'lastCalibrate'} value={form.lastCalibrate} handleChange={HandleChange} />
          <FormTextInput title="Periodo entre calibraciones" isRequired={false} stateKey={'calibratePeriod'} value={form.calibratePeriod} handleChange={HandleChange} />
          <FormTextInput title="Calibrado por" isRequired={false} stateKey={'calibratedBy'} value={form.calibratedBy} handleChange={HandleChange} />
          <FormSelectInput title="¿Se realiza mantención externa?" isRequired={false} stateKey={'externalMaintenance'} value={form.externalMaintenance} 
            options={boolAsStrSelectOptions} handleChange={HandleChange} />
          <FormDateInput title="Fecha última mantención" isRequired={false} stateKey={'lastMaintenance'} value={form.lastMaintenance} handleChange={HandleChange} />
          <FormTextInput title="Periodo entre mantenciones" isRequired={false} stateKey={'maintenancePeriod'} value={form.maintenancePeriod} handleChange={HandleChange} />
          <FormTextInput title="Nº último certificado de calibración o registro de verificación / mantención" isRequired={false} 
            stateKey={'lastCertificate'} value={form.lastCertificate} handleChange={HandleChange} />
          <FormTextInput title="Responsable del equipo" isRequired={false} stateKey={'equipmentResponsible'} value={form.equipmentResponsible} handleChange={HandleChange} />
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default EquipmentModalAdd;
