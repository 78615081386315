import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { ModalHeader } from '../Layout/ModalElements.js';
import LoadingModal from '../Layout/LoadingModal.js';
import FormTextInput from '../Generic/FormTextInput';
import ModalTableTextInput from '../Generic/ModalTableTextInput';
import ModalTableTextNumericInput from '../Generic/ModalTableTextNumericInput';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const DensAutocompleteValues = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [lastModifiedData, setLastModifiedData] = useState(false);
  const [form, setForm] = useState(null);

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/extras/densAutocomplete';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setForm( res.data?.data || null );
      setLastModifiedData({ lastModified: ( res.data?.lastModified || '' ),  lastModifiedUser: ( res.data?.lastModifiedUser || '' )});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const HandleChange = (stateKey, value) => {
    if (value !== form[stateKey]) {
      let newData = {...form};
      newData[stateKey] = value;
      setForm(newData);
    }
  }

  const HandleField4Change = (stateIdx, stateKey, value) => {
    if (value !== form.plateAndSamples[stateIdx][stateKey]) {
      let newData = {...form};
      newData.plateAndSamples[stateIdx][stateKey] = value;
      setForm(newData);
    }
  }

  const AddField4Item = () => {
    if (form?.plateAndSamples) {
      let newData = {...form};
      newData.plateAndSamples.push({ code: 'CAR-MSTD-00', value: '' });
      setForm(newData);
    }
  }

  const RemoveField4Item = (idx) => {
    if (form?.plateAndSamples?.length) {
      let newData = {...form};
      newData.plateAndSamples.splice(idx, 1);
      setForm(newData);
    }
  }

  const SaveDataStepOne = () => {
    if (form) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-large-confirm-container">
              <h4>Confirme esta acción</h4>
              <div className="d-flex mt-3">
                <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
                <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepTwo() }}> Si, deseo guardar </Button>
              </div>
            </div>
          );
        }
      })
    } else {
      alert.show('No se encontraron los datos iniciales. Recargue esta vista', {type: 'info'});
    }
  }

  const SaveDataStepTwo = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/extras/saveData';

    Axios.post(url, { code: 'densAutocomplete', data: form }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DisplayField4Items = () => {
    return (
      <div className="d-flex flex-column my-3">
        <div className="px-2">4. M. arena en cono y placa:</div>
        <div className="d-flex flex-column px-2">
          <div className="d-flex my-2">
            { ['Código Cono', 'Valor', '-'].map((item, itemIdx) => <div key={itemIdx} className="flex-1 text-center"> {item} </div> )}
          </div>
          { form?.plateAndSamples?.length ?
            form.plateAndSamples.map((item, itemIdx) => {
              return (
                <div key={itemIdx} className="d-flex align-items-center flex-1 my-2">
                  <ModalTableTextInput stateIdx={itemIdx} stateKey={'code'} value={item.code} handleChange={HandleField4Change} />
                  <ModalTableTextNumericInput stateIdx={itemIdx} stateKey={'value'} value={item.value} handleChange={HandleField4Change} />
                  <div className="modal-table-row-container">
                    <Button className="d-flex p-1 rounded-circle bg-lb-white" disabled={loading} title="Eliminar" onClick={() => RemoveField4Item(itemIdx)}>
                      <FaTrash color="white" className="icon-style"/>
                    </Button>
                  </div>
                </div>
              )
            })
          : <span className="modal-table-loading-text"> No hay información para mostrar </span> }
        </div>
        <div className="d-flex justify-content-center align-items-center p-1">
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" disabled={loading} title="Nuevo Registro" onClick={() => AddField4Item()}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title='Editar valores para autocompletar Dens. Terreno' closeModal={closeModal} />
        <div className="modal-form-container">
          { form ? 
            <>
              { lastModifiedData ? 
                <div className="my-2" title={'Última modificación: ' + (lastModifiedData.lastModified ? dayjs(lastModifiedData.lastModified).format('LLLL') : '-')}>
                  { 'Última modificación: ' + lastModifiedData.lastModifiedUser + ' ' + (lastModifiedData.lastModified ? dayjs().to(dayjs(lastModifiedData.lastModified)) : '')}
                </div>
              : null }
              <FormTextInput title="2. M. arena antes de ensayo" stateKey={'initialSand'} value={form.initialSand} handleChange={HandleChange} />
              <FormTextInput title="5. Densidad arena normal" stateKey={'initialDens'} value={form.initialDens} handleChange={HandleChange} />
              {DisplayField4Items()}
            </>
          : null }
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default DensAutocompleteValues