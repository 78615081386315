import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import { confirmAlert } from 'react-confirm-alert';
import { signStatusOptions, GetReportFullDisplayText, GetReportDisplayText } from '../Generic/Constants.js';
import ReactSelect from 'react-select';
import { RiFileEditLine } from 'react-icons/ri';
import { HiOutlineDocumentArrowUp, HiOutlineDocumentMagnifyingGlass } from 'react-icons/hi2';
import { FaUserEdit } from 'react-icons/fa';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-confirm-alert/src/react-confirm-alert.css';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const availableSignSpaces = [
  { value: 'labFirstSign', label: 'Lab - Primera Firma', opt: 'labExportInfo', space: 'firstSign', title: 'LABORATORISTA REVISOR(A): ', placeholder: 'LABORATORISTA' },
  { value: 'labSecondSign', label: 'Lab - Segunda Firma', opt: 'labExportInfo', space: 'secondSign', title: 'INGENIERO(A) REVISOR(A): ', placeholder: 'REVISOR(A)' },
  { value: 'clientFirstSign', label: 'Cliente - Primera Firma', opt: 'clientExportInfo', space: 'firstSign', title: 'JEFE(A) LABORATORIO: ', placeholder: 'JEFE(A) LABORATORIO' },
  { value: 'clientSecondSign', label: 'Cliente - Segunda Firma', opt: 'clientExportInfo', space: 'secondSign', title: 'VALIDADOR(A): ', placeholder: 'VALIDADOR(A)' }
];

const ReportSignModal = ({ reports: reportNumbers, openLastReport, closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [ogReports, setOGReports] = useState([]);
  const [users, setUsers] = useState([]);
  const [shouldReloadAfterClose, setShouldReloadAfterClose] = useState(false);

  const usersSelectOpts = useMemo(() => {
    let values = [];
    users.forEach((user) => {
      values.push({ value: user.username, label: user.fullname });
    });
    return values;
  }, [users])

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {

    setLoading(true);

    const opts = {
      headers: {   
        'Content-Type': 'application/json'
      },
      cancelToken: source.token,
    };

    const usersUrl = '/api/users';
    const reportsUrl = 'api/reports/list';

    const usersRequest = Axios.get(usersUrl, opts);
    const reportsRequest = Axios.post(reportsUrl, { reportNumbers }, opts);

    Axios.all([usersRequest, reportsRequest]).then(Axios.spread((...responses) => {
      setLoading(false);
      setUsers(responses[0].data);
      setOGReports(JSON.parse(JSON.stringify(responses[1].data)));
      setReports(JSON.parse(JSON.stringify(responses[1].data)));
    }))
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, reportNumbers]);

  const GetSignStatusText = (exportObj, opt) => {
    if (exportObj.length) {
      if (exportObj[0].signs) {
        const foundStatus = signStatusOptions.find(sOpt => sOpt.value === exportObj[0].signs[opt].status);
        return foundStatus ? foundStatus.label : '-';
      } else {
        return "-";
      }
    } else {
      return "-";
    }
  }

  const GetExportCardStyle = (exportObj, opt) => {
    if (exportObj.length) {
      if (exportObj[0].signs) {
        if (exportObj[0].signs[opt].status === "espera") {
          return " bg-primary bg-opacity-25";
        } else if (exportObj[0].signs[opt].status === "pendiente") {
          return " bg-warning bg-opacity-25";
        } else if (exportObj[0].signs[opt].status === "firmado") {
          return " bg-success bg-opacity-25";
        } else {
          return "";
        }
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  const InitSignData = (idx, opt) => {
    let newData = [...reports];
    const signsObj = InitSingle(newData[idx], opt);
    if (!signsObj.err) {
      newData[idx][opt][0].signs = signsObj.data;
      setReports(newData);
    } else {
      alert.show(signsObj.err, {type:'error', timeout: 10000});
    }
  }

  const InitAllData = (opt) => {
    let newData = [...reports];
    let errResults = [];
    let totalGood = 0;
    for (let i = 0; i < newData.length; i++) {
      if (newData[i][opt].length) {
        if (!newData[i][opt][0].signs) {
          const signsObj = InitSingle(newData[i], opt);
          if (!signsObj.err) {
            newData[i][opt][0].signs = signsObj.data;
            totalGood++;
          } else {
            errResults.push(signsObj.err);
          }
        }
      }
    }
    if (totalGood) {
      alert.show(('Se inicializaron los datos para la firma en ' + totalGood + ' de ' + newData.length + ' ensayos'), {type: 'success', timeout: 15000});
      setReports(newData);
    } else if (!errResults.length) {
      alert.show(('No se inicializó ningún ensayo. Es posible que no tengan archivos exportados para firmar, o que ya esten todos inicializados'), {type: 'info'});
    }
    if (errResults.length) {
      let errResp = <> {errResults.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
      alert.show(errResp, {type: 'error', timeout: 15000});
    }
  }

  const InitSingle = (report, opt) => {
    if (report.status !== 'anulado') {
      if (report.template.signData) {
        let firstSignData = null;
        let secondSignData = null;
        if (opt === 'labExportInfo') {
          firstSignData = JSON.parse(JSON.stringify(report.template.signData.labFirstSign));
          secondSignData = JSON.parse(JSON.stringify(report.template.signData.labSecondSign));
        } else if (opt === 'clientExportInfo') {
          firstSignData = JSON.parse(JSON.stringify(report.template.signData.clientFirstSign));
          secondSignData = JSON.parse(JSON.stringify(report.template.signData.clientSecondSign));
        } else {
          return { err: 'Error inesperado, consulte a su administrador', data: null };
        }
        if (firstSignData && secondSignData) {
          return { 
            err: null, 
            data: {
              firstSign: { 
                status: (opt === 'labExportInfo' ? 'pendiente' : 'espera'),
                signerOpts: firstSignData.defaultSigners, 
                signer: { username: '', date: '' }, 
                signData: { signPage: firstSignData.signPage, signText: firstSignData.signText, signCoords: firstSignData.signCoords }
              },
              secondSign: { 
                status: (opt === 'labExportInfo' ? 'espera' : ((report.labExportInfo[0]?.signs?.firstSign?.status === "firmado") && 
                (report.labExportInfo[0]?.signs?.secondSign?.status === "firmado")) ? 'pendiente' : 'espera'),
                signerOpts: secondSignData.defaultSigners, 
                signer: { username: '', date: '' }, 
                signData: { signPage: secondSignData.signPage, signText: secondSignData.signText, signCoords: secondSignData.signCoords }
              },
              lastModified: '',
              lastModifiedUser: ''
            } 
          };
        } else {
          return { err: ('Informe N°'+report.reportNumber+': El formulario no contiene datos para la firma ('+report.template.title+')'), data: null };
        }
      } else {
        return { err: ('Informe N°'+report.reportNumber+': El formulario no está habilitado para firmar ('+report.template.title+')'), data: null };
      }
    } else {
      return { err: ('Informe N°'+report.reportNumber+': Este ensayo ha sido anulado'), data: null };
    }
  }

  const HandleSignerChange = (idx, opt, key, value) => {
    let newData = [...reports];
    newData[idx][opt][0].signs[key].signerOpts = value.map(v => v.value);
    setReports(newData);
  }

  const BatchSignerChange = (opt, cb) => {

    let selectedSigner = '';
    let selectedSignSpaces = [...availableSignSpaces];

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h6>{'Seleccione al usuario para ' + (opt === 1 ? 'agregar a las firmas' : 'quitar de las firmas')}</h6>
            <ReactSelect 
              placeholder={'Usuario'} 
              noOptionsMessage={({ inputValue: string }) => 'No hay resultados'} 
              defaultValue={selectedSigner} 
              onChange={(e) => { selectedSigner = e }} 
              className="modal-form-input"
              options={GetAllUsersSelect()} 
            />
            <hr className="my-3"/>
            <h6>{'Seleccione los espacios donde se ' + (opt === 1 ? 'agregará al usuario (como primera opción)' : 'quitará al usuario')}</h6>
            <ReactSelect 
              placeholder={''} 
              noOptionsMessage={({ inputValue: string }) => 'No hay resultados'}
              defaultValue={selectedSignSpaces} 
              onChange={(e) => { selectedSignSpaces = e }}
              isMulti={true}
              hideSelectedOptions={false}
              options={availableSignSpaces}
            />
            <div className="modal-delete-confirm-buttons-container mt-3">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (selectedSigner) {
                    if (selectedSignSpaces.length) {
                      cb(selectedSigner, selectedSignSpaces);
                    }else{
                      alert.show('Seleccione al menos un espacio de firma', {type: 'info'});
                    }
                  }else{
                    alert.show('Seleccione un usuario', {type: 'info'});
                  }
                }}
              >
                { opt === 1 ? 'Agregar' : 'Quitar' }
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const AddSigners = (user, signSpaces) => {
    let newData = [...reports];
    let done = 0;
    for (let idx = 0; idx < newData.length; idx++) {
      let changed = false;
      signSpaces.forEach((signSpace) => {
        if (newData[idx][signSpace.opt][0].signs) {
          if (newData[idx][signSpace.opt][0].signs[signSpace.space].status !== "firmado") {
            if (!newData[idx][signSpace.opt][0].signs[signSpace.space].signerOpts.includes(user.value)) {
              newData[idx][signSpace.opt][0].signs[signSpace.space].signerOpts.unshift(user.value);
              changed = true;
            }
          }
        }
      })
      if (changed) { done++ }
    }
    if (done) {
      alert.show('Se agregó al firmante ' + user.label + ' en ' + done + ' de ' + newData.length + ' ensayos.', {type:'success'})
    } else {
      alert.show('No se realizaron cambios. Es posible que todos los ensayos estén firmados o no inicializados', {type: 'info'})
    }
    setReports(newData);
  }

  const RemoveSigners = (user, signSpaces) => {
    let newData = [...reports];
    let done = 0;
    for (let idx = 0; idx < newData.length; idx++) {
      let changed = false;
      signSpaces.forEach((signSpace) => {
        if (newData[idx][signSpace.opt][0].signs) {
          if (newData[idx][signSpace.opt][0].signs[signSpace.space].status !== "firmado") {
            newData[idx][signSpace.opt][0].signs[signSpace.space].signerOpts = newData[idx][signSpace.opt][0].signs[signSpace.space].signerOpts.filter((signer) => signer !== user.value);
            changed = true;
          }
        }
      })
      if (changed) { done++ }
    }
    if (done) {
      alert.show('Se quitó al firmante ' + user.label + ' en ' + done + ' de ' + newData.length + ' ensayos.', {type:'success'})
    } else {
      alert.show('No se realizaron cambios. Es posible que todos los ensayos estén firmados o no inicializados', {type: 'info'})
    }
    setReports(newData);
  }

  const RemoveSign = (idx, opt) => {
    let newData = [...reports];
    if (newData[idx][opt][0].signs) {
      if (!(newData[idx][opt][0].signs.firstSign.status === "firmado" && newData[idx][opt][0].signs.secondSign.status === "firmado")) {
        delete newData[idx][opt][0].signs;
        setReports(newData);
      } else {
        alert.show('No se puede anular un ensayo que tiene todas las firmas', {type:'error'});
      }
    }
  }

  const RemoveAllSigns = (opt) => {
    let newData = [...reports];
    let done = 0;
    let errs = [];
    for (let idx = 0; idx < newData.length; idx++) {
      if (newData[idx][opt][0].signs) {
        if (!(newData[idx][opt][0].signs.firstSign.status === "firmado" && newData[idx][opt][0].signs.secondSign.status === "firmado")) {
          delete newData[idx][opt][0].signs;
          done++;
        } else {
          errs.push(GetReportFullDisplayText(newData[idx]) + ': No se puede anular un ensayo firmado por completo');
        }
      }
    }
    if (done) {
      alert.show('Se eliminaron las firmas en ' + done + ' de ' + newData.length + ' ensayos.')
    }
    if (errs.length) {
      let deleteResults =
        <div className="d-flex flex-column">
          {'Se encontraron los siguientes errores: '}
          {errs.map((element, index) => <span key={index}>{element}</span> )}
        </div>
      alert.show(deleteResults, {type: 'error', timeout: 15000});
    }
    setReports(newData);
  }

  const AreStringArraysEqual = (arr1, arr2) => {
    let flag = true;
    if (arr1.length !== arr2.length) {
      flag = false;
    } else {
      for (let i = 0; i < arr1.length; i++) {
        if (arr1[i] !== arr2[i]) {
          flag = false;
          break;
        }
      }
    }
    return flag;
  }

  const FindSignOptErr = (report, opt) => {
    let foundErr = '';
    if (report[opt].length) {
      if (report[opt][0].signs) {
        if (!report[opt][0].signs.firstSign.signerOpts.length || !report[opt][0].signs.secondSign.signerOpts.length) {
          foundErr = 'Debe asignar al menos 1 firmante para cada firma';
        } else if ((report[opt][0].signs.firstSign.signerOpts.length === 1 && report[opt][0].signs.secondSign.signerOpts.length === 1) && 
        report[opt][0].signs.firstSign.signerOpts[0] === report[opt][0].signs.secondSign.signerOpts[0]) {
          foundErr = 'La misma persona no puede realizar ambas firmas';
        }
      }
    }
    return foundErr;
  }

  const FindErrs = () => {
    let foundErrs = [];
    reports.forEach((report, reportIdx) => {
      const labErr = FindSignOptErr(report, 'labExportInfo');
      const clientErr = FindSignOptErr(report, 'clientExportInfo');
      if (labErr) {
        foundErrs.push({ idx: reportIdx, err: labErr});
      }
      if (clientErr) {
        foundErrs.push({ idx: reportIdx, err: clientErr});
      }
    });
    if (foundErrs.length) {
      let errResp = <> <span>Se encontraron los siguientes errores</span> {foundErrs.map((el, elIdx) => <span key={elIdx}>{GetReportDisplayText(reports[el.idx]) + ': ' + el.err}</span>)} </>
      alert.show(errResp, {type: 'error', timeout: 15000});
      return true;
    } else {
      return false;
    }
  }

  const FindChangesInSign = (idx, opt) => {
    let toSend = null;
    if (ogReports[idx][opt].length) {
      if (!ogReports[idx][opt][0].signs) {
        if (reports[idx][opt][0].signs) {
          toSend = 'create';
        }
      } else {
        if (!reports[idx][opt][0].signs) {
          toSend = 'delete';
        } else {
          if ((ogReports[idx][opt][0].signs.firstSign.status !== reports[idx][opt][0].signs.firstSign.status) ||
          (ogReports[idx][opt][0].signs.secondSign.status !== reports[idx][opt][0].signs.secondSign.status)) {
            toSend = 'reset';
          } else if (!AreStringArraysEqual(ogReports[idx][opt][0].signs.firstSign.signerOpts, reports[idx][opt][0].signs.firstSign.signerOpts) || 
          !AreStringArraysEqual(ogReports[idx][opt][0].signs.secondSign.signerOpts, reports[idx][opt][0].signs.secondSign.signerOpts)) {
            toSend = 'edit';
          }
        }
      }
    }
    return toSend;
  }

  const SaveDataStepOne = () => {
    if (!FindErrs()) {
      let toSend = [];
      for (let i = 0; i < reports.length; i++) {
        const labAction = FindChangesInSign(i, 'labExportInfo');
        const clientAction = FindChangesInSign(i, 'clientExportInfo');
        if (labAction || clientAction) {
          toSend.push({ pos: i, report: reports[i], labAction, clientAction });
        }
      }
      if (toSend.length) {
        SaveDataStepTwo(toSend);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    }
  }

  const GetActionDisplayText = (action) => {
    let displayText = '';
    switch (action) {
      case 'create':
        displayText = 'Crear';
        break;
      case 'edit':
        displayText = 'Editar';
        break;
      case 'delete':
        displayText = 'Eliminar';
        break;
      case 'reset':
        displayText = 'Reiniciar';
        break;
      default:
        break;
    }
    return displayText;
  }

  const SaveDataStepTwo = (dataToSave) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-large-confirm-container">
            <h3>Guardar cambios para firmas electrónicas</h3>
            <p>Revise los cambios realizados y confirme</p>
            <hr />
            <div className="d-flex flex-column p-2 maxh-45vh overflow-auto fs-7">
              <p> Ensayos: </p>
              {dataToSave.map((element, elementIdx) => ( 
                <div key={elementIdx} className="mt-1">
                  <b>{(elementIdx+1) + '. '}</b> 
                  {GetReportDisplayText(element.report)}
                  <b>{element.labAction ? (' | LAB-' + GetActionDisplayText(element.labAction)) : ''}</b>
                  <b>{element.clientAction ? (' | CLIENTE-' + GetActionDisplayText(element.clientAction)) : ''}</b>
                </div> 
              ))}
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button className="modal-confirm-button round-button lb-white me-2" onClick={() => { onClose(); SaveDataStepThree(dataToSave) }}>
                Guardar
              </button>
            </div>
          </div>
        );
      }
    });
  }
  
  const SaveDataStepThree = (dataToSave) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/batch/updateSigns';

    Axios.post(url, {records: dataToSave}, opts)
    .then((res) => {
      setLoading(false);
      const totalLabChangesSent = dataToSave.filter(r => r.labAction).length;
      const totalClientChangesSent = dataToSave.filter(r => r.clientAction).length;
      const labReportsDone = res.data.filter((r, rIdx) => (!r.labErr && dataToSave[rIdx].labAction)).length;
      const clientReportsDone = res.data.filter((r, rIdx) => (!r.clientErr && dataToSave[rIdx].clientAction)).length;
      if (totalLabChangesSent) alert.show(('Se guardaron ' + (labReportsDone + ' de ' + totalLabChangesSent) + ' cambios a firma de LABORATORIO con éxito'), {type: 'success', timeout: 15000});
      if (totalClientChangesSent) alert.show(('Se guardaron ' + (clientReportsDone + ' de ' + totalClientChangesSent) + ' cambios a firma de CLIENTE con éxito'), {type: 'success', timeout: 15000});
      const resErrs = res.data.filter(r => r.labErr || r.clientErr);
      if (resErrs.length) {
        let errResp = <> 
          { resErrs.map((el, elIdx) => (
            <span key={elIdx} className="mt-1 fs-7">{GetReportDisplayText(dataToSave[elIdx].report) + ': ' + el.labErr + (el.clientErr ? ((el.labErr ? '; ' : '') + el.clientErr) : '')}</span>
          ))} 
        </>
        alert.show(errResp, {type: 'error', timeout: 30000});
      }
      setShouldReloadAfterClose(true);
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GetAllUsersSelect = () => {
    return users.map((user) => ({ label: user.fullname, value: user.username }));
  }

  const GetSignersSelect = (signerOpts) => {
    let value = [];
    signerOpts.forEach((so) => {
      let foundUser = usersSelectOpts.find(us => us.value === so)?.label || so;
      value.push({ label: foundUser, value: so });
    })
    return value;
  }

  const AreExportVersionEqual = (report) => {
    let areEqual = true;
    if (report.template?.signData) {
      if (report.labExportInfo.length && report.clientExportInfo.length) {
        if (report.clientExportInfo[0]?.labExportVersion !== report.labExportInfo[0]?.version) {
          areEqual = false;
        }
      }
    }
    return areEqual;
  }

  const GetSignerSelect = (report, reportIdx, dataKey) => {
    return (
      <div className={"d-flex flex-column flex-1 p-2" + GetExportCardStyle(report[availableSignSpaces[dataKey].opt], availableSignSpaces[dataKey].space)}>
        <span className="ps-1"> <b>{availableSignSpaces[dataKey].title}</b>{GetSignStatusText(report[availableSignSpaces[dataKey].opt], availableSignSpaces[dataKey].space)} </span>
        <ReactSelect 
          placeholder={availableSignSpaces[dataKey].placeholder} 
          noOptionsMessage={({ inputValue: string }) => 'No hay resultados'} 
          value={GetSignersSelect(report[availableSignSpaces[dataKey].opt][0].signs[availableSignSpaces[dataKey].space].signerOpts)} 
          onChange={(e) => HandleSignerChange(reportIdx, availableSignSpaces[dataKey].opt, availableSignSpaces[dataKey].space, e)}
          isMulti={true}
          hideSelectedOptions={false}
          options={usersSelectOpts} 
          isDisabled={report[availableSignSpaces[dataKey].opt][0].signs[availableSignSpaces[dataKey].space].status === "firmado"} 
          classNames={{ clearIndicator: () => 'p-1', dropdownIndicator: () => 'p-1' }}
          styles={{ control: (baseStyles) => ({ ...baseStyles, minHeight: '35px' }) }}
        />
      </div>
    )
  }

  const RenderReports = () => {
    if (users.length) {
      if (reports.length) {
        return (
          <>
            {reports.map((report, reportIdx) => (
              <Card key={reportIdx} className="d-flex flex-column align-items-center mb-3 bg-aliceblue">
                <Card.Header className="d-flex align-items-center w-100 flex-wrap">
                  <div className="me-2"><b>{GetReportFullDisplayText(report)}</b></div>
                  <div className="d-flex gap-2">
                    { report.labExportInfo.length && report.labExportInfo[0].signs ?
                      <Button className="rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' onClick={() => RemoveSign(reportIdx, 'labExportInfo')}>
                        Anular Lab
                      </Button>
                    : null }
                    { report.clientExportInfo.length && report.clientExportInfo[0].signs ?
                      <Button className="rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' onClick={() => RemoveSign(reportIdx, 'clientExportInfo')}>
                        Anular Cliente
                      </Button>
                    : null }
                    { report.labExportInfo.length || report.clientExportInfo.length ?
                      <Button className="rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' onClick={() => openLastReport([reports[reportIdx].reportNumber])}>
                        Ver ensayo
                      </Button>
                    : null }
                  </div>
                </Card.Header>
                <Card.Body className="d-flex w-100 gap-2 p-0">
                  <Card className="d-flex flex-1">
                    <div className="bg-aliceblue text-center fw-semibold">Ensayo de Laboratorio</div>
                    { 
                      report.labExportInfo.length ?
                        report.labExportInfo[0].signs ? 
                          <div className="d-flex flex-column flex-xl-row">
                            { GetSignerSelect(report, reportIdx, 0) }
                            { GetSignerSelect(report, reportIdx, 1) }
                          </div>   
                        : 
                          <div className="d-flex flex-1 align-items-center justify-content-center p-2">
                            <Button className="d-flex cursor-pointer fs-inherit py-2" onClick={() => InitSignData(reportIdx, 'labExportInfo')}>
                              <HiOutlineDocumentArrowUp color="white" size={17} className="icon-style me-1"/>
                              <span>Inicializar Datos</span>
                            </Button>
                          </div>
                      : <div className="d-flex flex-1 align-items-center justify-content-center p-2">Finalice este ensayo para firmar</div> 
                    }
                  </Card>
                  <Card className="d-flex flex-1">
                    { !AreExportVersionEqual(report) ? 
                    <div className="bg-danger text-center fw-semibold text-white">Exporte este ensayo para que coincida con la última versión generada de Laboratorio</div> 
                    : null }
                    <div className="bg-aliceblue text-center fw-semibold">Informe de Cliente</div>

                    { 
                      report.clientExportInfo.length ?
                        report.clientExportInfo[0].signs ? 
                          <div className="d-flex flex-column flex-xl-row">
                            { GetSignerSelect(report, reportIdx, 2) }
                            { GetSignerSelect(report, reportIdx, 3) }
                          </div>
                        : 
                          <div className="d-flex flex-1 align-items-center justify-content-center p-2">
                            <Button className="d-flex cursor-pointer fs-inherit py-2" onClick={() => InitSignData(reportIdx, 'clientExportInfo')}>
                              <HiOutlineDocumentArrowUp color="white" size={17} className="icon-style me-1"/>
                              <span>Inicializar Datos</span>
                            </Button>
                          </div>  
                      : <div className="d-flex flex-1 justify-content-center align-items-center p-2">Exporte este ensayo para firmar</div> 
                    }
                  </Card>
                </Card.Body>
              </Card>
            ))}
          </>
        )
      } else {
        return null;
      }
    } else {
      return <div>{loading ? 'Cargando usuarios..' : 'No hay datos para mostrar'}</div> 
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large h-100">
        <ModalHeader title={'Panel de Firmas'} closeModal={() => closeModal(shouldReloadAfterClose)} />
        <div className="d-flex align-items-center my-1">
          <div>Leyenda:</div>
          <div className="bg-warning bg-opacity-25 ms-2 p-1">PENDIENTE</div>
          <div className="bg-primary bg-opacity-25 ms-2 p-1">EN ESPERA</div>
          <div className="bg-success bg-opacity-25 ms-2 p-1">FIRMADO</div>
        </div>
        <div className="flex-1 mt-1 p-1 overflow-auto">
          <div className="d-flex flex-column my-1">
            <div> Paso 1: Generar los datos necesarios para comenzar a firmar los documentos. </div>
            <div> {'Paso 2 (opcional): Cambiar firmantes para un ensayo.'} </div>
            <div> Paso 3: Guardar los cambios realizados. </div>
            <div> Paso 4: Firmar en la aplicación de escritorio. </div>
            <div className="fw-semibold"> Nota: El primer usuario en las opciones de firmantes deberá ser quien firme. </div>
            <div className="fw-semibold"> Nota: Las firmas se realizan en orden, primero firma izquierda y luego firma derecha. </div>
          </div>
          <div className="d-flex flex-wrap align-items-center my-2 gap-2">
            <div> <b>{'Mostrando ' + reportNumbers.length + ' ensayos'}</b> </div>
            <div className="d-flex">
              <Button className="rounded-2 cursor-pointer fs-inherit" variant='success' size="sm" onClick={() => openLastReport(reports.map((report) => report.reportNumber))}>
                <HiOutlineDocumentMagnifyingGlass color="white" size={18} className="icon-style"/>
                <span className="fs-7 mx-1">Ver ensayos</span>
              </Button>
            </div>
            <div className="d-flex">
              <Dropdown size='sm' autoClose="outside">
                <Dropdown.Toggle size='sm' id="dropdown-basic-2" className="d-flex align-items-center px-2">
                  <RiFileEditLine color="white" size={18} className="icon-style"/>
                  <span className="fs-7 mx-1">Cambiar Datos</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="fs-inherit">
                  <Dropdown.ItemText variant="secondary">Para todos los ensayos en esta vista</Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Header className="bg-secondary color-white">Laboratorio</Dropdown.Header>
                  <Dropdown.Item className="py-2" onClick={() => InitAllData('labExportInfo')}>Generar Firmas</Dropdown.Item>
                  {/* <Dropdown.Item className="mt-1 py-2">Reiniciar Firmas</Dropdown.Item> */}
                  <Dropdown.Item className="mt-1 py-2" onClick={() => RemoveAllSigns('labExportInfo')}>Anular Firmas</Dropdown.Item>
                  <Dropdown.Divider />
                  <Dropdown.Header className="bg-secondary color-white">Cliente</Dropdown.Header>
                  <Dropdown.Item className="py-2" onClick={() => InitAllData('clientExportInfo')}>Generar Firmas</Dropdown.Item>
                  {/* <Dropdown.Item className="mt-1 py-2">Reiniciar Firmas</Dropdown.Item> */}
                  <Dropdown.Item className="mt-1 py-2" onClick={() => RemoveAllSigns('clientExportInfo')}>Anular Firmas</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              <Dropdown className="ms-2" autoClose="outside">
                <Dropdown.Toggle size='sm' id="dropdown-basic" className="d-flex align-items-center px-2">
                  <FaUserEdit color="white" size={18} className="icon-style"/>
                  <span className="fs-7 mx-1">Cambiar Firmantes</span>
                </Dropdown.Toggle>
                <Dropdown.Menu className="fs-inherit">
                  <Dropdown.ItemText variant="secondary">Para todos los ensayos en esta vista</Dropdown.ItemText>
                  <Dropdown.Divider />
                  <Dropdown.Item className="py-2" onClick={() => BatchSignerChange(1, AddSigners)}>Agregar Firmante</Dropdown.Item>
                  <Dropdown.Item className="mt-1 py-2" onClick={() => BatchSignerChange(2, RemoveSigners)}>Quitar Firmante</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          {RenderReports()}
        </div>
        <div className="d-flex justify-content-between mt-2">
          <Button className="fs-7 silver-black ms-2" variant="secondary" onClick={e => setReports(JSON.parse(JSON.stringify(ogReports)))} disabled={!reports.length}>Deshacer cambios</Button>
          <Button className="fs-7 lb-white" onClick={e => SaveDataStepOne()}>Guardar cambios</Button>
        </div>
      </div>
    </div>
  )
}

export default ReportSignModal;
