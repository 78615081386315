import React from 'react';
import { Outlet, Navigate } from "react-router";
import { useAuth } from "../Context/Auth";
import { useLocation } from "react-router-dom";
import LoadingSession from './LoadingSession.js';

const SessionRouter = () => {

  const { session } = useAuth();
  const location = useLocation();

  let origin = '/';
  if (location.state) {
    if (location.state.from) {
      origin = location.state.from.pathname || '/';
    }
  }

  if (session) {
    return <Navigate to={origin} replace />
  } else if (session === null) {
    return <LoadingSession />
  } else {
    return <Outlet />
  }
};

export default SessionRouter;