import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalItemSelection } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import { GetReportFullDisplayText } from '../Generic/Constants.js';
import Button from 'react-bootstrap/Button';
import { FaSyncAlt, FaFileExcel, FaFileExport, FaCloudDownloadAlt } from 'react-icons/fa';
import { BsPlus, BsDash } from 'react-icons/bs';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const availablePageScales = [
  { label: 90, value: 1.0 }, 
  { label: 100, value: 1.25 }, 
  { label: 125, value: 1.5 }, 
  { label: 150, value: 1.75 }, 
  { label: 175, value: 2.0 }, 
  { label: 200, value: 2.25 },
  { label: 250, value: 2.75 },
  { label: 300, value: 3.5 },
];

const ReportModalDocPreview = ({ loading: outsideLoading, reports: reportsSrc, src: docSrc, closeModal, finalizeReport, exportReport, downloadReportFile }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageScale, setPageScale] = useState(3);
  const [reports, setReports] = useState([]);
  const [activeSection, setActiveSection] = useState(0);
  const [currentFile, setCurrentFile] = useState(0);

  const isFirstRender = useRef(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const GetLastGeneratedReports = useCallback((reportNumbers, idx) => {

    setLoading(true);

    const opts = {
      headers: {   
        'Content-Type': 'application/json'
      },
      cancelToken: source.token,
    };

    const url = 'api/downloads/reports/batch/last';

    Axios.post(url, { reports: reportNumbers }, opts)
    .then((res) => {
      setLoading(false);
      if (idx || idx === 0) {
        let newReports = [...reports];
        newReports[idx] = res.data[0];
        setReports(newReports);
      } else {
        setReports(res.data);
      }
      let resErrs = res.data.filter(r => r.err);
      if (resErrs.length) {
        let errResp = (<> {resErrs.map((el, elIdx) => <span key={elIdx}>{'Informe N°' + el.reportNumber + ': ' + el.err}</span>)} </>)
        alert.show(errResp, {type: 'error', timeout: 25000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, reports]);

  const GenerateReportsPreview = useCallback((reportNumbers, idx) => {

    setLoading(true);

    const opts = {
      headers: {   
        'Content-Type': 'application/json'
      },
      cancelToken: source.token,
    };

    const url = '/api/exports/batch/preview';

    Axios.post(url, {records: reportNumbers}, opts)
    .then((res) => {
      setLoading(false);
      if (idx || idx === 0) {
        let newReports = [...reports];
        newReports[idx] = res.data[0];
        setReports(newReports);
      } else {
        setReports(res.data);
      }
      let resErrs = res.data.filter(r => r.err);
      if (resErrs.length) {
        let errResp = (<> {resErrs.map((el, elIdx) => <span key={elIdx}>{'Informe N°' + el.reportNumber + ': ' + el.err}</span>)} </>)
        alert.show(errResp, {type: 'error', timeout: 25000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }, [alert, reports]);

  const LoadReportsSwitch = useCallback((reportNumbers, idx) => {
    if (docSrc === 1) {
      GenerateReportsPreview(reportNumbers, idx);
    } else {
      GetLastGeneratedReports(reportNumbers, idx);
    }
  }, [docSrc, GenerateReportsPreview, GetLastGeneratedReports]);

  const RenderReportOpts = () => {
    return (
      <div className="d-flex position-sticky top-0 start-0 user-select-none zIndex-3 bg-secondary">
        <div className="d-flex flex-1 justify-content-center align-items-center flex-wrap">
          <div className="d-flex align-items-center">
            <Button className="d-flex p-1 rounded-circle" variant="secondary" disabled={loading || outsideLoading || (pageScale <= 0)} 
              title="Alejar" onClick={() => setPageScale(pageScale-1)}
            >
              <BsDash color="white" size={22} className="icon-style"/>
            </Button>
            <div className="color-white"> {availablePageScales[pageScale].label + '%'} </div>
            <Button className="d-flex p-1 rounded-circle" variant="secondary" disabled={loading || outsideLoading || (pageScale >= (availablePageScales.length-1))} 
              title="Acercar" onClick={() => setPageScale(pageScale+1)}
            >
              <BsPlus color="white" size={22} className="icon-style"/>
            </Button>
          </div>
          <div className="d-flex align-items-center ms-md-2 gap-md-3 gap-2">
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={loading || outsideLoading} 
              title="Recargar ensayo" onClick={() => LoadReportsSwitch([reports[currentFile].reportNumber], currentFile)}
            >
              <FaSyncAlt color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Recargar</span>
            </Button>
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={loading || outsideLoading} 
              title="Finalizar ensayo laboratorio" onClick={() => finalizeReport([reports[currentFile].reportNumber])}
            >
              <FaFileExcel color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Finalizar Lab</span>
            </Button>
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={loading || outsideLoading} 
              title="Generar informe cliente" onClick={() => exportReport([reports[currentFile].reportNumber])}
            >
              <FaFileExport color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Exportar Cliente</span>
            </Button>
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={(docSrc !== 2) || loading || outsideLoading} 
              title="Descargar" onClick={() => downloadReportFile(reports[currentFile].reportNumber, activeSection)}
            >
              <FaCloudDownloadAlt color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Descargar</span>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  useEffect(() => {
    if(isFirstRender.current){
      if (reportsSrc.length && docSrc) {
        const reportNumbers = reportsSrc.map(r => r.reportNumber);
        LoadReportsSwitch(reportNumbers, null);
      }
    }
  }, [reportsSrc, docSrc, LoadReportsSwitch]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container zIndex-3">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={(docSrc === 1 ? 'Vista Previa' : 'Última versión')} closeModal={closeModal} closeKb={!loading} />
        <ModalItemSelection 
          canChange={!loading} data={reportsSrc} currentItem={currentFile} setItem={(value) => { setNumPages(null); setCurrentFile(Number(value)) }} 
          optClasses={(item, itemIdx) => ""} optText={(opt) => GetReportFullDisplayText(opt)} backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
        />
        { docSrc === 2 ?
          <div className="d-flex align-items-center flex-wrap text-center">
            {['Laboratorio', 'Cliente'].map((item, itemIdx) => {
              const foundFile = [((docSrc !== 1 && reports[currentFile]?.labFile) ? true : false), ((docSrc !== 1 && reports[currentFile]?.clientFile) ? true : false)]
              return (
                <div
                  key={itemIdx}
                  className={"flex-1 py-1" + (foundFile[itemIdx] ? (activeSection === itemIdx ? " bg-lb-white-selected" : " bg-lb-white cursor-pointer") : " bg-danger bg-opacity-50 color-white")}
                  onClick={() => { 
                    if (activeSection !== itemIdx && foundFile[itemIdx]) { 
                      setNumPages(null); 
                      setActiveSection(itemIdx);
                    }
                  }}
                >
                  {item}
                </div>
              )
            }
            )}
          </div>
        : null }
        <div className="modal-pdf-outer-container">
          { reports.length ? RenderReportOpts() : null }
          { reports.length && ((docSrc === 1 && reports[currentFile].file) || ((activeSection === 0 && reports[currentFile].labFile) || (activeSection === 1 && reports[currentFile].clientFile))) ? 
            <Document
              file={docSrc === 1 ? reports[currentFile].file : (activeSection === 0 ? reports[currentFile].labFile : reports[currentFile].clientFile)}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-doc-container d-flex flex-column"
              width={'100%'}
            >
              { numPages ? Array.from({length: numPages}, (_, i) => i + 1).map((page, pageIdx) => 
                <Page
                  key={pageIdx}
                  pageNumber={page}
                  scale={availablePageScales[pageScale].value}
                  className={"d-flex w-100 justify-content-center bg-aliceblue" + (pageIdx < (numPages-1) ? " mb-4" : "")}
                  style={{ left: 'unset', inset: 'unset' }}
                />
              ) : null }
            </Document>
          : 
            <div className="my-4 text-center">
              No se encontró un archivo para mostrar
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ReportModalDocPreview;
