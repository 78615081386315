import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy, useRowSelect } from 'react-table';
import { FixedSizeList } from 'react-window';
import { Link } from "react-router-dom";
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPlus, FaPen, FaCheck, FaUndo, FaSyncAlt, FaTable, FaExternalLinkAlt } from 'react-icons/fa';
// import { VscOpenPreview } from 'react-icons/vsc';
import { MdClear } from 'react-icons/md';
import FWProjectModalAdd from './FWProjectModalAdd';
import FWAssetsModal from './FieldWorkAssets/FWAssetsModal.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { regularStatusSelectOptions } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, FilterDateMethod, SortDateMethod, 
  IndeterminateCheckbox, TableTextInput, TableSelectInput, TableDateInput, TableCheckboxInput, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const projectKeysToCompare = ['title', 'client', 'status', 'renderOnApp', 'startDate', 'endDate'];

function Table({ 
  data, 
  columns, 
  editing, 
  setEditing, 
  getData, 
  handleEdit, 
  addProjectProbing,
  saveEditedData, 
  setIsModalOpen,
  setIsAssetsModalOpen,
  discardChanges, 
  deleteRecord 
}) {

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    SelectCell: TableSelectInput,
    DateCell: TableDateInput,
    CheckboxCell: TableCheckboxInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    toggleAllRowsSelected,
    selectedFlatRows,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      addProjectProbing,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    useRowSelect
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (!selectedFlatRows.length) {
      if (editing) {
        return (
          <>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
              <FaCheck color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
              <FaUndo color="white" size={15} className="icon-style"/>
            </Button>
          </>
        )
      } else {
        return (
          <>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Nueva Muestra" onClick={() => setIsModalOpen(true)}>
              <FaPlus color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Refrescar" onClick={() => getData()}>
              <FaSyncAlt color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" disabled={!data.length} title="Editar" onClick={() => setEditing(true)}>
              <FaPen color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Tabla de Calibración" onClick={() => setIsAssetsModalOpen(true)}>
              <FaTable color="white" size={15} className="icon-style"/>
            </Button>
          </>
        )
      }
    } else {
      return null;
    }
  }

  const RenderRowSelectOptions = () => {
    if (selectedFlatRows.length) {
      return (
        <div className="d-flex align-items-center flex-wrap px-2 gap-2">
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' title="Limpiar selección de filas" 
          onClick={() => toggleAllRowsSelected(false)}>
            <MdClear color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">{selectedFlatRows.length + ' filas'}</span>
          </Button>
          {/* <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Vista previa del ensayo" 
          onClick={() => console.log(selectedFlatRows.map(sr => sr.index), 1)}>
            <VscOpenPreview color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Vista Previa</span>
          </Button> */}
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="table-inner-container">
      <div 
        className="d-flex align-items-center justify-content-between my-1 p-1 flex-wrap" 
        style={{ backgroundColor: editing ? '#ffb130cc' : selectedFlatRows.length ? '#00c30b5c' : 'initial' }}
      >
        <div className="d-flex align-items-center flex-wrap gap-1">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} toggleClass={selectedFlatRows.length ? "btn-success" : null} />
          { RenderRowSelectOptions() }
        </div>
        <div className="d-flex align-items-center gap-1 gap-md-2">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={35}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const FieldWork = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [data, setData] = useState([]);
  const [oldData, setOldData] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAssetsModalOpen, setIsAssetsModalOpen] = useState(false);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Terreno";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/fieldWork';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(JSON.parse(JSON.stringify(res.data)));
      setOldData(JSON.parse(JSON.stringify(res.data)));
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const AddProjectProbingStepOne = (rowId) => {
    let probingCode = '';
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está modificando un proyecto de terreno</h4>
            <p>Ingrese el código del nuevo sondaje</p>
            <input defaultValue={probingCode} onChange={(e) => { probingCode = e.target.value }} />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (probingCode) {
                    onClose();
                    AddProjectProbingStepTwo(data[rowId].code, probingCode);
                  } else {
                    alert.show('Ingrese el código del sondaje', {type: 'info'});
                  }
                }}
              >
                Si, deseo agregar
              </button>
            </div>
          </div>
        );
      }
    });
  }
  const AddProjectProbingStepTwo = (projectCode, probingCode) => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fieldWork/probing/add';

    Axios.post(url, { projectCode, probingCode }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está eliminando un proyecto de terreno</h4>
            <p>Se eliminarán todos los sondajes y calicatas asociadas a este proyecto.</p>
            <p>Esta acción es irreversible.</p>
            <p>{'¿Está seguro(a) de eliminar el proyecto '}{<b>{data[rowId].code}</b>}{'?'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].code);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (record) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está eliminando un proyecto de terreno</h4>
            <p>Confirme ingresando el código interno del proyecto que desea eliminar</p>
            <input type="text" className="modal-form-input" onChange={(e) => { deleteConfirmation = e.target.value }} />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (record === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(record)
                    } else {
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  } else {
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fieldWork/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      if (res.data) {
        if (res.data.err || res.data.msg) {
          alert.show((res.data.err || res.data.msg), {type: (res.data.err ? 'error' : 'success'), timeout: 15000});
        }
        const probingMsg = res.data?.probings.filter(p => p.msg)?.length || 0;
        alert.show(('Se eliminaron ' + probingMsg + ' de ' + res.data?.probings.length + ' sondajes con éxito'), {type: 'success', timeout: 15000});
        res.data.probings.forEach(s => {
          const deletedProbingSamples = s.probingSamples.filter(p => !p.err)?.length || 0;
          const probingSampleErrs = s.probingSamples.filter(p => p.err);
          if (s.err) {
            alert.show(s.err, {type: 'error', timeout: 15000});
          }
          if (deletedProbingSamples) {
            alert.show(('Se eliminaron ' + deletedProbingSamples + ' de ' + s.probingSamples.length + ' muestras con éxito'), {type: 'success', timeout: 15000});
          }
          if (probingSampleErrs.length) {
            let errResp = <> {probingSampleErrs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)} </>
            alert.show(errResp, {type: 'error', timeout: 15000});
          }
        })
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SaveEditedDataStepOne = () => {

    setLoading(true);
    let dataToUpdate = [];

    for (let i = 0; i < data.length; i++) {
      projectKeysToCompare.forEach(dKey => {
        if (data[i][dKey] !== oldData[i][dKey]) {
          dataToUpdate.push(data[i]);
        }
      })
    }

    if (dataToUpdate.length) {
      SaveEditedDataStepTwo(dataToUpdate);
    } else {
      alert.show('No se encontraron modificaciones', {type: 'info'});
      setEditing(false);
      setLoading(false);
    }
  }

  const SaveEditedDataStepTwo = (dataToUpdate) => {

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fieldWork/updateMany';

    Axios.post(url, { data: dataToUpdate }, opts)
    .then((res) => {
      setLoading(false);
      setEditing(false);
      if (res.data?.length) {
        const noErr = res.data?.filter(p => !p.err)?.length;
        const errs = res.data?.filter(p => p.err);
        if (noErr) {
          alert.show(('Se modificaron ' + noErr + ' de ' + res.data?.length + ' proyectos con éxito'), {type: 'success', timeout: 20000});
        }
        if (errs?.length) {
          let errResp = <> {errs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 20000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
    }
  }

  const tableColumns = useMemo(() => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps, editing }) => (
        <div className="d-flex align-items-center justify-content-center py-1">
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps({ title: 'Seleccionar todas las filas', style: { width: '14px', height: '14px' } })} disabled={editing} />
        </div>
      ),
      headerTitleProp: "Seleccionar todas las filas",
      Cell: ({ row, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: '', style: { width: '14px', height: '14px' } })} disabled={editing} />
        </div>
      ),
      disableResizing: true,
      disableFilters: true,
      disableSortBy: true,
      width: 50,
    }, {
      Header: 'Código Interno',
      accessor: 'code',
    }, {
      Header: 'Proyecto/Obra',
      accessor: 'title',
      cellRender: 'EditableCell',
    }, {
      Header: 'Cliente',
      accessor: 'client',
      cellRender: 'EditableCell',
    }, {
      Header: 'Sondajes',
      accessor: row => row,
      Cell: ({ row, selectedFlatRows, addProjectProbing, editing, value }) => (
        <div className="d-flex align-items-center justify-content-center">
          <span>{value?.probings?.length || 0}</span>
          <Button className="ms-2 d-flex p-1 rounded-circle" disabled={editing || selectedFlatRows.length} onClick={() => addProjectProbing(row.index)}>
            <FaPlus size={11} color="white" className="icon-style"/>
          </Button>
          <Link className="ms-3 px-2 py-1 rounded-2 bg-primary" to={('sondaje/'+value.code)}>
            <FaExternalLinkAlt color="white" size={11} className="icon-style me-1"/>
            <span className="fs-8 color-white">Ver</span>
          </Link>
        </div>
      ),
    }, {
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
    }, {
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: regularStatusSelectOptions,
      width: 125,
    }, {
      Header: 'Mostrar en App',
      accessor: 'renderOnApp',
      cellRender: 'CheckboxCell',
      filter: (rows, id, filterValue) => {
        let showKeywords = ['si', 'verdadero', 'true', 'mostrar', 'check'];
        return rows.filter(row => {
          if (showKeywords.some(sk => sk.includes(String(filterValue).trim().toLowerCase()))) {
            // Looking for true values
            if (row.values[id]) return true;
            return false;
          } else {
            // Not looking for true values
            if (!row.values[id]) return true;
            return false;
          }
        })
      },
      sortType: (rowA, rowB, id) => (rowB.values[id] ? 1 : -1),
      width: 110,
    }, {
      Header: 'Fecha Inicio',
      accessor: 'startDate',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Fecha Fin',
      accessor: 'endDate',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Fecha Ingreso',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Ingresado por',
      accessor: 'addedBy',
    }, {
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], []);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isModalOpen ? <FWProjectModalAdd closeModal={() => { setIsModalOpen(false); GetData() }}/> : null}
      {isAssetsModalOpen ? <FWAssetsModal closeModal={() => { setIsAssetsModalOpen(false); GetData() }}/> : null}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        getData={GetData}
        handleEdit={HandleEdit}
        addProjectProbing={AddProjectProbingStepOne}
        saveEditedData={SaveEditedDataStepOne}
        setIsModalOpen={setIsModalOpen}
        setIsAssetsModalOpen={setIsAssetsModalOpen}
        discardChanges={() => { setEditing(false); GetData() }}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default FieldWork;
