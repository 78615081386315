import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import SessionRouter from './SessionRouter.js';
import AuthenticatedRoute from './AuthenticatedRoute';
import AdminRoute from './AdminRoute';
import LabAdminRoute from './LabAdminRoute';
import FWAdminRoute from './FWAdminRoute';
import SuperAdminRoute from './SuperAdminRoute';
import PageLayout from './PageLayout.js';
import SignIn from '../Home/SignIn.js';
import Home from '../Home/Home.js';
import Users from '../Users/Users.js';
import Projects from '../Projects/Projects.js';
import ProjectResults from '../ProjectResults/ProjectResults.js';
import Book from '../Book/Book.js';
import Reports from '../Reports/Reports.js';
import Templates from '../Templates/Templates.js';
import Equipment from '../Equipment/Equipment.js';
import AppManager from '../AppManager/AppManager';
import Validation from '../Validation/Validation.js';
import DocValidation from '../Validation/DocValidation.js';
import Extras from '../Extras/Extras.js';
import FieldWork from '../FieldWork/FieldWork';
import FWProbing from '../FieldWork/FWProbing';
import Dev from '../Dev/Dev.js';
import NotFound from '../ErrorPages/NotFound.js';

const Routing = () => {
  return (
    <Routes>
      <Route element={<SessionRouter />}>
        <Route path="/login" element={<SignIn />} />
      </Route>
      <Route element={ <AuthenticatedRoute /> }>
        <Route element={ <PageLayout navBar={false} /> } >
          <Route path='/' element={ <Home /> } />
        </Route>
        <Route element={ <PageLayout navBar={true} /> } >
          <Route path='apps' element={ <AppManager /> } />
          <Route path="app-movil" element={ <Navigate to="/apps" /> } />
          <Route path="app-firmas" element={ <Navigate to="/apps" /> } />
        </Route>
        <Route element={ <SuperAdminRoute /> }>
          <Route element={ <PageLayout navBar={true} /> } >
            <Route path='/pool/office/dev' element={ <Dev /> } />
          </Route>
        </Route>
        <Route element={ <AdminRoute /> }>
          <Route element={ <PageLayout navBar={true} /> } >
            <Route path='usuarios' element={ <Users /> } />
          </Route>
        </Route>
        <Route element={ <LabAdminRoute /> }>
          <Route element={ <PageLayout navBar={true} /> } >
            <Route path='proyectos' element={ <Projects /> } />
            <Route path='resultados' element={ <ProjectResults /> } />
            <Route path='libro-muestras-de-suelo' element={ <Book /> } />
            <Route path='informes' element={ <Reports /> } />
            <Route path='formularios' element={ <Templates /> } />
            <Route path='equipos' element={ <Equipment /> } />
            <Route path='extras' element={ <Extras /> } />
          </Route>
        </Route>
        <Route element={ <FWAdminRoute /> }>
          <Route element={ <PageLayout navBar={true} /> } >
            <Route path='terreno'>
              <Route index element={ <FieldWork /> } />
              <Route path='sondaje' element={ <FWProbing /> } >
                <Route path=':projectId' element={ <FWProbing /> } />
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
      <Route path="verificar">
        <Route index element={ <Validation /> } />
        <Route path=":code" element={ <DocValidation /> } />
      </Route>
      <Route path="*" element={ <NotFound /> } />
    </Routes>
  )
}

export default Routing;