import React, { useEffect } from 'react';
import logo from '../../assets/img/full-logo.png';
import Spinner from 'react-bootstrap/Spinner';

const LoadingSession = () => {

  useEffect(() => {
    document.title = "MSTD Laboratorio";
  }, [])

  return (
    <div className="d-flex flex-column flex-1 align-items-center" style={{ marginTop: '10%', transition: '5s' }} >
      <img src={logo} alt="MSTD" className="mb-3" style={{ maxWidth: '225px' }} />
      <Spinner variant="primary" animation="grow" style={{ animationDuration: '1.5s' }} />
    </div>
  )
}

export default LoadingSession;
