import React from 'react';
import Form from 'react-bootstrap/Form';

const FormSelectInput = (props) => {
  return (
    <div className="d-md-flex align-items-center justify-content-center p-2">
      { props.title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { props.isRequired ? <span className="text-danger">*</span> : '' }
          { props.title }
        </div>
      : null }
      <Form.Select className="modal-form-input fs-inherit border border-secondary pe-2" size="sm" value={props.value || ''} onChange={(e) => props.handleChange(props.stateKey, e.target.value)}>
        {props.options.map((opt, optIdx) => <option key={optIdx} value={opt.value}> {opt.display} </option>)}
      </Form.Select>
    </div>
  )
}

export default FormSelectInput