import React from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import { ModalHeader } from '../Layout/ModalElements';
import { FaUndo } from 'react-icons/fa';
import { withAlert } from 'react-alert';
import Button from 'react-bootstrap/Button';

class BookModalEditReports extends React.Component{

  state = {
    reports: [],
    initialReports: [],
    sampleData: [],
    lastReport: '',
    saving: false,
    loading: false,
  };

  componentDidMount(){
    this.GetSampleAndReportsOptionsData();
    this.GetLastReport();
  }

  componentWillUnmount() {
    this.source.cancel("Operación cancelada por el usuario");
  }

  CancelToken = Axios.CancelToken;
  source = this.CancelToken.source();

  abortController = new AbortController();

  GetLastReport(){
    const header = {
      headers: {
        'Content-Type': 'application/json',
      }
    };
    let url = '/api/reports/lastReport';
    Axios.get(url, {cancelToken: this.source.token}, header)
    .then((res) => {
      this.setState({lastReport: res.data});
    })
    .catch((err) => {
      if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        this.props.alert.show('No se recibió respuesta del servidor', {type: 'error'});
      } else {
        this.props.alert.show(err.response.data.msg, {type: 'error'});
      }
    });
  }

  GetSampleAndReportsOptionsData(){
    // Get reports bound to a specific sample and get all active templates and its info
    if (this.props.sample) {
      this.setState({loading: true});

      const header = {
        headers: {
          'Content-Type': 'application/json',
        }
      };

      let sampleUrl = '/api/samples/reports/'+this.props.sample.sampleId;
      let templatesUrl = '/api/templates/active';

      let sampleRequest = Axios.get(sampleUrl, {cancelToken: this.source.token}, header);
      let templatesRequest = Axios.get(templatesUrl, {cancelToken: this.source.token}, header);

      Axios.all([sampleRequest, templatesRequest]).then(Axios.spread((...responses) => {
        let reports = [];
        let initialReports = [];
        responses[1].data.forEach((template) => {
          let isFound = responses[0].data.filter(sampleRep => (
            sampleRep.template.code === template.code &&
            sampleRep.template.version === template.version &&
            sampleRep.template.clientCode === template.clientCode &&
            sampleRep.template.clientVersion === template.clientVersion
          ));
          if (isFound.length) {
            // There are reports for this template
            if (isFound.length > 1) {
              // There are two or more reports for this template. Join them and make them unmodifiable
              template.isChecked = true;
              template.isDisabled = true;
              let jointReportNumber = ''+isFound[0].reportNumber;
              for (let i = 1; i < isFound.length; i++) {
                jointReportNumber += ' ('+isFound[i].reportNumber+')';
              }
              template.reportNumber = jointReportNumber;
            }else{
              // Check if it has been exported and make unmodifiable if so
              if (isFound[0].clientExportInfo.length) {
                // Report has been exported. Make unmodifiable
                template.isChecked = true;
                template.isDisabled = true;
                template.reportNumber = isFound[0].reportNumber;
              }else{
                // Report exists but hasnt been exported. Set data.
                template.isChecked = true;
                template.isDisabled = false;
                template.reportNumber = isFound[0].reportNumber;
              }
            }
          }else{
            // There is not report for this template.
            template.isChecked = false;
            template.isDisabled = false;
            template.reportNumber = '';
          }
          reports.push({...template});
          initialReports.push({...template});
        });
        this.setState({ sampleData: responses[0].data, reports, initialReports, loading: false });
      })).catch(err => {
        if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          this.setState({ loading: false }, () => this.props.alert.show('No se recibió respuesta del servidor', {type: 'error'}));
        } else {
          this.setState({ loading: false }, () => this.props.alert.show(err.response.data.msg, {type: 'error'}));
        }
      })
    }else{
      this.props.alert.show('Ocurrió un error al recuperar la muestra', {type: 'error'});
    }
  }

  SetInitialSampleReports(){
    if (this.state.reports.length) {
      let reports = this.state.reports;
      reports.forEach((report) => {
        let isFound = this.state.sampleData.filter(sampleRep => (
          sampleRep.template.code === report.code &&
          sampleRep.template.version === report.version &&
          sampleRep.template.clientCode === report.clientCode &&
          sampleRep.template.clientVersion === report.clientVersion
        ));
        if (isFound.length) {
          // There are reports for this template
          if (isFound.length > 1) {
            // There are two or more reports for this template. Join them and make them unmodifiable
            report.isChecked = true;
            report.isDisabled = true;
            let jointReportNumber = ''+isFound[0].reportNumber;
            for (let i = 1; i < isFound.length; i++) {
              jointReportNumber += ' ('+isFound[i].reportNumber+')';
            }
            report.reportNumber = jointReportNumber;
          }else{
            // Check if it has been exported and make unmodifiable if so
            if (isFound[0].clientExportInfo.length) {
              // Report has been exported. Make unmodifiable
              report.isChecked = true;
              report.isDisabled = true;
              report.reportNumber = isFound[0].reportNumber;
            }else{
              // Report exists but hasnt been exported. Set data.
              report.isChecked = true;
              report.isDisabled = false;
              report.reportNumber = isFound[0].reportNumber;
            }
          }
        }else{
          // There is not report for this template.
          report.isChecked = false;
          report.isDisabled = false;
          report.reportNumber = '';
        }
      });
      this.setState({ reports });
    }
  }

  SaveEditedReports(){
    if (this.props.sample && this.state.reports.length && this.state.initialReports) {

      let isAFieldEmpty = false;
      let reportsToSave = [];

      for (let i = 0; i < this.state.reports.length; i++) {
        if (this.state.reports[i].isChecked !== this.state.initialReports[i].isChecked) {
          // isChecked changed from its initial state
          // This report has to be modified
          if (this.state.reports[i].isChecked) {
            // Means initially it was initially unchecked and now it is checked
            if (!this.state.reports[i].reportNumber) {
              // This report is missing its reportNumber
              isAFieldEmpty = true;
              break;
            }else{
              // This report has to be created or modified to add the sample
              let addAction = {...this.state.reports[i]};
              addAction.action = 'add';
              reportsToSave.push(addAction);
            }
          }else{
            // This report has to be deleted or modified to sustract the sample using the recovered sampleNumber
            let addAction = {...this.state.initialReports[i]};
            addAction.action = 'delete';
            reportsToSave.push(addAction);
          }
        }else{
          // isChecked didnt change from its initial state
          // This report may have its reportNumber changed or not
          if (this.state.reports[i].isChecked) {
            if (!this.state.reports[i].reportNumber) {
              // This report is missing its reportNumber
              isAFieldEmpty = true;
              break;
            }else{
              if (this.state.reports[i].reportNumber !== this.state.initialReports[i].reportNumber){
                // This sample has to change reportNumber for this template.
                // Means this report is to be deleted or modified to remove the sample using the recovered sampleNumber
                // And create or modify to add the sample on the report with the new reportNumber
                let addAction = {...this.state.reports[i]};
                addAction.action = 'modify';
                addAction.oldReportNumber = this.state.initialReports[i].reportNumber;
                reportsToSave.push(addAction);
              }
            }
          }
        }
        // Else, Nothing to do with this report
      }

      if (!isAFieldEmpty) {
        if (reportsToSave.length) {
          this.setState({saving: true});
          const header = {
            headers: {
              'Content-Type': 'application/json'
            }
          };

          const newSampleReports = {
            sampleId: this.props.sample.sampleId,
            reports: reportsToSave,
          };

          // Save modified sample reports Request
          let url = '/api/samples/reports/update';
          Axios.post(url, newSampleReports, {cancelToken: this.source.token}, header)
          .then((res) => {
            this.setState({saving: false}, () => {
              let queryResponse =
                <div className="d-flex flex-column">
                  {'Los resultados de sus modificaciones son: '}
                  {res.data.map((element, index) => {
                    return <span key={index}>{element}</span>
                  })}
                </div>
              this.props.alert.show(queryResponse, {type: 'success', timeout: 60000});
              this.GetSampleAndReportsOptionsData();
              this.GetLastReport();
            });
          })
          .catch((err) => {
            if (!err.response) {
              console.log('Petición cancelada');
            } else if (err.response.status === 500) {
              this.setState({saving: false}, () => this.props.alert.show('No se recibió respuesta del servidor', {type: 'error'}));
            } else {
              this.setState({saving: false}, () => this.props.alert.show(err.response.data.msg, {type: 'error'}));
            }
          });
        }else{
          this.props.alert.show('No hay cambios para guardar', {type: 'info'});
        }
      }else{
        this.props.alert.show('Hay uno o más formularios para modificar sin número de informe', {type: 'info'});
      }
    }else{
      this.props.alert.show('Ocurrió un error al recuperar la muestra o los formularios', {type: 'info'});
    }
  }

  HandleReportNumberChange(report, index, value){
    let newState = this.state.reports;
    newState[index].reportNumber = value;
    this.setState({reports: newState});
  }

  HandleReportCheckBoxChange(report, index){
    let newState = this.state.reports;
    newState[index].isChecked = !newState[index].isChecked;
    this.setState({reports: newState});
  }

  RenderReportOptions(){
    return (
      <div className="d-flex flex-column align-items-center my-2">
        <div className="fw-semibold my-2"> Ensayos a realizar </div>
        <div className="p-2 border border-2">
          { 
            this.state.reports.length ? this.state.reports.map((report, index) => {
              return (
                <div key={index} className="my-1 py-1 modal-form-row-container">
                  <input
                    type="checkbox"
                    checked={report.isChecked}
                    disabled={report.isDisabled}
                    className="modal-form-checkbox ms-2"
                    onChange={(e) => this.HandleReportCheckBoxChange(report, index)}
                  />
                  <input
                    type="text"
                    placeholder={'N° Informe'}
                    className="modal-form-small-input"
                    value={report.reportNumber}
                    disabled={!report.isChecked || report.isDisabled}
                    onChange={(e) => this.HandleReportNumberChange(report, index, e.target.value)}
                  />
                  <span>{report.clientCode+'v'+report.clientVersion+' '+report.code+'v'+report.version+' | '+report.title}</span>
                </div>
              )
            }) : <div>Cargando ensayos..</div>
          }
        </div>
        <div className="d-flex position-sticky bottom-0 w-100 justify-content-center align-items-center py-2 bg-gw">
          <Button className="rounded-circle" size="sm" title="Descartar Cambios" disabled={this.state.saving || this.state.loading} onClick={() => this.SetInitialSampleReports()}>
            <FaUndo color="white"/>
          </Button>
          <span className="ms-2"> Datos Iniciales </span>
        </div>
      </div>
    )
  }

  FormatSampleText = (sample) => {
    if (sample) {
      return (sample.sampleId + ' | ' +
      (sample.trialPitNumber ? (sample.trialPitNumber + ' ') : '') +
      (sample.horizon ? (sample.horizon + ' ') : '') +
      (sample.depth ? (sample.depth) : '')) 
    } else {
      return '';
    }
  }

  RenderLastReportData = () => {
    if (this.state.lastReport) {
      let sample = this.state.lastReport.lastAddedReport.samples.find(Boolean);
      return(
        <div>
          <span>- Último Informe: </span>
          <b>
            <span>{this.state.lastReport.lastAddedReport.reportNumber} </span>
            <span>{this.FormatSampleText(sample)} </span>
            <span>{this.state.lastReport.lastAddedReport.template.title} </span>
            <span>{sample ? sample.project.code : ''} </span>
          </b>
        </div>
      )
    } else {
      return <span>- Ultimo Informe: -</span>;
    }
  }

  RenderLastReportNumber = () => {
    if (this.state.lastReport) {
      if (this.state.lastReport.lastReportNumber.reportNumber !== this.state.lastReport.lastAddedReport.reportNumber) {
        return(
          <div>
            <span>- Último N° Informe: </span>
            <b><span>{this.state.lastReport.lastReportNumber.reportNumber} </span></b>
          </div>
        )
      } else {
        return null;
      }
    } else {
      return null;
    }
  }

  render(){
    return (
      <div className="modal-outer-container">
        <LoadingModal loading={this.state.saving}/>
        <div className="modal-inner-container">
          <ModalHeader title={('Modificar informes para la muestra ' + this.FormatSampleText(this.props.sample))} closeModal={this.props.closeModal} />
          <div className="modal-form-container">
            <div className="d-flex flex-column mb-2">
              <span><span className="text-danger">*</span> Nota: quitar un informe o cambiar un número de 
              informe implica eliminar la muestra de dicho informe.</span>
              <span>- Solo se podrá modificar un informe que no haya sido exportado.</span>
              <span>- Si el formulario requiere 1 muestra, se eliminará el informe.</span>
              <span>- Si el formulario requiere más de una muestra, se desvinculará la muestra del informe.</span>
              {this.RenderLastReportData()}
              {this.RenderLastReportNumber()}
            </div>
            {this.RenderReportOptions()}
          </div>
          <div className="modal-header">
            <button onClick={() => this.SaveEditedReports()} className="modal-form-button lb-white" disabled={this.state.saving || this.state.loading}>
              Guardar
            </button>
          </div>
        </div>
      </div>
    )
  }
}

// PropTypes

export default withAlert()(BookModalEditReports);
