import React from 'react';

const ModalTableDateInput = (props) => {

  return (
    <div className="modal-table-row-container">
      <input
        type="date"
        className="modal-table-input"
        value={props.value || ''}
        onChange={(e) => props.handleChange(props.stateIdx, props.stateKey, e.target.value)}
      />
    </div>
  )
}

export default ModalTableDateInput