import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import ModalTableTextNumericInput from '../Generic/ModalTableTextNumericInput.js';
import PicEqModalAdd from './PicEqModalAdd.js';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { MdPlaylistAdd } from 'react-icons/md';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const PicEq = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  const [equipmentCodes, setEquipmentCodes] = useState([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);

  const isFirstRender = useRef(true);

  const codeExistsStyle = (index) => {
    if (equipmentCodes.indexOf(data[0].equipment[index].code) === -1) {
      return { borderColor: 'red' };
    } else {
      return {};
    }
  }

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const equipmentCodesUrl = '/api/equipment/codes';
    const equipmentPicUrl = '/api/equipment/pic';

    const equipmentCodesRequest = Axios.get(equipmentCodesUrl, opts);
    const equipmentPicRequest = Axios.get(equipmentPicUrl, opts);

    Axios.all([equipmentCodesRequest, equipmentPicRequest]).then(Axios.spread((...responses) => {
      setLoading(false);
      setEquipmentCodes(responses[0].data.filter(code => code.startsWith('PIC-MSTD-')).sort());
      setOldData(JSON.parse(JSON.stringify(responses[1].data)));
      setData(JSON.parse(JSON.stringify(responses[1].data)));
    })).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert])

  const HandleChange = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
    }
  }

  const HandleEqValueChange = (index, key, value) => {
    if (value !== data[index].equipment[key].value) {
      let newData = [...data];
      newData[index].equipment[key].value = value;
      setData(newData);
    }
  }

  const DeleteRecordStepOne = (index) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está eliminando la información de un Equipo</h4>
            <p>{'¿Está seguro(a) de eliminar la temperatura '}{<b>{data[index].temperature}</b>}{' y los valores asociados?'}</p>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); DeleteRecordStepTwo(index) }}> Si, deseo eliminar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const DeleteRecordStepTwo = (index) => {

    const temp = data[index].temperature;

    if (temp) {

      setLoading(true);
  
      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };
  
      const url = '/api/equipment/pic/delete';
  
      Axios.post(url, {temperature: temp}, opts)
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success', timeout: 10000});
        GetData();
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('falta información', {type: 'error'});
    }
  }

  const SaveDataStepOne = () => {
    if (data.length) {
      let toSaveArr = [];
      data.forEach((item, itemIdx) => {
        if (oldData[itemIdx]) {
          if (String(oldData[itemIdx].waterDens) !== String(item.waterDens)) {
            toSaveArr.push(item);
          } else {
            for (let i = 0; i < item.equipment.length; i++) {
              if ((item.equipment[i].code === oldData[itemIdx].equipment[i].code) && (String(item.equipment[i].value) !== (String(oldData[itemIdx].equipment[i].value)))) {
                toSaveArr.push(item);
              }
            }
          }
        }
      });
      if (toSaveArr.length) {
        SaveDataStepTwo(toSaveArr)
      } else {
        alert.show('No se encontraron modificaciones', {type: 'info'});
      }
    } else {
      alert.show('No se realizaron modificaciones', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está modificando información de equipos</h4>
            <p>Se guardarán los cambios para las siguientes temperaturas: </p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {saveArr.map((item, itemIdx) =>  <span key={itemIdx}>{('Temperatura: ' + item.temperature)}</span>)}
            </div>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr) }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/equipment/pic/update';

    Axios.post(url, saveArr, opts)
    .then((res) => {
      setLoading(false);
      let queryResponse =
        <>
          <span className="mb-2">Los resultados de sus modificaciones son: </span>
          { res.data.map((item, itemIdx) => <span key={itemIdx}>{item}</span> )}
        </>
      alert.show(queryResponse, {type: 'success', timeout: 20000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const AddPicEquipmentToDoc = (temperature, code) => {

    if (temperature && code) {

      setLoading(true);
      
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };

      const url = '/api/equipment/pic/addPicRecord';

      Axios.post(url, { temperature, code }, opts)
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success', timeout: 10000});
        GetData();
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('falta información', {type: 'error'});
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <LoadingModal loading={loading}/>
      { isAddModalOpen ? <PicEqModalAdd closeModal={() => { setIsAddModalOpen(false); GetData() }} equipmentCodes={equipmentCodes} /> : null }
      <div className="modal-table-outer-container">
        <div className="d-flex overflow-auto">
          {
            data.length ?
              <>
                <div className="modal-table-sticky-col-header">
                  <div className="hover-bg-light-red px-1 h-100">
                    <div className="modal-table-col-container" style={{minWidth: '80px'}}> Temperatura </div>
                    {
                      data.map((item, itemIdx) => {
                        return (
                          <div key={itemIdx} className="modal-table-col-container" style={{minWidth: '80px'}}>
                            <span className="modal-table-title"> {item.temperature || '-'} </span>
                          </div>
                        )
                      })
                    }
                  </div>
                  <div className="hover-bg-light-red px-1 h-100">
                    <div className="modal-table-col-container"> Dens. Agua </div>
                    {
                      data.map((item, itemIdx) => {
                        return (
                          <div key={itemIdx} className="modal-table-col-container">
                            <ModalTableTextNumericInput stateIdx={itemIdx} stateKey={'waterDens'} value={item.waterDens} handleChange={HandleChange} />
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                {
                  equipmentCodes.length ?
                    equipmentCodes.map((eqCode, eqCodeIndex) => {
                      return (
                        <div key={eqCodeIndex} className="hover-bg-light-red px-1 h-100">
                          <div className="modal-table-col-container">
                            <span className="modal-table-title" style={codeExistsStyle(eqCodeIndex)}> {eqCode || '-'} </span>
                          </div>
                          {
                            data.map((item, itemIdx) => {
                              const foundPicEq = item.equipment.findIndex(eq => eq.code === eqCode);
                              if (foundPicEq !== -1) {
                                return (
                                  <div key={itemIdx} className="modal-table-col-container">
                                    <ModalTableTextNumericInput stateIdx={itemIdx} stateKey={foundPicEq} value={data[itemIdx].equipment[foundPicEq].value} handleChange={HandleEqValueChange} />
                                  </div>
                                )
                              } else {
                                return (
                                  <div key={itemIdx} className="modal-table-col-container">
                                    <div
                                      className="modal-table-icon-container bg-lb-white"
                                      title="Nuevo Registro"
                                      onClick={() => AddPicEquipmentToDoc(item.temperature, eqCode)}
                                    >
                                      <MdPlaylistAdd color="white" size={16} className="icon-style"/>
                                    </div>
                                  </div>
                                )
                              }
                            })
                          }
                        </div>
                      )
                    })
                  :
                    <div className="modal-table-col-container"> No se encontraron equipos </div>
                }
                <div className="hover-bg-light-red px-1 h-100" style={{flex: 0.5, minWidth: '60px'}}>
                  <div className="modal-table-col-container"> - </div>
                  {
                    data.map((item, itemIdx) => {
                      return (
                        <div key={itemIdx} className="modal-table-col-container">
                          <Button className="d-flex p-1 rounded-circle bg-lb-white" disabled={loading} title="Eliminar" onClick={() => DeleteRecordStepOne(itemIdx)}>
                            <FaTrash color="white" className="icon-style"/>
                          </Button>
                        </div>
                      )
                    })
                  }
                </div>
              </>
            :
              <span className="modal-table-loading-text"> No hay temperaturas registradas </span>
          }
        </div>
        <div className="d-flex justify-content-center align-items-center p-1">
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" disabled={loading} title="Nuevo Registro" onClick={() => setIsAddModalOpen(true)}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
        </div>
      </div>
      <div className="mt-1">
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default PicEq;
