import React, { useState } from 'react';
import { useAuth } from "../Context//Auth";
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import { confirmAlert } from 'react-confirm-alert';
import LoadingModal from '../Layout/LoadingModal.js';
import FileDownload from 'js-file-download';
import FormPasswordInput from '../Generic/FormPasswordInput';
import FormFileInput from '../Generic/FormFileInput';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const UserModalEditSelf = ({ closeModal }) => {

  const alert = useAlert();
  const { session } = useAuth();

  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ currentPassword: '',  newPassword: '',  newPasswordConfirmation: '', signCertificate: '', signImg: '' });
  const [uselessParam, setUselessParam] = useState(Math.round((Math.random()*100000), 0));

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const DownloadFile = (opt) => {
    if (session[opt]) {
      setLoading(true);
  
      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Disposition': 'attachment'
        },
        responseType: 'blob',
        cancelToken: source.token
      };
  
      const url = 'api/downloads/users/signFiles';
  
      Axios.post(url, { username: '', opt }, opts)
      .then((res) => {
        FileDownload(res.data, session[opt]);
        setLoading(false);
        alert.show('Se ha descargado su archivo con éxito', {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else if (err.response.status === 404) {
          alert.show('No se pudo descargar el archivo', {type: 'error'})
        } else if (err.response.status === 400) {
          alert.show('No se encontró el archivo', {type: 'error'})
        } else {
          alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
        }
      });
    } else {
      alert.show('No se encontró un archivo para descargar', {type: 'info'})
    }
  }

  const ChangeFile = (opt) => {

    const file = form[opt];
    
    if (file) {
      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        cancelToken: source.token
      };

      const formData = new FormData();
      formData.append('record', JSON.stringify({ username: '' }));
      formData.append('filename', file);

      const url = '/api/users/upload/'+opt;

      Axios.post(url, formData, opts)
      .then((res) => {
        setLoading(false);
        setUselessParam(Math.round((Math.random()*100000), 0));
        alert.show(res.data.msg, {type: 'success', timeout: 10000});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }else{
      alert.show('Suba un archivo', {type: 'info'});
    }
  }

  const DeleteFileStepOne = (opt) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un archivo de firma</h3>
            <p>{'Los archivos de firma y la imagen son necesarios para firmar documentos en el sistema. Confirme para eliminar'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={onClose}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteFileStepTwo(opt);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteFileStepTwo = (opt) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/delete/signFile';
    Axios.post(url, { username: '', opt }, opts)
    .then((res) => {
      setLoading(false);
      setUselessParam(Math.round((Math.random()*100000), 0));
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const ChangePassword = () => {
    const { currentPassword, newPassword, newPasswordConfirmation } = form;
    if (currentPassword && newPassword && newPasswordConfirmation) {
      if (newPassword === newPasswordConfirmation) {
        setLoading(true);
  
        if (source) {
          source.cancel();
          source = CancelToken.source();
        }
    
        const opts = {
          headers: {
            'Content-Type': 'application/json'
          },
          cancelToken: source.token
        };
  
        const newUserPassword = {
          currentPassword,
          newPassword,
          newPasswordConfirmation,
        };
  
        const url = '/api/users/changePassword';
        Axios.post(url, newUserPassword, opts)
        .then((res) => {
          setLoading(false);
          alert.show(res.data.msg, {type: 'success', timeout: 10000});
          closeModal();
        })
        .catch((err) => {
          setLoading(false);
          if (Axios.isCancel(err)) {
            alert.show('Petición cancelada', {type: 'info'});
          } else if (!err.response) {
            console.log('Petición cancelada');
          } else if (err.response.status === 500) {
            alert.show('No se recibió respuesta del servidor', {type: 'error'})
          } else {
            alert.show(err.response.data.msg, {type: 'error'})
          }
        });
      } else {
        alert.show('La confirmación no coincide con la nueva contraseña', {type: 'info'});
      }
    }else{
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={('Cambiar información de usuario - ' + session.username)} closeModal={closeModal} />
        <div className="modal-form-container">
          {/* <FormFileInput title="Certificado Digital (.pfx)" isRequired={false} accept=".pfx" stateKey={'signCertificate'} handleChange={HandleChange} />
          <div className="d-flex ms-2">
            <Button className="lb-white" onClick={() => DownloadFile('signCertificate')} disabled={!session.signCertificate} size="sm">Descargar</Button>
            <Button className="silver-black ms-3" onClick={() => DeleteFileStepOne('signCertificate')} disabled={!session.signCertificate} size="sm">Eliminar</Button>
            <Button className="lb-white ms-3" onClick={() => ChangeFile('signCertificate')} size="sm">Guardar</Button>
          </div> */}
          {/* <hr className="my-2" /> */}
          <FormFileInput title="Cambiar Imagen Firma Digital" isRequired={false} accept="image/*" stateKey={'signImg'} handleChange={HandleChange} />
          <div className="d-flex flex-column justify-content-center align-items-center m-2">
            <div className="d-flex mh-100 w-75 border border-dark">
              <img src={'/api/downloads/users/signImg/'+uselessParam} alt='Imagen Firma' className="w-100 h-100"/>
            </div>
            <div className="d-flex mt-2">
              <Button className="lb-white" onClick={() => DownloadFile('signImg')} disabled={!session.signImg} size="sm">Descargar</Button>
              <Button className="silver-black ms-3" onClick={() => DeleteFileStepOne('signImg')} disabled={!session.signImg} size="sm">Eliminar</Button>
              <Button className="lb-white ms-3" onClick={() => ChangeFile('signImg')} size="sm">Guardar</Button>
            </div>
          </div>
          <hr className="my-2" />
          <FormPasswordInput title="Contraseña Actual" isRequired={true} stateKey={'currentPassword'} value={form.currentPassword} handleChange={HandleChange} />
          <FormPasswordInput title="Nueva Contraseña" isRequired={true} stateKey={'newPassword'} value={form.newPassword} handleChange={HandleChange} />
          <FormPasswordInput title="Confirmación Nueva Contraseña" isRequired={true} stateKey={'newPasswordConfirmation'} value={form.newPasswordConfirmation} handleChange={HandleChange} />
          <div className="ms-2">
            <Button className="lb-white" onClick={() => ChangePassword()} size="sm">Guardar Contraseña</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UserModalEditSelf;
