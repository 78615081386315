import { useState } from 'react';
import { ModalHeader, ModalSections } from '../../Layout/ModalElements';
import FWAssetsProbingPersonnel from './FWAssetsProbingPersonnel';
import FWAssetsProbingEquipment from './FWAssetsProbingEquipment';

const viewSections = ['Personal Sondaje', 'Equipo Sondaje'];

const FWAssetsModal = ({ closeModal }) => {

  const [activeSection, setActiveSection] = useState(0);

  return (
    <div className="modal-outer-container">
      <div className="modal-inner-container-large h-100">
        <ModalHeader title={'Información Adicional Terreno'} closeModal={closeModal} />
        <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
        {activeSection === 0 ? <FWAssetsProbingPersonnel/> : null}
        {activeSection === 1 ? <FWAssetsProbingEquipment/> : null}
      </div>
    </div>
  )
}

export default FWAssetsModal