import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import dayjs from 'dayjs';
import { GetReportSampleDisplayText, reportStatusSelectOptions } from '../Generic/Constants.js';
import LoadingModal from '../Layout/LoadingModal.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const ClasifExtra = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [selectedDay, setSelectedDay] = useState(dayjs().format('YYYY-MM-DD'));

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/revisionHelpers/clasifExtra';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  const FilterReportByDay = (dateKey, report) => {
    let reportDate = dayjs(report.data[dateKey][0].value);
    let currentDay = dayjs(selectedDay);
    let passesFilter = false;
    if (currentDay.isValid()) {
      if (reportDate.isValid()) {
        if (reportDate.isSame(currentDay, 'day')) {
          passesFilter = true;
        }
      }
    }
    return passesFilter;
  }

  const RenderData = () => {

    const tablesData = [
      {idx: 0, date: 'densStartDate', rowTitles: ['Proyecto', 'Muestra', 'Picnómetro N°'], dataKeys: ['field25']},
      {idx: 1, date: 'liquidLimitStartDate', rowTitles: ['Proyecto', 'Muestra', 'LL Cápsula 1', 'LL Cápsula 2', 'LL Cápsula 3'], 
      dataKeys: ['table3LiquidCapsule1', 'table3LiquidCapsule2', 'table3LiquidCapsule3']},
      {idx: 2, date: 'plasticLimitStartDate', rowTitles: ['Proyecto', 'Muestra', 'LP Cápsula 1', 'LP Cápsula 2', 'LP Cápsula 3'], 
      dataKeys: ['table3PlasticCapsule1', 'table3PlasticCapsule2', 'table3PlasticCapsule3']}
    ];

    return (
      <>
        { tablesData.map((tb) => {

          let filteredData = data.filter((report) => FilterReportByDay(tb.date, report));
          filteredData.sort((a, b) => {
            if (tb.dataKeys.length === 1) {
              return ( a.data[tb.dataKeys[0]][0].value - b.data[tb.dataKeys[0]][0].value )
            } else {
              return ( a.data[tb.dataKeys[1]][0].value - b.data[tb.dataKeys[1]][0].value )
            }
          });

          return (
            <div key={tb.idx} className="modal-table-outer-container mb-3 mb-md-4">
              <div className="d-flex overflow-auto">
                <div className="d-flex flex-column h-100 position-sticky start-0 bg-aliceblue border-end border-secondary" style={{minWidth: 'fit-content'}}>
                  {tb.rowTitles.map((header, headerIdx) => <div key={headerIdx} className="p-1" style={{minWidth: '100px'}}> {header} </div> )}
                </div>
                { filteredData.map((report, reportIdx) => {
                  const sampleText = GetReportSampleDisplayText(report);
                  const foundProject = report.samples.find(Boolean)?.project?.code;
                  const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
                  return (
                    <div key={reportIdx} className="d-flex flex-column align-items-center h-100 text-center border-end border-secondary" style={{ maxWidth: '125px' }}>
                      <div className="w-100 py-1 text-truncate" title={foundProject || ' - '}> <span className="px-1">{foundProject}</span> </div>
                      <div className={"w-100 py-1 " + (statusProps?.colorClasses || '')} title={sampleText + ' - ' + (statusProps?.display || ' - ')}>
                        <span className="fw-semibold px-1">{sampleText}</span> 
                      </div>
                      {tb.dataKeys.map((rKey, rKeyIdx) => <div key={rKeyIdx} className="flex-1 p-1" style={{minWidth: '45px'}}> {report.data[rKey][0].value || '-'} </div> )} 
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </>
    )
  }

  const ChangeSelectedDay = (direction) => {
    if (dayjs(selectedDay).isValid()) {
      setSelectedDay(dayjs(selectedDay).add(direction, 'day').format('YYYY-MM-DD'))
    } else {
      alert.show('Seleccione una fecha válida');
    }
  } 

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Taras y Picnómetros usados por día'} closeModal={closeModal} closeKb={!loading} />
        <p>Cada tabla se filtra según el día seleccionado, usando las fechas de inicio de D.P.S, Límite Liquido y Límite Plástico, respectivamente.</p>
        <div className="d-flex align-items-center justify-content-center my-3">
          <Button className="p-1 p-md-2" size="sm" variant="secondary" onClick={() => ChangeSelectedDay(-1)}> Anterior </Button>
          <div className="mx-3">
            <input type="date" className="p-1 p-md-2" value={selectedDay || ''} onChange={(e) => setSelectedDay(e.target.value)} />
          </div>
          <Button className="p-1 p-md-2" size="sm" variant="secondary" onClick={() => ChangeSelectedDay(1)}> Siguiente </Button>
        </div>
        { data.length ? RenderData() : <span className="p-1">No hay ensayos disponibles</span> }
      </div>
    </div>
  )
}

export default ClasifExtra