import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import { useAuth } from "../Context/Auth";
import logo from '../../assets/img/full-logo.png';
import Footer from '../Layout/Footer.js';
import LoadingPage from '../Layout/LoadingPage.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const SignIn = (props) => {

  const alert = useAlert();
  const { Login } = useAuth();

  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    document.title = "MSTD Laboratorio - Login";
  }, [])

  useEffect(() => {
    const HandleKeyDown = (e) => {
      if (e.key === 'Enter' && !loading) {
        e.preventDefault();
        Login(username, password)
      }
    };
    document.addEventListener("keydown", HandleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", HandleKeyDown);
    };
  }, [props, username, password, loading, Login]);

  const ForgotPasswordStepOne = () => {

    // Get username to send mail with a code.
    let forgotUsername = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Olvidé mi contraseña</h4>
            <p>Ingrese su nombre de usuario. Se enviará un código con duración de 20 minutos al correo asociado a esta cuenta, para que lo ingrese en el siguiente paso.</p>
            <input
              type="text"
              className="w-100 my-1"
              onChange={(e) => { forgotUsername = e.target.value }}
            />
            <div className="py-2">
              <span className="text-center cursor-pointer text-blue" onClick={() => {
                if (forgotUsername) {
                  onClose();
                  ForgotPasswordStepThree(forgotUsername);
                }else{
                  alert.show('Ingrese el nombre de usuario', {type: 'info', timeout: 5000});
                }
              }}>
                Ya tengo un código
              </span>
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button
                className="modal-confirm-button round-button silver-black me-2"
                onClick={() => onClose()}
              >
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (forgotUsername) {
                    ForgotPasswordStepTwo(onClose, forgotUsername);
                  }else{
                    alert.show('Ingrese el nombre de usuario', {type: 'info', timeout: 5000});
                  }
                }}
              >
                Enviar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const ForgotPasswordStepTwo = (closeConfirmCb, forgotUsername) => {

    // Send code in mail
    setLoading(true);

    const header = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/forgotPasswordGetCode';

    Axios.post(url, {username: forgotUsername}, header)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      closeConfirmCb();
      ForgotPasswordStepThree(forgotUsername);
    })
    .catch((err) => {
      setLoading(false);
      closeConfirmCb();
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const ForgotPasswordStepThree = (forgotUsername) => {

    // Get code to send and verify.
    let forgotCode = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Olvidé mi contraseña</h4>
            <p>{'Ingrese el código recibido para cambiar la contraseña de '}<b>{forgotUsername}</b></p>
            <input
              type="text"
              className="w-100 my-1"
              onChange={(e) => { forgotCode = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button
                className="modal-confirm-button round-button silver-black me-2"
                onClick={() => onClose()}
              >
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (forgotCode) {
                    ForgotPasswordStepFour(onClose, forgotUsername, forgotCode);
                  }else{
                    alert.show('Ingrese el código recibido', {type: 'info', timeout: 5000});
                  }
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const ForgotPasswordStepFour = (closeConfirmCb, forgotUsername, forgotCode) => {

    setLoading(true);

    const header = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/forgotPasswordConfirmCode';

    Axios.post(url, {username: forgotUsername, code: forgotCode}, header)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      closeConfirmCb();
      ForgotPasswordStepFive(forgotUsername, forgotCode);
    })
    .catch((err) => {
      setLoading(false);
      closeConfirmCb();
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const ForgotPasswordStepFive = (forgotUsername, forgotCode) => {

    // Get new pass and confirmation of the new pass
    let confirmText = '';
    let newPassword = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Olvidé mi contraseña</h4>
            <p>{'Ingrese la nueva contraseña de '}<b>{forgotUsername}</b></p>
            <input
              type="password"
              className="w-100 my-1"
              onChange={(e) => { newPassword = e.target.value }}
            />
            <p>Confirme la nueva contraseña</p>
            <input
              type="password"
              className="w-100 my-1"
              onChange={e => { confirmText = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button
                className="modal-confirm-button round-button silver-black me-2"
                onClick={() => onClose()}
              >
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (newPassword) {
                    if (confirmText) {
                      if (newPassword === confirmText) {
                        ForgotPasswordStepSix(onClose, forgotUsername, newPassword, forgotCode);
                      }else{
                        alert.show('Su confirmación no coincide con la contraseña ingresada', {type: 'info', timeout: 10000});
                      }
                    }else{
                      alert.show('Ingrese la confirmación de su nueva contraseña', {type: 'info', timeout: 5000});
                    }
                  }else{
                    alert.show('Ingrese la nueva contraseña', {type: 'info', timeout: 5000});
                  }
                }}
              >
                Continuar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const ForgotPasswordStepSix = (closeConfirmCb, forgotUsername, newPassword, forgotCode) => {
    // Send username and new pass
    setLoading(true);

    const header = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/forgotPasswordChangePass';

    const data = {
      username: forgotUsername,
      code: forgotCode,
      password: newPassword
    };

    Axios.post(url, data, header)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      closeConfirmCb();
    })
    .catch((err) => {
      setLoading(false);
      closeConfirmCb();
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  return (
    <div className="outer-container">
      <LoadingPage loading={loading}/>
      <div className="login-container d-flex flex-column justify-content-center py-5 px-3 m-auto">
        <div className="d-flex justify-content-center pb-3 mb-3">
          <img src={logo} className="h-100" alt="Logo"/>
        </div>
        <div className="modal-form-container">
          <div className="modal-form-input-container">
            <div className="modal-form-input-title">
              Usuario
            </div>
            <input
              type="text"
              className="modal-form-input"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div className="modal-form-input-container">
            <div className="modal-form-input-title">
              Contraseña
            </div>
            <input
              type="password"
              className="modal-form-input"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>
        <div className="text-center py-3">
          <span className="cursor-pointer text-blue" onClick={() => ForgotPasswordStepOne()}>
            Olvidé mi contraseña
          </span>
        </div>
        <div className="text-center py-3">
          <button onClick={() => Login(username, password)} className="w-100 round-button lb-white">
            Ingresar
          </button>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default SignIn;
