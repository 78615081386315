import React from 'react';

const ModalTableSelectInput = (props) => {

  return (
    <div className="modal-table-row-container">
      <select
        className="modal-table-input"
        value={props.value || ''}
        onChange={(e) => props.handleChange(props.stateIdx, props.stateKey, e.target.value)}
      >
        {props.options.map((opt, optIdx) => <option key={optIdx} value={opt.value}> {opt.label} </option>)}
      </select>
    </div>
  )
}

export default ModalTableSelectInput