import React, { useState, useEffect, useCallback } from 'react';
import logo from '../../assets/img/full-logo.png';
import { useNavigate, Link } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Footer from "../Layout/Footer";

const Validation = () => {

  const navigate = useNavigate();

  const [code, setCode] = useState(''); 

  useEffect(() => {
    document.title = "MSTD Ingeniería - Verificación de Documentos";
  }, []);

  const OpenNewTab = useCallback(() => {
    if (code) {
      navigate(code);
    }
  }, [code, navigate]);

  useEffect(() => {
    const HandleKeyDown = (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        OpenNewTab();
      }
    };
    document.addEventListener("keydown", HandleKeyDown);
    return function cleanup() {
      document.removeEventListener("keydown", HandleKeyDown);
    };
  }, [OpenNewTab]);

  return (
    <>
      <div className="d-flex flex-column h-100 bg-gw">
        <div className="d-flex flex-column justify-content-center mt-2">
          <img src={logo} className="h-100 validation-logo" alt="Logo" />
          <h5 className="d-flex justify-content-center mt-2">Verificación de Documentos</h5>
        </div>
        <div className="validation-text-container d-flex flex-column align-self-center fs-6 my-4 mx-5">
          <div className="d-flex flex-column m-auto w-75 gap-2 text-center">
            <p>En esta página podrá verificar los informes recibidos por parte de MSTD.</p>
            <p>Los documentos disponibles han sido firmados digitalmente con certificados de firma electrónica avanzada, adquiridos con prestadores acreditados, según Ley N° 19799.</p> 
            <p>A continuación puede ingresar el código asociado a su informe y hacer click en el enlace generado.</p>
          </div>
          <div className="d-flex flex-column mt-3 justify-content-center">
            <div className="d-flex align-items-center justify-content-center mb-2">
              <span>Código:</span>
              <input
                type="text"
                className="ms-2"
                value={code}
                onChange={(e) => setCode(e.target.value)}
              />
            </div>
            <div className="d-flex justify-content-center">
              <Link to={code}>
                <Button disabled={!code}>
                  {('Ir a mstdlab.com/verificar/'+code)}
                </Button>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Validation;
