import { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useSortBy, useRowSelect, useExpanded } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaImage, FaSyncAlt, FaPen, FaRegCopy, FaCloudDownloadAlt } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';
import { VscOpenPreview } from 'react-icons/vsc';
import { GrGallery } from "react-icons/gr";
import { MdClear } from 'react-icons/md';
import LoadingModal from '../Layout/LoadingModal.js';
import FWDocPreview from './FWDocPreview.js';
import FWProbingImgsModal from './FWProbingImgsModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { TestSpecialChars, fwStatusRSOpts } from '../Generic/Constants.js';
import { TableToolsToggleColumns, TableHeader, SortDateMethod, IndeterminateCheckbox, 
  TableSelectInput, TableReactSelectInput2, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormReactSelect from '../Generic/FormReactSelect';
import FileDownload from 'js-file-download';

let Buffer = require('buffer/').Buffer;

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();
const specialCharsErrText = 'no puede contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const DisplayImgGallery = (title, modalSubTitle, imgs) => {

  const [show, setShow] = useState(false);

  return (
    <div className="d-flex align-items-center justify-content-center">
      <Button className="p-1 rounded-2 fs-inherit" size='sm' onClick={() => setShow(true)}>
        <FaImage color="white" size={15} className="icon-style me-1"/>
        <span className="fs-8">Imágenes</span>
      </Button>
      <Modal show={show} scrollable className="pb-2" onHide={() => setShow(false)}>
        <Modal.Header closeButton>
          <h5> { 'Imágenes - ' + title } </h5>
        </Modal.Header>
        <h6 className="px-3 mb-2"> { modalSubTitle } </h6>
        <Modal.Body>
          { imgs.length ? imgs.map((img, imgIdx) => {
              return (
                <div key={imgIdx} className="d-flex flex-column justify-content-start mb-2">
                  <img 
                    src={'/api/fieldWork/img/'+img.remoteFilename} 
                    className={"w-100 border border-2 " + (img.status === 'selected' ? "border-primary" : "border-dark")} 
                    style={{objectFit: 'contain'}} 
                    alt={img.remoteFilename}
                    loading="lazy"
                  />
                </div>
              )
            })
          : <span>No se encontraron imágenes para mostrar</span> }
        </Modal.Body>
      </Modal>
    </div>
  )
}

function Table({ data, columns, getData, copyCoordinates, generateSampleFile, generateProbingFile, 
  downloadSampleFile, downloadProbingFile, openGallery, deleteRecord, editProbingCode, editDepth, handleRSEdit }) {

  const defaultColumn = useMemo(() => ({
    SelectCell: TableSelectInput,
    ReactSelectCell2: TableReactSelectInput2,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const SelectRow = (id, value) => {
    const foundRow = rows.find(r => (r.id === id));
    if (foundRow.depth) {
      toggleRowSelected(id, value)
      if (value) {
        const foundParent = rows.find(r => (r.id === id.split('.')[0]));
        if (!foundParent.isSelected) {
          toggleRowSelected(foundParent.id, true);
        }
      }
    } else {
      toggleRowSelected(id, value)
      rows.filter(r => r.id.startsWith(id+'.')).forEach(r => {
        if (value && !r.isSelected) {
          toggleRowSelected(r.id, true);
        } else if (!value && r.isSelected) {
          toggleRowSelected(r.id, false);
        }
      })
    }
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    toggleAllRowsExpanded,
    toggleAllRowsSelected,
    toggleRowSelected,
    selectedFlatRows,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetSortBy: false,
      autoResetExpanded: false,
      getSubRows: row => (row.probingSamples || []),
      copyCoordinates,
      SelectRow,
      deleteRecord,
      editProbingCode,
      editDepth,
      handleRSEdit
    },
    useFlexLayout,
    useResizeColumns,
    useSortBy,
    useExpanded,
    useRowSelect,
  )

  useEffect(() => {
    toggleAllRowsExpanded(true);
  }, [data, toggleAllRowsExpanded])

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    
    let containerClasses = 'align-items-center mt-1';
    if (row.depth) {
      containerClasses += ' rt-expanded-row-highlight ms-5' + (row.index % 2 ? ' rt-expanded-row-even' : ' rt-expanded-row-odd')
    } else {
      containerClasses += ' rt-row-highlight' + (row.index % 2 ? ' rt-row-even' : '')
    }

    return (
      <div className={containerClasses} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
  }, [prepareRow, rows]);

  const RenderEditIcons = () => {
    if (!selectedFlatRows.length) {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Abrir Galería" onClick={() => openGallery()}>
            <GrGallery color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Refrescar" onClick={() => getData()}>
            <FaSyncAlt color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    } else {
      return null;
    }
  }

  const GetSelectedRows = (cb) => {
    let selectedRowIds = [];
    selectedFlatRows.forEach((row) => {
      if (row.depth) {
        let foundRowIdx = selectedRowIds.findIndex(r => r.probing === Number(row.id.split('.')[0]));
        selectedRowIds[foundRowIdx].probingSamples.push(row.index);
      } else {
        selectedRowIds.push({ probing: row.index, probingSamples: [] });
      }
    })
    cb(selectedRowIds)
  }

  const RenderRowSelectOptions = () => {
    if (selectedFlatRows.length) {
      return (
        <div className="d-flex align-items-center flex-wrap px-2 gap-2">
          <Button className="p-1 rounded-2 fs-inherit" size='sm' variant='secondary' title="Limpiar selección de filas" 
          onClick={() => toggleAllRowsSelected(false)}>
            <MdClear color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">{selectedFlatRows.length + ' filas'}</span>
          </Button>
          <Button className="p-1 rounded-2 fs-inherit" size='sm' variant='success' title="Vista previa informe de sondaje" onClick={() => GetSelectedRows(generateProbingFile)}>
            <VscOpenPreview color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Ver Informe Sondaje</span>
          </Button>
          <Button className="p-1 rounded-2 fs-inherit" size='sm' variant='success' title="Descargar planilla de muestras" onClick={() => GetSelectedRows(downloadProbingFile)}>
            <FaCloudDownloadAlt color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Descargar Informe Sondaje</span>
          </Button>
          <Button className="p-1 rounded-2 fs-inherit ms-2" size='sm' variant='success' title="Vista previa planilla de muestras" onClick={() => GetSelectedRows(generateSampleFile)}>
            <VscOpenPreview color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Ver Plantilla Muestras</span>
          </Button>
          <Button className="p-1 rounded-2 fs-inherit" size='sm' variant='success' title="Descargar planilla de muestras" onClick={() => GetSelectedRows(downloadSampleFile)}>
            <FaCloudDownloadAlt color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Descargar Planilla Muestras</span>
          </Button>
        </div>
      )
    } else {
      return null
    }
  }

  return (
    <div className="table-inner-container">
      <div 
        className="d-flex align-items-center justify-content-between my-1 p-1 flex-wrap" 
        style={{ backgroundColor: selectedFlatRows.length ? '#00c30b5c' : 'initial' }}
      >
        <div className="d-flex align-items-center flex-wrap gap-1">
          <div><span className="fw-bold">{ 'Mostrando ' + data.length + ' items' }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} toggleClass={selectedFlatRows.length ? "btn-success" : null} />
          { RenderRowSelectOptions() }
        </div>
        <div className="d-flex align-items-center gap-1 gap-md-2">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={35}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const FWProbing = () => {

  const alert = useAlert();
  let { projectId } = useParams();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [selectedProject, setSelectedProject] = useState('');
  const [users, setUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [data, setData] = useState([]);
  const [docsPreview, setDocsPreview] = useState([]);
  const [docsPreviewSrc, setDocsPreviewSrc] = useState(null);
  const [isImgsModalOpen, setIsImgsModalOpen] = useState(false);
  
  const isFirstRender = useRef(true);
  const lastFetchedProject = useRef(null);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Sondaje";
  }, []);

  const GetExtraData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const requests = [Axios.get('/api/fieldWork/codes', opts), Axios.get('/api/users/names', opts)]

    Axios.all(requests).then(Axios.spread((...responses) => {
      setProjects(responses[0].data);
      setUsers(responses[1].data);
      setLoading(false);
    })).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }, [alert]);

  const GetProjectData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fieldWork/'+selectedProject;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data?.probings || []);
      lastFetchedProject.current = selectedProject;
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, selectedProject]);

  const CopyCoordinates = (text) => {
    if (text) {
      navigator.clipboard.writeText(text)
      alert.show('Coordenadas copiadas al portapapeles', {type: 'info'})
    } else {
      alert.show('No se encontró un valor para copiar al portapapeles', {type: 'info'})
    }
  }

  const GenerateSampleFileStepOne = (rowIds) => {
    let records = [];
    rowIds.forEach((item) => {
      let probingRecord = { 
        probing: data[item.probing]._id, 
        probingCode: data[rowIds[0].probing]?.probingCode, 
        probingNumber: data[rowIds[0].probing].probingNumber, 
        probingSamples: [] 
      };
      item.probingSamples.forEach((sample) => {
        probingRecord.probingSamples.push(data[item.probing].probingSamples[sample]._id);
      })
      records.push(probingRecord);
    })
    setDocsPreview(records);
    setDocsPreviewSrc(1)
  }

  const DownloadSampleFileStepOne = (rowIds) => {
    if (rowIds.length === 1) {
      let record = { 
        probing: data[rowIds[0].probing]._id, 
        probingCode: data[rowIds[0].probing]?.probingCode, 
        probingNumber: data[rowIds[0].probing].probingNumber, 
        probingSamples: [] 
      };
      rowIds[0].probingSamples.forEach((sample) => {
        record.probingSamples.push(data[rowIds[0].probing].probingSamples[sample]._id);
      })
      DownloadSampleFileStepTwo(record);
    } else {
      alert.show('Solo se puede descargar 1 sondaje a la vez', {type: 'info'})
    }
  }

  const DownloadSampleFileStepTwo = (record) => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/fwExports/probing/download/sheet';

    Axios.post(url, {projectCode: selectedProject, record}, opts)
    .then((res) => {
      setLoading(false);
      if (res.data?.err) {
        alert.show(res.data.err, {type: 'error'})
      } else if (!res.data?.file) {
        alert.show('Ocurrió un error al recuperar su archivo', {type: 'error'})
      } else {
        FileDownload(Buffer.from(res.data.file), (res.data?.docName + res.data?.ext));
        alert.show('Se ha descargado su planilla con éxito', {type: 'success'})
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }
  
  const GenerateProbingFileStepOne = (rowIds) => {
    let records = [];
    rowIds.forEach((item) => {
      let probingRecord = { 
        probing: data[item.probing]._id, 
        probingCode: data[rowIds[0].probing]?.probingCode, 
        probingNumber: data[rowIds[0].probing].probingNumber, 
        probingSamples: [] 
      };
      item.probingSamples.forEach((sample) => {
        probingRecord.probingSamples.push(data[item.probing].probingSamples[sample]._id);
      })
      records.push(probingRecord);
    })
    setDocsPreview(records);
    setDocsPreviewSrc(2);
  }

  const DownloadProbingFileStepOne = (rowIds) => {
    let records = [];
    rowIds.forEach((item) => {
      let probingRecord = { 
        probing: data[item.probing]._id, 
        probingCode: data[rowIds[0].probing]?.probingCode, 
        probingNumber: data[rowIds[0].probing].probingNumber, 
        probingSamples: [] 
      };
      item.probingSamples.forEach((sample) => {
        probingRecord.probingSamples.push(data[item.probing].probingSamples[sample]._id);
      })
      records.push(probingRecord);
    })
    DownloadProbingFileStepTwo(records)
  }

  const DownloadProbingFileStepTwo = (records) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/fwExports/probing/download/doc';

    Axios.post(url, {projectCode: selectedProject, records}, opts)
    .then((res) => {
      setLoading(false);
      if (res.data?.errs?.length) {
        let errResp =
          <div className="d-flex flex-column">
            <span> Se encontraron los siguientes errores: </span>
            <hr className="my-2"/>
            { res.data.errs.map((el, elIdx) => <span key={elIdx}>{el}</span> )}
          </div>
        alert.show(errResp, {type: 'error', timeout: 15000});
      } else if (!res.data?.file) {
        alert.show('Ocurrió un error al recuperar su archivo', {type: 'error'})
      } else {
        FileDownload(Buffer.from(res.data.file), (res.data?.docName + res.data?.ext));
        alert.show('Se ha descargado su documento con éxito', {type: 'success'})
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const EditProbingCodeStepOne = (rowId) => {
    let newRecordValue = (data[rowId].probingCode || '');
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>{'Editar Código de Sondaje - ' + (data[rowId]?.probingCode || ('Sondaje N°' + data[rowId].probingNumber))}</h4>
            <p>{'Ingrese el nuevo valor para Código de Sondaje'}</p>
            <input defaultValue={newRecordValue} onChange={(e) => { newRecordValue = e.target.value }} />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (newRecordValue) {
                    if (newRecordValue !== data[rowId].probingCode) {
                      if (!TestSpecialChars(newRecordValue)) {
                        if ((/^[a-zA-Z0-9].*[a-zA-Z0-9]$/i.test(newRecordValue))) {
                          onClose();
                          EditSpecificFieldStepTwo(rowId, 'probingCode', newRecordValue);
                        } else {
                          alert.show('El código del sondaje debe comenzar y terminar con números o letras', {type: 'info'});
                        }
                      } else {
                        alert.show(('El código del sondaje ' + specialCharsErrText), {type: 'info'});
                      }
                    } else {
                      alert.show('No se encontraron cambios para guardar', {type: 'info'});
                    }
                  } else {
                    alert.show('Ingrese el nuevo valor', {type: 'info'});
                  }
                }}
              >
                Editar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const EditDepthStepOne = (rowId) => {
    let newRecordValue = (data[rowId].depth || '');
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>{'Editar Profundidad - ' + (data[rowId]?.probingCode || ('Sondaje N°' + data[rowId].probingNumber))}</h4>
            <p>{'Ingrese el nuevo valor para Profundidad (m)'}</p>
            <p className="my-2"><b>{'Nota: Para decimales usar "." (punto).'}</b></p>
            <input type='number' defaultValue={newRecordValue} onChange={(e) => { newRecordValue = e.target.value }} />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (newRecordValue || (newRecordValue === 0)) {
                    if (newRecordValue !== data[rowId].depth) {
                      onClose();
                      EditSpecificFieldStepTwo(rowId, 'depth', newRecordValue);
                    } else {
                      alert.show('No se encontraron cambios para guardar', {type: 'info'});
                    }
                  } else {
                    alert.show('Ingrese el nuevo valor', {type: 'info'});
                  }
                }}
              >
                Editar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const HandleRSEdit = (rowId, fieldKey, fieldValue) => {
    if (fieldKey === 'responsible') {
      EditSpecificFieldStepTwo(rowId, fieldKey, fieldValue);
    }
  }

  const EditSpecificFieldStepTwo = (rowId, fieldKey, fieldValue) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/fieldWork/probing/update/specific';

    Axios.post(url, { id: data[rowId]._id, fieldKey, fieldValue }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      GetProjectData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DeleteRowStepOne = (rowId) => {
    if (data[rowId].probingCode) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-delete-confirm-container">
              <h4>Está eliminando un Sondaje</h4>
              <p>Se eliminarán todos las muestras asociadas a este sondaje.</p>
              <p>Esta acción es irreversible.</p>
              <p>{'¿Está seguro(a) de eliminar '}{<b>{(data[rowId].probingCode || ('Sondaje N°' + data[rowId].probingNumber))}</b>}{'?'}</p>
              <div className="modal-delete-confirm-buttons-container">
                <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                  No, deseo salir
                </button>
                <button
                  className="modal-confirm-button round-button lb-white"
                  onClick={() => {
                    onClose();
                    DeleteRowStepTwo(data[rowId].probingCode, data[rowId]._id);
                  }}
                >
                  Si, deseo eliminar
                </button>
              </div>
            </div>
          );
        }
      });
    } else {
      alert.show('Este sondaje no tiene código. Por favor modifique este sondaje para poder eliminarlo', {type: 'info'});
    }
  }

  const DeleteRowStepTwo = (record, recordId) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está eliminando un sondaje</h4>
            <p>Confirme ingresando el código del sondaje que desea eliminar</p>
            <input type="text" className="modal-form-input" onChange={(e) => { deleteConfirmation = e.target.value }} />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    console.log(record, deleteConfirmation);
                    if (record === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(recordId)
                    } else {
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  } else {
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/fieldWork/probing/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      if (res.data) {
        if (res.data.err || res.data.msg) {
          alert.show((res.data.err || res.data.msg), {type: (res.data.err ? 'error' : 'success'), timeout: 15000});
        }
        const deletedProbingSamples = res.data.probingSamples.filter(s => !s.err)?.length || 0;
        const probingSampleErrs = res.data.probingSamples.filter(s => s.err);
        if (deletedProbingSamples) {
          alert.show(('Se eliminaron ' + deletedProbingSamples + ' de ' + res.data.probingSamples.length + ' muestras con éxito'), {type: 'success', timeout: 15000});
        }
        if (probingSampleErrs.length) {
          let errResp = <> {probingSampleErrs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      GetProjectData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const ProjectCodesRSOpts = useMemo(() => {
    let newValue = [];
    projects.forEach((item) => {
      newValue.push({ label: item.code, value: item.code });
    })
    return newValue;
  }, [projects])

  const UsernamesRSOpts = useMemo(() => {
    let newValue = [];
    users.forEach((item) => {
      newValue.push({ label: item.fullname, value: item.username });
    })
    return newValue;
  }, [users])

  useEffect(() => {
    if (projectId) {
      const foundProject = projects.find(item => (item.code === projectId));
      if (foundProject) {
        setSelectedProject(projectId);
      }
    }
  }, [projectId, projects]);

  useEffect(() => {
    if (selectedProject && (selectedProject !== lastFetchedProject?.current)) {
      GetProjectData();
    }
  }, [selectedProject, GetProjectData]);

  useEffect(() => {
    if (isFirstRender.current) {
      GetExtraData();
    }
  }, [GetExtraData]);

  useEffect(() => { isFirstRender.current = false }, []);

  const tableColumns = useMemo(() => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps, editing }) => (
        <div className="d-flex align-items-center justify-content-center py-1">
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps({ title: 'Seleccionar todas las filas', style: { width: '14px', height: '14px' } })} disabled={editing} />
        </div>
      ),
      headerTitleProp: "Seleccionar todas las filas",
      Cell: ({ row, SelectRow, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <IndeterminateCheckbox 
            title='' 
            style={{ width: '14px', height: '14px', cursor: 'pointer' }} 
            checked={row.isSelected} 
            disabled={editing} 
            onChange={() => SelectRow(row.id, !row.isSelected)} 
          />
        </div>
      ),
      disableResizing: true,
      disableSortBy: true,
      width: 50,
    }, {
      Header: 'Código Sondaje / Identificación Muestra',
      accessor: row => row,
      Cell: ({ row, selectedFlatRows, editProbingCode, value }) => {
        if (!row.depth) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Button className="me-2 d-flex p-1 rounded-circle" disabled={selectedFlatRows.length} onClick={() => editProbingCode(row.index)}>
                <FaPen size={11} color="white" className="icon-style"/>
              </Button>
              <span>{(value?.probingCode || ('Sondaje N° ' + value.probingNumber))}</span>
            </div>
          )
        } else {
          const fromToText = [(value?.sampleFrom?.[0]?.value || ''), (value?.sampleTo?.[0]?.value || '')].join(' - ')
          return ('Muestra ' + (row.index+1) + ': ' + fromToText)
        }
      },
    }, {
      Header: 'Ubicación / N° Golpes',
      accessor: row => row,
      Cell: ({ row, selectedFlatRows, copyCoordinates, value }) => {
        if (!row.depth) {
          const foundValue = value?.location?.[0]?.value;
          if (foundValue && (foundValue?.latitude && foundValue?.longitude)) {
            return (
              <div className="d-flex">
                <Button 
                  className="d-flex align-items-center p-1 bg-transparent text-truncate" 
                  size="sm"
                  title="Copiar coordenadas"
                  disabled={selectedFlatRows?.length}
                  onClick={() => copyCoordinates(foundValue?.latitude + ', ' + foundValue?.longitude)} 
                >
                  <div> <FaRegCopy size={15} color="black" className="icon-style"/> </div>
                  <div className="ms-1 px-1 text-truncate text-black"> {foundValue?.latitude + ', ' + foundValue?.longitude} </div>
                </Button>
              </div>
            )
          } else {
            return '-'
          }
        } else {
          return [(value?.cellarHits1?.[0]?.value || ''), (value?.cellarHits2?.[0]?.value || ''), (value?.cellarHits3?.[0]?.value || '')].join(' - ')
        }
      },
      disableSortBy: true,
    }, {
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: fwStatusRSOpts,
      width: 125,
    }, {
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      sortType: SortDateMethod,
      sortDescFirst: true
    }, {
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
    }, {
      Header: 'Ver Imágenes',
      accessor: row => row,
      Cell: ({ data, row, value }) => {
        let modalTitle = "";
        let modalSubTitle = "";
        let imgsArr = "";
        if (row.depth) {
          const parentRow = Math.floor(Number(row.id));
          const hitsHelper = [(value?.cellarHits1?.[0]?.value || ''), (value?.cellarHits2?.[0]?.value || ''), (value?.cellarHits3?.[0]?.value || '')].join(' - ')
          modalTitle = (data[parentRow]?.probingCode || ('Sondaje N°' + data[parentRow]?.probingNumber));
          modalSubTitle = ('Muestra #' + (row.index+1) + ': ' + hitsHelper);
          imgsArr = value.sampleImgs;
        } else {
          modalTitle = (value?.probingCode || ('Sondaje N°' + value?.probingNumber));
          imgsArr = value.probingImgs;
        }
        return (
          <div className="d-flex align-items-center justify-content-center">
            {DisplayImgGallery(modalTitle, modalSubTitle, imgsArr)}
          </div>
        )
      },
      disableFilters: true,
      disableSortBy: true,
    },{
      Header: 'Profundidad (m)',
      accessor: 'depth',
      Cell: ({ row, selectedFlatRows, editDepth, value }) => {
        if (!row.depth) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              <Button className="me-2 d-flex p-1 rounded-circle" disabled={selectedFlatRows.length} onClick={() => editDepth(row.index)}>
                <FaPen size={11} color="white" className="icon-style"/>
              </Button>
              <span>{(value || '-')}</span>
            </div>
          )
        } else {
          return '-'
        }
      },
    }, {
      Header: 'Responsable(s)',
      accessor: 'responsible',
      cellRender: 'ReactSelectCell2',
      selectOpts: UsernamesRSOpts,
    }, {
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      subRowRender: false,
      disableSortBy: true,
      width: 100,
    }
  ], [UsernamesRSOpts]);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isImgsModalOpen ? 
        <FWProbingImgsModal 
          projectCode={selectedProject} 
          probingsData={data} 
          getData={GetProjectData}
          closeModal={() => setIsImgsModalOpen(null)} 
        /> 
      : null }
      {(docsPreviewSrc && docsPreview.length) ? 
        <FWDocPreview 
          projectCode={selectedProject} 
          docs={docsPreview} 
          docsSrc={docsPreviewSrc} 
          loading={loading}
          closeModal={() => { setDocsPreview([]); setDocsPreviewSrc(null) }}
        /> 
      : null }
      <div className="d-flex mx-3 mt-2 justify-content-center" style={{ maxWidth: '350px' }}>
        <div className="d-flex flex-1 align-items-center justify-content-center">
          <span>Proyecto: </span>
          <FormReactSelect value={selectedProject} items={ProjectCodesRSOpts} handleChange={(value) => navigate(value)} />
        </div>
      </div>
      <Table
        data={data}
        columns={tableColumns}
        getData={GetProjectData}
        copyCoordinates={CopyCoordinates}
        generateSampleFile={GenerateSampleFileStepOne}
        generateProbingFile={GenerateProbingFileStepOne}
        downloadSampleFile={DownloadSampleFileStepOne}
        downloadProbingFile={DownloadProbingFileStepOne}
        openGallery={() => setIsImgsModalOpen(true)}
        editProbingCode={EditProbingCodeStepOne}
        editDepth={EditDepthStepOne}
        handleRSEdit={HandleRSEdit}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default FWProbing