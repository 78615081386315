import React, { useEffect } from 'react';
import './App.css';
import { AuthProvider } from "./components/Context/Auth";
import Routing from './components/Layout/Routing.js';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import relativeTime from 'dayjs/plugin/relativeTime';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone'; // dependent on utc plugin
import localizedFormat  from 'dayjs/plugin/localizedFormat'; 

function App() {

  useEffect(() => {
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.locale('es');
    dayjs.tz.setDefault("America/Santiago");
  }, [])

  return (
    <div className="App">
      <AuthProvider>
        <Routing />
      </AuthProvider>
    </div>
  )
}

export default App;
