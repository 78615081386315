import React from 'react';

const FormFileInput = (props) => {

  return (
    <div className="d-md-flex align-items-center justify-content-center p-2">
      { props.title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { props.isRequired ? <span className="text-danger">*</span> : '' }
          { props.title }
        </div>
      : null }
      <input
        type="file"
        className="modal-form-input mt-1 mt-md-0"
        accept={ props.accept || "" }
        onChange={e => props.handleChange(props.stateKey, e.target.files[0])}
      />
    </div>
  )
}

export default FormFileInput