import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy, useRowSelect } from 'react-table';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPen, FaCheck, FaUndo, FaSyncAlt, FaFileExport, FaCloudDownloadAlt, FaFileExcel, FaFileSignature } from 'react-icons/fa';
import { BsFiletypeXls, BsFiletypePdf } from 'react-icons/bs';
import { VscOpenPreview } from 'react-icons/vsc';
import { HiOutlineFolderDownload } from 'react-icons/hi';
import { HiOutlineDocumentMagnifyingGlass } from 'react-icons/hi2';
import { GiUpgrade } from 'react-icons/gi';
import { AiOutlineLineChart, AiOutlineCheckCircle, AiOutlineCloseCircle, AiOutlineClockCircle, AiOutlineCopy } from 'react-icons/ai';
import { MdClear } from 'react-icons/md';
import ReportMultiDownload from './ReportMultiDownload.js';
import ReportModalDownloadsPanel from './ReportModalDownloadsPanel.js';
import ReportModalDocPreview from './ReportModalDocPreview.js';
import ReportSignModal from './ReportSignModal.js';
import RevisionModal from './Revision/RevisionModal.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { reportStatusSelectOptions, GetReportFullDisplayText, GetReportFullSampleDisplayText } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, TableFilterData, FilterDateMethod, SortDateMethod, 
  IndeterminateCheckbox, TableTextInput, TableSelectInput, TableCheckboxInput, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileDownload from 'js-file-download';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const copyDownloadsTableTdStyle = { border: '0.5pt solid black', paddingLeft: '1.5pt' };

function Table({ 
  data, 
  columns, 
  editing, 
  setEditing, 
  filterData, 
  handleFilterDataEdit, 
  resetFilterData,
  getData, 
  handleEdit, 
  saveEditedData, 
  updateReport, 
  openReportsPreview, 
  openSignsPanel,
  generateLabReport, 
  exportSingleReport, 
  exportBatchReport, 
  downloadReportFile,
  copyDownloadsTable, 
  setIsRevisionModalOpen, 
  setIsDownloadsPanelModalOpen, 
  setIsMultiDownloadModalOpen, 
  setReportIndexToDownloads, 
  discardChanges, 
  deleteRecord 
}) {

  const isTableFirstRender = useRef(true);
  const listRef = useRef();
  const copyRef = useRef();

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    SelectCell: TableSelectInput,
    CheckboxCell: TableCheckboxInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    toggleAllRowsSelected,
    selectedFlatRows,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['updateReport', 'downloadCode', 'delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      updateReport,
      openReportsPreview,
      openSignsPanel,
      downloadReportFile,
      setReportIndexToDownloads, 
      setIsDownloadsPanelModalOpen,
      generateLabReport, 
      exportSingleReport,
      exportBatchReport,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy,
    useRowSelect
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (!selectedFlatRows.length) {
      if (editing) {
        return (
          <>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
              <FaCheck color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
              <FaUndo color="white" size={15} className="icon-style"/>
            </Button>
          </>
        )
      } else {
        return (
          <>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" disabled={!data.length} title="Revisión de Resultados" onClick={() => setIsRevisionModalOpen(true)}>
              <AiOutlineLineChart color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" disabled={!data.length} title="Descargar Proyecto" onClick={() => setIsMultiDownloadModalOpen(true)}>
              <HiOutlineFolderDownload color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" title="Refrescar" onClick={() => getData()}>
              <FaSyncAlt color="white" size={15} className="icon-style"/>
            </Button>
            <Button className="d-flex p-2 rounded-circle bg-lb-white" disabled={!data.length} title="Editar" onClick={() => { setEditing(true); toggleAllRowsSelected(false) }}>
              <FaPen color="white" size={15} className="icon-style"/>
            </Button>
          </>
        )
      }
    } else {
      return null;
    }
  }

  const RenderRowSelectOptions = () => {
    if (selectedFlatRows.length) {
      return (
        <div className="d-flex align-items-center flex-wrap px-2 gap-2">
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' title="Limpiar selección de filas" 
          onClick={() => toggleAllRowsSelected(false)}>
            <MdClear color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">{selectedFlatRows.length + ' filas'}</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Vista previa del ensayo" 
          onClick={() => openReportsPreview(selectedFlatRows.map(sr => sr.index), 1)}>
            <VscOpenPreview color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Vista Previa</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Ver última versión generada" 
          onClick={() => openReportsPreview(selectedFlatRows.map(sr => sr.index), 2)}>
            <HiOutlineDocumentMagnifyingGlass color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Última Versión</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Finalizar ensayo laboratorio" 
          onClick={() => generateLabReport(selectedFlatRows.map(sr => sr.index))}>
            <FaFileExcel color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Finalizar Lab</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Generar informe cliente" 
          onClick={() => exportBatchReport(selectedFlatRows.map(sr => sr.index))}>
            <FaFileExport color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Exportar Cliente</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='success' title="Abrir panel de firmas" 
          onClick={() => openSignsPanel(selectedFlatRows.map(sr => sr.index))}>
            <FaFileSignature color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Panel de Firmas</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' title="Copiar tabla de descargas de informes" 
          onClick={() => copyDownloadsTable(selectedFlatRows.map(sr => sr.index), copyRef.current.outerHTML)}>
            <AiOutlineCopy color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Copiar Descarga Informes</span>
          </Button>
          <Button className="p-1 rounded-2 cursor-pointer fs-inherit" size='sm' variant='secondary' title="Actualizar versiones de los ensayos" 
          onClick={() => updateReport(selectedFlatRows.map(sr => sr.index))}>
            <GiUpgrade color="white" size={15} className="icon-style me-1"/>
            <span className="fs-8">Actualizar Versión</span>
          </Button>
        </div>
      )
    } else {
      return null
    }
  }

  const RowHeight = (idx) => {
    const foundSamples = rows[idx].values.samples.filter(Boolean).length;
    const foundLabExport = rows[idx].values.labExportInfo.length;
    const foundClientExport = rows[idx].values.clientExportInfo.length;
    return 35 + ((((allColumns[12].isVisible && foundSamples < 2 && foundLabExport) || (allColumns[13].isVisible && foundSamples < 2 && foundClientExport)) ? 10 : 0) + 
    (allColumns[4].isVisible && foundSamples > 1 ? ((foundSamples*17)-17) : 0));
  }

  const CreateCopyDownloadData = () => {
    const cpResults = <>
      {selectedFlatRows.map((item, itemIdx) => <tr key={itemIdx}>
        <td style={copyDownloadsTableTdStyle}>{data[item.index].reportNumber}</td>
        <td style={copyDownloadsTableTdStyle}>{data[item.index].template.completeTitle || data[item.index].template.title}</td>
        <td style={copyDownloadsTableTdStyle}>{GetReportFullSampleDisplayText(data[item.index])}</td>
        <td style={copyDownloadsTableTdStyle}>
          <a href={('https://www.mstdlab.com/verificar/'+data[item.index].downloadCode)}>{('mstdlab.com/verificar/'+data[item.index].downloadCode)}</a>
        </td>
      </tr> )}
    </>
    return cpResults;
  }

  useEffect(() => {
    if (!isTableFirstRender.current && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [rows])

  useEffect(() => { isTableFirstRender.current = false }, []);

  return (
    <div className="table-inner-container">
      <div className="d-none">
        <table ref={copyRef} style={{ width: '100%', fontSize: '10pt', fontFamily: 'Calibri, sans-serif', borderCollapse: 'collapse' }}>
          <thead>
            <tr>{['N° Informe', 'Ensayo', 'Muestra(s)', 'Link'].map((item, itemIdx) => <td key={itemIdx} style={copyDownloadsTableTdStyle}>{item}</td>)}</tr>
          </thead>
          <tbody>{CreateCopyDownloadData()}</tbody>
        </table>
      </div>
      <div className="d-flex align-items-center justify-content-between my-1 p-1 flex-wrap" style={{ backgroundColor: editing ? '#ffb130cc' : selectedFlatRows.length ? '#00c30b5c' : 'initial' }}>
        <div className="d-flex align-items-center flex-wrap gap-1">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} toggleClass={selectedFlatRows.length ? "btn-success" : null} />
          <TableFilterData getData={getData} filterData={filterData} handleFilterDataEdit={handleFilterDataEdit} resetFilterData={resetFilterData} disabled={editing || selectedFlatRows?.length} />
          { RenderRowSelectOptions() }
        </div>
        <div className="d-flex align-items-center gap-1 gap-md-2">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={RowHeight}
                  ref={listRef}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const Reports = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);

  const [isDocPreviewModalOpen, setIsDocPreviewModalOpen] = useState(false);
  const [isRevisionModalOpen, setIsRevisionModalOpen] = useState(false);
  const [isDownloadsPanelModalOpen, setIsDownloadsPanelModalOpen] = useState(false);
  const [isMultiDownloadModalOpen, setIsMultiDownloadModalOpen] = useState(false);
  const [isSignPanelModalOpen, setIsSignPanelModalOpen] = useState(false);
  const [reportIndexToDownloads, setReportIndexToDownloads] = useState(false);
  const [reportsPreview, setReportsPreview] = useState([]);
  const [reportsPreviewSrc, setReportsPreviewSrc] = useState(false);
  const [reportsSignPanel, setReportsSignPanel] = useState([]);

  const [filterData, setFilterData] = useState({ dateStart: dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dateEnd: dayjs().format('YYYY-MM-DD'), resultsLimit: 1500, projectId: '' });
  const [data, setData] = useState([]);
  const [dataIndexToEdit, setDataIndexToEdit] = useState([]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Informes";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };

    const url = '/api/reports/filtered';

    Axios.post(url, filterData, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, filterData]);

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  const UpdateReportVersionStepOne = (rowIds) => {
    if (rowIds.length) {
      if (rowIds.length <= 50) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            return (
              <div className="modal-large-confirm-container">
                <h3>Actualizar Versión Ensayos de Laboratorio</h3>
                <p> Se cambiará a la última versión del formulario, para laboratorio y cliente </p>
                <hr />
                <div className="d-flex flex-column p-2 maxh-45vh overflow-auto fs-7">
                  <p> Ensayos: </p>
                  {rowIds.map((rId, rIdIdx) => ( <div key={rIdIdx} className="mt-1"><b>{(rIdIdx+1) + '. '}</b> {GetReportFullDisplayText(data[rId])}</div> ))}
                </div>
                <div className="modal-delete-confirm-buttons-container">
                  <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                    Cancelar
                  </button>
                  <button className="modal-confirm-button round-button lb-white me-2" onClick={() => { onClose(); UpdateReportVersionStepTwo(rowIds) }}>
                    Actualizar
                  </button>
                </div>
              </div>
            );
          }
        });
      } else {
        alert.show(('Esta funcionalidad permite un máximo de 50 filas seleccionadas (Tiene '+rowIds.length+')'), {type: 'info'})
      }
    } else {
      alert.show('No se recibieron ensayos para finalizar', {type: 'info'})
    }
  }

  const UpdateReportVersionStepTwo = (rowIds) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/batch/updateVersion';

    let records = [];
    rowIds.forEach(ri => {
      records.push(data[ri].reportNumber);
    })

    Axios.post(url, {reports: records}, opts)
    .then((res) => {
      setLoading(false);
      let resultsMsgs = [];
      let resultsNoChange = [];
      let resultsErrs = [];
      if (res.data && res.data.results && res.data.results.length) {
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de su actualización son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 5000});
      }
      if (resultsMsgs.length) {
        GetData();
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un informe</h3>
            <p>{'Solo se puede eliminar un informe si no ha sido exportado previamente. ¿Está seguro(a) de eliminar el informe '}{<b>{data[rowId].reportNumber}</b>}{'?'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].reportNumber);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (record) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un informe</h3>
            <p>Esta acción es irreversible. Confirme ingresando el número del informe que desea eliminar</p>
            <input
              type="text"
              className="modal-form-input"
              onChange={(e) => { deleteConfirmation = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (record === Number(deleteConfirmation)) {
                      onClose();
                      DeleteRowStepThree(record)
                    }else{
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  }else{
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      let deletedElementsAlert =
        <div className="d-flex flex-column">
          {'Los resultados de la eliminación son: '}
          <span>{res.data.msg}</span>
          <hr className="my-2"/>
          <span>{res.data.files}</span>
        </div>
      alert.show(deletedElementsAlert, {type: 'success', timeout: 25000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const OpenReportsPreview = (rowIds, src) => {
    if (rowIds.length) {
      const maxRows = src === 1 ? 50 : src === 2 ? 200 : 5;
      if (rowIds.length <= maxRows) {
        let newReportsPreview = [];
        rowIds.forEach((rowId) => {
          if (rowId !== -1) {
            newReportsPreview.push(data[rowId])
          }
        })
        setReportsPreview(newReportsPreview);
        setReportsPreviewSrc(src);
        setIsDocPreviewModalOpen(true);
      } else {
        alert.show(('Esta funcionalidad permite un máximo de ' + maxRows + ' filas seleccionadas (Tiene '+rowIds.length+')'), {type: 'info'})
      }
    } else {
      alert.show('No se recibieron ensayos para revisar', {type: 'info'})
    }
  }

  const OpenSignsPanel = (rowIds) => {
    if (rowIds.length) {
      if (rowIds.length <= 200) {
        let newReportsSignPanel = [];
        rowIds.forEach(ri => {
          newReportsSignPanel.push(data[ri].reportNumber);
        })
        setReportsSignPanel(newReportsSignPanel);
        setIsSignPanelModalOpen(true);
      } else {
        alert.show(('Esta funcionalidad permite un máximo de 200 filas seleccionadas (Tiene '+rowIds.length+')'), {type: 'info'})
      }
    } else {
      alert.show('No se recibieron ensayos para revisar', {type: 'info'})
    }
  }

  const GenerateLabReportStepOne = (rowIds) => {
    if (rowIds.length) {
      if (rowIds.length <= 50) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            return (
              <div className="modal-large-confirm-container">
                <h3>Finalizar Ensayos de Laboratorio</h3>
                <p> Al finalizar un ensayo no se podrá ingresar datos en la aplicación. </p>
                <hr />
                <div className="d-flex flex-column p-2 maxh-45vh overflow-auto fs-7">
                  <p> Ensayos: </p>
                  {rowIds.map((rId, rIdIdx) => ( <div key={rIdIdx} className="mt-1"><b>{(rIdIdx+1) + '. '}</b> {GetReportFullDisplayText(data[rId])}</div> ))}
                </div>
                <div className="modal-delete-confirm-buttons-container">
                  <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                    Cancelar
                  </button>
                  <button className="modal-confirm-button round-button lb-white me-2" onClick={() => { onClose(); GenerateLabReportStepTwo(rowIds) }}>
                    Finalizar
                  </button>
                </div>
              </div>
            );
          }
        });
      } else {
        alert.show(('Esta funcionalidad permite un máximo de 50 filas seleccionadas (Tiene '+rowIds.length+')'), {type: 'info'})
      }
    } else {
      alert.show('No se recibieron ensayos para finalizar', {type: 'info'})
    }
  }

  const GenerateLabReportStepTwo = (rowIds) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/exports/batch/lab';

    let records = [];
    rowIds.forEach(ri => {
      records.push(data[ri].reportNumber);
    })

    Axios.post(url, {records}, opts)
    .then((res) => {
      setLoading(false);
      const doneReports = res.data.filter(r => !r.err).length;
      if (doneReports) {
        alert.show(('Se finalizaron '+doneReports+' de ' + res.data.length + ' ensayos con éxito'), {type: 'success', timeout: 10000});
      }
      const resErrs = res.data.filter(r => r.err);
      if (resErrs.length) {
        let errResp = <> {resErrs.map((el, elIdx) => <span key={elIdx}>{'Informe N°' + el.reportNumber + ': ' + el.err}</span>)} </>
        alert.show(errResp, {type: 'error', timeout: 25000});
      }
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const ExportSingleReportStepOne = (rowId) => {
    if ((data[rowId].status === "finalizado") || (data[rowId].status === "exportado")) {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-large-confirm-container">
              <h3>Exportar Informe para Cliente</h3>
              <p>{GetReportFullDisplayText(data[rowId])}</p>
              <p>{'Esta acción debiera realizarse solo una vez. Continúe en caso de estar seguro(a) que el ensayo se encuentra listo para entregar al cliente.'}</p>
              { data[rowId].clientExportInfo.length ?
                <>
                  <p><b>Este informe ya ha sido exportado</b></p>
                  <p> Si se han realizado cambios que requieran generar un nuevo informe con los mismos datos, presione "Nuevo".
                  {'\nPara seguir con el informe actual, presione "Continuar".'}</p>
                  <div className="modal-delete-confirm-buttons-container">
                    <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                      Cancelar
                    </button>
                    <button className="modal-confirm-button round-button lb-white me-2" onClick={() => { onClose(); ExportReportStepTwo([rowId]) }}>
                      Continuar
                    </button>
                    <button className="modal-confirm-button round-button lb-white" onClick={() => { onClose(); GenerateNewReportStepOne(rowId) }}>
                      Nuevo
                    </button>
                  </div>
                </>
              :
                <div className="modal-delete-confirm-buttons-container">
                  <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                    Cancelar
                  </button>
                  <button className="modal-confirm-button round-button lb-white" onClick={() => { onClose(); ExportReportStepTwo([rowId]) }}>
                    Exportar
                  </button>
                </div>
              }
            </div>
          );
        }
      });
    }else{
      alert.show('Este informe no ha sido finalizado.', {type: 'error'});
    }
  }

  const ExportBatchReportStepOne = (rowIds) => {
    if (rowIds.length) {
      if (rowIds.length <= 50) {

        const notFinalizedReports = [];
        rowIds.forEach(rId => {
          if ((data[rId].status !== "finalizado") && (data[rId].status !== "exportado")) {
            notFinalizedReports.push(GetReportFullDisplayText(data[rId]));
          }
        })

        if (!notFinalizedReports.length) {
          confirmAlert({
            closeOnEscape: false,
            closeOnClickOutside: false,
            customUI: ({ onClose }) => {
              return (
                <div className="modal-large-confirm-container">
                  <h3>Exportar Informe de Laboratorio para Cliente</h3>
                  <p><b>Si se han realizado cambios que requieran generar un nuevo informe con los mismos datos, use la opcion en la columna para exportar individual</b></p>
                  <hr />
                  <p>{'Esta acción debiera realizarse solo una vez. Continúe en caso de estar seguro(a) que el ensayo se encuentra listo para entregar al cliente.'}</p>
                  <hr />
                  <div className="d-flex flex-column p-2 maxh-45vh overflow-auto fs-7">
                    <p> Ensayos: </p>
                    {rowIds.map((rId, rIdIdx) => ( <div key={rIdIdx} className="mt-1"><b>{(rIdIdx+1) + '. '}</b> {GetReportFullDisplayText(data[rId])}</div> ))}
                  </div>
                  <div className="modal-delete-confirm-buttons-container">
                    <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                      Cancelar
                    </button>
                    <button className="modal-confirm-button round-button lb-white me-2" onClick={() => { onClose(); ExportReportStepTwo(rowIds) }}>
                      Exportar
                    </button>
                  </div>
                </div>
              );
            }
          });
        }else{
          let notFinalizedReportsAlert = (
            <> 
              <span className="mb-1">Los siguientes informes no han sido finalizados</span> 
              {notFinalizedReports.map((el, elIdx) => <span key={elIdx}>{el}</span>)} 
            </>
          )
          alert.show(notFinalizedReportsAlert, {type: 'error', timeout: 10000});
        }
      } else {
        alert.show(('Esta funcionalidad permite un máximo de 50 filas seleccionadas (Tiene '+rowIds.length+')'), {type: 'info'})
      }
    } else {
      alert.show('No se recibieron ensayos para exportar', {type: 'info'})
    }
  }

  const ExportReportStepTwo = (rowIds) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/exports/batch/client';

    let records = [];
    rowIds.forEach(ri => {
      records.push(data[ri].reportNumber);
    })

    Axios.post(url, {records}, opts)
    .then((res) => {
      setLoading(false);
      const doneReports = res.data.filter(r => !r.err).length;
      if (doneReports) {
        alert.show(('Se exportaron '+doneReports+' de ' + res.data.length + ' ensayos con éxito'), {type: 'success', timeout: 10000});
      }
      const resErrs = res.data.filter(r => r.err);
      if (resErrs.length) {
        let errResp = <> {resErrs.map((el, elIdx) => <span key={elIdx}>{'Informe N°' + el.reportNumber + ': ' + el.err}</span>)} </>
        alert.show(errResp, {type: 'error', timeout: 25000});
      }
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GenerateNewReportStepOne = (rowId) => {

    let newReportNumber = '';
    let newReportReason = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Exportar Nuevo Informe</h3>
            <p>{'Indique el nuevo número de informe para el informe N°'}<b>{data[rowId].reportNumber}</b>{', y el motivo.'}</p>
            <div className="d-flex py-3">
              <p className="flex-1">Nuevo N° Informe</p>
              <input
                type="text"
                className="flex-1"
                onChange={e => { newReportNumber = e.target.value }}
              />
            </div>
            <div className="d-flex py-3">
              <p className="flex-1">Motivo</p>
              <input
                type="text"
                className="flex-1"
                onChange={e => { newReportReason = e.target.value }}
              />
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (newReportNumber && newReportReason) {
                    let onlyNumbersPattern = /[^0-9]*/i;
                    if (newReportNumber.match(onlyNumbersPattern)) {
                      onClose();
                      GenerateNewReportStepTwo(rowId, newReportNumber, newReportReason);
                    }else{
                      alert.show('El campo "Nuevo número de informe" sólo acepta números.', {type: 'info', timeout: 10000});
                    }
                  }else{
                    alert.show('Complete los campos "Nuevo número de informe" y "Motivo"', {type: 'info', timeout: 10000});
                  }
                }}
              >
                Generar Nuevo Informe
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const GenerateNewReportStepTwo = (rowId, newReportNumber, newReportReason) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/copyNewReport';

    const newData = {
      oldReportNumber: Number(data[rowId].reportNumber),
      newReportNumber: Number(newReportNumber),
      comments: newReportReason
    }

    Axios.post(url, newData, opts)
    .then((res) => {
      alert.show(res.data.msg, {type: 'success', timeout: 5000});
      setLoading(false);
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SaveEditedDataStepOne = () => {
    if (dataIndexToEdit.length > 0) {
      
      setLoading(true);
      let dataToUpdate = [];
      let incompleteIndexes = [];

      dataIndexToEdit.forEach((dataIndex) => {
        if (!data[dataIndex].reportNumber || !data[dataIndex].status ) {
          if (incompleteIndexes.indexOf(dataIndex) === -1) {
            incompleteIndexes.push(dataIndex);
          }
        }
      });

      if (incompleteIndexes.length) {
        let incompleteIndexesAlert =
          <div className="d-flex flex-column">
            Los siguientes registros tienen campos sin completar:
            {incompleteIndexes.map((element, index) =>  <span key={index}>{data[element].username}</span> )}
          </div>

        setLoading(false);
        alert.show(incompleteIndexesAlert, {type: 'info'});
      }else{
        dataIndexToEdit.forEach((dataIndexToModify) => {
          dataToUpdate.push(data[dataIndexToModify]);
        });

        SaveEditedDataStepTwo(dataToUpdate);
      }
    }else{
      setEditing(false);
      alert.show('No se realizaron modificaciones', {type: 'success'});
    }
  }

  const SaveEditedDataStepTwo = (dataToUpdate) => {

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/updateMany';

    Axios.post(url, dataToUpdate, opts)
    .then((res) => {
      setLoading(false);
      setEditing(false);
      setDataIndexToEdit([]);
      let editResults =
        <div className="d-flex flex-column">
          {'Los resultados de sus modificaciones son: '}
          {res.data.map((element, index) => <span key={index}>{element}</span> )}
        </div>
      alert.show(editResults, {type: 'success', timeout: 25000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
      if (dataIndexToEdit.indexOf(index) === -1) {
        let newEditedIndex = dataIndexToEdit;
        newEditedIndex.push(index);
        setDataIndexToEdit(newEditedIndex);
      }
    }
  }

  const DownloadReportFile = (reportNumber, expObj, opt, formatOpt) => {
    
    // Opt is lab 1 client 2
    // formatOpt is pdf 1 calc 2
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/reports/single/'+reportNumber+'&'+expObj.version+'&'+opt+'&'+formatOpt;

    Axios.get(url, opts)
    .then((res) => {
      const downloadTitle = expObj.documentName + (formatOpt === 1 ? '.pdf' : (expObj.ext || '.xlsx'));
      FileDownload(res.data, downloadTitle);
      setLoading(false);
      alert.show('Se ha descargado su ensayo con éxito', {type: 'success'})
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 409) {
        alert.show('Este informe aún no ha sido firmado', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  const CopyDownloadsTable = (reportIdxs, value) => {
    let foundErrs = [];
    reportIdxs.forEach((idx) => {
      if (!data[idx].template.signData) {
        foundErrs.push(('Informe N°' + data[idx].reportNumber + ': No esta disponible para firma digital'));
      } else {
        const { totalSigns } = GetSignValues(data[idx]);
        if (totalSigns !== 4) {
          foundErrs.push(('Informe N°' + data[idx].reportNumber + ': No ha sido firmado por completo'));
        }
      }
    })
    if (foundErrs.length) {
      let foundErrsMsg =
        <>
          <span className="mb-2">Se encontraron errores al copiar la tabla de descargas: </span>
          {foundErrs.map((element, index) => <span key={index}>{element}</span> )}
        </>
      alert.show(foundErrsMsg, {type: 'error', timeout: 15000});
    }
    const resultsHelp = new Blob([value], {type: 'text/html'}); // WORKS SOMEHOW
    navigator.clipboard.write([new ClipboardItem({'text/html': resultsHelp})]);
    alert.show('Texto copiado al portapapeles');
  }

  const GetSignValues = (report) => {
    let totalSigns = 0;
    let lastSignDate = '';
    let lastSigner = '';
    if (report.template.signData) {
      if (report.labExportInfo[0]?.signs) {
        totalSigns = 0;
        if (report.labExportInfo[0].signs.firstSign.status === "firmado") {
          totalSigns++;
          lastSigner = report.labExportInfo[0].signs.firstSign.signer.username;
          lastSignDate = report.labExportInfo[0].signs.firstSign.signer.date;
        }
        if (report.labExportInfo[0].signs.secondSign.status === "firmado") {
          totalSigns++;
          lastSigner = report.labExportInfo[0].signs.secondSign.signer.username;
          lastSignDate = report.labExportInfo[0].signs.secondSign.signer.date;
        }
        if (report.clientExportInfo[0]?.signs) {
          if (report.clientExportInfo[0].signs.secondSign.status === "firmado") {
            totalSigns++;
            lastSigner = report.clientExportInfo[0].signs.secondSign.signer.username;
            lastSignDate = report.clientExportInfo[0].signs.secondSign.signer.date;
          }
          if (report.clientExportInfo[0].signs.firstSign.status === "firmado") {
            totalSigns++;
            lastSigner = report.clientExportInfo[0].signs.firstSign.signer.username;
            lastSignDate = report.clientExportInfo[0].signs.firstSign.signer.date;
          }
        }
      } else {
        totalSigns = '-';
      }
    }
    return { totalSigns, lastSignDate, lastSigner }
  }

  const HandleFilterDataEdit = (element, value) => {
    let newData = {...filterData};
    newData[element] = value;
    setFilterData(newData);
  }

  const ResetFilterData = () => {
    setFilterData({ dateStart: dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dateEnd: dayjs().format('YYYY-MM-DD'), resultsLimit: 1500, projectId: '' });
  }

  const tableColumns = useMemo(() => [
    {
      id: 'selection',
      Header: ({ getToggleAllRowsSelectedProps, editing }) => (
        <div className="d-flex align-items-center justify-content-center py-1">
          <IndeterminateCheckbox {...getToggleAllRowsSelectedProps({ title: 'Seleccionar todas las filas', style: { width: '14px', height: '14px' } })} disabled={editing} />
        </div>
      ),
      headerTitleProp: "Seleccionar todas las filas",
      Cell: ({ row, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <IndeterminateCheckbox {...row.getToggleRowSelectedProps({ title: '', style: { width: '14px', height: '14px' } })} disabled={editing} />
        </div>
      ),
      disableResizing: true,
      disableFilters: true,
      disableSortBy: true,
      width: 50,
    }, {
      Header: 'N° Informe',
      accessor: 'reportNumber',
      sortDescFirst: true,
      width: 110
    }, {
      id: 'project',
      Header: 'Código Proyecto',
      accessor: row => row.samples.find(sample => sample).project.code,
    }, {
      Header: 'Formulario',
      accessor: 'template',
      Cell: ({ value }) => (
        <div className="text-truncate" title={ value ? (value.title + ' | ' + value.code + '_v' + value.version + ' ' + value.clientCode + '_v' + value.clientVersion) : null }> 
          { value ? (value.title + ' | ' + value.code + '_v' + value.version + ' ' + value.clientCode + '_v' + value.clientVersion) : null } 
        </div>
      ),
      filter: (rows, id, filterValue) => rows.filter(row => row.values[id].title.toLowerCase().includes(filterValue.trim().toLowerCase())),
      sortType: (rowA, rowB, id) => {
        if (rowA.values[id].code === rowB.values[id].code) {
          return rowA.values[id].clientVersion > rowB.values[id].clientVersion ? 1 : -1;
        }else{
          return rowA.values[id].clientCode > rowB.values[id].clientCode ? 1 : -1;
        }
      }
    }, {
      Header: 'Muestra(s)',
      accessor: 'samples',
      Cell: ({ value }) => { 
        const samplesToRender = value ? value.filter(Boolean) : [];
        return (
          <div className="d-flex flex-column">
            {samplesToRender.length ? samplesToRender.map((s, sIdx) => {
              const sampleText = ([s.trialPitNumber, s.horizon, s.depth].filter(Boolean).join(' ')) + ' | ' + s.sampleId;
              return <span key={sIdx} className="text-truncate" title={sampleText}> {sampleText} </span>
            }) : null}
          </div>
        )
      },
      filter: (rows, id, filterValue) => {
        const valueHelper = String(filterValue).trim().toLowerCase();
        return rows.filter(row => {
          const foundSample = row.values[id].find(Boolean);
          if (foundSample) {
            if ((foundSample.trialPitNumber !== null && foundSample.trialPitNumber.toLowerCase().trim().includes(valueHelper)) ||
            (foundSample.horizon !== null && foundSample.horizon.toLowerCase().trim().includes(valueHelper)) ||
            (foundSample.depth !== null && String(foundSample.depth).toLowerCase().trim().includes(valueHelper)) ||
            (foundSample.sampleId !== null && foundSample.sampleId.toLowerCase().trim().includes(valueHelper))) {
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }
        })
      },
      sortType: (rowA, rowB, id) => {
        const foundASample = rowA.values[id].find(Boolean);
        const foundBSample = rowB.values[id].find(Boolean);
        return (('' + foundASample.trialPitNumber).localeCompare(foundBSample.trialPitNumber, 'es', {numeric: true}) || 
        ('' + foundASample.horizon).localeCompare(foundBSample.horizon, 'es', {numeric: true}) || 
        ('' + foundASample.depth).localeCompare(foundBSample.depth, 'es', {numeric: true}) ||
        ('' + foundASample.sampleId).localeCompare(foundBSample.sampleId, 'es', {numeric: true}))
      }
    }, {
      Header: 'Fecha Creación',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true,
      width: 125
    }, {
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true,
      width: 125
    }, {
      Header: 'Última Modificación Por',
      accessor: 'lastModifiedUser',
      width: 125
    }, {
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: reportStatusSelectOptions,
      filter: (rows, id, filterValue) => {
        const valueHelper = String(filterValue).trim().toLowerCase();
        return rows.filter(row => {
          const foundOpt = reportStatusSelectOptions.find(opt => opt.value === row.values[id]);
          return foundOpt ? foundOpt.display.toLowerCase().includes(valueHelper) : false;
        })
      },
      sortType: (rowA, rowB, id) => {
        let valA = reportStatusSelectOptions.find(opt => opt.value === rowA.values[id]);
        let valB = reportStatusSelectOptions.find(opt => opt.value === rowB.values[id]);
        if (valB) {
          if (valA) {
            return valA.sortPos - valB.sortPos;
          } else {
            return 1;
          }
        } else {
          return -1;
        }
      },
      width: 110,
    }, {
      Header: 'Mostrar en App',
      accessor: 'renderOnApp',
      cellRender: 'CheckboxCell',
      filter: (rows, id, filterValue) => {
        let showKeywords = ['si', 'verdadero', 'true', 'mostrar', 'check'];
        return rows.filter(row => {
          if (showKeywords.some(sk => sk.includes(String(filterValue).trim().toLowerCase()))) {
            // Looking for true values
            if (row.values[id]) return true;
            return false;
          } else {
            // Not looking for true values
            if (!row.values[id]) return true;
            return false;
          }
        })
      },
      sortType: (rowA, rowB, id) => (rowB.values[id] ? 1 : -1),
      width: 110,
    }, {
      Header: 'Observaciones (informe cliente)',
      accessor: 'comments',
      cellRender: 'EditableCell',
    }, {
      id: 'preview',
      Header: 'Vista Previa',
      accessor: row => row,
      Cell: ({ row, openReportsPreview, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button className="d-flex p-1 cursor-pointer bg-transparent" onClick={() => openReportsPreview([row.index], 1)} disabled={editing || selectedFlatRows.length}>
            <VscOpenPreview size={16} color={'#0070a7'} className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 125,
    }, {
      Header: 'Lab - Última Versión',
      accessor: 'labExportInfo',
      Cell: ({ row, value, downloadReportFile, openReportsPreview, selectedFlatRows, editing }) => {
        if (value.length) {
          return (
            <div className="px-1">
              <div className="text-center" title={dayjs(value[0].exportedAt).format('LLLL') + ' - ' + value[0].exportedBy}>
                {'('+value.length+') '}
                {dayjs().to(dayjs(value[0].exportedAt))}
              </div>
              <div className="d-flex justify-content-evenly align-items-center">
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Descargar último XLSX Lab'} 
                  onClick={() => downloadReportFile(row.values.reportNumber, value[0], 1, 2)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <BsFiletypeXls size={16} color={'green'} className="icon-style"/>
                </Button>
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Descargar último PDF Lab'} 
                  onClick={() => downloadReportFile(row.values.reportNumber, value[0], 1, 1)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <BsFiletypePdf size={16} color={'red'} className="icon-style"/>
                </Button>
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Ver última versión'} 
                  onClick={() => openReportsPreview([row.index], 2)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <HiOutlineDocumentMagnifyingGlass size={16} color={'black'} className="icon-style"/>
                </Button>
              </div>
            </div>
          )
        } else {
          return <div className="text-center"> - </div>
        }
      },
      filter: (rows, id, filterValue) => {
        return rows.filter( row => row.values[id].length ? dayjs(row.values[id][0].exportedAt).isValid() ? 
          dayjs(row.values[id][0].exportedAt).format('DD-MM-YYYY').includes(String(filterValue).toLowerCase()) : false : false )
      },
      sortType: (rowA, rowB, id, desc) => {
        if (rowB.values.labExportInfo.length) {
          if (rowA.values.labExportInfo.length) {
            if (dayjs(rowB.values.labExportInfo[0].exportedAt).isValid()) {
              if (dayjs(rowA.values.labExportInfo[0].exportedAt).isValid()) {
                if (dayjs(rowA.values.labExportInfo[0].exportedAt).diff(dayjs(rowB.values.labExportInfo[0].exportedAt)) > 0) {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        } else{
          return 1;
        }
      },
      sortDescFirst: true,
      width: 125,
    }, {
      Header: 'Cliente - Última Versión',
      accessor: 'clientExportInfo',
      Cell: ({ row, value, downloadReportFile, openReportsPreview, selectedFlatRows, editing }) => {
        if (value.length) {
          return (
            <div className="px-1">
              <div className="text-center" title={dayjs(value[0].exportedAt).format('LLLL') + ' - ' + value[0].exportedBy}>
                {'('+value.length+') '}
                {dayjs().to(dayjs(value[0].exportedAt))}
              </div>
              <div className="d-flex justify-content-evenly align-items-center">
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Descargar último XLSX Cliente'} 
                  onClick={() => downloadReportFile(row.values.reportNumber, value[0], 2, 2)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <BsFiletypeXls size={16} color={'green'} className="icon-style"/>
                </Button>
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Descargar último PDF Cliente'} 
                  onClick={() => downloadReportFile(row.values.reportNumber, value[0], 2, 1)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <BsFiletypePdf size={16} color={'red'} className="icon-style"/>
                </Button>
                <Button 
                  className="d-flex p-1 cursor-pointer bg-transparent" 
                  title={'Ver última versión'} 
                  onClick={() => openReportsPreview([row.index], 2)} 
                  disabled={editing || selectedFlatRows.length}
                >
                  <HiOutlineDocumentMagnifyingGlass size={16} color={'black'} className="icon-style"/>
                </Button>
              </div>
            </div>
          )
        } else {
          return <div className="text-center"> - </div>
        }
      },
      filter: (rows, id, filterValue) => {
        return rows.filter( row => row.values[id].length ? dayjs(row.values[id][0].exportedAt).isValid() ? 
          dayjs(row.values[id][0].exportedAt).format('DD-MM-YYYY').includes(String(filterValue).toLowerCase()) : false : false )
      },
      sortType: (rowA, rowB, id, desc) => {
        if (rowB.values.clientExportInfo.length) {
          if (rowA.values.clientExportInfo.length) {
            if (dayjs(rowB.values.clientExportInfo[0].exportedAt).isValid()) {
              if (dayjs(rowA.values.clientExportInfo[0].exportedAt).isValid()) {
                if (dayjs(rowA.values.clientExportInfo[0].exportedAt).diff(dayjs(rowB.values.clientExportInfo[0].exportedAt)) > 0) {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          }else {
            return -1;
          }
        }else{
          return 1;
        }
      },
      sortDescFirst: true,
      width: 125,
    }, {
      id: 'signStatus',
      Header: 'Estado Firma',
      accessor: row => row,
      Cell: ({ row }) => {
        if (!row.values.template.signData) {
          return <div className="text-center fw-light text-secondary fst-italic"> Firma en papel </div>
        } else {
          const { totalSigns, lastSignDate, lastSigner } = GetSignValues(row.values);
          if (totalSigns !== '-') {
            return (
              <div className="px-1">
                <div className="d-flex align-items-center justify-content-center">
                  { 
                    totalSigns === 0 ? <AiOutlineCloseCircle size={20} color="red" className="icon-style"/> : 
                    totalSigns === 4 ? <AiOutlineCheckCircle size={20} color="green" className="icon-style"/> :
                    <AiOutlineClockCircle size={20} color="#FFAA1D" className="icon-style"/> 
                  }
                  <div className="d-flex flex-column text-center ms-1" title={lastSignDate ? (dayjs(lastSignDate).format('LLLL') + ' - ' + lastSigner) : ''}>
                    <span>{totalSigns + ' de 4 firmas'}</span>
                    <span>{lastSignDate ? dayjs().to(dayjs(lastSignDate)) : ''}</span>
                  </div>
                </div>
              </div>
            )
          } else {
            return <div className="text-center"> - </div>
          }
        }
      },
      filter: (rows, id, filterValue) => {
        const valueHelper = String(filterValue).trim().toLowerCase();
        if (valueHelper) {
          return rows.filter(row => {
            if ('firma en papel'.includes(valueHelper)) {
              return row.values.template.signData ? false : true;
            } else {
              const { totalSigns } = GetSignValues(row.values);
              return (row.values.template.signData && (String(totalSigns) === valueHelper))
            }
          })
        } else {
          return rows;
        }
      },
      sortType: (rowA, rowB, id, desc) => {
        const { lastSignDate: lastSignDateA } = GetSignValues(rowA.values);
        const { lastSignDate: lastSignDateB } = GetSignValues(rowB.values);
        if (rowB.values.template.signData) {
          if (rowA.values.template.signData) {
            if (dayjs(lastSignDateB).isValid()) {
              if (dayjs(lastSignDateA).isValid()) {
                if (dayjs(lastSignDateA).diff(dayjs(lastSignDateB)) > 0) {
                  return 1;
                } else {
                  return -1;
                }
              } else {
                return -1;
              }
            } else {
              return 1;
            }
          } else {
            return -1;
          }
        } else {
          return 1;
        }
      },
      sortDescFirst: true,
      width: 175
    }, {
      id: 'downloadPanel',
      Header: 'Panel de Descargas',
      accessor: row => row,
      Cell: ({ row, setReportIndexToDownloads, setIsDownloadsPanelModalOpen, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button 
            className="d-flex p-1 cursor-pointer bg-transparent" 
            disabled={editing || (!row.values.labExportInfo.length && !row.values.clientExportInfo.length) || selectedFlatRows.length}
            onClick={() => { setReportIndexToDownloads(row.index); setIsDownloadsPanelModalOpen(true)}} 
          >
            <FaCloudDownloadAlt size={16} color="black" className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 125,
    }, {
      id: 'finalizeLab',
      Header: 'Finalizar Ensayo Lab',
      accessor: row => row,
      Cell: ({ row, generateLabReport, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button 
            className="d-flex p-1 cursor-pointer bg-transparent" 
            disabled={editing || selectedFlatRows.length}
            onClick={() => generateLabReport([row.index])} 
          >
            <FaFileExcel size={16} color="green" className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    }, {
      id: 'finalizeClient',
      Header: 'Generar Informe Cliente',
      accessor: row => row,
      Cell: ({ row, exportSingleReport, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button 
            className="d-flex p-1 cursor-pointer bg-transparent" 
            disabled={editing || !row.values.labExportInfo.length || selectedFlatRows.length}
            onClick={() => exportSingleReport(row.index)} 
          >
            <FaFileExport size={16} color="red" className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    }, {
      id: 'signs',
      Header: 'Panel de Firmas',
      accessor: row => row,
      Cell: ({ row, openSignsPanel, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button 
            className="d-flex p-1 cursor-pointer bg-transparent" 
            onClick={() => openSignsPanel([row.index])} 
            disabled={editing || (!row.values.labExportInfo.length && !row.values.clientExportInfo.length) || selectedFlatRows.length}
          >
            <FaFileSignature size={16} color={'black'} className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
      width: 125,
    }, {
      id: 'updateReport',
      Header: 'Cambiar a última versión',
      accessor: row => row,
      Cell: ({ row, updateReport, selectedFlatRows, editing }) => (
        <div className="d-flex align-items-center justify-content-center">
          <Button 
            className="d-flex p-1 cursor-pointer bg-transparent" 
            disabled={editing || selectedFlatRows.length}
            onClick={() => updateReport([row.index])} 
          >
            <GiUpgrade size={16} color="black" className="icon-style"/>
          </Button>
        </div>
      ),
      disableFilters: true,
      disableSortBy: true,
    }, {
      Header: 'Código Descarga',
      accessor: 'downloadCode',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }, {
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], []);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isRevisionModalOpen ? <RevisionModal closeModal={() => setIsRevisionModalOpen(false)}/> : null}
      {isMultiDownloadModalOpen ? <ReportMultiDownload closeModal={() => setIsMultiDownloadModalOpen(false)}/> : null}
      {isDownloadsPanelModalOpen ? 
        <ReportModalDownloadsPanel 
          report={data[reportIndexToDownloads]} 
          closeModal={() => { setIsDownloadsPanelModalOpen(false); setReportIndexToDownloads('') }}
        /> 
      : null}
      {isDocPreviewModalOpen ? 
        <ReportModalDocPreview 
          reports={reportsPreview} 
          src={reportsPreviewSrc} 
          finalizeReport={(reportNumbers) => GenerateLabReportStepOne(reportNumbers.map((reportNumber) => data.findIndex(d => d.reportNumber === reportNumber)))} 
          exportReport={(reportNumbers) => ExportBatchReportStepOne(reportNumbers.map((reportNumber) => data.findIndex(d => d.reportNumber === reportNumber)))} 
          downloadReportFile={(reportNumber, opt) => { 
            const reportIdx = data.findIndex(d => d.reportNumber === reportNumber);
            if (reportIdx !== -1) { 
              const expObj = opt === 0 ? data[reportIdx].labExportInfo : data[reportIdx].clientExportInfo;
              if (expObj.length) {
                DownloadReportFile(reportNumber, expObj[0], (opt+1), 1);
              } else {
                alert.show('No se encontró un archivo generado');
              }
            } else {
              alert.show('No se encontró el ensayo');
            }
          }} 
          loading={loading}
          closeModal={() => { setIsDocPreviewModalOpen(false); setReportsPreview([]); setReportsPreviewSrc(null) }}
        /> 
      : null}
      {isSignPanelModalOpen ? 
        <ReportSignModal 
          reports={reportsSignPanel} 
          openLastReport={(reportNumbers) => OpenReportsPreview((reportNumbers.map((reportNumber) => data.findIndex(d => d.reportNumber === reportNumber))), 2)} 
          closeModal={(reload) => { setIsSignPanelModalOpen(false); setReportsSignPanel([]); if (reload) { GetData() } }}
        /> 
      : null}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        filterData={filterData}
        handleFilterDataEdit={HandleFilterDataEdit}
        resetFilterData={ResetFilterData}
        getData={GetData}
        handleEdit={HandleEdit}
        saveEditedData={SaveEditedDataStepOne}
        updateReport={UpdateReportVersionStepOne}
        openReportsPreview={OpenReportsPreview}
        openSignsPanel={OpenSignsPanel}
        generateLabReport={GenerateLabReportStepOne}
        exportSingleReport={ExportSingleReportStepOne}
        exportBatchReport={ExportBatchReportStepOne}
        downloadReportFile={DownloadReportFile}
        copyDownloadsTable={CopyDownloadsTable}
        setIsRevisionModalOpen={setIsRevisionModalOpen}
        setIsDownloadsPanelModalOpen={setIsDownloadsPanelModalOpen}
        setIsMultiDownloadModalOpen={setIsMultiDownloadModalOpen}
        setReportIndexToDownloads={setReportIndexToDownloads}
        discardChanges={() => { setDataIndexToEdit([]); setEditing(false); GetData() }}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default Reports;
