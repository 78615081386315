import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalItemSelection } from '../../Layout/ModalElements';
import { GetReportSimpleDisplayText } from '../../Generic/Constants.js';
import { SelectedReportStatusText, GetRndInteger, CalcHumidityFields, GetDiff, GetRandWithDiff } from '../Helpers.js';
import { confirmAlert } from 'react-confirm-alert';
import { ImMagicWand } from 'react-icons/im';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const dataKeys = [
  [['table3LiquidCapsule1', 'table3Liquid1Field1', 'table3Liquid1Field2', 'table3Liquid1Field3', 'table3Liquid1Field4'], 
  ['table3LiquidCapsule2', 'table3Liquid2Field1', 'table3Liquid2Field2', 'table3Liquid2Field3', 'table3Liquid2Field4'],
  ['table3LiquidCapsule3', 'table3Liquid3Field1', 'table3Liquid3Field2', 'table3Liquid3Field3', 'table3Liquid3Field4']],
  [['table3PlasticCapsule1', 'table3Plastic1Field1', 'table3Plastic1Field2', 'table3Plastic1Field3'], 
  ['table3PlasticCapsule2', 'table3Plastic2Field1', 'table3Plastic2Field2', 'table3Plastic2Field3'],
  ['table3PlasticCapsule3', 'table3Plastic3Field1', 'table3Plastic3Field2', 'table3Plastic3Field3']]
];

const humidityControlItems = [
  {field: 'table3Liquid1Field3', additionalKeys: ['table3Liquid1Mass1', 'table3Liquid1Mass2']},
  {field: 'table3Liquid3Field3', additionalKeys: ['table3Liquid3Mass1', 'table3Liquid3Mass2']},
  {field: 'table3Plastic1Field2', additionalKeys: ['table3Plastic1Mass1', 'table3Plastic1Mass2']},
  {field: 'table3Plastic3Field2', additionalKeys: ['table3Plastic3Mass1', 'table3Plastic3Mass2']},
];

const ClasifHumidityCalc = ({ loading, setLoading, taras }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);
  const [calcValues, setCalcValues] = useState([]);
  const [selectedReport, setSelectedReport] = useState(0);

  const isFirstRender = useRef(true);

  const SetInitialCalc = useCallback((pos) => {
    let newCalc = {
      [dataKeys[0][0][0]]: data[pos].data[dataKeys[0][0][0]][0].value,
      [dataKeys[0][0][1]]: data[pos].data[dataKeys[0][0][1]][0].value,
      [dataKeys[0][0][2]]: data[pos].data[dataKeys[0][0][2]][0].value,
      [dataKeys[0][0][3]]: data[pos].data[dataKeys[0][0][3]][0].value,
      [dataKeys[0][0][4]]: data[pos].data[dataKeys[0][0][4]][0].value,
      [dataKeys[0][2][0]]: data[pos].data[dataKeys[0][2][0]][0].value,
      [dataKeys[0][2][1]]: data[pos].data[dataKeys[0][2][1]][0].value,
      [dataKeys[0][2][2]]: data[pos].data[dataKeys[0][2][2]][0].value,
      [dataKeys[0][2][3]]: data[pos].data[dataKeys[0][2][3]][0].value,
      [dataKeys[0][2][4]]: data[pos].data[dataKeys[0][2][4]][0].value,

      [dataKeys[1][0][0]]: data[pos].data[dataKeys[1][0][0]][0].value,
      [dataKeys[1][0][1]]: data[pos].data[dataKeys[1][0][1]][0].value,
      [dataKeys[1][0][2]]: data[pos].data[dataKeys[1][0][2]][0].value,
      [dataKeys[1][0][3]]: data[pos].data[dataKeys[1][0][3]][0].value,
      [dataKeys[1][2][0]]: data[pos].data[dataKeys[1][2][0]][0].value,
      [dataKeys[1][2][1]]: data[pos].data[dataKeys[1][2][1]][0].value,
      [dataKeys[1][2][2]]: data[pos].data[dataKeys[1][2][2]][0].value,
      [dataKeys[1][2][3]]: data[pos].data[dataKeys[1][2][3]][0].value,
    }
    return newCalc;
  }, [data]);

  const ResetAllCalcs = useCallback(() => {
    let newCalcValues = [];
    for (let i = 0; i < data.length; i++) {
      newCalcValues.push(SetInitialCalc(i))
    }
    setCalcValues(newCalcValues);
  }, [data, SetInitialCalc]);

  useEffect(() => {
    if (data.length) {
      ResetAllCalcs();
    }
  }, [data, ResetAllCalcs]);

  useEffect(() => {
    if (selectedReport !== 0 && selectedReport >= data.length) {
      setSelectedReport(0);
    }
  }, [data, selectedReport]);

  const ResetSingleCalc = (pos) => {
    let newCalcValues = JSON.parse(JSON.stringify(calcValues));
    newCalcValues[pos] = JSON.parse(JSON.stringify(SetInitialCalc(pos)))
    setCalcValues(newCalcValues);
  }

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/revisionHelpers/clasifHumidityCalc';

    Axios.get(url, opts)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    }).finally(() => setLoading(false));
  }, [alert, setLoading]);

  const CalcNewValues = (limit, col) => {
    if (taras && taras.length) {
      let limitCapsule = data[selectedReport].data[dataKeys[limit][1][0]][0].value;
      let limitField1 = data[selectedReport].data[dataKeys[limit][1][2-limit]][0].value;
      let limitField2 = data[selectedReport].data[dataKeys[limit][1][3-limit]][0].value;
      let limitField3 = data[selectedReport].data[dataKeys[limit][1][4-limit]][0].value;
  
      let { humValue3 } = CalcHumidityFields(limitField1, limitField2, limitField3);
  
      let capsule = '';
      let field0 = '';
      let field1 = '';
      let field2 = '';
      let field3 = '';
      let isGood = false;
  
      if ( limitCapsule && limitField1 && limitField2 && limitField3 && humValue3 ) {
        if (Number(limitCapsule) > 1 && Number(limitCapsule) < taras.length) {

          capsule = (Number(limitCapsule) - (1-col));
          field3 = taras[(capsule-1)].weight;
          let taraDif = (Number(limitField3)-field3);
          let field1BaseValue =  (Number(limitField1) - taraDif);
          let field2BaseValue =  (Number(limitField2) - taraDif);

          if (limit === 1) {
            // Plastic
            for (let i = 0; i < 100000; i++) {

              field1 = GetRandWithDiff((field1BaseValue-3), (field1BaseValue+3), field1BaseValue, 0, 3);
              field2 = GetRandWithDiff((field2BaseValue-3), (field2BaseValue+3), field2BaseValue, 0, 3);
              
              if (field1 > 0 && field2 > 0 && field3 > 0) {
                let newCol2Calcs = CalcHumidityFields(field1, field2, field3);
                if (GetDiff(humValue3, newCol2Calcs.humValue3, 0, 0.5, true)) {
                  capsule = capsule.toFixed(0);
                  field1 = field1.toFixed(2);
                  field2 = field2.toFixed(2);
                  field3 = field3.toFixed(2);
                  isGood = true;
                  break;
                }
              }
            }
          } else if (limit === 0) {
            // Liquid
            let minDiff = 0;
            let maxDiff = 0;
            if (humValue3 < 30) {
              minDiff = 0.9;
              maxDiff = 2;
            } else if (humValue3 >= 30 && humValue3 < 50) {
              minDiff = 2;
              maxDiff = 2.3;
            } else if (humValue3 >= 50 && humValue3 < 70) {
              minDiff = 2.5;
              maxDiff = 3;
            } else if (humValue3 >= 70 && humValue3 < 80) {
              minDiff = 3;
              maxDiff = 4;
            } else if (humValue3 >= 80) {
              minDiff = 4.8;
              maxDiff = 5.2;
            }

            for (let i = 0; i < 100000; i++) {

              field1 = (GetRndInteger((field1BaseValue-3), (field1BaseValue+3)) + Math.random());
              field2 = (GetRndInteger((field2BaseValue-3), (field2BaseValue+3)) + Math.random());

              if (field1 > 0 && field2 > 0 && field3 > 0 && ((field1 - field2) > 0) && ((field2 - field3) > 0)) {
                let newCol2Calcs = CalcHumidityFields(field1, field2, field3);
                let diff = false;
                if (col === 0) {
                  diff = GetDiff(newCol2Calcs.humValue3, humValue3, minDiff, maxDiff, false);
                } else {
                  diff = GetDiff(humValue3, newCol2Calcs.humValue3, minDiff, maxDiff, false);
                }
                if (diff) {
                  capsule = capsule.toFixed(0);
                  field1 = field1.toFixed(2);
                  field2 = field2.toFixed(2);
                  field3 = field3.toFixed(2);
                  let actualDiff = Math.abs(humValue3 - newCol2Calcs.humValue3);
                  if (col === 0) {
                    if (actualDiff < 1.4) {
                      field0 = "17";
                    } else if (actualDiff < 1.8) {
                      field0 = "16";
                    } else {
                      field0 = "15";
                    }
                  } else if ( col === 2) {
                    if (actualDiff < 0.7) {
                      field0 = "31";
                    } else if (actualDiff < 1.1) {
                      field0 = "32";
                    } else if (actualDiff < 1.7) {
                      field0 = "33";
                    } else {
                      field0 = "34";
                    }
                  }
                  isGood = true;
                  break;
                }
              }
            }
          }
    
          if (isGood) {
            return { capsule, field0, field1, field2, field3 }
          } else {
            return false;
          }
        } else {
          alert.show('El N° de Cápsula de la columna #2 no puede ser la primera o la última', {type: 'info'})
        }
      } else {
        alert.show('Se necesita la columna #2 para generar datos', {type: 'info'});
        return false;
      }
    } else {
      alert.show('No se enncontraron los valores de las taras para generar datos', {type: 'info'});
      return false;
    }
  }

  const GenerateColumnData = (limit, col) => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let newValues = CalcNewValues(limit, col);
    if (newValues) {
      if (limit === 0) {
        newData[selectedReport][dataKeys[limit][col][0]] = newValues.capsule;
        newData[selectedReport][dataKeys[limit][col][1]] = newValues.field0;
        newData[selectedReport][dataKeys[limit][col][2]] = newValues.field1;
        newData[selectedReport][dataKeys[limit][col][3]] = newValues.field2;
        newData[selectedReport][dataKeys[limit][col][4]] = newValues.field3;
      } else {
        newData[selectedReport][dataKeys[limit][col][0]] = newValues.capsule;
        newData[selectedReport][dataKeys[limit][col][1]] = newValues.field1;
        newData[selectedReport][dataKeys[limit][col][2]] = newValues.field2;
        newData[selectedReport][dataKeys[limit][col][3]] = newValues.field3;
      }
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const GenerateAllColumnData = () => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let newValues1 = CalcNewValues(0, 0);
    let newValues2 = CalcNewValues(0, 2);
    let newValues3 = CalcNewValues(1, 0);
    let newValues4 = CalcNewValues(1, 2);
    if (newValues1 && newValues2 && newValues3 && newValues4 ) {
      newData[selectedReport][dataKeys[0][0][0]] = newValues1.capsule;
      newData[selectedReport][dataKeys[0][0][1]] = newValues1.field0;
      newData[selectedReport][dataKeys[0][0][2]] = newValues1.field1;
      newData[selectedReport][dataKeys[0][0][3]] = newValues1.field2;
      newData[selectedReport][dataKeys[0][0][4]] = newValues1.field3;
      newData[selectedReport][dataKeys[0][2][0]] = newValues2.capsule;
      newData[selectedReport][dataKeys[0][2][1]] = newValues2.field0;
      newData[selectedReport][dataKeys[0][2][2]] = newValues2.field1;
      newData[selectedReport][dataKeys[0][2][3]] = newValues2.field2;
      newData[selectedReport][dataKeys[0][2][4]] = newValues2.field3;
      newData[selectedReport][dataKeys[1][0][0]] = newValues3.capsule;
      newData[selectedReport][dataKeys[1][0][1]] = newValues3.field1;
      newData[selectedReport][dataKeys[1][0][2]] = newValues3.field2;
      newData[selectedReport][dataKeys[1][0][3]] = newValues3.field3;
      newData[selectedReport][dataKeys[1][2][0]] = newValues4.capsule;
      newData[selectedReport][dataKeys[1][2][1]] = newValues4.field1;
      newData[selectedReport][dataKeys[1][2][2]] = newValues4.field2;
      newData[selectedReport][dataKeys[1][2][3]] = newValues4.field3;
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const CheckColChanges = (option, limit, col) => {
    let didChange = false;
    if (col !== 1) {
      for (let i = 0; i < dataKeys[limit][col].length; i++) {
        if (calcValues[option][dataKeys[limit][col][i]] !== data[option].data[dataKeys[limit][col][i]][0].value) {
          didChange = true;
          break;
        }
      }
    }
    return didChange;
  }

  const CheckOptionChange = (option) => {
    let didChange = false;
    for (let i = 0; i < dataKeys.length; i++) {
      for (let j  = 0; j < dataKeys[i].length; j++) {
        if (CheckColChanges(option, i, j)) {
          didChange = true;
          break;
        }
      }
    }
    return didChange;
  }

  const RenderDetailsTable = () => {

    let liquid1 = { capsule: calcValues[selectedReport][dataKeys[0][0][0]], field0: calcValues[selectedReport][dataKeys[0][0][1]], field1: calcValues[selectedReport][dataKeys[0][0][2]], 
      field2: calcValues[selectedReport][dataKeys[0][0][3]], field3: calcValues[selectedReport][dataKeys[0][0][4]]}; 
    let liquid2 = { capsule: data[selectedReport].data[dataKeys[0][1][0]][0].value, field0: data[selectedReport].data[dataKeys[0][1][1]][0].value, field1: data[selectedReport].data[dataKeys[0][1][2]][0].value, 
      field2: data[selectedReport].data[dataKeys[0][1][3]][0].value, field3: data[selectedReport].data[dataKeys[0][1][4]][0].value}; 
    let liquid3 = { capsule: calcValues[selectedReport][dataKeys[0][2][0]], field0: calcValues[selectedReport][dataKeys[0][2][1]], field1: calcValues[selectedReport][dataKeys[0][2][2]], 
      field2: calcValues[selectedReport][dataKeys[0][2][3]], field3: calcValues[selectedReport][dataKeys[0][2][4]]}; 
    let plastic1 = { capsule: calcValues[selectedReport][dataKeys[1][0][0]], field0: '', field1: calcValues[selectedReport][dataKeys[1][0][1]], 
      field2: calcValues[selectedReport][dataKeys[1][0][2]], field3: calcValues[selectedReport][dataKeys[1][0][3]]}; 
    let plastic2 = { capsule: data[selectedReport].data[dataKeys[1][1][0]][0].value, field0: '', field1: data[selectedReport].data[dataKeys[1][1][1]][0].value, 
      field2: data[selectedReport].data[dataKeys[1][1][2]][0].value, field3: data[selectedReport].data[dataKeys[1][1][3]][0].value}; 
    let plastic3 = { capsule: calcValues[selectedReport][dataKeys[1][2][0]], field0: '', field1: calcValues[selectedReport][dataKeys[1][2][1]], 
      field2: calcValues[selectedReport][dataKeys[1][2][2]], field3: calcValues[selectedReport][dataKeys[1][2][3]]}; 

    ({ humValue1: liquid1.humValue1, humValue2: liquid1.humValue2, humValue3: liquid1.humValue3 } = CalcHumidityFields(liquid1.field1, liquid1.field2, liquid1.field3));
    ({ humValue1: liquid2.humValue1, humValue2: liquid2.humValue2, humValue3: liquid2.humValue3 } = CalcHumidityFields(liquid2.field1, liquid2.field2, liquid2.field3));
    ({ humValue1: liquid3.humValue1, humValue2: liquid3.humValue2, humValue3: liquid3.humValue3 } = CalcHumidityFields(liquid3.field1, liquid3.field2, liquid3.field3));
    ({ humValue1: plastic1.humValue1, humValue2: plastic1.humValue2, humValue3: plastic1.humValue3 } = CalcHumidityFields(plastic1.field1, plastic1.field2, plastic1.field3));
    ({ humValue1: plastic2.humValue1, humValue2: plastic2.humValue2, humValue3: plastic2.humValue3 } = CalcHumidityFields(plastic2.field1, plastic2.field2, plastic2.field3));
    ({ humValue1: plastic3.humValue1, humValue2: plastic3.humValue2, humValue3: plastic3.humValue3 } = CalcHumidityFields(plastic3.field1, plastic3.field2, plastic3.field3));

    liquid1.diff = Math.abs(liquid1.humValue3 - liquid2.humValue3);
    liquid2.diff = 0;
    liquid3.diff = Math.abs(liquid3.humValue3 - liquid2.humValue3);
    plastic1.diff = Math.abs(plastic1.humValue3 - plastic2.humValue3);
    plastic2.diff = 0;
    plastic3.diff = Math.abs(plastic3.humValue3 - plastic2.humValue3);

    return (
      <div className="d-flex">
        <div className="px-1">
          {['Límite', 'N° Cápsula', '1 N° Golpes', '2 Masa suelo húmedo + tara', '3 Masa suelo seco + tara', '4 Masa tara', '5 Masa del agua (=2-3)', 
          '6 Masa suelo seco (=3-4)', '7 Humedad (=16/17*100)', 'Diferencia de Humedad', 'Generar Columna']
          .map((item, itemIdx) => <div key={itemIdx} className="ellipsis-text p-1"> {item} </div>)}
        </div>
        <div className="d-flex flex-column px-1 text-center">
          <div className="d-flex">
            {[{ title: 'Líquido', limitData: [liquid1, liquid2, liquid3]}, { title: 'Plástico', limitData: [plastic1, plastic2, plastic3]}].map((limit, limitIdx) => {
              return (
                <div key={limitIdx} className="d-flex flex-column">
                  <div className="p-1">{limit.title}</div>
                  <div className="d-flex mx-2">
                    {limit.limitData.map((element, elementIdx) => {
                      return (
                        <div key={elementIdx} className="d-flex flex-column justify-content-center mx-2">
                          <div className={"rounded-2" + (CheckColChanges(selectedReport, limitIdx, elementIdx) ? " bg-warning bg-opacity-50" : "")}>
                            {[element.capsule, element.field0, element.field1, element.field2, element.field3, 
                            element.humValue1.toFixed(2), element.humValue2.toFixed(2), element.humValue3.toFixed(2)]
                            .map((item, itemIdx) => <div key={itemIdx} className="py-1 px-2"> {item || '-'} </div>)}
                          </div>
                          <div className="p-1" style={{visibility: (elementIdx !== 1 ? 'visible' : 'hidden')}}>{element.diff.toFixed(2)}</div>
                          <Button size="sm" style={{visibility: (elementIdx !== 1 ? 'visible' : 'hidden')}} disabled={elementIdx === 1} onClick={() => GenerateColumnData(limitIdx, elementIdx)}>
                            <ImMagicWand color="white" className="icon-style"/>
                          </Button>
                        </div>
                      )
                    })}
                  </div>
                </div>
              )
            })}
          </div>
          <button className="modal-form-button lb-white my-3 user-select-none" onClick={() => GenerateAllColumnData()}>Generar todas las Columnas</button>
        </div>
      </div>
    )
  }

  const SaveDataStepOne = () => {
    if (data.length) {
      let toSaveArr = [];
      calcValues.forEach((report, reportIdx) => {
        let keysHelper = {};
        Object.keys(report).forEach((rKey) => {
          if (report[rKey] !== data[reportIdx].data[rKey][0].value) {
            keysHelper[rKey] = report[rKey];
          }
        })
        if (Object.keys(keysHelper).length) {
          toSaveArr.push({ idx: reportIdx, reportNumber: data[reportIdx].reportNumber, data: keysHelper });
        }
      });
      if (toSaveArr.length) {
        for (let i = 0; i < toSaveArr.length; i++) {
          humidityControlItems.forEach(ak => {
            if (toSaveArr[i].data[ak.field]) {
              if (data[toSaveArr[i].idx].data[ak.field][0].value !== toSaveArr[i].data[ak.field]) {
                ak.additionalKeys.forEach(ek => {
                  toSaveArr[i].data[ek] = toSaveArr[i].data[ak.field]
                })
              }
            }
          })
        }
        SaveDataStepTwo(toSaveArr);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    } else {
      alert.show('No hay ensayos para guardar', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    let reportTexts = [];
    saveArr.forEach(r => {
      reportTexts.push(GetReportSimpleDisplayText(data[r.idx]));
    })
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se guardarán cambios para los siguientes ensayos:</p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {reportTexts.map((rt, rtIdx) =>  <span key={rtIdx}>{rt}</span>)}
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={onClose}> No, deseo salir </button>
              <button className="modal-confirm-button round-button lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr); }}> Si, deseo guardar </button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/reports/batch/updateData';

    Axios.post(url, { records: saveArr }, opts)
    .then((res) => {
      
      setLoading(false);

      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 5000});
      }
      GetData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  const RenderTaras = () => {
    return (
      <div className="d-flex flex-1 flex-column p-1">
        <div>Listado Taras</div>
        <div className="grid-autofit overflow-auto"> 
          { 
            taras && taras.length ?
              taras.map((tara, taraIdx) => 
              <div key={taraIdx} className="p-1 text-center border border-info border-opacity-50" style={{ minWidth: 'max-content' }}> { '#' + (taraIdx+1) + ': ' + tara.weight} </div>)
            : 
              <div>No hay taras disponibles</div>
          }
        </div>
      </div>
    )
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <div className="modal-table-outer-container my-1">
        { (data.length && calcValues.length && (selectedReport < data.length )) ? 
          <div className="d-flex flex-column m-1 overflow-auto">
            <ModalItemSelection 
              canChange={!loading} data={data} currentItem={selectedReport} setItem={(value) => setSelectedReport(Number(value))} 
              optClasses={(item, itemIdx) => CheckOptionChange(itemIdx) ? "bg-warning bg-opacity-50" : ""} optText={(opt) => GetReportSimpleDisplayText(opt)} 
              backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
            />
            {SelectedReportStatusText(data[selectedReport])}
            <div className="d-flex flex-column flex-xxl-row overflow-auto">
              {RenderDetailsTable()}
              {RenderTaras()}
            </div>
            <hr/>
            <div className="d-flex justify-content-around">
              <Button className="my-1 mx-2" onClick={() => ResetSingleCalc(selectedReport)} variant="secondary" size="sm">Reiniciar este ensayo</Button>
              <Button className="my-1 mx-2" onClick={() => ResetAllCalcs()} variant="secondary" size="sm">Reiniciar todos los ensayos</Button>
            </div>
          </div>
        : 
          <span>No se encontraron ensayos</span>
        }
      </div>
      <div>
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default ClasifHumidityCalc