import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAuth } from "../Context/Auth";
import { useAlert } from 'react-alert';
import { ModalHeader, ModalItemSelection } from '../Layout/ModalElements';
import { GetReportFullDisplayText, GetReportDisplayText, eqSectionDict } from '../Generic/Constants.js';
import { SelectedReportStatusText } from './Helpers.js';
import { confirmAlert } from 'react-confirm-alert';
import ModalTableTextInput from '../Generic/ModalTableTextInput';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import LoadingModal from '../Layout/LoadingModal.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const fetchUrls = ['reports/data/equipment', 'extras/reportEq'];

const EquipmentExtra = ({ closeModal }) => {

  const alert = useAlert();
  const { session } = useAuth();

  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  const [defaultEquipment, setDefaultEquipment] = useState([]);
  const [selectedReport, setSelectedReport] = useState(0);

  const isFirstRender = useRef(true);

  const SetInitialCalc = useCallback((pos) => {
    let newCalc = {};
    Object.keys(oldData[pos].data.equipmentUsed).forEach(dKey => {
      newCalc[dKey] = [];
      for (let i = 0; i < oldData[pos].data.equipmentUsed[dKey].length; i++) {
        newCalc[dKey].push(JSON.parse(JSON.stringify(oldData[pos].data.equipmentUsed[dKey][i].equipment[0])));
      }
    })
    return newCalc;
  }, [oldData]);

  const ResetAllItems = useCallback(() => {
    let newData = [];
    for (let i = 0; i < oldData.length; i++) {
      newData.push(SetInitialCalc(i))
    }
    setData(newData);
  }, [oldData, SetInitialCalc]);

  const ResetSingleItem = (pos) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData[pos] = JSON.parse(JSON.stringify(SetInitialCalc(pos)))
    setData(newData);
  }

  useEffect(() => {
    if (oldData.length) {
      ResetAllItems();
    }
  }, [oldData, ResetAllItems]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let allRequests = [];
    fetchUrls.forEach((url) => { allRequests.push(Axios.get('/api/'+url, opts)) });

    Axios.all(allRequests).then(Axios.spread((...responses) => {
      setOldData(responses[0].data);
      setDefaultEquipment(responses[1].data?.data || []);
      setLoading(false);
    }))
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const HandleChange = (sectionKey, index, key, value) => {
    if (value !== data[selectedReport][sectionKey][index][key]) {
      let newData = [...data];
      data[selectedReport][sectionKey][index][key] = value;
      setData(newData);
    }
  }

  const GenerateEqData = (sectionKey) => {
    let newData = JSON.parse(JSON.stringify(data));
    const foundDefaultEq = defaultEquipment.find(item => (item.code === oldData[selectedReport].template.code && oldData[selectedReport].template.version && item.equipmentUsed[sectionKey]));
    if (foundDefaultEq) {
      newData[selectedReport][sectionKey].forEach((eq, eqIdx) => {
        if (foundDefaultEq.equipmentUsed[sectionKey][eqIdx].eqCode && foundDefaultEq.equipmentUsed[sectionKey][eqIdx].eqStatus) {
          eq.equipmentCode = foundDefaultEq.equipmentUsed[sectionKey][eqIdx].eqCode;
          eq.equipmentStatus = foundDefaultEq.equipmentUsed[sectionKey][eqIdx].eqStatus;
        }
      })
      setData(newData);
    } else {
      alert.show('No se encontraron los datos de equipo para este ensayo');
    }
  }

  const RenderData = () => {
    return (
      <div className="d-flex flex-column flex-sm-row gap-3">
        { Object.keys(data[selectedReport]).map((eqSection, eqSectionIdx) => {
          const foundEqTitle = eqSectionDict.find(teq => teq.eqKey === eqSection)?.title || 'Nombre de sección de equipo no encontrado';
          return (
            <div key={eqSectionIdx} className="d-flex flex-column border border-2 py-2 px-1">
              <div className="my-1 text-center fw-bold">{foundEqTitle}</div>
              <div className="d-flex flex-column flex-1">
                <div className="d-flex mt-1 align-items-center">
                  <div className="flex-1 ms-1 px-1 text-center"> Equipo </div>
                  <div> Estado </div>
                </div>
                { data[selectedReport][eqSection].map((eq, eqIdx) => {
                  const isChecked = eq.equipmentStatus === 'bueno' ? true : false;
                  return (
                    <div key={eqIdx} className={"d-flex mt-1 align-items-center" + (CheckRowChanges(selectedReport, eqSection, eqIdx) ? " bg-warning bg-opacity-50" : "")}>
                      <ModalTableTextInput stateIdx={eqIdx} stateKey={'equipmentCode'} value={eq.equipmentCode} handleChange={(index, key, value) => HandleChange(eqSection, index, key, value)} />
                      <Button className="ms-2 me-1 p-1 rounded-circle" variant="light" onClick={() => HandleChange(eqSection, eqIdx, 'equipmentStatus', (isChecked ? 'malo' : 'bueno'))} >
                        { isChecked ? <AiOutlineCheckCircle size={22} color="green" className="icon-style"/> : <AiOutlineCloseCircle size={22} color="red" className="icon-style"/> }
                      </Button>
                    </div>
                  )
                })}
              </div>
              <Button className="mt-2 mx-2" onClick={() => GenerateEqData(eqSection)} size="sm">Generar</Button>
            </div>
          )
        })}
      </div>
    )
  }

  const CheckRowChanges = (option, section, row) => {
    if ((data[option][section][row].equipmentCode !== oldData[option].data.equipmentUsed[section][row].equipment[0].equipmentCode) || 
    (data[option][section][row].equipmentStatus !== oldData[option].data.equipmentUsed[section][row].equipment[0].equipmentStatus)) {
      return true;
    } else {
      return false;
    }
  }

  const CheckOptionChange = (option) => {
    let didChange = false;
    const eqKeys = Object.keys(data[option]);
    for (let i = 0; i < eqKeys.length; i++) {
      for (let j = 0; j < data[option][eqKeys[i]].length; j++) {
        if (CheckRowChanges(option, eqKeys[i], j)) {
          didChange = true;
          break;
        }
      }
    }
    return didChange;
  }

  const SaveDataStepOne = () => {
    if (oldData.length) {
      let toSaveArr = [];
      data.forEach((report, reportIdx) => {
        let keysHelper = {};
        Object.keys(report).forEach((rKey) => {
          let rowsHelper = [];
          for (let i = 0; i < oldData[reportIdx].data.equipmentUsed[rKey].length; i++) {
            let itemToCopy = null;
            if (CheckRowChanges(reportIdx, rKey, i)) {
              itemToCopy = {...report[rKey][i]};
              itemToCopy.editedBy = session.username;
              itemToCopy.editedAt = Date.now();
            }
            rowsHelper.push(itemToCopy);
          }
          if (rowsHelper.some(Boolean)) { keysHelper[rKey] = rowsHelper };
        })
        if (Object.keys(keysHelper).length) {
          toSaveArr.push({ idx: reportIdx, reportNumber: oldData[reportIdx].reportNumber, data: {equipmentUsed: keysHelper} });
        }
      });
      if (toSaveArr.length) {
        SaveDataStepTwo(toSaveArr);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    } else {
      alert.show('No hay ensayos para guardar', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    let reportTexts = [];
    saveArr.forEach(r => {
      reportTexts.push(GetReportFullDisplayText(oldData[r.idx]));
    })
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-large-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se guardarán cambios para los siguientes ensayos:</p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {reportTexts.map((rt, rtIdx) =>  <span key={rtIdx}>{rt}</span>)}
            </div>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr) }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/reports/batch/updateData';

    Axios.post(url, { records: saveArr }, opts)
    .then((res) => {
      
      setLoading(false);

      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 5000});
      }
      GetData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Generar equipos para un ensayo'} closeModal={closeModal} />
        { (data.length && oldData.length && (selectedReport < data.length )) ? 
          <div className="d-flex flex-column m-1 overflow-auto">
            <ModalItemSelection 
              data={oldData} currentItem={selectedReport} setItem={(value) => setSelectedReport(Number(value))} 
              optClasses={(item, itemIdx) => CheckOptionChange(itemIdx) ? "bg-warning bg-opacity-50" : ""} 
              optText={(opt) => GetReportFullDisplayText(opt)} backBtnText={'Anterior'} fwdBtnText={'Siguiente'} 
            />
            <div className="my-2">
              {GetReportDisplayText(oldData[selectedReport])}
              {SelectedReportStatusText(oldData[selectedReport])}
            </div>
            <div className="d-flex flex-column flex-xxl-row overflow-auto">
              {RenderData()}
            </div>
            <hr/>
            <div className="d-flex justify-content-around">
              <Button className="my-1 mx-2" onClick={() => ResetSingleItem(selectedReport)} variant="secondary" size="sm">Reiniciar este ensayo</Button>
              <Button className="my-1 mx-2" onClick={() => ResetAllItems()} variant="secondary" size="sm">Reiniciar todos los ensayos</Button>
            </div>
          </div>
        : <span>No se encontraron ensayos</span> }
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default EquipmentExtra