import React, { useState, useEffect, useCallback, useRef } from 'react';
import logo from '../../assets/img/full-logo.png';
import Footer from "../Layout/Footer";
import Axios from 'axios';
import FileDownload from 'js-file-download';
import { useAlert } from 'react-alert';
import { useParams } from "react-router-dom";
import { MdDownload } from 'react-icons/md';
import { BsPlus, BsDash } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import { Link } from "react-router-dom";
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import dayjs from 'dayjs';

const availablePageScales = [
  { label: 90, value: 1.0 }, 
  { label: 100, value: 1.25 }, 
  { label: 125, value: 1.5 }, 
  { label: 150, value: 1.75 }, 
  { label: 175, value: 2.0 }, 
  { label: 200, value: 2.25 },
  { label: 250, value: 2.75 },
  { label: 300, value: 3.5 },
];

const DocValidation = () => {

  const { code } = useParams();
  const alert = useAlert();
  const isFirstRender = useRef(true);

  const [report, setReport] = useState(null);
  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageScale, setPageScale] = useState(3);

  useEffect(() => {
    document.title = "MSTD Ingeniería - Verificación de Documentos";
  }, []);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const GetReportInfoAndFile = useCallback(() => {

    setLoading(true);

    const opts = {
      headers: {   
        'Content-Type': 'application/json'
      },
    };

    const url = '/api/downloads/reports/preview/'+code;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setReport(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [code, alert]);

  const DownloadFile = () => {

    setLoading(true);

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
    };

    const url = '/api/downloads/reports/'+code;

    Axios.get(url, opts)
    .then((res) => {
      if (res.data) {
        FileDownload(res.data, code+'.pdf');
      } else {
        alert.show('No se encontró un archivo para descargar', {type: 'error'})
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetReportInfoAndFile();
    }
  }, [GetReportInfoAndFile]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex flex-column flex-md-row flex-1 h-25 bg-gw justify-content-center">
        <div className="validation-doc-info-container d-flex flex-column flex-1 p-2">
          <div className="d-flex flex-column align-items-center justify-content-center mt-2">
            <Link to={'/verificar'}>
              <img src={logo} className="h-100" alt="Logo" style={{ maxHeight: '75px', objectFit: 'scale-down' }} />
            </Link>
            <h6 className="d-flex justify-content-center my-2">Verificación de Documentos</h6>
          </div>
          <div className="d-flex flex-column align-self-center mt-2 px-2">
            { report ?
              <div className="d-flex flex-row flex-md-column align-items-center">
                <div className="my-1 text-center"><b> { 'Informe N°' + report.reportNumber } </b></div> 
                <div className="d-none d-md-flex flex-column">
                  <div className="my-1"> {'Cliente: ' + report.projectClient } </div> 
                  <div className="my-1"> {'Proyecto: ' + report.projectTitle } </div> 
                  <div className="my-1"> {'Firmado: ' + (report.signedAt ? dayjs(report.signedAt).format('LLLL') : '-')} </div> 
                </div>
                <Button className="mt-md-2 ms-2 ms-md-0" onClick={() => DownloadFile()}>
                  Descargar
                </Button>
              </div>
            : !loading ?
              <div className="d-flex flex-column align-items-center justify-content-center mt-2">
                <span className="mb-3"> Parece que no hay nada por aquí.. </span>
                <Link to={'/verificar'}>
                  <Button className="fs-7">
                    Volver a Verificación
                  </Button>
                </Link>
              </div> 
            : null }
          </div>
        </div>
        <div className="d-flex flex-column overflow-auto">
          { report ?
            <div className="d-flex position-sticky top-0 start-0 user-select-none zIndex-3 bg-secondary" style={{transition: '0.7s'}}>
              <div className="d-flex flex-1 justify-content-center align-items-center">
                <div className="d-flex align-items-center">
                  <Button className="d-flex p-1 rounded-circle cursor-pointer" variant="secondary" 
                  disabled={(pageScale <= 0)} title="Alejar" onClick={() => setPageScale(pageScale-1)}>
                    <BsDash color="white" size={22} className="icon-style"/>
                  </Button>
                  <div className="mx-2 color-white"> {availablePageScales[pageScale].label + '%'} </div>
                  <Button className="d-flex p-1 rounded-circle cursor-pointer" variant="secondary" 
                  disabled={(pageScale >= (availablePageScales.length-1))} title="Acercar" onClick={() => setPageScale(pageScale+1)}>
                    <BsPlus color="white" size={22} className="icon-style"/>
                  </Button>
                </div>
                <Button className="d-flex ms-5 p-1 rounded-circle cursor-pointer" variant="secondary" title="Descargar" onClick={() => DownloadFile()}>
                  <MdDownload color="white" size={22} className="icon-style"/>
                </Button>
              </div>
            </div> 
          : null }
          <div className="d-flex flex-1 flex-column">
            { report ? 
              <Document
                file={report?.file}
                onLoadSuccess={onDocumentLoadSuccess}
                width={'100%'}
              >
                { numPages ? Array.from({length: numPages}, (_, i) => i + 1).map((page, pageIdx) => 
                  <Page
                    key={pageIdx}
                    pageNumber={page}
                    scale={availablePageScales[pageScale].value}
                    className={"d-flex w-100 justify-content-center bg-aliceblue border border-1 border-secondary" + (pageIdx < (numPages-1) ? " mb-4" : "")}
                  />
                ) : null }
              </Document> 
            : null }
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default DocValidation;
