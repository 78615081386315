import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
// import * as serviceWorker from './serviceWorker';

import "@fontsource/poppins";
import "@fontsource/poppins/400.css"; // Specify weight
import "@fontsource/poppins/500.css"; // Specify weight
import "@fontsource/poppins/600.css"; // Specify weight
import "@fontsource/poppins/700.css"; // Specify weight
import "@fontsource/poppins/800.css"; // Specify weight
import "@fontsource/poppins/900.css"; // Specify weight
import "@fontsource/poppins/400-italic.css"; // Specify weight and style

import { FaInfoCircle, FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { BrowserRouter } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import { transitions, positions, Provider as AlertProvider } from 'react-alert';

import { pdfjs } from 'react-pdf';
// pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.js', import.meta.url).toString();
// pdfjs.GlobalWorkerOptions.workerSrc = './pdf.worker.js';  // old prod
pdfjs.GlobalWorkerOptions.workerSrc = 'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/' + pdfjs.version + '/pdf.worker.js'; // works prod

const options = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  offset: '30px',
  transition: transitions.SCALE,
  containerStyle: {
    fontFamily: 'Poppins, sans-serif !important',
    lineHeight: '1.4px !important',
    height: 'fit-content',
    fontSize: '14px',
    zIndex: 100
  }
};

const AlertTemplate = ({ style, options, message, close }) => (
  <div
    className="alert-outer-container"
    style={
      options.type === 'info' ? {backgroundColor: '#00016B'} :
      options.type === 'success' ? {backgroundColor: '#3D7100'} :
      options.type === 'error' ? {backgroundColor: '#8C0000'} : null }
  >
    {options.type === 'info' && <FaInfoCircle color="white" size={'18px'} className="alert-icon-style"/>}
    {options.type === 'success' && <FaCheckCircle color="white" size={'18px'} className="alert-icon-style"/>}
    {options.type === 'error' && <FaTimesCircle color="white" size={'18px'} className="alert-icon-style"/>}
    <div className="alert-inner-container">{message}</div>
    <button className="alert-close-button" onClick={close}>X</button>
  </div>
)

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <AlertProvider template={AlertTemplate} {...options}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </AlertProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
