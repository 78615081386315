import React, { useState } from 'react';
import { useAuth } from "../Context//Auth";
import { useLocation } from "react-router-dom";
import { FaUserEdit } from 'react-icons/fa';
import { GiExitDoor } from 'react-icons/gi';
import { Link } from "react-router-dom";
import logo from '../../assets/img/full-logo.png';
import UserModalEditSelf from '../Users/UserModalEditSelf.js';

const Header = () => {

  const { session, Logout } = useAuth();
  const location = useLocation();

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      { isModalOpen ? <UserModalEditSelf closeModal={() => setIsModalOpen(false)} /> : null }
      <div className="header-bs d-flex bg-gw zIndex-1 user-select-none p-1" style={{height: '50px'}}>
        <div className="d-flex flex-1 align-self-center justify-content-center" style={{height: '45px'}}>
          { location.pathname !== '/' ?
            <Link to={'/'}>
              <img className="h-100 cursor-pointer" src={logo} alt="MSTD"/> 
            </Link>
          : null }
        </div>
        <div className="d-flex flex-1">
          <div className="d-flex flex-1 justify-content-evenly align-items-center">
            <div className="d-flex h-100 align-items-center fs-6">
              {session ? session.username : 'Usuario'}
              <div className="h-100 m-2 cursor-pointer" style={{maxHeight: '25px'}} title="Cambiar contraseña" onClick={() => setIsModalOpen(true)}>
                <FaUserEdit className="icon-style icon-color-lb"/>
              </div>
            </div>
            <button className="d-md-flex d-none rounded-button px-3 py-2 lb-white" onClick={() => Logout()}>
              Cerrar Sesión
            </button>
            <div className="d-initial d-md-none h-100 cursor-pointer" style={{maxHeight: '25px'}} title="Cerrar Sesión" onClick={() => Logout()}>
              <GiExitDoor className="icon-style icon-color-lb"/>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header;
