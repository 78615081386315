import { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalItemSelection } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import { FaCheck, FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { IoMdClose } from "react-icons/io";
import dayjs from 'dayjs';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const FWProbingImgsModal = ({ projectCode, probingsData: oldData, getData, closeModal }) => {

  const alert = useAlert();
  
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [activeSection, setActiveSection] = useState(0);

  const MoveArrImg = (imgSrc, sampleIdx, imgIdx, toMove) => {
    let newState = [...data];
    if (imgSrc === 1) {
      const arrItem = newState[activeSection].probingImgs.splice(imgIdx, 1)[0];
      newState[activeSection].probingImgs.splice((imgIdx+toMove), 0, arrItem);
    } else {
      const arrItem = newState[activeSection].probingSamples[sampleIdx].sampleImgs.splice(imgIdx, 1)[0];
      newState[activeSection].probingSamples[sampleIdx].sampleImgs.splice((imgIdx+toMove), 0, arrItem);
    }
    setData(newState);
  }

  const ToggleImgStatus = (imgSrc, sampleIdx, imgIdx) => {
    let newState = [...data];
    if (imgSrc === 1) {
      newState[activeSection].probingImgs[imgIdx].status = (newState[activeSection].probingImgs[imgIdx].status === "selected" ? "unselected" : "selected");
    } else {
      newState[activeSection].probingSamples[sampleIdx].sampleImgs[imgIdx].status = 
        (newState[activeSection].probingSamples[sampleIdx].sampleImgs[imgIdx].status === "selected" ? "unselected" : "selected");
    }
    setData(newState);
  }

  const SaveChangesStepOne = () => {
  
    let probingItem = {
      _id: data[activeSection]._id,
      probingCode: data[activeSection].probingCode,
      probingNumber: data[activeSection].probingNumber,
      probingImgs: [],
      probingSamples: []
    }

    for (let j = 0; j < data[activeSection].probingImgs.length; j++) {
      const oldImgIdx = oldData[activeSection].probingImgs.findIndex(imgItem => (imgItem.remoteFilename === data[activeSection].probingImgs[j].remoteFilename))
      if ((j !== oldImgIdx) || (data[activeSection].probingImgs[j].status !== oldData[activeSection].probingImgs[oldImgIdx].status)) {
        probingItem.probingImgs.push({
          remoteFilename: data[activeSection].probingImgs[j].remoteFilename,
          oldPos: oldImgIdx,
          newPos: j,
          status: data[activeSection].probingImgs[j].status
        })
      }
    }

    for (let j = 0; j < data[activeSection].probingSamples.length; j++) {
      let probingSampleItem = {
        _id: data[activeSection].probingSamples[j]._id,
        sampleImgs: []
      }
      for (let k = 0; k < data[activeSection].probingSamples[j].sampleImgs.length; k++) {          
        const oldImgIdx = oldData[activeSection].probingSamples[j].sampleImgs
          .findIndex(imgItem => (imgItem.remoteFilename === data[activeSection].probingSamples[j].sampleImgs[k].remoteFilename))
        if ((k !== oldImgIdx) || (data[activeSection].probingSamples[j].sampleImgs[k].status !== oldData[activeSection].probingSamples[j].sampleImgs[oldImgIdx].status)) {
          probingSampleItem.sampleImgs.push({
            remoteFilename: data[activeSection].probingSamples[j].sampleImgs[k].remoteFilename,
            oldPos: oldImgIdx,
            newPos: k,
            status: data[activeSection].probingSamples[j].sampleImgs[k].status
          })
        }
      }
      if (probingSampleItem.sampleImgs.length) {
        probingItem.probingSamples.push(probingSampleItem)
      }
    }

    if (probingItem.probingImgs.length || probingItem.probingSamples.length) {
      SaveChangesStepTwo(probingItem);
    } else {
      alert.show('No se encontraron cambios para guardar', {type: 'info'})
    }
  }

  const SaveChangesStepTwo = (dataToSave) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/fieldWork/img/edit';

    Axios.post(url, { data: dataToSave }, opts)
    .then((res) => {
      setLoading(false);
      if (res.data) {
        let probingSamplesErrs = res.data.probingSamples.filter(ps => ps.err);
        if (res.data.err || probingSamplesErrs.length) {
          let queryResponse =
            <>
              <span>Se encontraron los siguientes errores: </span>
              {res.data.err ? <span><b>{res.data.err}</b></span> : null } 
              <hr/>
              {probingSamplesErrs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)}
            </>
          alert.show(queryResponse, {type: 'error', timeout: 15000});
        } else {
          alert.show('Se guardaron los cambios con éxito', {type: 'success', timeout: 10000});
          getData();
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const RenderProbingImgs = () => {
    if (data?.length) {
      return (
        <>
          <div className="d-flex flex-column my-2">
            <h6 className="ms-2 mb-2 text-blue fw-bold"> {(data[activeSection]?.probingCode || ('Sondaje N°' + data[activeSection].probingNumber))} </h6>
            {RenderImgsGroup(1, null, data[activeSection].probingImgs)}
          </div>
          {data[activeSection].probingSamples?.length ? 
            data[activeSection]?.probingSamples.map((pSample, pSampleIdx) => (
              <div key={pSampleIdx} className="d-flex flex-column my-2">
                <h6 className="ms-2 mb-2 text-blue fw-bold"> 
                  {('Muestra ' + (pSampleIdx+1) + ': ' + (pSample?.sampleFrom?.[0]?.value || '') + ' - ' + (pSample?.sampleTo?.[0]?.value || ''))} 
                </h6>
                {RenderImgsGroup(2, pSampleIdx, pSample.sampleImgs)}
              </div>
            ))
          : null }
        </>
      )
    } else {
      return <span>No se encontraron sondajes para mostrar</span>
    }
  }

  const RenderImgsGroup = (imgsSrc, sampleIdx, imgs) => {
    if (imgs?.length) {
      return (
        <Container className="flex-1 pb-2 ps-2 m-0">
          <Row xs={2} lg={3} xl={4} className="pb-2">
            { imgs.map((img, imgIdx) => {
              return (
                <div key={imgIdx} className="d-flex flex-column justify-content-start my-2">
                  <img 
                    src={'/api/fieldWork/img/'+img.remoteFilename} 
                    className={"w-100 border border-2 " + (img.status === 'selected' ? "border-primary" : "border-dark")} 
                    style={{objectFit: 'contain'}} 
                    alt={img.remoteFilename}
                    loading="lazy"
                  />
                  <div className="d-flex"> 
                    <Button 
                      className="d-flex p-2 rounded-0 bg-lb-white" 
                      title="Mover hacia el inicio" 
                      disabled={imgIdx === 0} 
                      onClick={() => MoveArrImg(imgsSrc, sampleIdx, imgIdx, -1)}>
                      <FaAngleLeft color="white" size={15} className="icon-style"/>
                    </Button>
                    <div 
                      className={"d-flex flex-1 justify-content-center align-items-center p-2 cursor-pointer " + (img.status === 'selected' ? "bg-lb-white" : "bg-gw-lb")}
                      title={img.status === 'selected' ? "Deseleccionar" : "Seleccionar"}
                      onClick={() => ToggleImgStatus(imgsSrc, sampleIdx, imgIdx)}
                    >
                      { img.status === 'selected' ? <FaCheck color="white" size={15} className="icon-style me-1"/> : <IoMdClose size={15} className="icon-style me-1"/> }
                      <span>{img.status === 'selected' ? 'Seleccionada' : 'No Seleccionada'}</span>
                    </div>
                    <Button 
                      className="d-flex p-2 rounded-0 bg-lb-white" 
                      title="Mover hacia el final" 
                      disabled={imgIdx >= (imgs.length-1)} 
                      onClick={() => MoveArrImg(imgsSrc, sampleIdx, imgIdx, 1)}>
                      <FaAngleRight color="white" size={15} className="icon-style"/>
                    </Button>
                  </div>
                  <div className="d-flex justify-content-center" title={img.editedAt ? dayjs(img.editedAt).format('LLLL') : '-'}>
                    <span>{(dayjs().to(dayjs(img.editedAt))) + ' - ' + img.editedBy}</span>
                  </div>
                </div>
              )
            })}
          </Row>
        </Container>
      )
    } else {
      return <span>No se encontraron imágenes para mostrar</span>
    }
  }

  useEffect(() => {
    setData(oldData ? JSON.parse(JSON.stringify(oldData)) : []);
  }, [activeSection, oldData])

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={((projectCode || '') + ' - Imágenes')} closeModal={closeModal} closeKb />
        <ModalItemSelection 
          canChange data={data} currentItem={activeSection} setItem={(value) => setActiveSection(Number(value))} 
          optText={(opt) => (opt?.probingCode || ('Sondaje N°' + opt.probingNumber))} backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
        />
        <div className="d-flex flex-column my-2 overflow-auto">
          <div className="grid-container">
            {RenderProbingImgs()}
          </div>
        </div>
        <div className="mt-2">
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveChangesStepOne()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default FWProbingImgsModal;
