import React from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Form from 'react-bootstrap/Form';

const FormPasswordInput = (props) => {
  return (
    <div className="d-md-flex align-items-center justify-content-center p-2">
      { props.title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { props.isRequired ? <span className="text-danger">*</span> : '' }
          { props.title }
          { props.tooltip ? 
            <OverlayTrigger placement="right" delay={{ show: 100, hide: 250 }} overlay={<Tooltip id="button-tooltip"> {props.tooltip} </Tooltip>} > 
              {({ ref, ...triggerHandler }) => <div className="ms-1 cursor-pointer" {...triggerHandler} ref={ref}><FiHelpCircle color="blue" size={16} className="icon-style"/></div>}
            </OverlayTrigger> 
          : '' }
        </div>
      : null }
      <Form.Control type="password" className="modal-form-input fs-inherit border border-secondary" 
      size="sm" value={props.value || ''} onChange={(e) => props.handleChange(props.stateKey, e.target.value)} />
    </div>
  )
}

export default FormPasswordInput