import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy } from 'react-table';
import { VariableSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPlus, FaPen, FaCheck, FaUndo, FaSyncAlt, FaEdit, FaDownload } from 'react-icons/fa';
import { FiHelpCircle } from 'react-icons/fi';
import BookModalAdd from './BookModalAdd.js';
import BookModalEditReports from './BookModalEditReports.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { regularStatusSelectOptions, TestSpecialChars } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, TableFilterData, FilterDateMethod, FilterDateTextMethod, SortDateMethod, 
  TableTextInput, TableSelectInput, TableDateInput, TableDateTextInput, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileDownload from 'js-file-download';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const specialCharsErrText = 'no puede contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const SpecialCharsToolTip = () => {
  return (
    <OverlayTrigger placement="bottom" delay={{ show: 50, hide: 150 }} overlay={<Tooltip id="button-tooltip"> {specialCharsErrText} </Tooltip>} > 
      {({ ref, ...triggerHandler }) => <div className="d-flex cursor-pointer" {...triggerHandler} ref={ref}><FiHelpCircle color="blue" size={15} className="icon-style"/></div>}
    </OverlayTrigger> 
  )
}

const SamplePropsSort = (rowA, rowB, id) => rowA.values[id].localeCompare(rowB.values[id], 'es', {numeric: true})

function Table({ data, columns, editing, setEditing, filterData, handleFilterDataEdit, resetFilterData, getData, handleEdit, 
  saveEditedData, setIsModalOpen, setIsEditModalOpen, downloadData, setSampleToEditReports, discardChanges, deleteRecord }) {

  const isTableFirstRender = useRef(true);
  const listRef = useRef();

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    SelectCell: TableSelectInput,
    DateCell: TableDateInput,
    DateTextCell: TableDateTextInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['layerThickness', 'item', 'comments', 'samplePlan', 'sampleProcess', 'addedAt', 'addedBy', 'status', 'delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      setIsEditModalOpen,
      setSampleToEditReports,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (editing) {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
            <FaCheck color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
            <FaUndo color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    } else {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Refrescar" onClick={() => getData()}>
            <FaSyncAlt color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Nueva Muestra" onClick={() => setIsModalOpen(true)}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Editar" onClick={() => setEditing(true)}>
            <FaPen color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Descargar Libro" onClick={() => downloadData(true)}>
            <FaDownload color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }
  }

  const RowHeight = (idx) => {
    const foundReports = rows[idx].values.reports.length;
    return 35 + (allColumns[2].isVisible && foundReports > 1 ? ((foundReports*17)-17) : 0);
  }

  useEffect(() => {
    if (!isTableFirstRender.current && listRef.current) {
      listRef.current.resetAfterIndex(0);
    }
  }, [rows])

  useEffect(() => { isTableFirstRender.current = false }, []);

  return (
    <div className="table-inner-container">
      <div className="d-flex align-items-center justify-content-between my-1 py-1 px-1 flex-wrap" style={{backgroundColor: editing ? '#ffb130cc' : 'initial'}}>
        <div className="d-flex align-items-center">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} />
          <TableFilterData getData={getData} filterData={filterData} handleFilterDataEdit={handleFilterDataEdit} resetFilterData={resetFilterData} disabled={editing} />
        </div>
        <div className="d-flex align-items-center">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <VariableSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={RowHeight}
                  ref={listRef}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </VariableSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const Book = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [filterData, setFilterData] = useState({ dateStart: dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dateEnd: dayjs().format('YYYY-MM-DD'), resultsLimit: 1500, projectId: '' });
  const [data, setData] = useState([]);
  const [dataIndexToEdit, setDataIndexToEdit] = useState([]);
  const [sampleToEditReports, setSampleToEditReports] = useState('');

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Libro de muestras de suelo";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/samples/filtered';
    
    Axios.post(url, filterData, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, filterData]);

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando una muestra</h3>
            <p>{'Eliminar la muestra '}{<b>{data[rowId].sampleId}</b>}{' implica eliminar los informes asociados. ¿Está seguro(a) de continuar?'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].sampleId);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (record) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando una muestra</h3>
            <p>{'Esta acción es irreversible. Confirme ingresando el código de la muestra que desea eliminar'}</p>
            <input
              type="text"
              className="modal-form-input"
              onChange={(e) => { deleteConfirmation = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (record === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(record)
                    }else{
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  }else{
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/samples/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      if (res.data) {
        if (res.data.err) {
          alert.show(res.data.err, {type: 'error', timeout: 20000});
        }
        if (res.data.msg) {
          alert.show(res.data.msg, {type: 'success', timeout: 20000});
        }
        let resultsMsgs = [];
        let resultsErrs = [];
        res.data.results.forEach(s => {
          if (s.err) {
            resultsErrs.push(s.err);
          } else if (s.msg) {
            resultsMsgs.push(s.msg);
          }
        })
        if (resultsMsgs.length) {
          let queryResponse = ( <> { resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span> )} </> );
          alert.show(queryResponse, {type: 'success', timeout: 20000});
        }
        if (resultsErrs.length) {
          let errResp = ( <> { resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span> )} </> );
          alert.show(errResp, {type: 'error', timeout: 20000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const FindErrs = () => {
    let errsFound = [];

    for (let i = 0; i < dataIndexToEdit.length; i++) {
      if (!data[dataIndexToEdit[i]].project.code || !data[dataIndexToEdit[i]].sampleId) {
        errsFound.push({ msg: 'Se encontró un registro sin ID o Proyecto.', pos: dataIndexToEdit[i] });
      }
      if (TestSpecialChars(data[dataIndexToEdit[i]].trialPitNumber)) {
        errsFound.push({ msg: ('El campo Calicata o Sondaje ' + specialCharsErrText), pos: dataIndexToEdit[i] });
      }
      if (TestSpecialChars(data[dataIndexToEdit[i]].horizon)) {
        errsFound.push({ msg: ('El campo Horizonte o Muestra ' + specialCharsErrText), pos: dataIndexToEdit[i] });
      }
      if (TestSpecialChars(data[dataIndexToEdit[i]].depth)) {
        errsFound.push({ msg: ('El campo Profundidad / Cota (m) ' + specialCharsErrText), pos: dataIndexToEdit[i] });
      }
    }

    if (errsFound.length) {
      let sampleMsg = 
        <>
          <span>{'Se encontraron los siguientes errores: '}</span>
          {errsFound.map((element, index) => {
            return <span key={index} className="mt-1">{'Fila ' + (element.pos+1) + ' (' + (data[element.pos].sampleId) + '): ' + element.msg}</span>
          })}
        </>
      alert.show(sampleMsg, {type: 'info', timeout: 15000});
      return true;
    } else {
      return false;
    }
  }

  const SaveEditedData = () => {
    if (dataIndexToEdit.length > 0) {

      setLoading(true);
      if (!FindErrs()) {
        let dataToUpdate = [];
        dataIndexToEdit.forEach((dataIndexToModify) => {
          let extractReportsFromUpdate = {...data[dataIndexToModify]};
          delete extractReportsFromUpdate.reports;
          dataToUpdate.push(extractReportsFromUpdate);
        });

        const opts = {
          headers: {
            'Content-Type': 'application/json'
          },
          cancelToken: source.token
        };

        const url = '/api/samples/updateMany';

        Axios.post(url, dataToUpdate, opts)
        .then((res) => {
          setLoading(false);
          setEditing(false);
          setDataIndexToEdit([]);
          let editResults =
            <div className="d-flex flex-column">
              {'Los resultados de sus modificaciones son: '}
              {res.data.map((element, index) => <span key={index}>{element}</span> )}
            </div>
          alert.show(editResults, {type: 'success', timeout: 25000});
          GetData();
        })
        .catch((err) => {
          setLoading(false);
          if (Axios.isCancel(err)) {
            alert.show('Petición cancelada', {type: 'info'});
          } else if (!err.response) {
            console.log('Petición cancelada');
          } else if (err.response.status === 500) {
            alert.show('No se recibió respuesta del servidor', {type: 'error'})
          } else {
            alert.show(err.response.data.msg, {type: 'error'})
          }
        });
      } else {
        setLoading(false);
      }
    }else{
      setEditing(false);
      alert.show('No se realizaron modificaciones', {type: 'success'});
    }
  }

  const DownloadDataStepOne = () => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Confirme esta acción</h4>
            <p> Está descargando el Libro de Muestras </p>
            <p> Esta operación podría tardar, confirme si desea continuar </p>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); DownloadDataStepTwo() }}> Si, deseo descargar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const DownloadDataStepTwo = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/book';

    Axios.get(url, opts)
    .then((res) => {
      FileDownload(res.data, 'MSTD LIBRO INGRESO DE MUESTRAS '+(dayjs().format("DD-MM-YYYY"))+'.xlsx');
      setLoading(false);
      alert.show('Se ha descargado el Libro de Ingresos con éxito', {type: 'success'})
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
      if (dataIndexToEdit.indexOf(index) === -1) {
        let newEditedIndex = dataIndexToEdit;
        newEditedIndex.push(index);
        setDataIndexToEdit(newEditedIndex);
      }
    }
  }

  const HandleFilterDataEdit = (element, value) => {
    let newData = {...filterData};
    newData[element] = value;
    setFilterData(newData);
  }

  const ResetFilterData = () => {
    setFilterData({ dateStart: dayjs().subtract(6, 'month').format('YYYY-MM-DD'), dateEnd: dayjs().format('YYYY-MM-DD'), resultsLimit: 1500, projectId: '' });
  }

  const tableColumns = useMemo(() => [
    {
      Header: 'Código Proyecto',
      accessor: 'project.code',
      width: 175
    },{
      Header: 'ID Muestra',
      accessor: 'sampleId',
      sortType: (rowA, rowB, id) => rowA.values[id].localeCompare(rowB.values[id], 'es', {numeric: true}),
      sortDescFirst: true,
      width: 135,
    },{
      Header: 'Ensayos',
      accessor: 'reports',
      Cell: ({ row, value, editing, setIsEditModalOpen, setSampleToEditReports }) => {
        let reportTexts = [];
        value.forEach(el => {
          reportTexts.push(el.reportNumber + ' | '+ el.template.title + ' | ' + el.template.code+'_v' + el.template.version + '-' + el.template.clientCode + '_v' + el.template.clientVersion)
        });
        return (
          <div className="d-flex align-items-center">
            { editing ? 
              <Button 
                className="d-flex p-1 cursor-pointer bg-transparent"
                title="Editar Ensayos"
                onClick={() => { 
                  setIsEditModalOpen(true); 
                  setSampleToEditReports({ sampleId: row.values.sampleId, trialPitNumber: row.values.trialPitNumber, horizon: row.values.horizon, depth: row.values.depth }) 
                }}
              >
                <FaEdit size={16} color="black" className="icon-style"/>
              </Button>
            : null }
            <div className="d-flex flex-column text-truncate" title={reportTexts.length ? reportTexts.join('\n') : ''}>
              { reportTexts.length ? reportTexts.map((txt, txtIdx) => <span key={txtIdx} className="text-truncate">{txt}</span>) : <span> - </span> }
            </div>
          </div>
        )
      },
      filter: (rows, id, filterValue) => { 
        const valueHelper = String(filterValue).trim().toLowerCase();
        return rows.filter(row => row.values[id].some(el => (el.template.title.toLowerCase().includes(valueHelper) || String(el.reportNumber).includes(valueHelper))));
      },
      width: 175,
    },{
      Header: 'Fecha Muestreo',
      accessor: 'sampleDate',
      cellRender: 'DateTextCell',
      filter: FilterDateTextMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Fecha Ingreso/Recepción',
      accessor: 'entryDate',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'N° Boleta',
      accessor: 'billNumber',
      cellRender: 'EditableCell',
      sortDescFirst: true,
      width: 125,
    },{
      Header: 'Muestreado por',
      accessor: 'sampledBy',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: () => <div className="d-flex"> <SpecialCharsToolTip /> <span className="ms-1 text-truncate">Calicata o Sondaje número</span> </div>,
      headerTitleProp: "Calicata o Sondaje número",
      accessor: 'trialPitNumber',
      cellRender: 'EditableCell',
      sortType: SamplePropsSort,
    },{
      Header: () => <div className="d-flex"> <SpecialCharsToolTip /> <span className="ms-1 text-truncate">Horizonte o Muestra</span> </div>,
      headerTitleProp: "Horizonte o Muestra",
      accessor: 'horizon',
      cellRender: 'EditableCell',
      sortType: SamplePropsSort,
    },{
      Header: () => <div className="d-flex"> <SpecialCharsToolTip /> <span className="ms-1 text-truncate">{'Profundidad / Cota (m)'}</span> </div>,
      headerTitleProp: "Profundidad / Cota (m)",
      accessor: 'depth',
      cellRender: 'EditableCell',
      sortType: SamplePropsSort,
    },{
      Header: 'Tipo de suelo',
      accessor: 'soilType',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Fecha Control',
      accessor: 'controlDate',
      cellRender: 'DateTextCell',
      filter: FilterDateTextMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Espesor capa(m)',
      accessor: 'layerThickness',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Item(obra)',
      accessor: 'item',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Área representada',
      accessor: 'repArea',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Observaciones (Ensayo Lab)',
      accessor: 'comments',
      cellRender: 'EditableCell',
    },{
      Header: 'Plan de muestreo',
      accessor: 'samplePlan',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Procedimiento de muestreo',
      accessor: 'sampleProcess',
      cellRender: 'EditableCell',
      width: 125,
    },{
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
      width: 125,
    },{
      Header: 'Fecha Creación',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Ingresado por',
      accessor: 'addedBy',
      width: 125,
    },{
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: regularStatusSelectOptions,
      width: 125,
    },{
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], []);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isModalOpen ? <BookModalAdd closeModal={() => { setIsModalOpen(false); GetData() }}/> : null}
      {isEditModalOpen ? <BookModalEditReports sample={sampleToEditReports} closeModal={() => { setIsEditModalOpen(false); setSampleToEditReports(''); GetData() }}/> : null}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        filterData={filterData}
        handleFilterDataEdit={HandleFilterDataEdit}
        resetFilterData={ResetFilterData}
        getData={GetData}
        handleEdit={HandleEdit}
        saveEditedData={SaveEditedData}
        setIsModalOpen={setIsModalOpen}
        setIsEditModalOpen={setIsEditModalOpen}
        downloadData={DownloadDataStepOne}
        setSampleToEditReports={setSampleToEditReports}
        discardChanges={() => { setDataIndexToEdit([]); setEditing(false); GetData() }}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default Book;
