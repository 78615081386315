import React from 'react';

const ModalTableTextInput = (props) => {

  return (
    <div className="modal-table-row-container">
      <input
        type="text"
        className="modal-table-input"
        value={props.value || ''}
        style={props.styles ? props.styles : {}}
        onChange={(e) => props.handleChange(props.stateIdx, props.stateKey, e.target.value)}
      />
    </div>
  )
}

export default ModalTableTextInput