import React from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import { withAlert } from 'react-alert';

class PicEqModalAdd extends React.Component{

  state = {
    temperature: '',
    waterDens: '',
    code: 'PIC-MSTD-',
    saving: false,
  };

  componentWillUnmount() {
    this.source.cancel("Operación cancelada por el usuario");
  }

  CancelToken = Axios.CancelToken;
  source = this.CancelToken.source();

  abortController = new AbortController();

  codeInputStyle(){
    if ( this.props.equipmentCodes.indexOf(this.state.code) === -1 ) {
      return {
        borderColor: 'red'
      }
    }
  }

  SaveNewPropsRecord(){
    if (this.state.temperature) {
      this.setState({saving: true});
      const header = {
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const newEqProps = {
        temperature: this.state.temperature,
        waterDens: this.state.waterDens,
      };

      let url = '/api/equipment/pic/addProps';
      Axios.post(url, newEqProps, {cancelToken: this.source.token}, header)
      .then((res) => {
        this.setState({saving: false}, () => this.props.alert.show(res.data.msg, {type: 'success'}));
      })
      .catch((err) => {
        if (err.response.status === 500) {
          this.setState({saving: false}, () => this.props.alert.show('No se recibió respuesta del servidor', {type: 'error'}));
        } else {
          this.setState({saving: false}, () => this.props.alert.show(err.response.data.msg, {type: 'error'}));
        }
      });
    }else{
      this.props.alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  render(){
    return (
      <div className="modal-outer-container">
        <LoadingModal loading={this.state.saving}/>
        <div className={(this.state.saving ? "modal-inner-container-small modal-loading-blocked" : "modal-inner-container-small")} style={{maxWidth: '350px'}}>
          <div className="modal-header">
            <h6> Nueva Información Picnómetro </h6>
            <div onClick={() => {this.props.closeModal()}} className="modal-close-button lb-white cursor-pointer user-select-none">
              x
            </div>
          </div>
          <div className="modal-form-container">
            <div className="modal-form-input-container">
              <div className="modal-form-input-title">
                <span style={{color: 'red'}}>*</span> Temperatura Agua (°C)
              </div>
              <input
                type="text"
                value={this.state.temperature}
                onChange={(e) => {this.setState({temperature: e.target.value.replace(/,/gi, ".")})}}
              />
            </div>
            <div className="modal-form-input-container">
              <div className="modal-form-input-title">
                Densidad Agua (g/ml)
              </div>
              <input
                type="text"
                value={this.state.waterDens}
                onChange={(e) => {this.setState({waterDens: e.target.value.replace(/,/gi, ".")})}}
              />
            </div>
            <div className="modal-form-input-container">
              <button onClick={() => this.SaveNewPropsRecord()} className="modal-form-button lb-white" disabled={this.state.saving}>
                Guardar Información
              </button>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

// PropTypes

export default withAlert()(PicEqModalAdd);
