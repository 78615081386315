import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalItemSelection } from '../../Layout/ModalElements';
import { GetReportSimpleDisplayText } from '../../Generic/Constants.js';
import { SelectedReportStatusText, GetAvg, GetDiff, GetRandWithDiff } from '../Helpers.js';
import { confirmAlert } from 'react-confirm-alert';
import { ImMagicWand } from 'react-icons/im';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const promKeys = [[
  'table2DryCol1Field1', 'table2DryCol1Field2', 'table2DryCol1Field3', 'table2DryCol1Field4', 
  'table2DryCol2Field1', 'table2DryCol2Field2', 'table2DryCol2Field3', 'table2DryCol2Field4',
  'table2DryCol3Field1', 'table2DryCol3Field2', 'table2DryCol3Field3', 'table2DryCol3Field4',
], [
  'table2DryCol1Field5', 'table2DryCol1Field6', 'table2DryCol1Field7', 'table2DryCol1Field8', 
  'table2DryCol2Field5', 'table2DryCol2Field6', 'table2DryCol2Field7', 'table2DryCol2Field8',
  'table2DryCol3Field5', 'table2DryCol3Field6', 'table2DryCol3Field7', 'table2DryCol3Field8',
], [
                         'table2DryCol1Field10', 'table2DryCol1Field11', 'table2DryCol1Field12', 
  'table2DryCol2Field9', 'table2DryCol2Field10', 'table2DryCol2Field11', 'table2DryCol2Field12',
  'table2DryCol3Field9', 'table2DryCol3Field10', 'table2DryCol3Field11', 'table2DryCol3Field12',
], [
                          'table2DryCol1Field14', 'table2DryCol1Field15', 'table2DryCol1Field16', 
  'table2DryCol2Field13', 'table2DryCol2Field14', 'table2DryCol2Field15', 'table2DryCol2Field16',
  'table2DryCol3Field13', 'table2DryCol3Field14', 'table2DryCol3Field15', 'table2DryCol3Field16',
]];

const MaxMinHumidityCalc = ({ loading, setLoading }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);
  const [calcValues, setCalcValues] = useState([]);
  const [selectedReport, setSelectedReport] = useState(0);

  const isFirstRender = useRef(true);

  const SetInitialCalc = useCallback((pos) => {
    let newCalc = {
      table2DryCol1Field9: data[pos].data.table2DryCol1Field9[0].value,
      table2DryCol1Field13: data[pos].data.table2DryCol1Field13[0].value,
    }
    return newCalc;
  }, [data]);

  const ResetAllCalcs = useCallback(() => {
    let newCalcValues = [];
    for (let i = 0; i < data.length; i++) {
      newCalcValues.push(SetInitialCalc(i))
    }
    setCalcValues(newCalcValues);
  }, [data, SetInitialCalc]);

  useEffect(() => {
    if (data.length) {
      ResetAllCalcs();
    }
  }, [data, ResetAllCalcs]);

  useEffect(() => {
    if (selectedReport !== 0 && selectedReport >= data.length) {
      setSelectedReport(0);
    }
  }, [data, selectedReport]);

  const ResetSingleCalc = (pos) => {
    let newCalcValues = JSON.parse(JSON.stringify(calcValues));
    newCalcValues[pos] = JSON.parse(JSON.stringify(SetInitialCalc(pos)))
    setCalcValues(newCalcValues);
  }

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/revisionHelpers/maxminHumidityCalc';

    Axios.get(url, opts)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    }).finally(() => setLoading(false));
  }, [alert, setLoading]);

  const CalcNewValues = (col) => {

    let newField2 = 0;
    let currentProm = 0;
    let currentPromHelper = [];
    let newProm = 0;
    let newPromHelper = [];
    let isGood = false;
    let baseValueKey = col === 0 ? 'table2DryCol1Field1' : 'table2DryCol1Field5';
    let promKey1Pos = col === 0 ? 0 : 1;
    let promKey2Pos = col === 0 ? 2 : 3;

    if (data[selectedReport].data[baseValueKey][0].value) {
      for (let i = 0; i < promKeys[promKey1Pos].length; i++) {
        if (data[selectedReport].data[promKeys[promKey1Pos][i]][0].value) {
          currentPromHelper.push(data[selectedReport].data[promKeys[promKey1Pos][i]][0].value);
        }
      }
      for (let i = 0; i < promKeys[promKey2Pos].length; i++) {
        if (data[selectedReport].data[promKeys[promKey2Pos][i]][0].value) {
          newPromHelper.push(data[selectedReport].data[promKeys[promKey2Pos][i]][0].value);
        }
      }
      currentProm = GetAvg(currentPromHelper);

      for (let i = 0; i < 100000; i++) {
        newField2 = GetRandWithDiff(
          (Number(data[selectedReport].data[baseValueKey][0].value)-0.05), 
          (Number(data[selectedReport].data[baseValueKey][0].value)+0.05), 
          Number(data[selectedReport].data[baseValueKey][0].value), 
          0.03,
          0.05
        );
        if (newField2) {
          newProm = GetAvg([...newPromHelper, newField2]);
        }
        if (newField2 && GetDiff(currentProm, newProm, 0.03, 0.05, true)) {
          isGood = true;
          break;
        }
      }
      if (isGood) {
        return newField2.toFixed(3);
      } else {
        return false;
      }
    } else {
      alert.show('Se necesita el valor 6.1 para generar datos', {type: 'info'});
    }
  }

  const GenerateColumnData = (col) => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let newValues = CalcNewValues(col);
    if (col === 0) {
      newData[selectedReport].table2DryCol1Field9 = newValues;
    } else if (col === 1) {
      newData[selectedReport].table2DryCol1Field13 = newValues;
    }
    if (newValues) {
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const GenerateAllColumnData = () => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let newValue62 = CalcNewValues(0);
    let newValue72 = CalcNewValues(1);
    if (newValue62 && newValue72) {
      newData[selectedReport].table2DryCol1Field9 = newValue62;
      newData[selectedReport].table2DryCol1Field13 = newValue72;
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const CheckColChanges = (option, col) => {
    let calcKey = col === 0 ? 'table2DryCol1Field9' : 'table2DryCol1Field13';
    if (calcValues[option][calcKey] !== data[option].data[calcKey][0].value) {
      return true;
    } else {
      return false;
    }
  }

  const CheckOptionChange = (option) => {
    if (CheckColChanges(option, 0) || CheckColChanges(option, 1)) {
      return true;
    } else {
      return false;
    }
  }

  const RenderDetailsTable = () => {

    let proms = [0, 0, 0, 0];
    let promsHelper = [[], [], [], []];

    for (let i = 0; i < promKeys.length; i++) {
      for (let j = 0; j < promKeys[i].length; j++) {
        if (data[selectedReport].data[promKeys[i][j]][0].value) {
          promsHelper[i].push(data[selectedReport].data[promKeys[i][j]][0].value);
        }
      }
    }

    if (calcValues[selectedReport]?.table2DryCol1Field9) {
      promsHelper[2].push(calcValues[selectedReport].table2DryCol1Field9);
    }
    if (calcValues[selectedReport]?.table2DryCol1Field13) {
      promsHelper[3].push(calcValues[selectedReport].table2DryCol1Field13);
    }

    for (let i = 0; i < promsHelper.length; i++) {
      proms[i] = GetAvg(promsHelper[i]);
    }

    return (
      <div className="d-flex flex-column">
        <div className="d-flex my-2">
          <div className="d-flex flex-1 flex-column mx-2">
            {['Campo', '6.1 Lecturas iniciales (molde) (mm)', '6.2 Lecturas iniciales (molde) (mm)', '7.1 Lecturas finales (placa) (mm)', '7.2 Lecturas finales (placa) (mm)']
            .map((item, itemIdx) => <div key={itemIdx} className="ellipsis-text p-2"> {item} </div>)}
          </div>
          <div className="d-flex flex-1 flex-column align-items-center mx-2">
            <div className="ellipsis-text p-2"> Valor Dial 1 </div>
            <div className="p-2"> { data[selectedReport].data.table2DryCol1Field1[0].value || '-' } </div>
            <div className={"p-2" + (CheckColChanges(selectedReport, 0) ? " bg-warning bg-opacity-50" : "")}> { calcValues[selectedReport].table2DryCol1Field9 || '-' } </div>
            <div className="p-2"> { data[selectedReport].data.table2DryCol1Field5[0].value || '-' } </div>
            <div className={"p-2" + (CheckColChanges(selectedReport, 1) ? " bg-warning bg-opacity-50" : "")}> { calcValues[selectedReport].table2DryCol1Field13 || '-' } </div>
          </div>
          <div className="d-flex flex-1 flex-column align-items-center mx-2">
            <div className="p-2"> Promedio </div>
            <div className="p-2"> { proms[0].toFixed(3) } </div>
            <div className={"p-2" + (CheckColChanges(selectedReport, 0) ? " bg-warning bg-opacity-50" : "")}> { proms[2].toFixed(3) } </div>
            <div className="p-2"> { proms[1].toFixed(3) } </div>
            <div className={"p-2" + (CheckColChanges(selectedReport, 1) ? " bg-warning bg-opacity-50" : "")}> { proms[3].toFixed(3) } </div>
          </div>
          <div className="d-flex flex-1 flex-column align-items-center mx-2">
            <div className="p-2"> Generar </div>
            <div className="d-flex flex-column flex-1 justify-content-around">
              <Button size="sm" onClick={() => GenerateColumnData(0)}> <ImMagicWand color="white" className="icon-style"/> </Button>
              <Button size="sm" onClick={() => GenerateColumnData(1)}> <ImMagicWand color="white" className="icon-style"/> </Button>
            </div>
          </div>
        </div>
        <button className="modal-form-button lb-white my-3 user-select-none" onClick={() => GenerateAllColumnData()}>Generar todas las Columnas</button>
      </div>
    )
  }

  const SaveDataStepOne = () => {
    if (data.length) {
      let toSaveArr = [];
      calcValues.forEach((report, reportIdx) => {
        let keysHelper = {};
        Object.keys(report).forEach((rKey) => {
          if (report[rKey] !== data[reportIdx].data[rKey][0].value) {
            keysHelper[rKey] = report[rKey];
          }
        })
        if (Object.keys(keysHelper).length) {
          toSaveArr.push({ idx: reportIdx, reportNumber: data[reportIdx].reportNumber, data: keysHelper });
        }
      });
      if (toSaveArr.length) {
        SaveDataStepTwo(toSaveArr);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    } else {
      alert.show('No hay ensayos para guardar', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    let reportTexts = [];
    saveArr.forEach(r => {
      reportTexts.push(GetReportSimpleDisplayText(data[r.idx]));
    })
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se guardarán cambios para los siguientes ensayos:</p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {reportTexts.map((rt, rtIdx) =>  <span key={rtIdx}>{rt}</span>)}
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={onClose}> No, deseo salir </button>
              <button className="modal-confirm-button round-button lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr); }}> Si, deseo guardar </button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/reports/batch/updateData';

    Axios.post(url, { records: saveArr }, opts).then((res) => {
      
      setLoading(false);

      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 5000});
      }
      GetData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <div className="modal-table-outer-container my-1">
        { (data.length && calcValues.length && (selectedReport < data.length )) ? 
          <div className="d-flex flex-column m-1 overflow-auto">
            <ModalItemSelection 
              canChange={!loading} data={data} currentItem={selectedReport} setItem={(value) => setSelectedReport(Number(value))} 
              optClasses={(item, itemIdx) => CheckOptionChange(itemIdx) ? "bg-warning bg-opacity-50" : ""} optText={(opt) => GetReportSimpleDisplayText(opt)} 
              backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
            />
            {SelectedReportStatusText(data[selectedReport])}
            <div className="d-flex flex-column flex-xxl-row overflow-auto">
              {RenderDetailsTable()}
            </div>
            <hr/>
            <div className="d-flex justify-content-around">
              <Button className="my-1 mx-2" onClick={() => ResetSingleCalc(selectedReport)} variant="secondary" size="sm">Reiniciar este ensayo</Button>
              <Button className="my-1 mx-2" onClick={() => ResetAllCalcs()} variant="secondary" size="sm">Reiniciar todos los ensayos</Button>
            </div>
          </div>
        : 
          <span>No se encontraron ensayos</span>
        }
      </div>
      <div>
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default MaxMinHumidityCalc