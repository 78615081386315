import React, { useState } from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import { confirmAlert } from 'react-confirm-alert';
import { GetReportFullDisplayText } from '../Generic/Constants.js';
import FormTextInput from '../Generic/FormTextInput';
import FormFileInput from '../Generic/FormFileInput';
import Button from 'react-bootstrap/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const CNCImg = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [reportNumber, setReportNumber] = useState('');
  const [report, setReport] = useState(null);
  const [newFile, setNewFile] = useState(null);
  const [uselessParam, setUselessParam] = useState(Math.round((Math.random()*100000), 0));

  const GetReportData = () => {
    if (reportNumber) {

      setLoading(true);
  
      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };
  
      const url = '/api/reports/cnc/'+reportNumber;
  
      Axios.get(url, opts)
      .then((res) => {
        setLoading(false);
        setNewFile(null);
        setReport(res.data);
        setUselessParam(Math.round((Math.random()*100000), 0));
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('Ingrese el N° de Informe', {type: 'error'})
    }
  }

  const SaveNewImgStepOne = () => {
    if (reportNumber) {
      if (newFile) {
        confirmAlert({
          closeOnEscape: false,
          closeOnClickOutside: false,
          customUI: ({ onClose }) => {
            return (
              <div className="modal-delete-confirm-container">
                <h4>Confirme esta acción</h4>
                <p>Al subir la nueva imagen, se perderá la imagen anterior</p>
                <p>¿Desea continuar?</p>
                <div className="modal-delete-confirm-buttons-container">
                  <button
                    className="modal-confirm-button round-button silver-black me-2"
                    onClick={onClose}
                  >
                    No, deseo salir
                  </button>
                  <button
                    className="modal-confirm-button round-button lb-white"
                    onClick={() => {
                      onClose();
                      SaveNewImgStepTwo();
                    }}
                  >
                    Si, deseo guardar
                  </button>
                </div>
              </div>
            );
          }
        })
      } else {
        alert.show('Seleccione la nueva imagen', {type: 'info'})
      }
    } else {
      alert.show('Ingrese el N° de Informe', {type: 'info'})
    }
  }

  const SaveNewImgStepTwo = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };

    const formData = new FormData();
    formData.append('reportNumber', reportNumber);
    formData.append('filename', newFile);

    const url = '/api/reports/cnc/img/add';

    Axios.post(url, formData, opts)
    .then((res) => {
      setLoading(false);
      setUselessParam(Math.round((Math.random()*100000), 0));
      alert.show(res.data.msg, {type: 'success'});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Subir nueva imagen para CNC'} closeModal={closeModal} closeKb={!loading} />
        <div className="d-flex flex-md-row flex-column my-2 overflow-auto">
          <div className="d-flex flex-1 flex-column align-items-center">
            <FormTextInput title="N° de Informe:" value={reportNumber} handleChange={(key, value) => {setReportNumber(value); setReport(null)}} />
            <Button className="my-1 fs-inherit" onClick={() => GetReportData()}>Buscar</Button>
            { report ?
              <div className="d-flex flex-column my-2">
                <hr/>
                <div className="text-center fw-semibold">
                  {GetReportFullDisplayText(report)}
                </div>
                <div className="d-flex flex-column align-items-center">
                  <FormFileInput title="Nueva Imagen" isRequired={true} accept="image/*" handleChange={(key, value) => setNewFile(value)} />
                  <Button className="my-1 fs-inherit" onClick={() => SaveNewImgStepOne()}>Guardar nueva imagen</Button>
                </div>
              </div>
            : null }
          </div>
          { report ? 
            <div className="d-flex flex-1 flex-column align-items-center px-2">
              <span className="fw-semibold"> Imagen actual </span>
              <div className="d-flex mh-100 overflow-auto mt-1 border border-dark">
                <img src={'/api/reports/cnc/img/'+Number(reportNumber)+'&'+uselessParam} alt='Imagen CNC' className="w-100 h-100"/>
              </div>
            </div> 
          : null }
        </div>
      </div>
    </div>
  )
} 

export default CNCImg;