import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { FaList } from 'react-icons/fa';

const menuTabs = [
  {
    to: '/usuarios',
    title: 'Usuarios'
  },
  {
    to: '/proyectos',
    title: 'Proyectos'
  },
  {
    to: '/libro-muestras-de-suelo',
    title: 'Libro de Muestras'
  },
  {
    to: '/informes',
    title: 'Informes'
  },
  // {
  //   to: '/resultados',
  //   title: 'Resultados'
  // },
  {
    to: '/formularios',
    title: 'Formularios'
  },
  {
    to: '/equipos',
    title: 'Equipos'
  },
];

const TopNavBar = () => {

  const [isLateralMenuOpen, setIsLateralMenuOpen] = useState(false);

  const RenderLinks = () => {
    return menuTabs.map((link, index) => (
      <NavLink key={index} to={link.to} className={({ isActive }) => "w-100 p-1 bg-gw-lb be-black-light color-inherit ellipsis-text text-decoration-none" + (isActive ? " color-darklb" : "")}>
        {link.title}
      </NavLink>
    ));
  }

  return (
    <div className="header-bs d-flex flex-column w-100 bg-gw zIndex-1 my-1">
      <div className="d-none d-md-flex text-center">
        {RenderLinks()}
      </div>
      <div className="d-flex d-md-none ps-2">
        <div onClick={() => setIsLateralMenuOpen(!isLateralMenuOpen)}>
          <FaList size={'18px'} className="icon-style" />
        </div>
      </div>
      <div className="d-flex d-md-none zIndex-1 fs-6">
        <div className="header-bs d-flex flex-column position-fixed bg-gw w-100 ps-1 my-1">
        {isLateralMenuOpen ? RenderLinks() : null}
        </div>
      </div>
    </div>
  )
}

export default TopNavBar;
