import React from 'react';
import Form from 'react-bootstrap/Form';

const FormDateTextInput = (props) => {
  return (
    <div className="d-md-flex align-items-center justify-content-center p-2">
      { props.title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { props.isRequired ? <span className="text-danger">*</span> : '' }
          { props.title }
        </div>
      : null }
      {
        (props.value === "NO INFORMADA") ? 
          <div
            onClick={() => props.handleChange(props.stateKey, '')}
            className="modal-form-input cursor-pointer lb-white d-flex align-items-center justify-content-center p-1"
            title="NO INFORMADA"
          >
            <b>NO INFORMADA</b>
          </div>
      :
        <div className="modal-form-input d-flex">
          <div
            onClick={() => props.handleChange(props.stateKey, 'NO INFORMADA')}
            className="cursor-pointer silver-black d-flex flex-1 align-items-center justify-content-center"
            title="NO INFORMADA"
          >
            <b>NO INFORMADA</b>
          </div>
          <Form.Control type="date" className="modal-form-input flex-1 fs-inherit border border-secondary ms-2" 
          size="sm" value={props.value || ''} onChange={(e) => props.handleChange(props.stateKey, e.target.value)} />
        </div>
      }
    </div>
  )
}

export default FormDateTextInput