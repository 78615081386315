// Constant values and reused functions

const regularStatusSelectOptions = [
  { value: "activo", display: "Activo" },
  { value: "inactivo", display: "Inactivo" }
];

const roleSelectOptions = [
  { value: "superAdmin", label: "Super Admin" },
  { value: "labUser", label: "Usuario Lab" },
  { value: "labAdmin", label: "Admin Lab" },
  { value: "fwUser", label: "Usuario Terreno" },
  { value: "fwAdmin", label: "Admin Terreno" }
];

const reportStatusSelectOptions = [
  { value: "anulado", display: "ANULADO", sortPos: 1, disabled: false, colorClasses: "bg-secondary" },
  { value: "inicial", display: "INICIAL", sortPos: 2, disabled: true, colorClasses: "bg-primary color-white" },
  { value: "incompleto", display: "EN PROCESO", sortPos: 3, disabled: false, colorClasses: "bg-yellow" },
  { value: "revision", display: "LISTO PARA REVISIÓN", sortPos: 4, disabled: false, colorClasses: "bg-success color-white" },
  { value: "finalizado", display: "FINALIZADO", sortPos: 5, disabled: false, colorClasses: "bg-purple color-white" },
  { value: "exportado", display: "EXPORTADO", sortPos: 6, disabled: false, colorClasses: "bg-info" }
];

const fwStatusRSOpts = [
  { value: "incompleto", display: "EN PROCESO", sortPos: 1, disabled: false, colorClasses: "bg-yellow" },
  { value: "finalizado", display: "FINALIZADO", sortPos: 2, disabled: false, colorClasses: "bg-purple color-white" },
  { value: "deleted", display: "ELIMINADO", sortPos: 3, disabled: true, colorClasses: "bg-dark color-white" },
];

const boolAsStrSelectOptions = [
  { value: "si", display: "Si" },
  { value: "no", display: "No" },
  { value: "", display: "-" }
];

const availableStatusOptions = [
  { value: 'activo', label: 'Activo' },
  { value: 'no disponible', label: 'No Disponible' },
  { value: 'inactivo', label: 'Inactivo' }
];

const signStatusOptions = [
  { value: 'espera', label: 'Espera' },
  { value: 'pendiente', label: 'Pendiente' },
  { value: 'firmado', label: 'Firmado' }
];

const eqSectionDict = [
  { eqKey: 'granEquipment', title: 'Equipo Granulometría' },
  { eqKey: 'densEquipment', title: 'Equipo Densidad' },
  { eqKey: 'atterbergEquipment', title: 'Equipo Límites de Atterberg' },
  { eqKey: 'proctorEquipment', title: 'Equipo Proctor' },
  { eqKey: 'cbrEquipment', title: 'Equipo CBR' },
  { eqKey: 'maxminEquipment', title: 'Equipo MaxMin' },
  { eqKey: 'porchetEquipment', title: 'Equipo Porchet' },
  { eqKey: 'saltEquipment', title: 'Equipo Sales' },
  { eqKey: 'cncEquipment', title: 'Equipo CNC' },
  { eqKey: 'directCutEquipment', title: 'Equipo Corte Directo' },
  { eqKey: 'consEquipment', title: 'Equipo Consolidación' },
];

const fwProbingPersonnelRoleSelectOpts = [
  { value: "supervisor", label: "Supervisor" },
  { value: "probingSupervisor", label: "Supervisor de Sondaje" },
  { value: "probingOperator", label: "Operador de Sondaje" },
  { value: "probingAssistant", label: "Asistente de Sondaje" }
];

const TestSpecialChars = (text) => {
  return (text && text.match(/[,/\\'"*¿?]/gi)) ? true : false;
}

const GetReportFullDisplayText = (report) => {
  let reportText = '';
  const foundSample = report.samples.find(Boolean);
  if (foundSample) {
    reportText = (foundSample.project.code + ' - ' + report.template.title + ' - ' + report.reportNumber);
    if (report.samples.length === 1) {
      reportText += ' - ' + (([foundSample.trialPitNumber, foundSample.horizon, foundSample.depth].filter(Boolean).join(' ')) || foundSample.sampleId);
    }
  } else {
    reportText = (report.template.title + report.reportNumber);
  }
  
  const statusText = reportStatusSelectOptions.find(opt => opt.value === report.status);
  if (statusText) {
    reportText += (' - ' + statusText.display);
  }

  return reportText;
}

const GetReportDisplayText = (report) => {
  let reportText = '';
  const foundSample = report.samples.find(Boolean);
  if (foundSample) {
    reportText = (foundSample.project.code + ' - ' + report.template.title + ' - ' + report.reportNumber);
    if (report.samples.length === 1) {
      reportText += ' - ' + (([foundSample.trialPitNumber, foundSample.horizon, foundSample.depth].filter(Boolean).join(' ')) || foundSample.sampleId);
    }
  } else {
    reportText = (report.template.title + report.reportNumber);
  }
  return reportText;
}

const GetReportSimpleDisplayText = (report) => {
  let reportText = '';
  const foundSample = report.samples.find(Boolean);
  if (foundSample) {
    reportText = (foundSample.project.code + ' - ' + report.reportNumber);
    if (report.samples.length === 1) {
      reportText += ' - ' + (([foundSample.trialPitNumber, foundSample.horizon, foundSample.depth].filter(Boolean).join(' ')) || foundSample.sampleId);
    }
  } else {
    reportText = (report.template.title + report.reportNumber);
  }
  return reportText;
}

const GetReportSampleDisplayText = (report) => {
  const foundSample = report.samples.find(Boolean);
  let reportText = [foundSample.trialPitNumber, foundSample.horizon, foundSample.depth].filter(Boolean).join(' ');
  return reportText || '-';
}

const GetReportFullSampleDisplayText = (report) => {
  let samplesTextArr = [];
  report.samples.forEach(s => {
    if (s) {
      let foundSampleProps = ([s.trialPitNumber, s.horizon, s.depth].filter(Boolean));
      if (foundSampleProps.length > 2) foundSampleProps.pop();
      if (foundSampleProps.length) {
        samplesTextArr.push(foundSampleProps.join(' '));
      } else {
        samplesTextArr.push(s.sampleId);
      }
    }
  })
  const finalText = samplesTextArr.join(' - ');
  return finalText || '-';
}

const GetSampleDisplayText = (sample) => {
  let reportText = [sample.trialPitNumber, sample.horizon, sample.depth].filter(Boolean).join(' ');
  return reportText || sample.sampleId || '-';
}

export { regularStatusSelectOptions, roleSelectOptions, reportStatusSelectOptions, fwStatusRSOpts, boolAsStrSelectOptions, 
  availableStatusOptions, signStatusOptions, eqSectionDict, fwProbingPersonnelRoleSelectOpts,
  TestSpecialChars, GetReportFullDisplayText, GetReportDisplayText, GetReportSimpleDisplayText, GetReportFullSampleDisplayText, GetReportSampleDisplayText, GetSampleDisplayText }