import React from 'react';
import PacmanLoader from 'react-spinners/PacmanLoader';

const LoadingModal = (props) => {
  return (
    props.loading ?
      <div className="loading-modal-outer-container w-100 h-100 position-fixed top-0 start-0">
        <div className="loading-modal-container d-flex flex-1 position-relative justify-content-center">
          <PacmanLoader
            sizeUnit={"px"}
            size={85}
            color={'yellow'}
            margin={'auto'}
            loading={props.loading}
          />
        </div>
      </div>
    :
      null
  )
}

export default LoadingModal;
