import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPlus, FaPen, FaCheck, FaUndo, FaSyncAlt, FaTable, FaDownload } from 'react-icons/fa';
import EquipmentModalAdd from './EquipmentModalAdd.js';
import EquipmentModalEditInfo from './EquipmentModalEditInfo.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { regularStatusSelectOptions, boolAsStrSelectOptions } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, FilterDateMethod, SortDateMethod, 
  TableTextInput, TableSelectInput, TableDateInput, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';
import 'react-confirm-alert/src/react-confirm-alert.css';
import FileDownload from 'js-file-download';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const PeriodTypeText = (opt) => {
  let helper = '';
  switch (opt) {
    case 1:
      helper = 'VERIFICACIÓN';
      break;

    case 2:
      helper = 'CALIBRACIÓN';
      break;
    case 3:
      helper = 'MANTENCIÓN';
      break;
  
    default:
      break;
  }
  return helper;
}

const EquipmentPeriodSort = (aValue, aPeriod, bValue, bPeriod) => {
  if (bValue && bPeriod) {
    if (aValue && aPeriod) {
      let nextDateA = dayjs(dayjs(aValue).add(aPeriod, 'month'));
      let nextDateB = dayjs(dayjs(bValue).add(bPeriod, 'month'));
      if (dayjs(nextDateA).diff(dayjs(nextDateB)) > 0) {
        return 1;
      } else {
        return -1;
      }
    }else {
      return -1;
    }
  }else{
    return 1;
  }
}

const EquipmentPeriodFilter = (rowValue, rowPeriod, filterValue) => {
  let nextDate = '';
  if (rowValue && rowPeriod) {
    nextDate = dayjs(dayjs(rowValue).add(rowPeriod, 'month'));
    if (nextDate) {
      if (nextDate.utc().format("DD-MM-YYYY").includes(filterValue)) {
        return true;
      } else {
        return false;
      }
    }else {
      return false;
    }
  }else{
    return false;
  }
}

const EquipmentPeriodCell = (cellValue, cellPeriod, periodOpt) => {
  let diff = 500;
  let nextDate = '';
  let styleHelper = {};
  let elementTitle = '-';
  const periodType = PeriodTypeText(periodOpt);

  if (cellValue && cellPeriod) {
    nextDate = dayjs(dayjs(cellValue).add(cellPeriod, 'month'));
    if (nextDate) {
      diff = dayjs(nextDate).diff(dayjs(), 'days');
      if ( diff <= 31 ) {
        if (diff === 0) {
          elementTitle = "Se hará "+periodType+" HOY";
          styleHelper = { border: '2px solid #DA2647', color: '#DA2647' };
        } else if ( diff > 0) {
          elementTitle = "Se hará "+periodType+" dentro de un mes";
          styleHelper = { border: '2px solid #FF7A00', color: '#FF7A00' };
        } else {
          elementTitle = "Ya se realizó "+periodType;
          styleHelper = { border: '2px solid #299617', color: '#0a4600' };
        }
      }
    }
  }

  return <div title={elementTitle} className="ps-2 text-truncate" style={styleHelper}>{(nextDate ? nextDate.utc().format("DD-MM-YYYY") : '-')}</div>
}

function Table({ data, columns, editing, setEditing, getData, handleEdit, saveEditedData, setIsModalOpen, setIsMoldModalOpen, downloadData, discardChanges, deleteRecord }) {

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    SelectCell: TableSelectInput,
    DateCell: TableDateInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (editing) {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
            <FaCheck color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
            <FaUndo color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }else{
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Refrescar" onClick={() => getData()}>
            <FaSyncAlt color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Nuevo Usuario" onClick={() => setIsModalOpen(true)}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Editar" onClick={() => setEditing(true)}>
            <FaPen color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Descargar Planilla de Equipos" onClick={() => downloadData()}>
            <FaDownload color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Tabla de Calibración" onClick={() => setIsMoldModalOpen(true)}>
            <FaTable color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }
  }

  return (
    <div className="table-inner-container">
      <div className="d-flex align-items-center justify-content-between my-1 py-1 px-1 flex-wrap" style={{backgroundColor: editing ? '#ffb130cc' : 'initial'}}>
        <div className="d-flex align-items-center">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} />
        </div>
        <div className="d-flex align-items-center">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={35}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const Equipment = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isMoldModalOpen, setIsMoldModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dataIndexToEdit, setDataIndexToEdit] = useState([]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Equipo";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/equipment';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un equipo</h3>
            <p> {'¿Está seguro(a) de eliminar el equipo '}{<b>{data[rowId].code}</b>}{'?'} </p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].code);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (record) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un equipo</h3>
            <p>Esta acción es irreversible. Confirme ingresando el código del equipo que desea eliminar</p>
            <input
              type="text"
              className="modal-form-input"
              onChange={(e) => { deleteConfirmation = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (record === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(record)
                    }else{
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  }else{
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/equipment/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  const SaveEditedDataStepOne = () => {
    if (dataIndexToEdit.length > 0) {

      setLoading(true);
      let dataToUpdate = [];
      let incompleteIndexes = [];

      dataIndexToEdit.forEach((dataIndex) => {
        if (!data[dataIndex].code || !data[dataIndex].nameAndBrand ) {
          if (incompleteIndexes.indexOf(dataIndex) === -1) {
            incompleteIndexes.push(dataIndex);
          }
        }
      });

      if (incompleteIndexes.length) {
        let incompleteIndexesAlert =
          <div className="d-flex flex-column">
            Los siguientes registros tienen campos sin completar:
            {incompleteIndexes.map((element, index) =>  <span key={index}>{data[element].username}</span> )}
          </div>
        setLoading(false);
        alert.show(incompleteIndexesAlert, {type: 'info'});
      }else{
        dataIndexToEdit.forEach((dataIndexToModify) => {
          dataToUpdate.push(data[dataIndexToModify]);
        });

        SaveEditedDataStepTwo(dataToUpdate);
      }
    }else{
      setEditing(false);
      alert.show('No se realizaron modificaciones', {type: 'success'});
    }
  }

  const SaveEditedDataStepTwo = (dataToUpdate) => {

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/equipment/updateMany';

    Axios.post(url, dataToUpdate, opts)
    .then((res) => {
      setLoading(false);
      setEditing(false);
      setDataIndexToEdit([]);
      let editResults =
        <div className="d-flex flex-column">
          {'Los resultados de sus modificaciones son: '}
          {res.data.map((element, index) => <span key={index}>{element}</span> )}
        </div>
      alert.show(editResults, {type: 'success', timeout: 25000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const DownloadDataStepOne = () => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Confirme esta acción</h4>
            <p> Está descargando toda la información de los Equipos </p>
            <p> Esta operación podría tardar, confirme si desea continuar </p>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); DownloadDataStepTwo() }}> Si, deseo descargar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const DownloadDataStepTwo = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/equipment';

    Axios.get(url, opts)
    .then((res) => {
      FileDownload(res.data, 'MSTD-FOR-005 '+(dayjs().format("DD-MM-YYYY"))+'.xlsx');
      setLoading(false);
      alert.show('Se ha descargado el archivo con los equipos con éxito', {type: 'success'})
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se pudo descargar el archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('No se encontró el archivo', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al realizar esta operación', {type: 'error'})
      }
    });
  }

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
      if (dataIndexToEdit.indexOf(index) === -1) {
        let newEditedIndex = dataIndexToEdit;
        newEditedIndex.push(index);
        setDataIndexToEdit(newEditedIndex);
      }
    }
  }

  const tableColumns = useMemo(() => [
    {
      Header: 'N° Serie / Código Equipo',
      accessor: 'code',
      width: 175
    },{
      Header: 'Equipo y Marca',
      accessor: 'nameAndBrand',
      cellRender: 'EditableCell',
      width: 175
    },{
      Header: 'Rango o Capacidad',
      accessor: 'capacity',
      cellRender: 'EditableCell',
    },{
      Header: 'Resolución',
      accessor: 'resolution',
      cellRender: 'EditableCell',
    },{
      Header: '¿Se verifica?',
      accessor: 'shouldVerify',
      cellRender: 'SelectCell',
      selectOpts: boolAsStrSelectOptions,
      width: 125,
    },{
      Header: 'Última verificación',
      accessor: 'lastVerification',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Periodo entre verificaciones',
      accessor: 'verificationPeriod',
      cellRender: 'EditableCell',
    },{
      id: 'nextVerification',
      Header: 'Próxima verificación',
      accessor: row => row,
      Cell: ({ row }) => EquipmentPeriodCell(row.values.lastVerification, row.values.verificationPeriod, 1),
      filter: (rows, id, filterValue) => rows.filter(row => EquipmentPeriodFilter(row.values.lastVerification, row.values.verificationPeriod, filterValue)),
      sortType: (rowA, rowB) => EquipmentPeriodSort(rowA.values.lastVerification, rowA.values.verificationPeriod, rowB.values.lastVerification, rowB.values.verificationPeriod),
      sortDescFirst: true
    },{
      Header: '¿Se calibra?',
      accessor: 'shouldCalibrate',
      cellRender: 'SelectCell',
      selectOpts: boolAsStrSelectOptions,
      width: 125,
    },{
      Header: 'Última calibración',
      accessor: 'lastCalibrate',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Periodo entre calibraciones',
      accessor: 'calibratePeriod',
      cellRender: 'EditableCell',
    },{
      Header: 'Calibrado por',
      accessor: 'calibratedBy',
      cellRender: 'EditableCell',
    },{
      id: 'nextCalibrate',
      Header: 'Próxima calibración',
      accessor: row => row,
      Cell: ({ row }) => EquipmentPeriodCell(row.values.lastCalibrate, row.values.calibratePeriod, 2),
      filter: (rows, id, filterValue) => rows.filter(row => EquipmentPeriodFilter(row.values.lastCalibrate, row.values.calibratePeriod, filterValue)),
      sortType: (rowA, rowB) => EquipmentPeriodSort(rowA.values.lastCalibrate, rowA.values.calibratePeriod, rowB.values.lastCalibrate, rowB.values.calibratePeriod),
      sortDescFirst: true
    },{
      Header: '¿Se realiza mantención externa?',
      accessor: 'externalMaintenance',
      cellRender: 'SelectCell',
      selectOpts: boolAsStrSelectOptions,
      width: 125,
    },{
      Header: 'Última mantención',
      accessor: 'lastMaintenance',
      cellRender: 'DateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Periodo entre Mantenciones',
      accessor: 'maintenancePeriod',
      cellRender: 'EditableCell',
    }, {
      id: 'nextMaintenance',
      Header: 'Próxima mantención',
      accessor: row => row,
      Cell: ({ row }) => EquipmentPeriodCell(row.values.lastMaintenance, row.values.maintenancePeriod, 3),
      filter: (rows, id, filterValue) => rows.filter(row => EquipmentPeriodFilter(row.values.lastMaintenance, row.values.maintenancePeriod, filterValue)),
      sortType: (rowA, rowB) => EquipmentPeriodSort(rowA.values.lastMaintenance, rowA.values.maintenancePeriod, rowB.values.lastMaintenance, rowB.values.maintenancePeriod),
      sortDescFirst: true
    },{
      Header: 'Nº último certificado de calibración o registro de verificación / mantención',
      accessor: 'lastCertificate',
      cellRender: 'EditableCell',
    }, {
      Header: 'Responsable del equipo',
      accessor: 'equipmentResponsible',
      cellRender: 'EditableCell',
    },{
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: regularStatusSelectOptions,
      width: 125,
    },{
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
    },{
      Header: 'Fecha Creación',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], []);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isModalOpen ? <EquipmentModalAdd closeModal={() => { setIsModalOpen(false); GetData() }}/> : null}
      {isMoldModalOpen ? <EquipmentModalEditInfo closeModal={() => setIsMoldModalOpen(false)}/> : null}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        getData={GetData}
        handleEdit={HandleEdit}
        saveEditedData={SaveEditedDataStepOne}
        setIsModalOpen={setIsModalOpen}
        setIsMoldModalOpen={setIsMoldModalOpen}
        downloadData={DownloadDataStepOne}
        discardChanges={() => { setDataIndexToEdit([]); setEditing(false); GetData() }}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default Equipment;
