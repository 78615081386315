import React, { useState } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalSections } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import { GetReportFullDisplayText } from '../Generic/Constants.js';
import { FaFilePdf, FaFileExcel } from 'react-icons/fa';
import dayjs from 'dayjs';
import FileDownload from 'js-file-download';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const viewSections = ['Informes de Cliente', 'Ensayos de Laboratorio'];

const ReportModalDownloadsPanel = ({ report, closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(0);

  const DownloadReportFile = (expObj, opt, formatOpt) => {
    // Opt is lab 1 client 2
    // formatOpt is pdf 1 calc 2
    setLoading(true);

    const opts = {
      headers: {
        'Content-Type': 'application/octet-stream',
        'Content-Disposition': 'attachment'
      },
      responseType: 'blob',
      cancelToken: source.token
    };

    const url = '/api/downloads/reports/single/'+report.reportNumber+'&'+expObj.version+'&'+opt+'&'+formatOpt;

    Axios.get(url, opts)
    .then((res) => {
      let downloadTitle = expObj.documentName + (formatOpt === 1 ? '.pdf' : (expObj.ext || '.xlsx'));
      FileDownload(res.data, downloadTitle);
      setLoading(false);
      alert.show('Se ha descargado su ensayo con éxito', {type: 'success', timeout: 5000});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else if (err.response.status === 409) {
        alert.show('Este informe aún no ha sido firmado', {type: 'error'})
      } else if (err.response.status === 404) {
        alert.show('No se encontró su archivo', {type: 'error'})
      } else if (err.response.status === 400) {
        alert.show('Ocurrió un error al generar su archivo', {type: 'error'})
      }
    });
  }

  const RenderLabReports = () => {
    if (report.labExportInfo.length) {
      const rowTitles = ['Versión', 'Acción', 'Exportado', 'Por', 'PDF', 'Planilla'];
      return (
        <>
          <div className="modal-table-sticky-header-container">
            <div className="modal-table-sticky-header">
              {rowTitles.map((item, itemIdx) => <div key={itemIdx} className="modal-table-row-container"> {item} </div> )}
            </div>
          </div>
          {
            report.labExportInfo.map((el, elIdx) => (
              <div key={elIdx} className="modal-table-row-input-container">
                <div className="modal-table-row-container">
                  <span className="modal-table-title"> {el.version || '-'} </span>
                </div>
                <div className="modal-table-row-container">
                  <span className="modal-table-title"> {el.action || '-'} </span>
                </div>
                <div className="modal-table-row-container">
                  <span className="modal-table-title" title={el.exportedAt ? dayjs(el.exportedAt).format('LLLL') : '-'}>
                    {el.exportedAt ? (dayjs().to(dayjs(el.exportedAt))) : '-'}
                  </span>
                </div>
                <div className="modal-table-row-container">
                  <span className="modal-table-title"> {el.exportedBy || '-'} </span>
                </div>
                <div className="modal-table-row-container">
                  <div title="Descargar PDF" onClick={() => DownloadReportFile(el, 1, 1)}>
                    <FaFilePdf color="red" size={18} className="icon-style cursor-pointer"/>
                  </div>
                </div>
                <div className="modal-table-row-container">
                  <div title="Descargar Planilla" onClick={() => DownloadReportFile(el, 1, 2)}>
                    <FaFileExcel color={'#03A700'} size={18} className="icon-style cursor-pointer"/>
                  </div>
                </div>
              </div>
            ))
          }
        </>
      )
    } else {
      return <span className="m-3 text-center"> No hay ensayos finalizados para mostrar </span>
    }
  }

  const RenderClientReports = () => {
    if (report.clientExportInfo.length) { 
      const rowTitles = ['Versión', 'Versión Informe Laboratorio', 'Acción', 'Exportado', 'Por', 'PDF', 'Planilla'];
      return (
        <>
          <div className="modal-table-sticky-header-container">
            <div className="modal-table-sticky-header">
              {rowTitles.map((item, itemIdx) => <div key={itemIdx} className="modal-table-row-container"> {item} </div> )}
            </div>
          </div>
          {
            report.clientExportInfo.map((el, elIdx) => {
              return (
                <div key={elIdx} className="modal-table-row-input-container">
                  <div className="modal-table-row-container">
                    <span className="modal-table-title"> {el.version || '-'} </span>
                  </div>
                  <div className="modal-table-row-container">
                    <span className="modal-table-title"> {el.labExportVersion || '-'} </span>
                  </div>
                  <div className="modal-table-row-container">
                    <span className="modal-table-title"> {el.action || '-'} </span>
                  </div>
                  <div className="modal-table-row-container">
                    <span className="modal-table-title" title={el.exportedAt ? dayjs(el.exportedAt).format('LLLL') : '-'}>
                      {el.exportedAt ? (dayjs().to(dayjs(el.exportedAt))) : '-'}
                    </span>
                  </div>
                  <div className="modal-table-row-container">
                    <span className="modal-table-title"> {el.exportedBy || '-'} </span>
                  </div>
                  <div className="modal-table-row-container">
                    <div title="Descargar PDF" onClick={() => DownloadReportFile(el, 2, 1)}>
                      <FaFilePdf color="red" size={18} className="icon-style cursor-pointer"/>
                    </div>
                  </div>
                  <div className="modal-table-row-container">
                    <div title="Descargar Planilla" onClick={() => DownloadReportFile(el, 2, 2)}>
                      <FaFileExcel color={'#03A700'} size={18} className="icon-style cursor-pointer"/>
                    </div>
                  </div>
                </div>
              )
            })
          }
        </>
      )
    } else {
      return <span className="m-3 text-center"> No hay informes exportados para mostrar </span>
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Panel de Descargas'} closeModal={closeModal} closeKb={!loading} />
        <div className="mb-2"> {GetReportFullDisplayText(report)} </div>
        <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
        <div className="modal-table-outer-container">
          {activeSection === 0 ? RenderClientReports() : null}
          {activeSection === 1 ? RenderLabReports() : null}
        </div>
      </div>
    </div>
  )
}

export default ReportModalDownloadsPanel;
