import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { useTable, useFlexLayout, useResizeColumns, useFilters, useSortBy } from 'react-table';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import scrollbarWidth from '../Layout/ScrollBarWidth.js';
import { FaPlus, FaPen, FaCheck, FaUndo, FaSyncAlt, FaRegCheckCircle } from 'react-icons/fa';
import UserModalAdd from './UserModalAdd.js';
import LoadingModal from '../Layout/LoadingModal.js';
import { confirmAlert } from 'react-confirm-alert';
import { regularStatusSelectOptions, roleSelectOptions } from '../Generic/Constants.js';
import { DefaultColumnFilter, TableToolsToggleColumns, TableHeader, FilterDateMethod, SortDateMethod, 
  TableTextInput, TableSelectInput, TableReactSelectInput, TableDisplayDateCell, TableDeleteCell } from '../Generic/ReactTableElements.js';
import Button from 'react-bootstrap/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

function Table({ data, columns, editing, setEditing, getData, handleEdit, saveEditedData, setIsModalOpen, discardChanges, updateFile, deleteRecord }) {

  const defaultColumn = useMemo(() => ({
    Filter: DefaultColumnFilter,
    EditableCell: TableTextInput,
    ReactSelectCell: TableReactSelectInput,
    SelectCell: TableSelectInput,
    DisplayDateCell: TableDisplayDateCell,
    DeleteCell: TableDeleteCell
  }), []);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    allColumns,
    getToggleHideAllColumnsProps,
    rows,
    totalColumnsWidth
  } = useTable(
    {
      columns,
      data,
      initialState: { hiddenColumns: ['delete'] },
      defaultColumn,
      autoResetHiddenColumns: false,
      autoResetFilters: false,
      autoResetSortBy: false,
      editing,
      handleEdit,
      updateFile,
      deleteRecord
    },
    useFlexLayout,
    useResizeColumns,
    useFilters,
    useSortBy
  )

  const scrollBarSize = useMemo(() => scrollbarWidth(), []);

  const RenderVirtualizedRow = useCallback(({ index, style }) => {
    const row = rows[index];
    prepareRow(row);
    return (
      <div className={"align-items-center rt-row-highlight mt-1"+(index % 2 ? " rt-row-even" : "")} {...row.getRowProps({style})}>
        { row.cells.map(cell => 
          <div className="p-1 text-truncate" {...cell.getCellProps()}> 
            { cell.column.cellRender ? cell.render(cell.column.cellRender) : cell.render('Cell') } 
          </div> 
        )}
      </div>
    )
    // Editing is added to force a re render on all EditableCells
  }, [prepareRow, rows, editing]);

  const RenderEditIcons = () => {
    if (editing) {
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Guardar Cambios" onClick={() => saveEditedData()}>
            <FaCheck color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Descartar Cambios" onClick={() => discardChanges()}>
            <FaUndo color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }else{
      return (
        <>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" title="Refrescar" onClick={() => getData()}>
            <FaSyncAlt color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" title="Nuevo Usuario" onClick={() => setIsModalOpen(true)}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white ms-2" disabled={!data.length} title="Editar" onClick={() => setEditing(true)}>
            <FaPen color="white" size={15} className="icon-style"/>
          </Button>
        </>
      )
    }
  }

  return (
    <div className="table-inner-container">
      <div className="d-flex align-items-center justify-content-between my-1 py-1 px-1 flex-wrap" style={{backgroundColor: editing ? '#ffb130cc' : 'initial'}}>
        <div className="d-flex align-items-center">
          <div><span className="fw-bold">{ 'Mostrando ' + rows.length + ' de ' + data.length }</span></div>
          <TableToolsToggleColumns toggleProps={getToggleHideAllColumnsProps} columns={allColumns} />
        </div>
        <div className="d-flex align-items-center">
          {RenderEditIcons()}
        </div>
      </div>
      <div className="d-flex flex-1 overflow-y-hidden rounded-3 pb-2 rt-bg">
        <div className="d-flex flex-1 flex-column h-100 w-100" {...getTableProps({ style: { minWidth: (totalColumnsWidth+scrollBarSize), border: '1px solid #0000001f' }})}>
          <TableHeader headerGroups={headerGroups} scrollBarSize={scrollBarSize}/>
          <div className="flex-1" {...getTableBodyProps()}>
            <AutoSizer>
              {({ height, width }) => (
                <FixedSizeList
                  height={height}
                  width={width}
                  itemCount={rows.length}
                  itemSize={35}
                  className="overflow-x-hidden overflow-y-scroll"
                >
                  {RenderVirtualizedRow}
                </FixedSizeList>
              )}
            </AutoSizer>
          </div>
        </div>
      </div>
    </div>
  )
}

const Users = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [editing, setEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState([]);
  const [dataIndexToEdit, setDataIndexToEdit] = useState([]);

  const isFirstRender = useRef(true);

  useEffect(() => {
    document.title = "MSTD Laboratorio - Usuarios";
  }, []);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  const DeleteRowStepOne = (rowId) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un usuario</h3>
            <p>{'¿Está seguro(a) de eliminar al usuario '}{<b>{data[rowId].username}</b>}{'?'}</p>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                No, deseo salir
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  onClose();
                  DeleteRowStepTwo(data[rowId].username);
                }}
              >
                Si, deseo eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepTwo = (record) => {

    let deleteConfirmation = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está eliminando un usuario</h3>
            <p>{'Esta acción es irreversible. Confirme ingresando el nombre del usuario que desea eliminar'}</p>
            <input
              type="text"
              className="modal-form-input"
              onChange={(e) => { deleteConfirmation = e.target.value }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                disabled={loading}
                onClick={() => {
                  if (deleteConfirmation) {
                    if (record === deleteConfirmation) {
                      onClose();
                      DeleteRowStepThree(record)
                    }else{
                      alert.show('La confirmación no coincide con el registro a eliminar', {type: 'info'});
                    }
                  }else{
                    alert.show('Ingrese la confirmación', {type: 'info'});
                  }
                }}
              >
                Eliminar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const DeleteRowStepThree = (record) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/delete';

    Axios.post(url, {record}, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 10000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SaveEditedDataStepOne = () => {
    if (dataIndexToEdit.length > 0) {

      setLoading(true);
      let dataToUpdate = [];
      let incompleteIndexes = [];

      dataIndexToEdit.forEach((dataIndex) => {
        if (!data[dataIndex].fullname || !data[dataIndex].role.length || !data[dataIndex].status ) {
          if (incompleteIndexes.indexOf(dataIndex) === -1) {
            incompleteIndexes.push(dataIndex);
          }
        }
      });

      if (incompleteIndexes.length) {
        let incompleteIndexesAlert =
          <div className="d-flex flex-column">
            Los siguientes registros tienen campos sin completar:
            {incompleteIndexes.map((element, index) =>  <span key={index}>{data[element].username}</span> )}
          </div>

        setLoading(false);
        alert.show(incompleteIndexesAlert, {type: 'info'});
      }else{
        dataIndexToEdit.forEach((dataIndexToModify) => {
          dataToUpdate.push(data[dataIndexToModify]);
        });

        SaveEditedDataStepTwo(dataToUpdate);
      }
    }else{
      setEditing(false);
      alert.show('No se realizaron modificaciones', {type: 'success'});
    }
  }

  const SaveEditedDataStepTwo = (dataToUpdate) => {

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/users/updateMany';

    Axios.post(url, dataToUpdate, opts)
    .then((res) => {
      setLoading(false);
      setEditing(false);
      setDataIndexToEdit([]);
      let editResults =
        <div className="d-flex flex-column">
          {'Los resultados de sus modificaciones son: '}
          {res.data.map((element, index) => <span key={index}>{element}</span> )}
        </div>
      alert.show(editResults, {type: 'success', timeout: 25000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const UpdateFileStepOne = (rowId, colId) => {

    const acceptField = (colId === "signCertificate" ? ".pfx, .p12" : "image/*");
    const fieldTitle = (colId === "signCertificate" ? "Certificado" : "Imagen");
    let file = '';

    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h3>Está modificando un archivo de Usuario</h3>
            <p>{'Seleccione el nuevo archivo de ' + fieldTitle + ' para el usuario '} <b>{data[rowId].username}</b></p>
            <input
              type="file"
              className="modal-form-input"
              accept={acceptField}
              onChange={(e) => { file = e.target.files[0] }}
            />
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={() => onClose()}>
                Cancelar
              </button>
              <button
                className="modal-confirm-button round-button lb-white"
                onClick={() => {
                  if (file) {
                    onClose();
                    UpdateFileStepTwo(rowId, colId, file);
                  }else{
                    alert.show('Ingrese el archivo', {type: 'info'});
                  }
                }}
              >
                Modificar
              </button>
            </div>
          </div>
        );
      }
    });
  }

  const UpdateFileStepTwo = (rowId, colId, file) => {
    if (file) {
      setLoading(true);

      const formData = new FormData();
      formData.append('record', JSON.stringify({ username: data[rowId].username }));
      formData.append('filename', file);

      const url = ('/api/users/upload/' + colId);

      Axios.post(url, formData, {cancelToken: source.token})
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success', timeout: 5000});
        GetData();
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }else{
      alert.show('Falta información', {type: 'info'});
    }
  }

  const HandleEdit = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
      if (dataIndexToEdit.indexOf(index) === -1) {
        let newEditedIndex = dataIndexToEdit;
        newEditedIndex.push(index);
        setDataIndexToEdit(newEditedIndex);
      }
    }
  }

  const tableColumns = useMemo(() => [
    {
      Header: 'Usuario',
      accessor: 'username',
    },{
      Header: 'Nombre',
      accessor: 'fullname',
      cellRender: 'EditableCell',
      width: 200,
    },{
      Header: 'Correo Electrónico',
      accessor: 'email',
      cellRender: 'EditableCell',
      width: 200,
    },{
      Header: 'Permisos',
      accessor: 'role',
      cellRender: 'ReactSelectCell',
      selectOpts: roleSelectOptions,
      width: 200,
    },{
      Header: 'Cargo',
      accessor: 'station',
      cellRender: 'EditableCell',
    },{
      Header: 'Imagen Firma',
      accessor: 'signImg',
      Cell: ({ row, column, value, updateFile, editing }) => (
        <div className="d-flex align-items-center justify-content-center" title={editing ? '' : (value || 'No se encontró el archivo')}>
          { editing ? <Button className="lb-white" size={'sm'} onClick={() => updateFile(row.index, column.id)}> Modificar </Button> : 
          <div className="p-1"> {value ? <FaRegCheckCircle color="black" size={16} className="icon-style" /> : '-'} </div> }
        </div>
      ),
      width: 125,
      disableFilters: true,
      disableSortBy: true,
    },{
      Header: 'Estado',
      accessor: 'status',
      cellRender: 'SelectCell',
      selectOpts: regularStatusSelectOptions,
      width: 125,
    },{
      Header: 'Última Modificación',
      accessor: 'lastModified',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      Header: 'Modificado Por',
      accessor: 'lastModifiedUser',
    },{
      Header: 'Fecha Creación',
      accessor: 'addedAt',
      cellRender: 'DisplayDateCell',
      filter: FilterDateMethod,
      sortType: SortDateMethod,
      sortDescFirst: true
    },{
      id: 'delete',
      Header: 'Eliminar',
      accessor: row => row,
      cellRender: 'DeleteCell',
      disableFilters: true,
      disableSortBy: true,
      width: 100,
    }
  ], []);
  
  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="outer-container fs-7">
      <LoadingModal loading={loading}/>
      {isModalOpen ? <UserModalAdd closeModal={() => { setIsModalOpen(false); GetData() }}/> : null}
      <Table
        data={data}
        columns={tableColumns}
        editing={editing}
        setEditing={setEditing}
        getData={GetData}
        handleEdit={HandleEdit}
        saveEditedData={SaveEditedDataStepOne}
        setIsModalOpen={setIsModalOpen}
        discardChanges={() => { setDataIndexToEdit([]); setEditing(false); GetData() }}
        updateFile={UpdateFileStepOne}
        deleteRecord={DeleteRowStepOne}
      />
    </div>
  )
}

export default Users;
