import React, { useEffect, useState, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import LoadingModal from '../Layout/LoadingModal.js';
import { TestSpecialChars } from '../Generic/Constants.js';
import FormTextInput from '../Generic/FormTextInput';
import FormDateInput from '../Generic/FormDateInput';
import FormDateTextInput from '../Generic/FormDateTextInput';
import ReactSelect from 'react-select';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();
const specialCharsErrText = 'no puede contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const SingleAdd = (props) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [maxReportNumber, setMaxReportNumber] = useState('');
  const [projectSuggestions, setProjectSuggestions] = useState([]);
  const [reports, setReports] = useState([]);
  const [sample, setSample] = useState({
    sampleId: 'M-',
    project: '',
    soilType: '',
    item: '',
    entryDate: '',
    sampleDate: '',
    controlDate: '',
    billNumber: '',
    trialPitNumber: '',
    horizon: '',
    layerThickness: '',
    depth: '',
    repArea: '',
    sampledBy: '',
    samplePlan: 'MSTD-PP-110',
    sampleProcess: 'MSTD-PP-100',
    comments: '',
  });

  const isMaxReportSet = useRef(false);
  
  useEffect(() => {
    if(props.reportNumbers.length && !isMaxReportSet.current){
      const newMax = props.reportNumbers.length ? Math.max(...props.reportNumbers) : '';
      setMaxReportNumber(newMax);
      isMaxReportSet.current = true;
    }
  }, [props.reportNumbers]);

  useEffect(() => {
    setReports(JSON.parse(JSON.stringify(props.availableReports)))
  }, [props.availableReports]);

  useEffect(() => {
    let projectsHelper = [];
    props.projectData.forEach(p => {
      projectsHelper.push({ value: p.code, label: p.code })
    })
    setProjectSuggestions(projectsHelper);
  }, [props.projectData])

  // Start Report Selection

  const HandleReportNumberChange = (index, value) => {
    let newState = [...reports];
    newState[index].reportNumber = value;
    setReports(newState);
  }

  const HandleReportCheckBoxChange = (report, index) => {
    let newState = [...reports];
    newState[index].isChecked = !newState[index].isChecked;

    let counter = 1;
    newState.forEach((rep) => {
      if (rep.isChecked) {
        if (maxReportNumber) {
          rep.reportNumber = maxReportNumber+counter;
        }else{
          rep.reportNumber = '';
        }
        counter++;
      }else{
        rep.reportNumber = '';
      }
    });
    setReports(newState);
  }

  const ReportSelectionValidationStyle = (reportNumber) => {
    return (props.reportNumbers.indexOf(Number(reportNumber)) !== -1) ? { backgroundColor: '#FFAA1D' } : { backgroundColor: 'white' }
  }

  const RenderReportOptions = () => {
    return (
      <div className="d-flex flex-column align-items-center my-2">
        <div className="fw-semibold my-2"> Ensayos a realizar </div>
        <div className="p-2 border border-2">
          { 
            reports.length ? reports.map((report, reportIdx) => {
              return (
                <div key={reportIdx} className="d-flex align-items-center my-1 py-1 bg-gw-lb">
                  <input
                    type="checkbox"
                    checked={report.isChecked}
                    className="modal-form-checkbox ms-2"
                    onChange={(e) => HandleReportCheckBoxChange(report, reportIdx)}
                  />
                  <input
                    type="text"
                    placeholder={'N° Informe'}
                    value={report.reportNumber}
                    disabled={!report.isChecked}
                    className="modal-form-small-input"
                    style={(ReportSelectionValidationStyle(report.reportNumber))}
                    onChange={(e) => HandleReportNumberChange(reportIdx, e.target.value)}
                  />
                  <span>{report.clientCode+'v'+report.clientVersion+' '+report.code+'v'+report.version+' | '+report.title}</span>
                </div>
              )
            }) : <div>Cargando ensayos..</div>
          }
        </div>
      </div>
    )
  }

  const HandleChange = (element, value) => {
    let newState = {...sample};
    newState[element] = value;
    setSample(newState);
  }

  const FindErrs = () => {
    let errsFound = [];
    if (!sample.sampleId || sample.sampleId === 'M-') {
      errsFound.push('El código de la muestra es requerido');
    }
    if (!sample.project) {
      errsFound.push('El código del proyecto es requerido');
    }
    if (sample.sampleId && TestSpecialChars(sample.sampleId)) {
      errsFound.push('El campo ID Muestra ' + specialCharsErrText);
    }
    if (sample.trialPitNumber && TestSpecialChars(sample.trialPitNumber)) {
      errsFound.push('El campo Calicata o Sondaje ' + specialCharsErrText);
    }
    if (sample.horizon && TestSpecialChars(sample.horizon)) {
      errsFound.push('El campo Horizonte o Muestra ' + specialCharsErrText);
    }
    if (sample.depth && TestSpecialChars(sample.depth)) {
      errsFound.push('El campo Profundidad / Cota (m) ' + specialCharsErrText);
    }
    if (errsFound.length) {
      let errsMsg = 
        <>
          <span>{'Se encontraron los siguientes errores: '}</span>
          {errsFound.map((element, index) => {
            return <span key={index} className="mt-1">{element}</span>
          })}
        </>
        alert.show(errsMsg, {type: 'error', timeout: 15000});
      return true;
    } else {
      return false;
    }
  }

  const SaveNewRecordStepOne = () => {
    if (!FindErrs()) {
      
      let isAFieldEmpty = false;
      let reportsToSave = [];
  
      for (let i = 0; i < reports.length; i++) {
        if (reports[i].isChecked) {
          if (!reports[i].reportNumber) {
            isAFieldEmpty = true;
            break;
          }else{
            reportsToSave.push(reports[i]);
          }
        }
      }
  
      if ((!reportsToSave.length || (reportsToSave.length && !isAFieldEmpty))) {
        const newRecord = {
          sampleId: sample.sampleId,
          project: sample.project,
          reports: reportsToSave,
          soilType: sample.soilType,
          item: sample.item,
          entryDate: sample.entryDate,
          sampleDate: sample.sampleDate,
          controlDate: sample.controlDate,
          billNumber: sample.billNumber,
          trialPitNumber: sample.trialPitNumber,
          horizon: sample.horizon,
          layerThickness: sample.layerThickness,
          depth: sample.depth,
          repArea: sample.repArea,
          sampledBy: sample.sampledBy,
          samplePlan: sample.samplePlan,
          sampleProcess: sample.sampleProcess,
          comments: sample.comments,
          status: 'activo'
        };
        SaveNewRecordStepTwo(newRecord);
      }else{
        alert.show('Se encontraron un ensayo seleccionado sin N° de Informe', {type: 'info'});
      }
    }
  }

  const SaveNewRecordStepTwo = (record) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    // Add new sample with reports to be made or added to
    let url = '/api/samples/add';
    Axios.post(url, { record }, opts)
    .then((res) => {
      setLoading(false);
      if (res.data) {
        if (res.data.sample.err) {
          alert.show(('Muestra: ' + res.data.sample.err), {type: 'error', timeout: 0});
        } else {
          let reportMsgs = [];
          let reportErrs = [];
          res.data.reports.forEach(r => {
            if (r.err) {
              reportErrs.push(r.err);
            } else {
              reportMsgs.push(r.msg);
            }
          })
          let queryResponse =
            <>
              <span>Los resultados de su ingreso son: </span>
              <span><b>{res.data.sample.msg}</b></span>
              <hr/>
              {reportMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 0});
          if (reportErrs.length) {
            let errResp = <> {reportErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
            alert.show(errResp, {type: 'error', timeout: 0});
          }

          let newMaxReport = [];
          record.reports.forEach(r => { newMaxReport.push(r.reportNumber) });
          if (newMaxReport.length) { setMaxReportNumber(Math.max(...newMaxReport)) }

        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
      }
      props.getExtraData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="modal-form-container">
        <div className="modal-form-input-container">
          <div className="modal-form-input-title">
            <span className="text-danger">*</span> Código Proyecto
          </div>
          <ReactSelect 
            placeholder={'Código'} 
            noOptionsMessage={({ inputValue: string }) => 'No hay resultados'} 
            defaultValue={sample.project} 
            onChange={(e) => HandleChange('project', e.value)} 
            className="modal-form-input"
            options={projectSuggestions} 
          />
        </div>
        <FormTextInput title="ID Muestra" isRequired={true} tooltip={specialCharsErrText} stateKey={'sampleId'} value={sample.sampleId} handleChange={HandleChange} />
        {RenderReportOptions()}
        <FormDateTextInput title="Fecha Muestreo" isRequired={false} stateKey={'sampleDate'} value={sample.sampleDate} handleChange={HandleChange} />
        <FormDateInput title="Fecha Ingreso/Recepción" isRequired={false} stateKey={'entryDate'} value={sample.entryDate} handleChange={HandleChange} />
        <FormTextInput title="N° Boleta" isRequired={false} stateKey={'billNumber'} value={sample.billNumber} handleChange={HandleChange} />
        <FormTextInput title="Muestreado por" isRequired={false} stateKey={'sampledBy'} value={sample.sampledBy} handleChange={HandleChange} />
        <FormTextInput title="Calicata o Sondaje número" isRequired={false} tooltip={specialCharsErrText} stateKey={'trialPitNumber'} value={sample.trialPitNumber} handleChange={HandleChange} />
        <FormTextInput title="Horizonte o Muestra" isRequired={false} tooltip={specialCharsErrText} stateKey={'horizon'} value={sample.horizon} handleChange={HandleChange} />
        <FormTextInput title="Profundidad / Cota (m)" isRequired={false} tooltip={specialCharsErrText} stateKey={'depth'} value={sample.depth} handleChange={HandleChange} />
        <FormTextInput title="Tipo de suelo" isRequired={false} stateKey={'soilType'} value={sample.soilType} handleChange={HandleChange} />
        <FormDateTextInput title="Fecha Control" isRequired={false} stateKey={'controlDate'} value={sample.controlDate} handleChange={HandleChange} />
        <FormTextInput title="Espesor Capa (m)" isRequired={false} stateKey={'layerThickness'} value={sample.layerThickness} handleChange={HandleChange} />
        <FormTextInput title="Item (obra)" isRequired={false} stateKey={'item'} value={sample.item} handleChange={HandleChange} />
        <FormTextInput title="Area representada" isRequired={false} stateKey={'repArea'} value={sample.repArea} handleChange={HandleChange} />
        <FormTextInput title="Observaciones" isRequired={false} stateKey={'comments'} value={sample.comments} handleChange={HandleChange} />
        <FormTextInput title="Plan de muestreo" isRequired={false} stateKey={'samplePlan'} value={sample.samplePlan} handleChange={HandleChange} />
        <FormTextInput title="Procedimiento de muestreo" isRequired={false} stateKey={'sampleProcess'} value={sample.sampleProcess} handleChange={HandleChange} />
      </div>
      <div>
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecordStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default SingleAdd;
