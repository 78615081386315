import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import { TestSpecialChars } from '../Generic/Constants.js';
import { FaPlus, FaTrash } from 'react-icons/fa';
import FormTextInput from '../Generic/FormTextInput';
import FormDateInput from '../Generic/FormDateInput';
import FormReactSelect from '../Generic/FormReactSelect';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();
const specialCharsErrText = 'no puede contener los siguientes caracteres: ,/\\"*¿?() (coma, slash, backslash, comilla, comillas dobles, asterisco, signos de interrogación, paréntesis)';

const FWProjectModalAdd = ({ closeModal }) => {
  
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([])
  const [form, setForm] = useState({
    code: dayjs().format('MM-YY '),
    title: '',
    client: '',
    startDate: dayjs().format('YYYY-MM-DD'),
    endDate: dayjs().format('YYYY-MM-DD'),
    addedAt: dayjs().format('YYYY-MM-DD'),
    probings: [],
    status: 'activo'
  });

  const isFirstRender = useRef(true);
  const scrollEndRef = useRef();

  const GetUsers = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = '/api/users/names';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setUsers(res.data);
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert])

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const AddProbing = () => {
    let newState = {...form};
    newState.probings.push({
      probingCode: '',
      depth: '',
      responsible: []
    });
    setForm(newState);
  }

  const RemoveProbing = (idx) => {
    let newState = {...form};
    newState.probings.splice(idx, 1);
    setForm(newState);
  }

  const HandleProbingChange = (value, element, idx) => {
    let newState = {...form};
    newState.probings[idx][element] = value;
    setForm(newState);
  }

  const FindErrs = () => {
    let errsFound = [];
    if (!form.code) {
      errsFound.push('El código del proyecto es requerido');
    }
    if (form.code && TestSpecialChars(form.code)) {
      errsFound.push('El código del proyecto ' + specialCharsErrText);
    }
    if (form.code && !(/^[a-zA-Z0-9].*[a-zA-Z0-9]$/i.test(form.code))) {
      errsFound.push('El código del proyecto debe comenzar y terminar con números o letras.');
    }
    for (let i = 0; i < form.probings.length; i++) {
      if (!form.probings[i].probingCode) {
        errsFound.push('Sondaje #' + (i+1) + ': El código del sondaje es requerido');
      }
      if (form.probings[i].probingCode && TestSpecialChars(form.probings[i].probingCode)) {
        errsFound.push('Sondaje #' + (i+1) + ': El código del sondaje ' + specialCharsErrText);
      }
      if (form.probings[i].probingCode && !(/^[a-zA-Z0-9].*[a-zA-Z0-9]$/i.test(form.probings[i].probingCode))) {
        errsFound.push('Sondaje #' + (i+1) + ': El código del sondaje debe comenzar y terminar con números o letras.');
      }
    }
    if (errsFound.length) {
      let errsMsg = 
        <>
          <span>Se encontraron los siguientes errores:</span>
          {errsFound.map((item, itemIdx) => ( <span key={itemIdx} className="mt-1">{item}</span> ))}
        </>
        alert.show(errsMsg, {type: 'error', timeout: 15000});
      return true;
    } else {
      return false;
    }
  }

  const SaveNewRecord = () => {
    if (!FindErrs()) {

      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = { cancelToken: source.token };
      const url = '/api/fieldWork/add';

      Axios.post(url, { record: form }, opts)
      .then((res) => {
        setLoading(false);
        if (res.data) {
          if (res.data.project.err) {
            alert.show(('Proyecto: ' + res.data.project.err), {type: 'error', timeout: 0});
          } else {
            let probingMsgs = [];
            let probingErrs = [];
            res.data.probings.forEach(r => {
              if (r.err) {
                probingErrs.push(r.err);
              } else {
                probingMsgs.push(r.msg);
              }
            })
            let queryResponse =
              <>
                <span>Los resultados de su ingreso son: </span>
                <span><b>{res.data.project.msg}</b></span>
                <hr/>
                {probingMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
              </>
            alert.show(queryResponse, {type: 'success', timeout: 10000});
            if (probingErrs.length) {
              let errResp = <> {probingErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
              alert.show(errResp, {type: 'error', timeout: 20000});
            }
          }
        } else {
          alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 0});
        }
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }
  }

  const UsernamesRSOpts = useMemo(() => {
    let newValue = [];
    users.forEach((item) => {
      newValue.push({ label: item.fullname, value: item.username });
    })
    return newValue;
  }, [users])

  useEffect(() => {
    scrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }, [form?.probings?.length])

  useEffect(() => {
    if(isFirstRender.current){
      GetUsers();
    }
  }, [GetUsers]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nuevo Proyecto'} closeModal={closeModal} />
        <div className="modal-form-container">
          <FormTextInput title="Código Interno (MM-YY Proyecto)" isRequired={true} tooltip={specialCharsErrText} stateKey={'code'} value={form.code} handleChange={HandleChange} />
          <FormTextInput title="Proyecto/Obra (para informe)" stateKey={'title'} value={form.title} handleChange={HandleChange} />
          <FormTextInput title="Cliente (para informe)" stateKey={'client'} value={form.client} handleChange={HandleChange} />
          <FormDateInput title="Fecha Ingreso" stateKey={'addedAt'} value={form.addedAt} handleChange={HandleChange} />
          <FormDateInput title="Fecha Inicio" stateKey={'startDate'} value={form.startDate} handleChange={HandleChange} />
          <FormDateInput title="Fecha Fin" stateKey={'endDate'} value={form.endDate} handleChange={HandleChange} />
          { form.probings.length ?
            <div className="mx-2">
              {form.probings.map((probing, probingIdx) => {
                return (
                  <div key={probingIdx} className="my-2 p-2 border border-1">
                    <div className="d-flex align-items-center">
                      <span><b>{'Sondaje N°' + (probingIdx+1)}</b></span>
                      <Button className="ms-2 p-1 rounded-circle bg-lb-white fs-8" size="sm" disabled={loading} title="Eliminar Sondaje" onClick={() => RemoveProbing(probingIdx)}>
                        <FaTrash color="white" size={15} className="icon-style"/>
                      </Button>
                    </div>
                    <FormTextInput title="Código Sondaje" stateKey={'probingCode'} value={probing.probingCode} handleChange={(element, value) => HandleProbingChange(value, element, probingIdx)} />
                    <FormTextInput title="Profundidad" stateKey={'depth'} value={probing.depth} handleChange={(element, value) => HandleProbingChange(value, element, probingIdx)} />
                    <FormReactSelect title="Responsable(s)" stateKey={'responsible'} value={probing.responsible} isMulti items={UsernamesRSOpts} handleChange={(value, element) => HandleProbingChange(value, element, probingIdx)} />
                  </div>
                )
              })}
            </div>            
          : null }
          <div className="d-flex justify-content-center align-items-center my-2" ref={scrollEndRef}>
            <Button className="d-flex align-items-center rounded-3 fs-inherit bg-lb-white" disabled={loading} title="Agregar Sondaje" onClick={() => AddProbing()}>
              <FaPlus color="white" size={15} className="icon-style"/>
              <span className="text-white ms-1">Agregar Sondaje</span>
            </Button>
          </div>
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default FWProjectModalAdd;
