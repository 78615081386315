import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

const FormReactSelect = ({ title, isRequired, isMulti, isDisabled, placeholder, items, value, handleChange, stateKey, stateIdx }) => {

  const GetCurrentSelectValue = useMemo(() => {
    let newValue = [];
    if (value) {
      if (isMulti) {
        value.forEach((so) => {
          const foundItem = items.find(opt => opt.value === so)?.label || so;
          newValue.push({ label: foundItem, value: so });
        })
      } else {
        const foundItem = items.find(opt => opt.value === value)?.label || value;
        newValue = { label: foundItem, value };
      }
    }
    return newValue;
  }, [items, isMulti, value])

  return (
    <div className="d-md-flex flex-1 align-items-center justify-content-center p-2">
      { title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { isRequired ? <span className="text-danger">*</span> : '' }
          { title }
        </div>
      : null }
      <div className="modal-form-input fs-inherit">
        <ReactSelect 
          placeholder={placeholder || ''} 
          noOptionsMessage={({ inputValue: string }) => 'No hay opciones para seleccionar'} 
          value={GetCurrentSelectValue} 
          onChange={(e) => (isMulti ? handleChange(e.map(v => v.value), stateKey, stateIdx) : handleChange(e.value, stateKey, stateIdx))}
          isMulti={isMulti || false}
          hideSelectedOptions={false}
          options={items} 
          isDisabled={isDisabled || false} 
          classNames={{ clearIndicator: () => 'p-1', dropdownIndicator: () => 'p-1' }}
          styles={{ control: (baseStyles) => ({ ...baseStyles, minHeight: '35px' }) }}
        />
      </div>
    </div>
  )
}

export default FormReactSelect