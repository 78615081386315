import React, { useEffect } from 'react';
import { Link } from "react-router-dom";

function NotFound() {

  useEffect(() => {
    document.title = "MSTD Laboratorio - Página no encontrada";
  }, []);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center h-100">
      <p>Sitio Privado !</p>
      <Link to={'/'}>
        <button className="lb-white round-button">
          Ir a Inicio
        </button>
      </Link>
    </div>
  )
}

export default NotFound;
