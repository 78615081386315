import React from 'react';
import { Outlet, Navigate } from "react-router";
import { useAuth } from "../Context/Auth";
import { useLocation, useResolvedPath } from "react-router-dom";
import LoadingSession from './LoadingSession.js';

const AuthenticatedRoute = () => {

  const { session } = useAuth();
  const location = useLocation();
  const resolvedPath = useResolvedPath(".");

  if (session) {
    return <Outlet />
  }else{
    if (session === false) {
      return <Navigate to={"/login"} replace state={{ from: location, parent: resolvedPath }}/>
    } else {
      return <LoadingSession />
    }
  }
}

export default AuthenticatedRoute;