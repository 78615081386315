import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { GetReportSampleDisplayText, reportStatusSelectOptions } from '../../Generic/Constants.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = [
  'Muestra', 'Fecha Finalización', 'Probeta', 'Humedad', 'Densidad Seca', 'Lectura Vertical Inicial', 'Lectura Vertical Inicial Cons.', 'Lecturas Def. Horizontal 0.00', 
  'Lecturas Def. Horizontal 0.15', 'Lecturas Def. Horizontal 0.25', 'Lecturas Def. Horizontal 0.75', 'Lecturas Def. Horizontal 1.50', 'Lecturas Def. Horizontal 3.00', 
  'Lecturas Def. Horizontal 4.50', 'Lecturas Def. Horizontal 6.00', 'Lecturas Def. Horizontal 8.00', 'Lecturas Def. Horizontal 10.00', 'Lecturas Def. Horizontal 15.00', 
  'Lecturas Def. Horizontal 20.00', 'Lecturas Def. Horizontal 25.00'
];

const CdRevision = ({ setLoading, project }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/revisionHelpers/cdResults/'+project;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, project, setLoading]);

  useEffect(() => {
    if (project) {
      GetData();
    }
  }, [project, GetData]);

  const RenderData = () => {
    return (
      <div className="d-flex overflow-auto">
        <div className="d-flex flex-column h-100 position-sticky start-0 bg-aliceblue border-end border-secondary" style={{minWidth: 'fit-content'}}>
          { rowTitles.map((header, headerIdx) => <div key={headerIdx} className="p-1"> {header} </div> )}
        </div>
        {data.map((report, reportIdx) => {
          const sampleText = GetReportSampleDisplayText(report);
          const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
          return (
            <div key={reportIdx} className="d-flex flex-column align-items-center h-100 text-center border-end border-secondary" style={{minWidth: 'fit-content'}}>
              <div className={"w-100 py-1 " + (statusProps?.colorClasses || '')} title={sampleText + ' - ' + (statusProps?.display || ' - ')}> 
                <span className="fw-semibold px-1">{sampleText}</span> 
              </div>
              <div className="p-1" style={{minWidth: '45px'}}> {report.finalizedDate || '-'} </div>
              <div className="d-flex">
                {report.data.map((rSection, rSectionIdx) =>
                  <div key={rSectionIdx} className={"px-1 " + (rSectionIdx === (report.data.length-1) ? "" : "border-end border-info border-opacity-25")} style={{minWidth: 'fit-content'}}>
                    <div className="p-1" style={{minWidth: '45px'}}> {('Probeta ' + (rSectionIdx+1))} </div>
                    <div title={'Inicial - Final'} className="d-flex w-100 justify-content-evenly">
                      <div className="flex-1 p-1" style={{minWidth: '45px'}}> {rSection.startField11 || '-'} </div>
                      <div className="flex-1 p-1" style={{minWidth: '45px'}}> {rSection.endField11 || '-'} </div>
                    </div>
                    <div title={'Inicial - Final'} className="d-flex w-100 justify-content-evenly">
                      <div className="flex-1 p-1" style={{minWidth: '45px'}}> {rSection.startField12 || '-'} </div>
                      <div className="flex-1 p-1" style={{minWidth: '45px'}}> {rSection.endField12 || '-'} </div>
                    </div>
                    <div className="p-1" style={{minWidth: '45px'}}> {rSection.field15 || '-'} </div>
                    <div className="p-1" style={{minWidth: '45px'}}> {rSection.field16 || '-'} </div>
                    {[[rSection.row1Col3, rSection.row1Col4, rSection.row1Col5], [rSection.row4Col3, rSection.row4Col4, rSection.row4Col5], 
                    [rSection.row6Col3, rSection.row6Col4, rSection.row6Col5], [rSection.row8Col3, rSection.row8Col4, rSection.row8Col5],
                    [rSection.row11Col3, rSection.row11Col4, rSection.row11Col5], [rSection.row14Col3, rSection.row14Col4, rSection.row14Col5],
                    [rSection.row17Col3, rSection.row17Col4, rSection.row17Col5], [rSection.row19Col3, rSection.row19Col4, rSection.row19Col5],
                    [rSection.row21Col3, rSection.row21Col4, rSection.row21Col5], [rSection.row23Col3, rSection.row23Col4, rSection.row23Col5],
                    [rSection.row28Col3, rSection.row28Col4, rSection.row28Col5], [rSection.row33Col3, rSection.row33Col4, rSection.row33Col5],
                    [rSection.row38Col3, rSection.row38Col4, rSection.row38Col5]]
                    .map((item, itemIdx) => 
                      <div key={itemIdx} title={'Carga Corte - Def. Vertical - Tiempo'} className="d-flex w-100 justify-content-evenly">
                        <div className="p-1" style={{minWidth: '45px'}}> {item[0] || '-'} </div>
                        <div className="p-1" style={{minWidth: '45px'}}> {item[1] || '-'} </div>
                        <div className="p-1" style={{minWidth: '45px'}}> {item[2] || '-'} </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="modal-table-outer-container mt-2">
      { (project && data.length) ? RenderData() : <span className="p-1">No hay ensayos disponibles</span> }
    </div>
  )
}

export default CdRevision