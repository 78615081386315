import { reportStatusSelectOptions } from '../Generic/Constants.js';
import dayjs from 'dayjs';

const SelectedReportStatusText = (report) => {

  const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
  
  return (
    <div className="my-2">
      <span>Estado: </span>
      <span className={"px-1 " + (statusProps?.colorClasses || '')}> {(statusProps?.display || ' - ')} </span>
      <span className="ms-1" title={'Última modificación: ' + (report.lastModified ? dayjs(report.lastModified).format('LLLL') : '-')}>
        { ' - ' + report.lastModifiedUser + ' ' + (report.lastModified ? dayjs().to(dayjs(report.lastModified)) : '')}
      </span>
    </div>
  )
}

const GetRndInteger = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1) ) + min;
}

const GetDiff = (field1, field2, minDiff, maxDiff, withAbs) => {
  let diff = false;
  if (withAbs) {
    diff = Math.abs(Number(field1) - Number(field2));
  } else {
    diff = (Number(field1) - Number(field2));
  }
  return ((diff < maxDiff && diff > minDiff))
}

const GetRandWithDiff = (min, max, diffField, minDiff, maxDiff) => {
  let fieldValue = 0;
  let isGood = false;
  for (let i = 0; i < 100000; i++) {
    fieldValue = (GetRndInteger(min, max) + Math.random());
    if (GetDiff(diffField, fieldValue, minDiff, maxDiff, true)) {
      isGood = true;
      break;
    }
  }
  return isGood ? fieldValue : 0
}

const GetAvg = (arr) => {
  let total = 0;
  let totalItems = 0;
  for (let i = 0; i < arr.length; i++) {
    if (arr[i]) {
      total = total + Number(arr[i]);
      totalItems++;
    }
  }  
  return (totalItems ? (total/totalItems) : 0);
}

const CalcHumidityFields = (field1, field2, field3) => {
  let humValue1 = (Number(field1) - Number(field2));
  let humValue2 = (Number(field2) - Number(field3));
  let humValue3 = humValue2 ? ((humValue1/humValue2)*100) : 0;
  return { humValue1, humValue2, humValue3 }
}

export { SelectedReportStatusText, GetRndInteger, GetDiff, GetRandWithDiff, GetAvg, CalcHumidityFields }