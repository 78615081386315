import React, { useState, useEffect } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import LoadingModal from '../Layout/LoadingModal.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const Dev = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [overwriteData, setOverwriteData] = useState(false);

  useEffect(() => {
    document.title = "MSTD Laboratorio - PROHIBIDA LA ENTRADA";
  }, []);

  const ConfirmDevPass = (cb) => {
    let confirmText = '';
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>CONFIRM DEV</h4>
            <input type="text" className="modal-form-input" onChange={e => { confirmText = e.target.value }} />
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> Cancelar </Button>
              <Button 
                className="flex-1 lb-white" 
                onClick={() => { 
                  if (confirmText === 'as2kl32lmKG') {
                    onClose();
                    cb();
                  } else {
                    alert.show('La confirmación es incorrecta', {type: 'info', timeout: 5000});
                  }
                }}
              > 
                Continuar 
              </Button>
            </div>
          </div>
        );
      }
    });
  }

  const CreateNewFieldsStepOne = () => {
    let reportNumbers = '';
    let fieldKeys = '';
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>INGRESAR DATOS</h4>
            <div>NROS ENSAYO, SEPARADO POR COMA</div>
            <input type="text" className="modal-form-input" onChange={e => { reportNumbers = e.target.value }} />
            <div>NEW FIELD KEYS, SEPARADO POR COMA</div>
            <input type="text" className="modal-form-input" onChange={e => { fieldKeys = e.target.value }} />
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> Cancelar </Button>
              <Button 
                className="flex-1 lb-white" 
                onClick={() => { 
                  if (reportNumbers && fieldKeys) {
                    onClose();
                    CreateNewFieldsStepTwo(reportNumbers, fieldKeys);
                  } else {
                    alert.show('Falta un dato', {type: 'info', timeout: 5000});
                  }
                }}
              > 
                Si, deseo guardar 
              </Button>
            </div>
          </div>
        );
      }
    });
  }

  const CreateNewFieldsStepTwo = (reportNumbers, fieldKeys) => {
    const arrayReportNumbers = reportNumbers.split(',').map(el => el.trim()).filter(Boolean);
    const arrayFieldKeys = fieldKeys.split(',').map(el => el.trim()).filter(Boolean);
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/batch/add/fieldKeys';

    Axios.post(url, { reportNumbers: arrayReportNumbers, fieldKeys: arrayFieldKeys }, opts)
    .then((res) => {
      setLoading(false);
      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp =
            <>
              {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp =
            <>
              {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 10000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const AddReportDownloadCodeStepOne = () => {
    let reportNumbers = '';
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>INGRESAR DATOS</h4>
            <p>NROS ENSAYO, SEPARADO POR COMA</p>
            <input type="text" className="modal-form-input" onChange={e => { reportNumbers = e.target.value }} />
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> Cancelar </Button>
              <Button 
                className="flex-1 lb-white" 
                onClick={() => { 
                  if (reportNumbers) { 
                    onClose(); 
                    AddReportDownloadCodeStepTwo(reportNumbers);
                  } else { 
                    alert.show('Falta un dato', {type: 'info', timeout: 5000});
                  }
                }}
              > 
                Si, deseo guardar 
              </Button>
            </div>
          </div>
        );
      }
    });
  }

  const AddReportDownloadCodeStepTwo = (reportNumbers) => {
    const arrayReportNumbers = reportNumbers.split(',').map(el => el.trim()).filter(Boolean);
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/batch/add/downloadCode';

    Axios.post(url, { reportNumbers: arrayReportNumbers }, opts)
    .then((res) => {
      setLoading(false);
      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 10000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SetInitialTemplateSignData = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/templates/initial/addSignData';

    Axios.post(url, { overwrite: overwriteData }, opts)
    .then((res) => {
      setLoading(false);
      const doneGood = res.data.filter(el => !el.err);
      if (doneGood.length) {
        alert.show(('Se inicializaron los datos para la firma en ' + doneGood.length + ' de ' + res.data.length + ' formularios'), {type: 'success', timeout: 15000});
      }
      const errs = res.data.filter(el => el.err);
      if (errs.length) {
        let errResp = <> {errs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)} </>
        alert.show(errResp, {type: 'error', timeout: 15000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SetInitialDefaultEq = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/extras/initial/reportEq';

    Axios.post(url, { overwrite: overwriteData }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 15000});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const SetInitialDensAutocomplete = () => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/extras/initial/densAutocomplete';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success', timeout: 15000});
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GetReportSummary = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/reports/summary';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      console.log(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GetSamplers = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/samplers';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      console.log(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GetAnythingLive = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/anything';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      console.log(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }
  
  const GetLaboratorists = () => {
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/laboratorists';

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      console.log(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const RemoveGeneratedReportsStepOne = () => {
    let reportNumbers = '';
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>ELIMINAR ARCHIVOS GENERADOS</h4>
            <p>NROS ENSAYO, SEPARADO POR COMA</p>
            <input type="text" className="modal-form-input" onChange={e => { reportNumbers = e.target.value }} />
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> Cancelar </Button>
              <Button 
                className="flex-1 lb-white" 
                onClick={() => { 
                  if (reportNumbers) { 
                    onClose(); 
                    RemoveGeneratedReportsStepTwo(reportNumbers);
                  } else { 
                    alert.show('Falta un dato', {type: 'info', timeout: 5000});
                  }
                }}
              > 
                Si, deseo guardar 
              </Button>
            </div>
          </div>
        );
      }
    });
  }

  const RemoveGeneratedReportsStepTwo = (reportNumbers) => {
    const arrayReportNumbers = reportNumbers.split(',').map(el => el.trim()).filter(Boolean);
    console.log(arrayReportNumbers);
    
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/dev/reports/removeGenerated';

    Axios.post(url, {records: arrayReportNumbers}, opts)
    .then((res) => {
      setLoading(false);
      console.log(res.data);
      alert.show(('Se eliminaron los archivos generados en ' + res.data.filter(el => !el.err).length + ' de ' + res.data.length), {type: 'success', timeout: 15000});
      const errs = res.data.filter(el => el.err);
      if (errs.length) {
        const errResp = <> {errs.map((el, elIdx) => <span key={elIdx}>{el.err}</span>)} </>
        alert.show(errResp, {type: 'error', timeout: 15000});
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  return (
    <div className="outer-container">
      <LoadingModal loading={loading}/>
      <div className="mt-3 mx-4">
        <h4 className="my-2">DEV VIEW</h4>
        <div className="my-2">
          IGNORAR ESTA VISTA
        </div>
        <div className="d-flex flex-column my-2 gap-3">
          <div>
            <Button variant={overwriteData ? "success" : "danger"} onClick={e => setOverwriteData(!overwriteData)}>OVERWRITE DATA ??</Button>
            <div>{'OVERWRITE ??: ' + overwriteData}</div>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(CreateNewFieldsStepOne)}>Add New Report Fields</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(AddReportDownloadCodeStepOne)}>Add Report DownloadCode</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(RemoveGeneratedReportsStepOne)}>Remove Generated Reports </Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(SetInitialTemplateSignData)}>Initial Template Sign Data</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(SetInitialDefaultEq)}>Initial Default Equipment</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(SetInitialDensAutocomplete)}>Initial Dens Autocomplete</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(GetReportSummary)}>Reports Summary</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(GetSamplers)}>Samplers</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(GetLaboratorists)}>Laboratorists</Button>
          </div>
          <div>
            <Button onClick={e => ConfirmDevPass(GetAnythingLive)}>Any Live</Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Dev