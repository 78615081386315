import React, { useMemo } from 'react';
import ReactSelect from 'react-select';

const ModalTableReactSelect = ({ isMulti, isDisabled, placeholder, items, value, handleChange, stateKey, stateIdx }) => {

  const GetCurrentSelectValue = useMemo(() => {
    let newValue = [];
    value.forEach((so) => {
      const foundItem = items.find(opt => opt.value === so)?.label || so;
      newValue.push({ label: foundItem, value: so });
    })
    return newValue;
  }, [items, value])

  return (
    <div className="modal-table-row-container">
      <div className="w-100">
        <ReactSelect 
          placeholder={placeholder || ''} 
          noOptionsMessage={({ inputValue: string }) => 'No hay opciones para seleccionar'} 
          value={GetCurrentSelectValue} 
          onChange={(e) => handleChange(e.map(v => v.value), stateKey, stateIdx)}
          isMulti={isMulti || false}
          hideSelectedOptions={false}
          options={items} 
          isDisabled={isDisabled || false} 
          classNames={{ clearIndicator: () => 'p-1', dropdownIndicator: () => 'p-1' }}
          styles={{ control: (baseStyles) => ({ ...baseStyles, minHeight: '25px' }) }}
        />
      </div>
    </div>
  )
}

export default ModalTableReactSelect