import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import LoadingModal from '../Layout/LoadingModal.js';
import ModalTableTextInput from '../Generic/ModalTableTextInput.js';
import ModalTableTextNumericInput from '../Generic/ModalTableTextNumericInput.js';
import { FaPlus, FaTrash } from 'react-icons/fa';
import { useAlert } from 'react-alert';
import { confirmAlert } from 'react-confirm-alert';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = ['Código Molde', 'Peso', 'Volumen', 'Editado', 'Por', 'Eliminar'];
const compareKeys = ['code', 'weight', 'volume'];

const ProctorMold = () => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  const [equipmentCodes, setEquipmentCodes] = useState([]);

  const isFirstRender = useRef(true);
  const scrollEndRef = useRef();

  const CodeInputStyle = (index) => {
    if ( equipmentCodes.indexOf(data[index].code) === -1 || data.filter(eq => eq.code === data[index].code).length > 1) {
      return { borderColor: 'red' }
    } else {
      return {}
    }
  }

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let equipmentCodesRequest = Axios.get('/api/equipment/codes', opts);
    let dataRequest = Axios.get('/api/equipment/proctor', opts);

    Axios.all([equipmentCodesRequest, dataRequest]).then(Axios.spread((...responses) => {
      setLoading(false);
      setEquipmentCodes(responses[0].data.filter(code => code.startsWith('MPR')));
      setOldData(JSON.parse(JSON.stringify(responses[1].data)));
      setData(JSON.parse(JSON.stringify(responses[1].data)));
    })).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert])

  const HandleChange = (index, key, value) => {
    if (value !== data[index][key]) {
      let newData = [...data];
      newData[index][key] = value;
      setData(newData);
    }
  }

  const DeleteRecordStepOne = (index) => {
    if (!data[index]._id) {
      let newData = [...data];
      newData.splice(index, 1);
      setData(newData);
    } else {
      confirmAlert({
        closeOnEscape: false,
        closeOnClickOutside: false,
        customUI: ({ onClose }) => {
          return (
            <div className="modal-delete-confirm-container">
              <h4>Está eliminando la información de un Equipo</h4>
              <p> {'¿Está seguro(a) de eliminar el equipo '}{<b>{data[index].code}</b>}{'?'} </p>
              <div className="d-flex mt-3">
                <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
                <Button className="flex-1 lb-white" onClick={() => { onClose(); DeleteRecordStepTwo(index) }}> Si, deseo eliminar </Button>
              </div>
            </div>
          );
        }
      })
    }
  }

  const DeleteRecordStepTwo = (index) => {

    const code = data[index].code;

    if (code) {
      setLoading(true);
  
      if (source) {
        source.cancel();
        source = CancelToken.source();
      }
  
      const opts = {
        headers: {
          'Content-Type': 'application/json'
        },
        cancelToken: source.token
      };
  
      const url = '/api/equipment/proctor/delete';
  
  
      Axios.post(url, {code}, opts)
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success', timeout: 10000});
        GetData();
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('falta información', {type: 'error'});
    }
  }

  const AddRecord = () => {
    const newRecord = {
      code: 'MPR-MSTD-0',
      weight: '',
      volume: ''
    };
    let newState = [...data];
    newState.push(newRecord);
    setData(newState);
    scrollEndRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const SaveDataStepOne = () => {
    if (data.length) {

      let foundErrs = [];
      for (let i = 0; i < data.length; i++) {
        if (!data[i].code) {
          foundErrs.push('Fila ' + (i+1) + ': El código del equipo es requerido');
        }
        if (data.some((item, itemIdx) => ((item.code === data[i].code) && (itemIdx !== i)))) {
          foundErrs.push('Fila ' + (i+1) + ': El código ' + data[i].code + ' ya existe');
        }
      }

      if (foundErrs.length) {
        let errsMsg =
        <>
          <span className="mb-2">Se encontraron los siguientes errores: </span>
          { foundErrs.map((item, itemIdx) => <span key={itemIdx}>{item}</span> )}
        </>
        alert.show(errsMsg, {type: 'info'})
      } else {
        let toSaveArr = [];
        data.forEach((item, itemIdx) => {
          if (oldData[itemIdx]) {
            if (compareKeys.some(itemKey => (String(item[itemKey]) !== String(oldData[itemIdx][itemKey])))) {
              toSaveArr.push(item);
            }
          } else {
            toSaveArr.push(item);
          }
        });
        if (toSaveArr.length) {
          SaveDataStepTwo(toSaveArr)
        } else {
          alert.show('No se encontraron modificaciones', {type: 'info'});
        }
      }
    } else {
      alert.show('No se realizaron modificaciones', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Está modificando información de equipos</h4>
            <p>Se guardarán los cambios para los siguientes equipos: </p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {saveArr.map((item, itemIdx) =>  <span key={itemIdx}>{item.code}</span>)}
            </div>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr) }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/equipment/proctor/update';

    Axios.post(url, saveArr, opts)
    .then((res) => {
      setLoading(false);
      let queryResponse =
        <>
          <span className="mb-2">Los resultados de sus modificaciones son: </span>
          { res.data.map((item, itemIdx) => <span key={itemIdx}>{item}</span> )}
        </>
      alert.show(queryResponse, {type: 'success', timeout: 20000});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="modal-table-outer-container">
        <div className="modal-table-row-inner-container">
          <div className="modal-table-sticky-header-container">
            <div className="modal-table-sticky-header">
              { rowTitles.map((item, itemIdx) => <div key={itemIdx} className="modal-table-row-container"> {item} </div> )}
            </div>
          </div>
          {
            data.length ?
              data.map((item, itemIdx) => {
                return (
                  <div key={itemIdx} className="modal-table-row-input-container">
                    { item._id ? <span className="modal-table-title" style={CodeInputStyle(itemIdx)}> {item.code || '-'} </span> :
                    <ModalTableTextInput styles={CodeInputStyle(itemIdx)} stateIdx={itemIdx} stateKey={'code'} value={item.code} handleChange={HandleChange} /> }
                    <ModalTableTextNumericInput stateIdx={itemIdx} stateKey={'weight'} value={item.weight} handleChange={HandleChange} />
                    <ModalTableTextNumericInput stateIdx={itemIdx} stateKey={'volume'} value={item.volume} handleChange={HandleChange} />
                    <div className="modal-table-row-container">
                      <span className="modal-table-title" title={item.lastModified ? dayjs(item.lastModified).format('LLLL') : '-'}>
                        {item.lastModified ? (dayjs().to(dayjs(item.lastModified))) : '-'}
                      </span>
                    </div>
                    <div className="modal-table-row-container">
                      <span className="modal-table-title"> {item.lastModifiedUser || '-'} </span>
                    </div>
                    <div className="modal-table-row-container">
                      <Button className="d-flex p-1 rounded-circle bg-lb-white" disabled={loading} title="Eliminar" onClick={() => DeleteRecordStepOne(itemIdx)}>
                        <FaTrash color="white" className="icon-style"/>
                      </Button>
                    </div>
                  </div>
                )
              })
            :
              <span className="modal-table-loading-text"> No hay información para mostrar </span>
          }
          <div ref={scrollEndRef}></div>
        </div>
        <div className="d-flex justify-content-center align-items-center p-1">
          <Button className="d-flex p-2 rounded-circle cursor-pointer bg-lb-white" disabled={loading} title="Nuevo Registro" onClick={() => AddRecord()}>
            <FaPlus color="white" size={15} className="icon-style"/>
          </Button>
        </div>
      </div>
      <div className="mt-1">
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default ProctorMold;
