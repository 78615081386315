import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalSections } from '../../Layout/ModalElements';
import LoadingModal from '../../Layout/LoadingModal.js';
import ClasifHumidityCalc from './ClasifHumidityCalc';
import ProctorHumidityCalc from './ProctorHumidityCalc';
import CbrHumidityCalc from './CbrHumidityCalc';
import MaxMinHumidityCalc from './MaxMinHumidityCalc';

const viewSections = ['Clasificación', 'Proctor', 'CBR', 'MaxMin'];

const HumidityCalcModal = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [extrasTara, setExtrasTara] = useState('');

  const isFirstRender = useRef(true);

  const GetExtrasTara = useCallback(() => {

    const url = '/api/extras/taras';

    Axios.get(url)
    .then((res) => {
      setExtrasTara(res.data.data);
    })
    .catch((err) => {
      if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const ChangeLoadingState = useCallback(value => { setLoading(value) }, [])

  useEffect(() => {
    if (isFirstRender.current) {
      GetExtrasTara();
    }
  }, [GetExtrasTara]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Cálculos de Humedad'} closeModal={closeModal} closeKb={!loading} />
        <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
        {activeSection === 0 ? <ClasifHumidityCalc loading={loading} setLoading={ChangeLoadingState} taras={extrasTara} /> : null}
        {activeSection === 1 ? <ProctorHumidityCalc loading={loading} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 2 ? <CbrHumidityCalc loading={loading} setLoading={ChangeLoadingState} /> : null}
        {activeSection === 3 ? <MaxMinHumidityCalc loading={loading} setLoading={ChangeLoadingState} /> : null}
      </div>
    </div>
  )
}

export default HumidityCalcModal
