import React from 'react';
import PulseLoader from 'react-spinners/PulseLoader';

const LoadingPage = (props) => {
  return (
    props.loading ?
      <div className="loading-modal-outer-container w-100 h-100 position-fixed top-0 start-0">
        <div className="loading-modal-container d-flex flex-1 position-relative justify-content-center">
          <PulseLoader
            sizeUnit={"px"}
            size={30}
            color={'lightblue'}
            margin={'auto'}
            loading={props.loading}
          />
        </div>
      </div>
    :
      null
  )
}

export default LoadingPage;
