import React, { useEffect, useState, createContext, useContext } from "react";
import { useAlert } from 'react-alert';
import Axios from 'axios';
import LoadingPage from '../Layout/LoadingPage.js';

const AuthContext = createContext({});

const AuthProvider = (props) => {

  const alert = useAlert();

  const [session, setSession] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {

    const url = '/api/userAuth/isSessionActive';

    Axios.get(url).then((res) => {
      if (res.data.isLogged) {
        setSession(res.data.user);
      } else {
        setSession(false);
      }
    }).catch((err) => {
      if (!err.response) {
        console.log('Petición cancelada');
      }
      setSession(false);
    });
  }, []);

  const Login = (username, password) => {

    if (username && password) {
      setLoading(true);
      const opts = {
        headers: { 'Content-Type': 'application/json' }
      };
      
      const url = '/api/userAuth/session';
      
      Axios.post(url, { username, password }, opts).then((res) => {
        setSession(res.data);
      }).catch((err) => {
        console.log(err);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      }).finally(() => setLoading(false))
    } else {
      alert.show('Complete los campos de Usuario y Contraseña', {type: 'info'});
    }

  }

  const Logout = () => {

    const url = '/api/userAuth/logoutSession';

    Axios.get(url)
    .then((res) => {
      setSession(false);
    })
    .catch((err) => {
      console.log(err);
      if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show('Ocurrió un error al cerrar su sesión', {type: 'error'})
      }
    });
  }

  const authContextValue = { session, Login, Logout };

  return (
    <AuthContext.Provider value={authContextValue}>
      <LoadingPage loading={loading}/>
      {props.children}
    </AuthContext.Provider>
  )
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };