import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { GetReportSampleDisplayText, reportStatusSelectOptions } from '../../Generic/Constants.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = ['Muestra', 'Fecha Finalización', 'Humedad (estimada)', 'Humedad (final)', 'D.C.H.', 'D.C.S. (estimada)', 'D.C.S. (máxima)'];
const dataKeys = ['field3', 'field16', 'field9', 'field10', 'field17'];

const ProctorRevision = ({ setLoading, project }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/revisionHelpers/proctorResults/'+project;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, project, setLoading]);

  useEffect(() => {
    if (project) {
      GetData();
    }
  }, [project, GetData]);

  const RenderData = () => {
    return (
      <div className="d-flex overflow-auto">
        <div className="d-flex flex-column h-100 position-sticky start-0 bg-aliceblue border-end border-secondary" style={{minWidth: 'fit-content'}}>
          {rowTitles.map((header, headerIdx) => 
            <div key={headerIdx} className="p-1"> {header} </div>
          )}
        </div>
        {data.map((report, reportIdx) => {
          const sampleText = GetReportSampleDisplayText(report);
          const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
          return (
            <div key={reportIdx} className="d-flex flex-column align-items-center h-100 text-center border-end border-secondary" style={{minWidth: 'fit-content'}}>
              <div className={"w-100 py-1 " + (statusProps?.colorClasses || '')} title={sampleText + ' - ' + (statusProps?.display || ' - ')}> 
                <span className="fw-semibold px-1">{sampleText}</span> 
              </div>
              <div className="p-1" style={{minWidth: '45px'}}> {report.finalizedDate || '-'} </div>
              {dataKeys.map((rKey, rKeyIdx) => <div key={rKeyIdx} className="p-1" style={{minWidth: '45px'}}> {report.data[rKey] || '-'} </div> )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="modal-table-outer-container mt-2">
      { (project && data.length) ? RenderData() : <span className="p-1">No hay ensayos disponibles</span> }
    </div>
  )
}

export default ProctorRevision