import React, { useState, useEffect, useRef, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import ReactSelect from 'react-select';
import FileDownload from 'js-file-download';
import dayjs from 'dayjs';
import PDFMerger from 'pdf-merger-js/browser';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const formatOptions = [
  {type: 'Lab PDF', opt: 1, formatOpt: 1},
  {type: 'Lab Excel', opt: 1, formatOpt: 2},
  {type: 'Cliente PDF', opt: 2, formatOpt: 1},
  {type: 'Cliente Excel', opt: 2, formatOpt: 2},
]

const ReportMultiDownload = ({ closeModal }) => {
  
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState('');

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const projectsUrl = '/api/projects/codes';
    const templatesUrl = '/api/templates/titles';

    const projectsRequest = Axios.get(projectsUrl, opts);
    const templatesRequest = Axios.get(templatesUrl, opts);

    Axios.all([projectsRequest, templatesRequest]).then(Axios.spread((...responses) => {
      setLoading(false);
      let projectDataHelper = [{ value: '', label: '' }, { value: 'all', label: 'Todos' }];
      responses[0].data.forEach(p => {
        projectDataHelper.push({ value: p.code, label: p.code })
      })
      setProjects(projectDataHelper);
      setTemplates(responses[1].data);
    }))
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const GetReportFiles = (opt, formatOpt) => {
    if ( selectedProject && selectedTemplate && opt && formatOpt ) {

      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }

      const opts = {
        headers: {
          'Content-Disposition': 'attachment',
          'Content-Type': 'application/zip, application/octet-stream',
        },
        responseType: 'blob',
        timeout: 1200000, // 20mins
        cancelToken: source.token
      };

      const url = '/api/downloads/reports/batch/'+selectedProject+'&'+selectedTemplate+'&'+opt+'&'+formatOpt;

      Axios.get(url, opts)
      .then((res) => {
        setLoading(false);

        let downloadTitle = 'Ensayos MSTD' + (selectedProject === 'all' ? '' : (' - '+selectedProject)) + 
        (selectedTemplate === 'all' ? '' : (' - '+selectedTemplate)) +
        (opt === 1 ? ' - LAB' : ' - CLIENTE') +
        (formatOpt === 1 ? ' - PDF' : ' - EXCEL') +
        (' - ' + dayjs().format('DD-MM-YYYY')) + '.zip';

        FileDownload(res.data, downloadTitle);
        alert.show('Se ha descargado un archivo comprimido ZIP con los ensayos seleccionados', {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else if (err.response.status === 404){
          alert.show('Ocurrió un error al generar su archivo', {type: 'error'})
        } else if (err.response.status === 400){
          alert.show('No se encontraron ensayos para descargar', {type: 'error'})
        }
      });
    } else {
      alert.show('Falta información', {type: 'error'});
    }
  }

  const HandleFilesUpload = async (files) => {
    if (files && (files.length > 1)) {
      setLoading(true);
      const merger = new PDFMerger();
      for(const file of files) {
        await merger.add(file);
      }
      const mergedPdf = await merger.saveAsBlob();
      FileDownload(mergedPdf, 'MSTD-JOINT-PDF'+Date.now()+'.pdf');
      setLoading(false);
    } else {
      alert.show('Se necesita por lo menos 2 archivos para unir', {type: 'info'})
    }
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <LoadingModal loading={loading}/>
      <div className="modal-outer-container">
        <div className="modal-inner-container">
          <ModalHeader title={'Descargar última versión de cada ensayo para un proyecto'} closeModal={closeModal} closeKb={!loading} />
          <div className="mt-2 mb-1"> 1. Seleccionar Proyecto</div>
          <ReactSelect 
            placeholder={'Código Proyecto'} 
            noOptionsMessage={({ inputValue: string }) => 'No hay resultados'} 
            defaultValue={selectedProject} 
            onChange={(e) => setSelectedProject(e.value)}
            className="modal-form-input"
            options={projects} 
          />
          <div className="mt-2 mb-1"> 2. Seleccionar Ensayo</div>
          <select className="form-select-sm" value={selectedTemplate} onChange={(e) => setSelectedTemplate(e.target.value)}>
            <option value={''}>{''}</option>
            <option value={'all'}>{'Todos'}</option>
            {templates.map((t, ti) => <option key={ti} value={t}>{t}</option>)}
          </select>
          <div className="mt-3 mb-1"> 3. Seleccionar tipo y formato</div>
          <div className="d-flex flex-wrap">
            { 
              formatOptions.map((f, fIdx) => (
                <button key={fIdx} className="modal-form-button lb-white my-2 my-md-1 me-3" onClick={() => GetReportFiles(f.opt, f.formatOpt)}>
                  {f.type}
                </button>
              ))
            }
          </div>
          <hr/>
          <div className="mt-2 mb-1 fw-bold">Unir Múltiples PDF</div>
          <div className="d-flex">
            { !loading ? 
              <label>
                <div className="modal-form-button lb-white">
                  <input type="file" className="d-none" multiple accept=".pdf" onChange={e => HandleFilesUpload(e.target.files)}/>
                  Subir Archivos
                </div>
              </label>
            : 
              <div className="modal-form-button lb-white">
                Subir Archivos
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}

export default ReportMultiDownload;
