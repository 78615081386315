import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAuth } from "../../Context/Auth";
import { useAlert } from 'react-alert';
import { ModalItemSelection } from '../../Layout/ModalElements';
import { GetReportSimpleDisplayText } from '../../Generic/Constants.js';
import { SelectedReportStatusText, GetRndInteger, CalcHumidityFields } from '../Helpers.js';
import { confirmAlert } from 'react-confirm-alert';
import { ImMagicWand } from 'react-icons/im';
import Button from 'react-bootstrap/Button';
import dayjs from 'dayjs';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const dataKeys = [
  [['table1Col1Field2', 'table1Col1Field3', 'table1Col1Field5', 'table1Col1Field6', 'table1Col1Field8'],
  ['table2Col1Field11', 'table2Col1Field12', 'table2Col1Field13'], 
  ['table2Col2Field11', 'table2Col2Field12', 'table2Col2Field13']], 

  [['table1Col2Field2', 'table1Col2Field3', 'table1Col2Field5', 'table1Col2Field6', 'table1Col2Field8'],
  ['table2Col3Field11', 'table2Col3Field12', 'table2Col3Field13'], 
  ['table2Col4Field11', 'table2Col4Field12', 'table2Col4Field13']],

  [['table1Col3Field2', 'table1Col3Field3', 'table1Col3Field5', 'table1Col3Field6', 'table1Col3Field8'],
  ['table2Col5Field11', 'table2Col5Field12', 'table2Col5Field13'], 
  ['table2Col6Field11', 'table2Col6Field12', 'table2Col6Field13']],

  [['table1Col4Field2', 'table1Col4Field3', 'table1Col4Field5', 'table1Col4Field6', 'table1Col4Field8'],
  ['table2Col7Field11', 'table2Col7Field12', 'table2Col7Field13'], 
  ['table2Col8Field11', 'table2Col8Field12', 'table2Col8Field13']], 

  [['table1Col5Field2', 'table1Col5Field3', 'table1Col5Field5', 'table1Col5Field6', 'table1Col5Field8'],
  ['table2Col9Field11', 'table2Col9Field12', 'table2Col9Field13'], 
  ['table2Col10Field11', 'table2Col10Field12', 'table2Col10Field13']]
];

const humidityControlItems = [
  { field: 'table2Col1Field12', additionalKey: 'field1' },
  { field: 'table2Col2Field12', additionalKey: 'field2' },
  { field: 'table2Col3Field12', additionalKey: 'field3' },
  { field: 'table2Col4Field12', additionalKey: 'field4' },
  { field: 'table2Col5Field12', additionalKey: 'field5' },
  { field: 'table2Col6Field12', additionalKey: 'field6' },
  { field: 'table2Col7Field12', additionalKey: 'field7' },
  { field: 'table2Col8Field12', additionalKey: 'field8' },
  { field: 'table2Col9Field12', additionalKey: 'field9' },
  { field: 'table2Col10Field12', additionalKey: 'field10' }
];

const ProctorHumidityCalc = ({ loading, setLoading }) => {

  const alert = useAlert();
  const { session } = useAuth();

  const [data, setData] = useState([]);
  const [calcValues, setCalcValues] = useState([]);
  const [selectedReport, setSelectedReport] = useState(0);

  const isFirstRender = useRef(true);

  const SetInitialCalc = useCallback((pos) => {
    let newCalc = {};
    for (let i = 0; i < dataKeys.length; i++) {
      for (let j = 0; j < dataKeys[i].length; j++) {
        for (let k = 0; k < dataKeys[i][j].length; k++) {
          newCalc[dataKeys[i][j][k]] = data[pos].data[dataKeys[i][j][k]][0].value;
        }
      }
    }
    return newCalc;
  }, [data]);

  const ResetAllCalcs = useCallback(() => {
    let newCalcValues = [];
    for (let i = 0; i < data.length; i++) {
      newCalcValues.push(SetInitialCalc(i))
    }
    setCalcValues(newCalcValues);
  }, [data, SetInitialCalc]);

  useEffect(() => {
    if (data.length) {
      ResetAllCalcs();
    }
  }, [data, ResetAllCalcs]);

  useEffect(() => {
    if (selectedReport !== 0 && selectedReport >= data.length) {
      setSelectedReport(0);
    }
  }, [data, selectedReport]);

  const ResetSingleCalc = (pos) => {
    let newCalcValues = JSON.parse(JSON.stringify(calcValues));
    newCalcValues[pos] = JSON.parse(JSON.stringify(SetInitialCalc(pos)))
    setCalcValues(newCalcValues);
  }

  const GetData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/revisionHelpers/proctorHumidityCalc';

    Axios.get(url, opts)
    .then((res) => {
      setData(res.data);
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    }).finally(() => setLoading(false));
  }, [alert, setLoading]);

  const CalcNewValues = (col, subCol) => {
    const dataCol = subCol === 1 ? 2 : 1;
    let col1Field13 = data[selectedReport].data[dataKeys[col][dataCol][0]][0].value;
    let col1Field14 = data[selectedReport].data[dataKeys[col][dataCol][1]][0].value;
    let col1Field15 = data[selectedReport].data[dataKeys[col][dataCol][2]][0].value;

    let { humValue3 } = CalcHumidityFields(col1Field13, col1Field14, col1Field15);

    if ( col1Field13 && col1Field14 && col1Field15 && humValue3 ) {
      
      let field13 = (GetRndInteger(500, 650) + Math.random()).toFixed(1);
      let field14 = '0';
      let field15 = (GetRndInteger(78, 100)/10).toFixed(1);
      let isGood = false;
      
      for (let i = 0; i < 100000; i++) {
        field14 = (GetRndInteger(1, (Number(col1Field14)*3)) + Math.random()).toFixed(1);
        let newCol2Calcs = CalcHumidityFields(field13, field14, field15);
        let diff = ( subCol === 1 ? (newCol2Calcs.humValue3 - humValue3) : (humValue3 - newCol2Calcs.humValue3));
        if ((diff < 0.4) && (diff > 0.1)) {
          isGood = true;
          break;
        }
      }

      if (isGood) {
        return { field13, field14, field15 }
      } else {
        return false;
      }

    } else {
      alert.show(('Se necesita la columna #' + dataCol + ' para generar datos'), {type: 'info'});
      return false;
    }
  }

  const GenerateColumnData = (col, subCol) => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let newValues = CalcNewValues(col, subCol);
    if (newValues) {
      newData[selectedReport][dataKeys[col][subCol][0]] = newValues.field13;
      newData[selectedReport][dataKeys[col][subCol][1]] = newValues.field14;
      newData[selectedReport][dataKeys[col][subCol][2]] = newValues.field15;
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const GenerateAllColumnData = () => {
    let newData = JSON.parse(JSON.stringify(calcValues));
    let saveFlag = true;
    for (let i = 0; i < dataKeys.length; i++) {
      for (let j = 1; j < dataKeys[i].length; j++) {
        const hasValues = (data[selectedReport].data[dataKeys[i][j][0]][0].value || data[selectedReport].data[dataKeys[i][j][0]][0].value || data[selectedReport].data[dataKeys[i][j][0]][0].value);
        if (!hasValues) {
          let newValues = CalcNewValues(i, j);
          if (newValues) {
            newData[selectedReport][dataKeys[i][j][0]] = newValues.field13;
            newData[selectedReport][dataKeys[i][j][1]] = newValues.field14;
            newData[selectedReport][dataKeys[i][j][2]] = newValues.field15;
          } else {
            saveFlag = false;
            break;
          }
        }
      }
    }

    if (saveFlag) {
      setCalcValues(newData);
    } else {
      alert.show('No se pudo generar los valores correctamente, inténtelo nuevamente');
    }
  }

  const CheckColChanges = (option, col, subCol) => {
    let didChange = false;
    for (let i = 0; i < dataKeys[col][subCol].length; i++) {
      if (calcValues[option][dataKeys[col][subCol][i]] !== data[option].data[dataKeys[col][subCol][i]][0].value) {
        didChange = true;
        break;
      }
    }
    return didChange;
  }

  const CheckOptionChange = (option) => {
    let didChange = false;
    for (let i = 0; i < dataKeys.length; i++) {
      for (let j = 1; j < dataKeys[i].length; j++) {
        if (CheckColChanges(option, i, j)) {
          didChange = true;
          break;
        }
      }
      if (didChange) break;
    }
    return didChange;
  }

  const RenderDetailsTable = () => {

    const fieldTitles = [
      'N° Molde', '3 Humedad estimada', '5 M. Suelo (húmedo + molde)', '6 M. Molde', '8 Volúmen molde', 
      '11 Masa suelo húmedo + tara', '12 Masa suelo seco + tara', '13 Masa tara', '14 Masa del agua (=11-12)', 
      '15 Masa suelo seco (=12-13)', 'Humedad (=14/15*100)', 'GENERAR DATOS', 'Diferencia de Humedad', '16 Humedad Final'
    ];
    let displayCols = [];

    for (let i = 0; i < dataKeys.length; i++) {

      const colA = CalcHumidityFields(calcValues[selectedReport][dataKeys[i][1][0]], calcValues[selectedReport][dataKeys[i][1][1]], calcValues[selectedReport][dataKeys[i][1][2]]);
      const colB = CalcHumidityFields(calcValues[selectedReport][dataKeys[i][2][0]], calcValues[selectedReport][dataKeys[i][2][1]], calcValues[selectedReport][dataKeys[i][2][2]]);
      const humidityDiff = (colA.humValue3 - colB.humValue3);
      const finalHumidity = ((colA.humValue3 + colB.humValue3)/2);

      let outerColHelper = [];
      for (let j = 0; j < dataKeys[i].length; j++) {
        let colHelper = [];
        for (let k = 0; k < dataKeys[i][j].length; k++) {
          colHelper.push(calcValues[selectedReport][dataKeys[i][j][k]]);
        }
        outerColHelper.push(colHelper);
      }
      displayCols.push({ idx: i, data: outerColHelper, results: { colA, colB, humidityDiff, finalHumidity }});
    }

    displayCols.sort((a, b) => (Number(a.data[0][1]) - Number(b.data[0][1])));
    
    return (
      <div className="d-flex">
        <div className="px-1"> {fieldTitles.map((item, itemIdx) => <div key={itemIdx} className="ellipsis-text p-1"> {item} </div>)} </div>
        <div className="d-flex flex-column px-1 text-center">
          <div className="d-flex">
            {displayCols.map((col, colIdx) => {
              return (
                <div key={colIdx} className="d-flex flex-column align-items-center justify-content-center mx-2 mx-lg-3">
                  {col.data[0].map((element, elementIdx) => <div key={elementIdx} className="py-1 px-2"> {element || '-'} </div>)}
                  <div className="d-flex gap-2">
                    <div className="d-flex flex-column">
                      <div className={"rounded-2" + (CheckColChanges(selectedReport, col.idx, 1) ? " bg-warning bg-opacity-50" : "")}>
                        {col.data[1].map((element, elementIdx) => <div key={elementIdx} className="py-1 px-2"> {element || '-'} </div>)}
                        <div className="py-1 px-2">{col.results.colA.humValue1.toFixed(1)}</div>
                        <div className="py-1 px-2">{col.results.colA.humValue2.toFixed(1)}</div>
                        <div className="py-1 px-2">{col.results.colA.humValue3.toFixed(1)}</div>
                      </div>
                      <Button size="sm" onClick={() => GenerateColumnData(col.idx, 1)}>
                        <ImMagicWand color="white" className="icon-style"/>
                      </Button>
                    </div>
                    <div className="d-flex flex-column">
                      <div className={"rounded-2" + (CheckColChanges(selectedReport, col.idx, 2) ? " bg-warning bg-opacity-50" : "")}>
                        {col.data[2].map((element, elementIdx) => <div key={elementIdx} className="py-1 px-2"> {element || '-'} </div>)}
                        <div className="py-1 px-2">{col.results.colB.humValue1.toFixed(1)}</div>
                        <div className="py-1 px-2">{col.results.colB.humValue2.toFixed(1)}</div>
                        <div className="py-1 px-2">{col.results.colB.humValue3.toFixed(1)}</div>
                      </div>
                      <Button size="sm" onClick={() => GenerateColumnData(col.idx, 2)}>
                        <ImMagicWand color="white" className="icon-style"/>
                      </Button>
                    </div>
                  </div>
                  <div className="py-1 px-2">{col.results.humidityDiff.toFixed(3)}</div>
                  <div className="py-1 px-2">{col.results.finalHumidity.toFixed(1)}</div>
                </div>
              )
            })}
          </div>
          <button className="modal-form-button lb-white my-3 user-select-none" onClick={() => GenerateAllColumnData()}>Generar todas las Columnas</button>
        </div>
      </div>
    )
  }

  const SaveDataStepOne = () => {
    if (data.length) {
      let toSaveArr = [];
      calcValues.forEach((report, reportIdx) => {
        let keysHelper = {};
        Object.keys(report).forEach((rKey) => {
          if (report[rKey] !== data[reportIdx].data[rKey][0].value) {
            keysHelper[rKey] = report[rKey];
          }
        })
        if (Object.keys(keysHelper).length) {
          toSaveArr.push({ idx: reportIdx, reportNumber: data[reportIdx].reportNumber, data: keysHelper });
        }
      });
      if (toSaveArr.length) {
        for (let i = 0; i < toSaveArr.length; i++) {
          toSaveArr[i].data['humidityControl'] = {};
          humidityControlItems.forEach(ak => {
            if (toSaveArr[i].data[ak.field]) {
              const timeValue = data[toSaveArr[i].idx].data.furnaceEndDate[0].value;
              toSaveArr[i].data['humidityControl'][ak.additionalKey] = [{ 
                value1: toSaveArr[i].data[ak.field], 
                time1: (toSaveArr[i].data[ak.field] ? timeValue ? dayjs(timeValue).subtract(1, 'hour').format('HH:mm') : dayjs().format('HH:mm') : ''), 
                value2: toSaveArr[i].data[ak.field], 
                time2: (toSaveArr[i].data[ak.field] ? timeValue ? dayjs(timeValue).format('HH:mm') : dayjs().add(1, 'hour').format('HH:mm') : ''), 
                editedBy: session.username,
                editedAt: Date.now()
              }]
            }
          })
        }
        SaveDataStepTwo(toSaveArr);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    } else {
      alert.show('No hay ensayos para guardar', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    let reportTexts = [];
    saveArr.forEach(r => {
      reportTexts.push(GetReportSimpleDisplayText(data[r.idx]));
    })
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-delete-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se guardarán cambios para los siguientes ensayos:</p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {reportTexts.map((rt, rtIdx) =>  <span key={rtIdx}>{rt}</span>)}
            </div>
            <div className="modal-delete-confirm-buttons-container">
              <button className="modal-confirm-button round-button silver-black me-2" onClick={onClose}> No, deseo salir </button>
              <button className="modal-confirm-button round-button lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr); }}> Si, deseo guardar </button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let url = '/api/reports/batch/updateData';

    Axios.post(url, { records: saveArr }, opts)
    .then((res) => {

      setLoading(false);

      if (res.data && res.data.results && res.data.results.length) {
        let resultsMsgs = [];
        let resultsNoChange = [];
        let resultsErrs = [];
        res.data.results.forEach(r => {
          if (r.err) {
            resultsErrs.push(r.err);
          } else if (r.noChange) {
            resultsNoChange.push(r.noChange);
          } else {
            resultsMsgs.push(r.msg);
          }
        });
        if (resultsMsgs.length) {
          let queryResponse =
            <>
              <span className="mb-2">Los resultados de sus cambios son: </span>
              {resultsMsgs.map((el, elIdx) => <span key={elIdx}>{el}</span>)}
            </>
          alert.show(queryResponse, {type: 'success', timeout: 15000});
        }
        if (resultsNoChange.length) {
          let errResp = <> {resultsNoChange.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'info', timeout: 15000});
        }
        if (resultsErrs.length) {
          let errResp = <> {resultsErrs.map((el, elIdx) => <span key={elIdx}>{el}</span>)} </>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        alert.show('Ocurrió un problema al recuperar los resultados', {type: 'info', timeout: 5000});
      }
      GetData();
    }).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <>
      <div className="modal-table-outer-container my-1">
        { (data.length && calcValues.length && (selectedReport < data.length )) ? 
          <div className="d-flex flex-column m-1 overflow-auto">
            <ModalItemSelection 
              canChange={!loading} data={data} currentItem={selectedReport} setItem={(value) => setSelectedReport(Number(value))} 
              optClasses={(item, itemIdx) => CheckOptionChange(itemIdx) ? "bg-warning bg-opacity-50" : ""} optText={(opt) => GetReportSimpleDisplayText(opt)} 
              backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
            />
            {SelectedReportStatusText(data[selectedReport])}
            <div className="d-flex flex-column flex-xxl-row overflow-auto">
              {RenderDetailsTable()}
            </div>
            <hr/>
            <div className="d-flex justify-content-around">
              <Button className="my-1 mx-2" onClick={() => ResetSingleCalc(selectedReport)} variant="secondary" size="sm">Reiniciar este ensayo</Button>
              <Button className="my-1 mx-2" onClick={() => ResetAllCalcs()} variant="secondary" size="sm">Reiniciar todos los ensayos</Button>
            </div>
          </div>
        : 
          <span>No se encontraron ensayos</span>
        }
      </div>
      <div>
        <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
      </div>
    </>
  )
}

export default ProctorHumidityCalc