import React from 'react';

const Footer = () => {
  return (
    <div className="d-flex align-items-center justify-content-center py-2 bg-darkerblue">
      <a
        href="http://www.mstdingenieria.cl"
        target="_blank"
        rel="noopener noreferrer"
        className="color-white text-decoration-none"
      >
        <span>{'MSTD Ingeniería '}{new Date(Date.now()).getFullYear()}</span>
      </a>
    </div>
  )
}

export default Footer;
