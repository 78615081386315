import React from 'react';
import Form from 'react-bootstrap/Form';

const FormDateInput = (props) => {
  return (
    <div className="d-md-flex align-items-center justify-content-center p-2">
      { props.title ?
        <div className="d-flex flex-1 align-items-center me-md-2">
          { props.isRequired ? <span className="text-danger">*</span> : '' }
          { props.title }
        </div>
      : null }
      <Form.Control type="date" className="modal-form-input fs-inherit border border-secondary" size="sm" value={props.value || ''} onChange={(e) => props.handleChange(props.stateKey, e.target.value)} />
    </div>
  )
}

export default FormDateInput