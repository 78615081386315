import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalItemSelection } from '../Layout/ModalElements';
import { eqSectionDict } from '../Generic/Constants.js';
import { confirmAlert } from 'react-confirm-alert';
import ModalTableTextInput from '../Generic/ModalTableTextInput';
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai';
import LoadingModal from '../Layout/LoadingModal.js';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const EditEquipmentDefaults = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [oldData, setOldData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedItem, setSelectedItem] = useState(0);

  const isFirstRender = useRef(true);

  const ResetAllItems = useCallback(() => {
    let newData = [];
    oldData.forEach(d => {
      newData.push(JSON.parse(JSON.stringify(d)));
    })
    setData(newData);
  }, [oldData]);

  const ResetSingleItem = (pos) => {
    let newData = JSON.parse(JSON.stringify(data));
    newData[pos] = JSON.parse(JSON.stringify(oldData[pos]))
    setData(newData);
  }

  useEffect(() => {
    if (oldData.length) {
      ResetAllItems();
    }
  }, [oldData, ResetAllItems]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    Axios.get('/api/extras/reportEq', opts)
    .then((res) => {
      setLoading(false);
      setOldData(JSON.parse(JSON.stringify(res.data?.data || [])));
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert]);

  const HandleChange = (sectionKey, index, key, value) => {
    if (value !== data[selectedItem].equipmentUsed[sectionKey][index][key]) {
      let newData = [...data];
      data[selectedItem].equipmentUsed[sectionKey][index][key] = value;
      setData(newData);
    }
  }

  const RenderData = () => {
    return (
      <div className="d-flex flex-column flex-sm-row gap-3">
        { Object.keys(data[selectedItem].equipmentUsed).map((eqSection, eqSectionIdx) => {
          const foundEqTitle = eqSectionDict.find(teq => teq.eqKey === eqSection)?.title || 'Nombre de sección de equipo no encontrado';
          return (
            <div key={eqSectionIdx} className="d-flex flex-column border border-2 py-2 px-1 my-2">
              <div className="my-1 text-center fw-bold">{foundEqTitle}</div>
              <div className="d-flex flex-column flex-1">
                <div className="d-flex mt-1 align-items-center">
                  <div className="d-flex justify-content-center flex-4"> Equipo </div>
                  <div className="d-flex justify-content-center flex-1 mx-1"> Estado </div>
                  <div className="d-flex justify-content-center flex-1"> Usar </div>
                </div>
                { data[selectedItem].equipmentUsed[eqSection].map((eq, eqIdx) => {
                  const isChecked = eq.eqStatus === 'bueno' ? true : false;
                  return (
                    <div key={eqIdx} className={"d-flex mt-1 align-items-center" + (CheckRowChanges(selectedItem, eqSection, eqIdx) ? " bg-warning bg-opacity-50" : "")}>
                      <div className="d-flex justify-content-center flex-4">
                        <ModalTableTextInput stateIdx={eqIdx} stateKey={'eqCode'} value={eq.eqCode} handleChange={(index, key, value) => HandleChange(eqSection, index, key, value)} />
                      </div>
                      <div className="d-flex justify-content-center flex-1 mx-1">
                        <Button className="p-1 rounded-circle" variant="light" onClick={() => HandleChange(eqSection, eqIdx, 'eqStatus', (isChecked ? 'malo' : 'bueno'))} >
                          { isChecked ? <AiOutlineCheckCircle size={22} color="green" className="d-flex icon-style"/> : <AiOutlineCloseCircle size={22} color="red" className="d-flex icon-style"/> }
                        </Button>
                      </div>
                      <div className="d-flex justify-content-center flex-1">
                        <Button className="p-1 rounded-circle" variant="light" onClick={() => HandleChange(eqSection, eqIdx, 'initialOnly', !eq.initialOnly)} >
                          { !eq.initialOnly ? <AiOutlineCheckCircle size={22} color="green" className="d-flex icon-style"/> : <AiOutlineCloseCircle size={22} color="red" className="d-flex icon-style"/> }
                        </Button>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  const CheckRowChanges = (option, section, row) => {
    if ((data[option].equipmentUsed[section][row].eqCode !== oldData[option].equipmentUsed[section][row].eqCode) || 
    (data[option].equipmentUsed[section][row].eqStatus !== oldData[option].equipmentUsed[section][row].eqStatus)) {
      return true;
    } else {
      return false;
    }
  }

  const CheckOptionChange = (option) => {
    let didChange = false;
    Object.keys(data[option].equipmentUsed).forEach(eKey => {
      for (let i = 0; i < data[option].equipmentUsed[eKey].length; i++) {
        if (CheckRowChanges(option, eKey, i)) {
          didChange = true;
          break;
        }
      }
    })
    return didChange;
  }

  const SaveDataStepOne = () => {
    if (oldData.length) {
      let toSaveArr = [];
      data.forEach((item, itemIdx) => {
        let keysHelper = {};
        Object.keys(item.equipmentUsed).forEach((rKey) => {
          let rowsHelper = [];
          for (let i = 0; i < oldData[itemIdx].equipmentUsed[rKey].length; i++) {
            let itemToCopy = null;
            if (CheckRowChanges(itemIdx, rKey, i)) {
              itemToCopy = {...item.equipmentUsed[rKey][i]};
            }
            rowsHelper.push(itemToCopy);
          }
          if (rowsHelper.some(Boolean)) { keysHelper[rKey] = rowsHelper };
        })
        if (Object.keys(keysHelper).length) {
          toSaveArr.push({ idx: itemIdx, code: oldData[itemIdx].code, version: oldData[itemIdx].version, data: {equipmentUsed: keysHelper} });
        }
      });
      if (toSaveArr.length) {
        SaveDataStepTwo(toSaveArr);
      } else {
        alert.show('No se encontraron cambios para guardar', {type: 'info'});
      }
    } else {
      alert.show('No hay formularios para guardar', {type: 'info'});
    }
  }

  const SaveDataStepTwo = (saveArr) => {
    let itemTexts = [];
    saveArr.forEach(r => {
      itemTexts.push(GetOptText(oldData[r.idx]));
    })
    confirmAlert({
      closeOnEscape: false,
      closeOnClickOutside: false,
      customUI: ({ onClose }) => {
        return (
          <div className="modal-large-confirm-container">
            <h4>Confirme esta acción</h4>
            <p>Se guardarán cambios para los siguientes ensayos:</p>
            <div className="d-flex flex-column overflow-auto" style={{maxHeight: '50vh'}}>
              {itemTexts.map((rt, rtIdx) =>  <span key={rtIdx}>{rt}</span>)}
            </div>
            <div className="d-flex mt-3">
              <Button className="flex-1 silver-black me-2" onClick={() => onClose()}> No, deseo salir </Button>
              <Button className="flex-1 lb-white" onClick={() => { onClose(); SaveDataStepThree(saveArr) }}> Si, deseo guardar </Button>
            </div>
          </div>
        );
      }
    })
  }

  const SaveDataStepThree = (saveArr) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/extras/saveData';

    Axios.post(url, { code: 'reportEq', data: saveArr }, opts)
    .then((res) => {
      setLoading(false);
      alert.show(res.data.msg, {type: 'success'});
      GetData();
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }

  const GetOptText = (item) => {
    return (item.title + ' - ' + item.code + ' v' + item.version)
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={'Editar equipos por defecto para un formulario'} closeModal={closeModal} />
        { (data.length && oldData.length && (selectedItem < data.length )) ? 
          <div className="d-flex flex-column m-1 overflow-auto">
            <ModalItemSelection 
              data={oldData} currentItem={selectedItem} setItem={(value) => setSelectedItem(Number(value))} 
              optClasses={(item, itemIdx) => CheckOptionChange(itemIdx) ? "bg-warning bg-opacity-50" : ""} optText={(opt) => GetOptText(opt)}
            />
            <div className="fw-bold my-2"> {GetOptText(oldData[selectedItem])} </div>
            <div>Los equipos definidos en esta vista serán usados para generar los datos iniciales de los ensayos</div>
            <div>La tercera opción define si el equipo se usará para autocompletar los equipos de un ensayo en la vista de editar equipos usados</div>
            <div className="d-flex flex-column flex-xxl-row overflow-auto">
              {RenderData()}
            </div>
            <hr/>
            <div className="d-flex justify-content-around">
              <Button className="my-1 mx-2" onClick={() => ResetSingleItem(selectedItem)} variant="secondary" size="sm">Reiniciar este formulario</Button>
              <Button className="my-1 mx-2" onClick={() => ResetAllItems()} variant="secondary" size="sm">Reiniciar todos los formularios</Button>
            </div>
          </div>
        : <span>No se encontraron formularios</span> }
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveDataStepOne()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default EditEquipmentDefaults