import React, { useState } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import FormTextInput from '../Generic/FormTextInput';
import FormFileInput from '../Generic/FormFileInput';
import FormDateInput from '../Generic/FormDateInput';
import LoadingModal from '../Layout/LoadingModal.js';
import dayjs from 'dayjs';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const FWManagerModalAdd = ({ closeModal }) => {

  const alert = useAlert();
  
  const [loading, setLoading] = useState(false);  
  const [form, setForm] = useState({
    version: '', 
    releaseDate: dayjs().format('YYYY-MM-DD'), 
    filename: '', 
    comment: ''
  });

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const SaveNewRecord = () => {
    if ( form.version && form.releaseDate && form.filename && form.comment ) {

      setLoading(true);

      const formData = new FormData();
      formData.append('record', JSON.stringify(form));
      formData.append('filename', form.filename);

      const url = '/api/fieldWorkApp/add';

      Axios.post(url, formData, {cancelToken: source.token})
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    }else{
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nueva Versión App Terreno'} closeModal={closeModal} closeKb={!loading} />
        <div className="modal-form-container">
          <FormTextInput title="Versión APK" isRequired={true} stateKey={'version'} value={form.version} handleChange={HandleChange} />
          <FormDateInput title="Fecha de Lanzamiento" isRequired={true} stateKey={'releaseDate'} value={form.releaseDate} handleChange={HandleChange} />
          <FormTextInput title="Comentario" isRequired={true} stateKey={'comment'} value={form.comment} handleChange={HandleChange} />
          <FormFileInput title="Archivo (.apk)" isRequired={true} accept=".apk" stateKey={'filename'} handleChange={HandleChange} />
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default FWManagerModalAdd;
