import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalSections } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import SingleAdd from './SingleAdd.js';
import BatchAdd from './BatchAdd.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const viewSections = ['Individual', 'Múltiples'];
const fetchUrls = ['projects/codes', 'samples/codes', 'reports/codes', 'templates/active', 'reports/lastReport', 'samples/lastSample'];

const BookModalAdd = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [activeSection, setActiveSection] = useState(0);
  const [lastReport, setLastReport] = useState('');
  const [lastSample, setLastSample] = useState('');
  const [projectData, setProjectData] = useState([]);
  const [sampleIds, setSampleIds] = useState([]);
  const [reportNumbers, setReportNumbers] = useState([]);
  const [availableReports, setAvailableReports] = useState([]);

  const isFirstRender = useRef(true);

  const GetData = useCallback(() => {

    setLoading(true);

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    let allRequests = [];
    fetchUrls.forEach((url) => { allRequests.push(Axios.get('/api/'+url, opts)) });

    Axios.all(allRequests).then(Axios.spread((...responses) => {
      setProjectData(responses[0].data);
      setSampleIds(responses[1].data);
      setReportNumbers(responses[2].data.map(rep => rep.reportNumber));
      let reports = responses[3].data;
      reports.forEach((newReport) => {
        newReport.isChecked = false;
        newReport.reportNumber = '';
      });
      setAvailableReports(reports);
      setLastReport(responses[4].data);
      setLastSample(responses[5].data);
      setLoading(false);
    })).catch(err => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    })
  }, [alert]);

  const FormatSampleText = (sample) => {
    if (sample) {
      return (sample.sampleId + ' | ' +
      (sample.trialPitNumber ? (sample.trialPitNumber + ' ') : '') +
      (sample.horizon ? (sample.horizon + ' ') : '') +
      (sample.depth ? (sample.depth) : '')) 
    } else {
      return '';
    }
  }

  const RenderLastReportData = () => {
    if (lastReport?.lastAddedReport) {
      const sample = lastReport.lastAddedReport.samples.find(Boolean);
      return (
        <div>
          <span>- Último Informe Ingresado: </span>
          <b>
            <span>{FormatSampleText(sample)} </span>
            <span>{lastReport.lastAddedReport.reportNumber} </span>
            <span>{lastReport.lastAddedReport.template.title} </span>
            <span>{sample?.project?.code || ''} </span>
          </b>
        </div>
      )
    } else {
      return <span>- Ultimo Informe Ingresado: -</span>;
    }
  }

  const RenderLastSample = () => {
    if (lastSample) {
      const sample = lastSample?.lastAddedSample;
      return(
        <div>
          <span>- Última Muestra Ingresada: </span>
          { sample ? <b> <span>{FormatSampleText(sample) + (sample?.project?.code || '')} </span> </b> : '-' }
        </div>
      )
    } else {
      return <span>- Ultima Muestra Ingresada: -</span>;
    }
  }

  const RenderLastAdded = () => {
    return (
      <div>
        <span>- Últimos Correlativos: </span>
        <span> ID Muestra </span>
        {( lastSample?.lastSampleId) ? <b><span>{lastSample.lastSampleId.sampleId} </span></b> : <span> {'-'} </span> }
        <span> N° Informe </span>
        {( lastReport?.lastReportNumber) ? <b><span>{lastReport.lastReportNumber.reportNumber} </span></b> : <span> {'-'} </span> }
      </div>
    )
  }

  useEffect(() => {
    if(isFirstRender.current){
      GetData();
    }
  }, [GetData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className={(activeSection === 0 ? "modal-inner-container" : "modal-inner-container-large")}>
        <ModalHeader title={'Nueva Muestra de Suelo'} closeModal={closeModal} />
        <div className="modal-form-container">
          <div className="mb-2">
            {RenderLastSample()}
            {RenderLastReportData()}
            {RenderLastAdded()}
          </div>
          <ModalSections sections={viewSections} activeSection={activeSection} setActiveSection={(value) => setActiveSection(value)} />
          {
            activeSection === 0 ? 
              <SingleAdd projectData={projectData} sampleIds={sampleIds} reportNumbers={reportNumbers} availableReports={availableReports} getExtraData={GetData} /> 
            : 
              <BatchAdd projectData={projectData} sampleIds={sampleIds} reportNumbers={reportNumbers} availableReports={availableReports} getExtraData={GetData} /> 
          }
        </div>
      </div>
    </div>
  )
}

export default BookModalAdd
