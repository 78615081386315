import React, { useState, useEffect, useCallback, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader, ModalItemSelection } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import Button from 'react-bootstrap/Button';
import { FaSyncAlt, FaCloudDownloadAlt } from 'react-icons/fa';
import { BsPlus, BsDash } from 'react-icons/bs';
import { Document, Page } from 'react-pdf';
import FileDownload from 'js-file-download';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

let Buffer = require('buffer/').Buffer;

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const availablePageScales = [
  { label: 90, value: 1.0 }, 
  { label: 100, value: 1.25 }, 
  { label: 125, value: 1.5 }, 
  { label: 150, value: 1.75 }, 
  { label: 175, value: 2.0 }, 
  { label: 200, value: 2.25 },
  { label: 250, value: 2.75 },
  { label: 300, value: 3.5 },
];

const ReportModalDocPreview = ({ loading: outsideLoading, projectCode, docs, docsSrc, closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [pageScale, setPageScale] = useState(3);
  const [projectFiles, setProjectFiles] = useState([]);
  const [currentFile, setCurrentFile] = useState(0);

  const isFirstRender = useRef(true);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  }

  const GenerateDocsPreview = useCallback((records, idx) => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { headers: { 'Content-Type': 'application/json' }, cancelToken: source.token };
    const url = docsSrc === 1 ? '/api/fwExports/probing/preview/sheet' : docsSrc === 2 ? '/api/fwExports/probing/preview/doc' : '';

    Axios.post(url, { projectCode, records }, opts)
    .then((res) => {
      setLoading(false);
      if (docsSrc === 2) {
        setProjectFiles([res.data]);
        if (res.data?.errs?.length) {
          let errResp =
            <div className="d-flex flex-column">
              <span> Se encontraron los siguientes errores: </span>
              <hr className="my-2"/>
              { res.data.errs.map((el, elIdx) => <span key={elIdx}>{el}</span> )}
            </div>
          alert.show(errResp, {type: 'error', timeout: 15000});
        }
      } else {
        if (idx || idx === 0) {
          let newRecords = [...projectFiles];
          newRecords[idx] = res.data[0];
          setProjectFiles(newRecords);
        } else {
          setProjectFiles(res.data);
        }
        let resErrs = res.data.filter(r => r.err);
        if (resErrs.length) {
          let errResp = (<> {resErrs.map((el, elIdx) => <span key={elIdx}>{((el?.probingCode || ('Sondaje N°' + el.probingNumber)) + ': ' + el.err)}</span>)} </>)
          alert.show(errResp, {type: 'error', timeout: 25000});
        }
      }
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, projectFiles, docsSrc, projectCode]);

  const RenderReportOpts = () => {
    return (
      <div className="d-flex position-sticky top-0 start-0 user-select-none zIndex-3 bg-secondary">
        <div className="d-flex flex-1 justify-content-center align-items-center flex-wrap">
          <div className="d-flex align-items-center">
            <Button className="d-flex p-1 rounded-circle" variant="secondary" disabled={loading || outsideLoading || (pageScale <= 0)} 
              title="Alejar" onClick={() => setPageScale(pageScale-1)}
            >
              <BsDash color="white" size={22} className="icon-style"/>
            </Button>
            <div className="color-white"> {availablePageScales[pageScale].label + '%'} </div>
            <Button className="d-flex p-1 rounded-circle" variant="secondary" disabled={loading || outsideLoading || (pageScale >= (availablePageScales.length-1))} 
              title="Acercar" onClick={() => setPageScale(pageScale+1)}
            >
              <BsPlus color="white" size={22} className="icon-style"/>
            </Button>
          </div>
          <div className="d-flex align-items-center ms-md-2 gap-md-3 gap-2">
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={loading || outsideLoading} 
              title="Recargar ensayo" onClick={() => GenerateDocsPreview([docs[currentFile]], currentFile)}
            >
              <FaSyncAlt color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Recargar</span>
            </Button>
            <Button className="py-1 px-2 fs-inherit" size='sm' variant='secondary' disabled={loading || outsideLoading} title="Descargar" onClick={() => DownloadPreviewFile()}
            >
              <FaCloudDownloadAlt color="white" size={15} className="icon-style me-1"/>
              <span className="fs-8">Descargar</span>
            </Button>
          </div>
        </div>
      </div>
    )
  }

  const DownloadPreviewFile = () => {
    const downloadTitle = (projectFiles[currentFile].docName + projectFiles[currentFile].ext);
    FileDownload(Buffer.from(projectFiles[currentFile].doc), downloadTitle);
  }

  const GetCurrentLabel = (opt, optIdx) => {
    let text = '';
    if (projectFiles.length) {
      text = (docsSrc === 2 ? ('Informe Proyecto ' + projectCode) : (projectFiles[optIdx]?.probingCode || ('Sondaje N°' + projectFiles[optIdx].probingNumber)));
    } else {
      text = 'No hay archivos para mostrar'
    }
    return text;
  }

  useEffect(() => {
    if (isFirstRender.current) {
      if (docs.length && docsSrc) {
        GenerateDocsPreview(docs, null);
      }
    }
  }, [docs, docsSrc, GenerateDocsPreview]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container zIndex-3">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container-large">
        <ModalHeader title={('Vista Previa - ' + (projectCode || ''))} closeModal={closeModal} closeKb={!loading} />
        <ModalItemSelection 
          canChange={!loading} data={(docsSrc === 2 ? [true] : docs)} currentItem={currentFile} setItem={(value) => { setNumPages(null); setCurrentFile(Number(value)) }} 
          optClasses={(item, itemIdx) => ""} optText={GetCurrentLabel} backBtnText={'(A) Anterior'} fwdBtnText={'(S) Siguiente'} 
        />
        <div className="modal-pdf-outer-container">
          { projectFiles.length ? RenderReportOpts() : null }
          { (projectFiles.length && projectFiles[currentFile].file) ? 
            <Document
              file={projectFiles[currentFile].file}
              onLoadSuccess={onDocumentLoadSuccess}
              className="pdf-doc-container d-flex flex-column"
              width={'100%'}
            >
              { numPages ? Array.from({length: numPages}, (_, i) => i + 1).map((page, pageIdx) => 
                <Page
                  key={pageIdx}
                  pageNumber={page}
                  scale={availablePageScales[pageScale].value}
                  className={"d-flex w-100 justify-content-center bg-aliceblue" + (pageIdx < (numPages-1) ? " mb-4" : "")}
                  style={{ left: 'unset', inset: 'unset' }}
                />
              ) : null }
            </Document>
          : 
            <div className="my-4 text-center">
              No se encontró un archivo para mostrar
            </div>
          }
        </div>
      </div>
    </div>
  )
}

export default ReportModalDocPreview;
