import React, { useState, useEffect, useCallback } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { GetReportSampleDisplayText, reportStatusSelectOptions } from '../../Generic/Constants.js';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const rowTitles = [
  '(10-25-56G)/Muestra', 'Fecha Finalización', 'ADI Humedad Final', 'ADI D.C.S.', 'DDI Humedad', 'DDI D.C.S.', 'Hinchamiento', 
  'Lecturas 0.5', 'Lecturas 1.5', 'Lecturas 2.5', 'Lecturas 3.5', 'Lecturas 4.5', 'Lecturas 5.5', 'Lecturas 6.5'
];

const sectionKeys = [
  ['conf1Field18', 'conf2Field18', 'conf3Field18'], ['conf1Field19', 'conf2Field19', 'conf3Field19'], ['after1Humidity', 'after2Humidity', 'after3Humidity'],
  ['after1Field19', 'after2Field19', 'after3Field19'], ['col1Field23', 'col2Field23', 'col3Field23'], ['table4Col1Field2', 'table4Col2Field2', 'table4Col3Field2'],
  ['table4Col1Field4', 'table4Col2Field4', 'table4Col3Field4'], ['table4Col1Field6', 'table4Col2Field6', 'table4Col3Field6'], 
  ['table4Col1Field8', 'table4Col2Field8', 'table4Col3Field8'], ['table4Col1Field10', 'table4Col2Field10', 'table4Col3Field10'], 
  ['table4Col1Field12', 'table4Col2Field12', 'table4Col3Field12'], ['table4Col1Field14', 'table4Col2Field14', 'table4Col3Field14']
];

const CbrRevision = ({ setLoading, project }) => {

  const alert = useAlert();

  const [data, setData] = useState([]);

  const GetData = useCallback(() => {
    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = {
      headers: {
        'Content-Type': 'application/json'
      },
      cancelToken: source.token
    };

    const url = '/api/revisionHelpers/cbrResults/'+project;

    Axios.get(url, opts)
    .then((res) => {
      setLoading(false);
      setData(res.data);
    })
    .catch((err) => {
      setLoading(false);
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    });
  }, [alert, project, setLoading]);

  useEffect(() => {
    if (project) {
      GetData();
    }
  }, [project, GetData]);

  const RenderData = () => {
    return (
      <div className="d-flex overflow-auto">
        <div className="d-flex flex-column h-100 position-sticky start-0 bg-aliceblue border-end border-secondary" style={{minWidth: 'fit-content'}}>
          { rowTitles.map((header, headerIdx) => <div key={headerIdx} className="p-1"> {header} </div> )}
        </div>
        {data.map((report, reportIdx) => {
          const sampleText = GetReportSampleDisplayText(report);
          const statusProps = reportStatusSelectOptions.find(rs => rs.value === report.status);
          return (
            <div key={reportIdx} className="d-flex flex-column h-100 align-items-center text-center border-end border-secondary" style={{minWidth: 'fit-content'}}>
              <div className={"w-100 py-1 " + (statusProps?.colorClasses || '')} title={sampleText + ' - ' + (statusProps?.display || ' - ')}> 
                <span className="fw-semibold px-1">{sampleText}</span> 
              </div>
              <div className="p-1" style={{minWidth: '45px'}}> {report.finalizedDate || '-'} </div>
              {sectionKeys.map((rSection, rSectionIdx) => 
                <div key={rSectionIdx} className="d-flex w-100 justify-content-evenly">
                  {rSection.map((rKey, rKeyIdx) => 
                    <div key={rKeyIdx} className={"p-1 " + (rKeyIdx === (rSection.length-1) ? "" : "border-end border-info border-opacity-25")} style={{minWidth: '45px'}}>
                      {report.data[rKey] || '-'} 
                    </div>
                  )}
                </div>
              )}
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <div className="modal-table-outer-container mt-2">
      { (project && data.length) ? RenderData() : <span className="p-1">No hay ensayos disponibles</span> }
    </div>
  )
}

export default CbrRevision