import React from 'react';
import { Outlet, Navigate } from "react-router";
import { useAuth } from "../Context/Auth";
import { useLocation, useResolvedPath } from "react-router-dom";
import { useAlert } from 'react-alert';
import LoadingSession from './LoadingSession.js';

const permissionRequired = ['superAdmin', 'labAdmin'];

const LabAdminRoute = () => {

  const alert = useAlert();
  const { session } = useAuth();
  const location = useLocation();
  const resolvedPath = useResolvedPath(".");
  if (session) {
    if (session.role.some(role => permissionRequired.includes(role))) {
      return <Outlet />
    } else {
      alert.show('No tiene permisos para entrar a esta sección', {type: 'info'}); // this causes a warning.
      return <Navigate to={"/"} replace state={{ from: location, parent: resolvedPath }}/>
    }
  } else {
    if (session === false) {
      return <Navigate to={"/login"} replace state={{ from: location, parent: resolvedPath }}/>
    } else {
      return <LoadingSession />
    }
  }
}

export default LabAdminRoute;