import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import Axios from 'axios';
import { useAlert } from 'react-alert';
import { ModalHeader } from '../Layout/ModalElements';
import LoadingModal from '../Layout/LoadingModal.js';
import FormTextInput from '../Generic/FormTextInput';
import FormReactSelect from '../Generic/FormReactSelect';
import FormFileInput from '../Generic/FormFileInput';
import Button from 'react-bootstrap/Button';

let CancelToken = Axios.CancelToken;
let source = CancelToken.source();

const TemplateModalAdd = ({ closeModal }) => {

  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [form, setForm] = useState({
    code: 'FOR-',
    title: '',
    completeTitle: '',
    version: '',
    clientCode: 'FOR-',
    clientVersion: '',
    samplesRequired: 1,
    filename: '',
    assignedUsers: [],
    versionComments: '',
  });

  const isFirstRender = useRef(true);

  const assignedUsersOpts = useMemo(() => {
    let formattedUsers = [];
    if (users.length) {
      users.forEach(user => {
        formattedUsers.push({ value: user.fullname, label: user.fullname })
      })
    }
    return formattedUsers
  }, [users]);

  const GetUsersData = useCallback(() => {

    setLoading(true);

    if (source) {
      source.cancel();
      source = CancelToken.source();
    }

    const opts = { cancelToken: source.token };
    const url = '/api/users/names';

    Axios.get(url, opts)
    .then((res) => {
      setUsers(res.data);
    })
    .catch((err) => {
      if (Axios.isCancel(err)) {
        alert.show('Petición cancelada', {type: 'info'});
      } else if (!err.response) {
        console.log('Petición cancelada');
      } else if (err.response.status === 500) {
        alert.show('No se recibió respuesta del servidor', {type: 'error'})
      } else {
        alert.show(err.response.data.msg, {type: 'error'})
      }
    }).finally(() => setLoading(false));
  }, [alert]);

  const HandleChange = (element, value) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const HandleArrChange = (value, element) => {
    let newState = {...form};
    newState[element] = value;
    setForm(newState);
  }

  const SaveNewRecord = () => {

    if ( form.clientCode && form.clientVersion && form.title && form.code && form.version && form.samplesRequired && form.filename && form.assignedUsers.length ) {
      
      setLoading(true);

      if (source) {
        source.cancel();
        source = CancelToken.source();
      }

      const newRecord = {
        code: form.code,
        title: form.title,
        completeTitle: form.completeTitle,
        version: form.version,
        clientCode: form.clientCode,
        clientVersion: form.clientVersion,
        samplesRequired: form.samplesRequired,
        assignedUsers: form.assignedUsers,
        versionComments: form.versionComments,
        status: 'activo'
      };

      const formData = new FormData();
      formData.append('record', JSON.stringify(newRecord));
      formData.append('filename', form.filename);

      let url = '/api/templates/add';

      Axios.post(url, formData, {cancelToken: source.token})
      .then((res) => {
        setLoading(false);
        alert.show(res.data.msg, {type: 'success'});
      })
      .catch((err) => {
        setLoading(false);
        if (Axios.isCancel(err)) {
          alert.show('Petición cancelada', {type: 'info'});
        } else if (!err.response) {
          console.log('Petición cancelada');
        } else if (err.response.status === 500) {
          alert.show('No se recibió respuesta del servidor', {type: 'error'})
        } else {
          alert.show(err.response.data.msg, {type: 'error'})
        }
      });
    } else {
      alert.show('Complete los campos requeridos', {type: 'info'});
    }
  }

  useEffect(() => {
    if (isFirstRender.current) {
      GetUsersData();
    }
  }, [GetUsersData]);

  useEffect(() => { isFirstRender.current = false }, []);

  return (
    <div className="modal-outer-container">
      <LoadingModal loading={loading}/>
      <div className="modal-inner-container">
        <ModalHeader title={'Nuevo Formulario'} closeModal={closeModal} />
        <div className="modal-form-container">
          <FormTextInput title="Código Informe Cliente" isRequired stateKey={'clientCode'} value={form.clientCode} handleChange={HandleChange} />
          <FormTextInput title="Versión Informe Cliente" isRequired stateKey={'clientVersion'} value={form.clientVersion} handleChange={HandleChange} />
          <FormTextInput title="Título" isRequired stateKey={'title'} value={form.title} handleChange={HandleChange} />
          <FormTextInput title="Título Completo" isRequired={false} stateKey={'completeTitle'} value={form.completeTitle} handleChange={HandleChange} />
          <FormTextInput title="Código Formulario Laboratorio" isRequired stateKey={'code'} value={form.code} handleChange={HandleChange} />
          <FormTextInput title="Versión Formulario Laboratorio" isRequired stateKey={'version'} value={form.version} handleChange={HandleChange} />
          <FormTextInput title="Número de Muestras" isRequired stateKey={'samplesRequired'} value={form.samplesRequired} handleChange={HandleChange} />
          <FormTextInput title="Comentarios para esta versión" isRequired={false} stateKey={'versionComments'} value={form.versionComments} handleChange={HandleChange} />
          <FormReactSelect title="Usuarios Autorizados" isRequired stateKey={'assignedUsers'} value={form.assignedUsers} isMulti items={assignedUsersOpts} handleChange={HandleArrChange} />
          <FormFileInput title="Plantilla (.xlsx, .xlsm)" isRequired accept=".xlsx, .xlsm" stateKey={'filename'} handleChange={HandleChange} />
        </div>
        <div>
          <Button className="lb-white fs-7" disabled={loading} onClick={() => SaveNewRecord()}>Guardar</Button>
        </div>
      </div>
    </div>
  )
}

export default TemplateModalAdd;
